import { FC, useMemo } from 'react'

import PaymentPlanPaymentList from 'components/payment-plans/payment-plan-payment-list'
import { Body, Portlet } from 'components/utilities'
import useList from 'hooks/useList'
import { listPaymentPlans, Request } from 'thunks/payment-plans/list'

import { PaymentPlan as PaymentPlanType } from './type'
// @ts-ignore
import { paymentPlanQuery } from './type?query'

type Props = {
  memberId: number
}

const PaymentPlan: FC<Props> = ({ memberId }) => {
  const [paymentPlans] = useList<PaymentPlanType, Request>(listPaymentPlans, {
    query: paymentPlanQuery,
    member_id: memberId,
  })

  const paymentPlan = useMemo(() => {
    if (!paymentPlans || paymentPlans.length === 0) {
      return
    }
    const paymentPlan = paymentPlans[0]
    if (paymentPlan.status !== 'approved') {
      return
    }
    return paymentPlan
  }, [paymentPlans])

  if (!paymentPlan) return null

  return (
    <Portlet boxed className="payment-plan">
      <div className="portlet-header">
        <h4 className="portlet-title">Your payment plan</h4>
      </div>
      <Body>
        <PaymentPlanPaymentList paymentPlanPayments={paymentPlan.payment_plan_payments} />
      </Body>
    </Portlet>
  )
}

export default PaymentPlan
