import asyncThunk from 'utils/asyncThunk'

export type Request = {
  password_reset: {
    code: string
    password: string
  }
}

export const usePasswordReset = asyncThunk<Request, any>({
  name: 'password-resets/use',
  uri: '/password_resets/0/use',
  method: 'PUT',
})
