import mixpanel from 'mixpanel-browser'

import { createAsyncThunk } from '@reduxjs/toolkit'

export type Request = Record<string, never>

export type Response = Record<string, never>

const logout = createAsyncThunk<Response, Request>('auth/logout', async () => {
  mixpanel.reset()

  return {}
})

export default logout
