import { DocumentFolderPurpose } from 'types/document-folder'
import asyncThunk from 'utils/asyncThunk'

export type Request = {
  query: string
  document_folder: {
    name: string
    purpose?: DocumentFolderPurpose
    document_folder_id?: number
    owner_type?: 'Organization' | 'Federation' | 'Group' | 'ExecutiveBoardPosition'
    owner_id?: number
  }
}

export const createDocumentFolder = asyncThunk<Request, any>({
  name: 'document-folders/create',
  uri: '/document_folders',
  method: 'POST',
})
