import classNames from 'classnames'
import { FC, ReactNode } from 'react'

type Props = {
  className?: string
  children?: ReactNode
}

const Content: FC<Props> = ({ children, className }) => (
  <div className={classNames('content', className)}>{children}</div>
)

export default Content
