import { FC, ReactElement, ReactNode } from 'react'

type Props = {
  fallback: ReactElement<any, any> | 'n/a'
  children?: ReactNode
}

const Fallback: FC<Props> = ({ fallback, children }) => {
  if (children) {
    return <span>{children}</span>
  }

  if (fallback === 'n/a') {
    return <span className="text-muted not-applicable"></span>
  }

  return fallback
}

export default Fallback
