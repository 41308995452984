import asyncThunk from 'utils/asyncThunk'

export type Request = {
  id: number
}

export const archiveTerm = asyncThunk<Request, void>({
  name: 'terms/archive',
  uri: ({ id }) => `/terms/${id}`,
  method: 'DELETE',
})
