export const contributionTrackerQuery = `
  contribution_tracker {
    id
    title
    description
    specificity
    purpose
    has_document_upload
    data {
      requirement
    }
    contributions {
      id
      amount
      details
      approved_at
      denied_at
      created_at
      document {
        mimetype
        url
      }
      creator {
        id
        first_name
        last_name
      }
    }
  }
`
export const contributionQuery = `
  contribution {
    id
    amount
    details
    approved_at
    denied_at
    created_at
    document {
      mimetype
      url
    }
    creator {
      id
      first_name
      last_name
    }
  }
`
