import moment from 'moment'
import { FC, useCallback, useEffect, useRef, useState } from 'react'
import { useAuthSelector } from 'store'

import * as FS from '@fullstory/browser'

import { useLocalStorage } from 'hooks/useLocalStorage'

import Item from './item'

const FullStory: FC = () => {
  const { user } = useAuthSelector()
  const [debugEndDate, setDebugEndDate] = useLocalStorage('fullstory')

  const [isActive, setIsActive] = useState(false)
  const fsRef = useRef(false)
  const initialize = useCallback(() => {
    if (!user) return
    if (fsRef.current) return

    fsRef.current = true

    FS.init({
      orgId: 'o-1K2WZ3-na1',
    })

    FS.setUserVars({
      uid: user.id,
      displayName: `${user.first_name} ${user.last_name}`,
      email: user.email,
      env: _env_.GCM_ENV,
    })

    const endDate = moment().add(2, 'days').toISOString()
    setDebugEndDate(endDate)

    setIsActive(true)
  }, [setDebugEndDate, user])

  useEffect(() => {
    if (!debugEndDate) return

    const date = moment(debugEndDate)
    if (date.isBefore(moment())) {
      setDebugEndDate('')
      return
    }

    initialize()
  }, [debugEndDate, setDebugEndDate, initialize])

  const handleClick = useCallback(() => {
    if (!isActive) initialize()
  }, [isActive, initialize])

  return (
    <Item icon="info-circle" onClick={handleClick}>
      {isActive ? 'Debugging in progress' : 'Start debugging session'}
    </Item>
  )
}

export default FullStory
