import { FC } from 'react'
import { Grid } from 'react-bootstrap'
import { Redirect, Route, Switch } from 'react-router-dom'

import Settings from 'components/settings'
import Content from 'components/utilities/content'
import useActiveSidebarItem from 'hooks/useActiveSidebarItem'
import MemberSettingsAccess from 'pages/Member/Settings/Access'
import MemberSettingsPassword from 'pages/Member/Settings/Password'
import MemberSettingsPaymentMethods from 'pages/Member/Settings/PaymentMethods'
import MemberSettingsProfile from 'pages/Member/Settings/Profile'

const MemberSettingsWrapper: FC = () => {
  useActiveSidebarItem('settings#user')

  return (
    <Content>
      <Grid>
        <Settings
          level={3}
          items={
            <>
              <Settings.Item icon="user" path="profile">
                My profile
              </Settings.Item>
              <Settings.Item icon="lock" path="password">
                Change password
              </Settings.Item>
              <Settings.Item icon="users" path="access">
                Parent access
              </Settings.Item>
              <Settings.Item icon="credit-card" path="payment-methods">
                Payment methods
              </Settings.Item>
            </>
          }
        >
          <Switch>
            <Redirect path="/member/settings" exact to="/member/settings/profile" />
            <Route path="/member/settings/profile" component={MemberSettingsProfile} />
            <Route path="/member/settings/password" component={MemberSettingsPassword} />
            <Route path="/member/settings/access" component={MemberSettingsAccess} />
            <Route path="/member/settings/payment-methods" component={MemberSettingsPaymentMethods} />
          </Switch>
        </Settings>
      </Grid>
    </Content>
  )
}

export default MemberSettingsWrapper
