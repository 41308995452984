import { FC, useCallback, useContext, useState } from 'react'
import { User } from 'resources'

import { ConfirmModal, InputModal } from 'components/modals'
import PlainInput from 'components/modals/plain-input'
import {
  Address, Body, Divider, Email, FaButton, Item, Phone, Portlet, SettingsDropdown,
} from 'components/utilities'
import LegacyContext from 'contexts/legacy'
import { useAPI } from 'hooks/useAPI'
import { closeModal, openModal } from 'hooks/useModal'
import useMorph from 'hooks/useMorph'
import { changeUserPassword } from 'thunks/users/change-password'
import { isAPIError } from 'utils/asyncThunk'
import notify from 'utils/notify'

import useArchiveParent from '../hooks/useArchiveParent'
import { Parent } from '../type'
import ParentBillDelivery, { DeliverySetting } from './bill-delivery'
import ParentsContext from './context'
import EditParentModal from './edit-parent-modal'

type Props = {
  parent: Parent
  greenDeliveryEnabled: boolean
  noParentsGetPhysicalBills: boolean
}

const ParentDetails: FC<Props> = ({ parent, greenDeliveryEnabled, noParentsGetPhysicalBills }) => {
  const { user } = useContext(LegacyContext)
  const { updateParent, archiveParent } = useContext(ParentsContext)
  const morph = useMorph(parent.user.id)

  const [archive] = useArchiveParent(parent.id, archiveParent)

  const handleDeliveryUpdate = useCallback(
    (setting: DeliverySetting, enabled: boolean) => {
      updateParent({
        ...parent,
        [setting]: enabled,
      })
    },
    [parent, updateParent]
  )

  const [change, { timer }] = useAPI(changeUserPassword)
  const [passwordError, setPasswordError] = useState<string>()
  const handleChangePassword = useCallback(
    async (password: string) => {
      try {
        await change({
          id: parent.user.id,
          user: {
            password,
          },
        })

        closeModal()
        notify('Password has been updated!')
      } catch (err) {
        if (isAPIError(err)) {
          setPasswordError(err.user.password)
        } else {
          setPasswordError(`Error: ${(err as any).error}`)
        }
      }
    },
    [change, parent.user.id]
  )

  const fullName = `${parent.user.first_name || ''} ${parent.user.last_name || ''}`
  const displayName = fullName.replaceAll(/ /g, '').length > 0 ? fullName : '[no name]'

  return (
    <>
      <Portlet className="portlet-posts portlet-with-table">
        <div className="portlet-header clearfix thin ">
          <h5 className="portlet-title">{displayName}</h5>
          <div className="pull-right">
            {user?.role === 'root' && (
              <FaButton icon="user-secret" onClick={() => morph()}>
                Morph
              </FaButton>
            )}
            &nbsp;
            <SettingsDropdown>
              <Item icon="pencil" onClick={openModal('EditParent', parent.id)}>
                Edit parent
              </Item>
              {user?.role === 'root' && (
                <Item icon="key" onClick={openModal('PlainInput', parent.user.id)}>
                  Edit password
                </Item>
              )}
              <Divider />
              <Item icon="ban" onClick={openModal('Confirm', parent.id)}>
                Remove parent
              </Item>
            </SettingsDropdown>
          </div>
        </div>

        <Body>
          <dl className="dl-horizontal">
            <dt>Email</dt>
            <dd>
              {parent.user.email ? (
                <Email className="no-style">{parent.user.email}</Email>
              ) : (
                <span onClick={openModal('EditParent', parent.id)} className="text-danger c-pointer">
                  add email address
                </span>
              )}
            </dd>
            <hr className="spacer-xs" />
            <dt>Phone number</dt>
            <dd>
              {parent.user.phone_number ? (
                <Phone className="no-style">{parent.user.phone_number}</Phone>
              ) : (
                <span onClick={openModal('EditParent', parent.id)} className="text-danger c-pointer">
                  add phone number
                </span>
              )}
            </dd>
            <hr className="spacer-xs" />
            <dt>Address</dt>
            <dd>
              {parent.user.address ? (
                <Address className="no-style" address={parent.user.address} />
              ) : (
                <span onClick={openModal('EditParent', parent.id)} className="text-danger c-pointer">
                  add address
                </span>
              )}
            </dd>
            <hr className="spacer-xs" />
            <div>
              <dt>Bill delivery</dt>
              <dd>
                <ParentBillDelivery
                  parent={parent}
                  noParentsGetPhysicalBills={noParentsGetPhysicalBills}
                  greenDeliveryEnabled={greenDeliveryEnabled}
                  onUpdate={handleDeliveryUpdate}
                />
              </dd>
            </div>
          </dl>
        </Body>
      </Portlet>

      <EditParentModal id={parent.id} parent={parent} onUpdate={updateParent} />

      <PlainInput
        id={parent.user.id}
        title="Update password"
        action="Update password"
        label="Password"
        error={passwordError}
        timer={timer}
        onSubmit={handleChangePassword}
      />

      <ConfirmModal
        id={parent.id}
        prompt="Are you sure you want to remove this parent?"
        yes="Remove parent"
        onConfirm={() => {
          archive()
          closeModal()
        }}
      />
    </>
  )
}

export default ParentDetails
