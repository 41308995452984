export const reimbursementQuery = `
  reimbursement {
    id
    status
    reason
    description
    amount
    method
    approved_at
    denied_at
    created_at
    document {
      id
      mimetype
      url
    }
    budget {
      id
      name
    }
    created_by {
      id
      first_name
      last_name
    }
    approved_by {
      id
      first_name
      last_name
    }
    denied_by {
      id
      first_name
      last_name
    }
    user {
      id
      first_name
      last_name
      member {
        id
      }
    }
  }
`
