import { FC, useMemo } from 'react'
import r from 'routes'

import { Money } from 'components/utilities'
import { useLegacyMember } from 'hooks/useLegacyContext'
import { Member } from 'types/user'

import Header from './header'
import Item from './item'

const MemberMenuGMoney: FC = () => {
  const { member } = useLegacyMember()

  const issuedCards = useMemo(
    () =>
      member.budgets.reduce((memo, budget) => {
        budget.issued_cards.forEach(card => {
          memo.push(card)
        })
        return memo
      }, [] as Member['budgets'][0]['issued_cards']),
    [member]
  )

  if (issuedCards.length === 0) {
    return null
  }

  return (
    <ul className="sidebar-menu">
      <Header>gMoney</Header>
      {issuedCards.map((card, idx) => (
        <Item key={idx} url={r.member.cards.show(card.id)} icon="credit-card">
          {card.name_on_card}

          <Money amount={card.balance} className="pull-right" />
        </Item>
      ))}
    </ul>
  )
}

export default MemberMenuGMoney
