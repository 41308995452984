import moment from 'moment'

import { DateRange } from 'slices/dateRange'

export const storeDateRange = (dateRange: DateRange) => {
  const formattedDateRange = {
    start: dateRange.start.format(),
    end: dateRange.end.format(),
  }

  const serializedDateRange = JSON.stringify(formattedDateRange)

  localStorage.setItem('dateRange', serializedDateRange)
}

export const restoreDateRange = (): DateRange | undefined => {
  const dateRange = localStorage.getItem('dateRange')
  if (!dateRange) return

  const deserializedDateRange = JSON.parse(dateRange)

  const parsedDateRange = {
    start: moment(deserializedDateRange.start),
    end: moment(deserializedDateRange.end),
  }

  return parsedDateRange
}
