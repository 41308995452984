import { connectWithDateRange } from 'helpers'
import { JSXElementConstructor } from 'react'
import { AppState } from 'store'

import { ParentState } from 'slices/parent'

import { ConnectWithDateRangeProps } from './date-range'

export type ConnectParentProps = ParentState & ConnectWithDateRangeProps

const connectParent = (Component: JSXElementConstructor<any>) => {
  function mapStateToProps(state: AppState) {
    return {
      ...state.parent,
    }
  }

  return connectWithDateRange(Component, mapStateToProps)
}

export default connectParent
