import { FC } from 'react'
import { Alert } from 'react-bootstrap'
import { Date } from 'components/utilities'
import useLegacyContext from 'hooks/useLegacyContext'

import { Reimbursement } from 'components/reimbursements/type'

type User = {
  id: number
  first_name: string
  last_name: string
}

type Props = {
  reimbursement: Reimbursement
}

const Status: FC<Props> = ({ reimbursement }) => {
  const { user } = useLegacyContext()

  const creator = reimbursement.created_by
  const reviewer = (reimbursement.approved_by ?? reimbursement.denied_by) as User
  const reviewedAt = (reimbursement.approved_at ?? reimbursement.denied_at) as string
  const createdAt = reimbursement.created_at

  return (
    <>
      {reimbursement.status === 'proposed' && (
        <Alert bsStyle="info">
          Proposed by {creator.first_name} {creator.last_name} on <Date date={createdAt} human />
        </Alert>
      )}

      {reimbursement.status === 'approved' && (
        <Alert bsStyle="success">
          Approved by {reviewer.first_name} {reviewer.last_name} on <Date date={reviewedAt} human />
        </Alert>
      )}

      {reimbursement.status === 'accepted' && (
        <Alert bsStyle="success">
          {user.id === reviewer.id ? 'Accepted by you' : `Accepted by ${reviewer.first_name} ${reviewer.last_name}`} on{' '}
          <Date date={reviewedAt} human />
        </Alert>
      )}

      {reimbursement.status === 'denied' && (
        <Alert bsStyle="danger">
          Denied by {reviewer.first_name} {reviewer.last_name} on <Date date={reviewedAt} human />
          <div className="m-t-05">
            {reimbursement.reason ? (
              <span>reason for denial: {reimbursement.reason}</span>
            ) : (
              <em>no denial reason given</em>
            )}
          </div>
        </Alert>
      )}

      {reimbursement.status === 'rejected' && (
        <Alert bsStyle="danger">
          {user.id === reviewer.id ? 'Rejected by you' : `Rejected by ${reviewer.first_name} ${reviewer.last_name}`} on{' '}
          <Date date={reviewedAt} human />
          <div className="m-t-05">
            {reimbursement.reason ? (
              <span>rejection reason: {reimbursement.reason}</span>
            ) : (
              <em>no rejection reason given</em>
            )}
          </div>
        </Alert>
      )}

      {reimbursement.status === 'pending' && (
        <Alert bsStyle="info">
          {user.id === creator.id ? 'Requested by you' : `Requested by ${creator.first_name} ${creator.last_name}`} on{' '}
          <Date date={createdAt} human />
        </Alert>
      )}
    </>
  )
}

export default Status
