import { ReactElement, ReactNode, useCallback, useEffect, useMemo } from 'react'
import Autocomplete from 'react-google-autocomplete'
import { ControllerRenderProps, FieldValues, Path, useFormContext } from 'react-hook-form'

import { Address } from 'types/address'

import ConnectedField from './connected-field'

export type Props<Fields extends FieldValues = FieldValues, Field extends Path<Fields> = Path<Fields>> = {
  label: string | ReactNode | false
  name: Field
  placeholder?: string
  inputSize?: number
  children?: (props: ControllerRenderProps<Fields, Field>) => ReactElement
}

function ConnectedAddress<Fields extends FieldValues = FieldValues, Field extends Path<Fields> = Path<Fields>>({
  label,
  name,
  placeholder,
  inputSize,
}: Props<Fields, Field>) {
  const { register, setValue, clearErrors, watch } = useFormContext<Fields>()
  const address = watch(name) as Address

  const addressStr = useMemo(
    () =>
      address?.address
        ? `${address.address}, ${address.city} ${address.state} ${address.postal_code}, ${address.country}`
        : '',
    [address]
  )

  useEffect(() => {
    if (JSON.stringify(address) === '{"address_2":""}') {
      // @ts-ignore
      setValue(name, undefined)
    }
  }, [name, address, setValue])

  const handlePlaceSelected = useCallback(
    (place: any) => {
      // @ts-ignore
      setValue(name, {
        ...address,
        ...parseToAddress(place),
      })
      clearErrors()
    },
    [name, address, setValue, clearErrors]
  )

  const handleClearAddress = useCallback(() => {
    // @ts-ignore
    setValue(name, undefined)
    // @ts-ignore
    setValue(`${name}.address_2`, undefined)
  }, [name, setValue])

  return (
    <div>
      <ConnectedField label={label} name={name} errorObjectKey="address" inputSize={inputSize}>
        <Autocomplete
          apiKey={import.meta.env.VITE_GOOGLE_PLACES_API_KEY}
          defaultValue={addressStr}
          placeholder={placeholder}
          options={{
            types: ['address'],
            componentRestrictions: {
              country: ['us', 'ca'],
            },
          }}
          className="form-control m-b-1"
          onChange={e => (e.target as HTMLInputElement).value.length === 0 && handleClearAddress()}
          onPlaceSelected={handlePlaceSelected}
          onKeyUp={e => (e.target as HTMLInputElement).value.length === 0 && handleClearAddress()}
        />

        {/* @ts-ignore */}
        <input {...register(`${name}.address_2`)} className="form-control" />
      </ConnectedField>
    </div>
  )
}

export default ConnectedAddress

function parseToAddress(place: any): Omit<Address, 'address_2'> {
  const parts = (place.address_components as any[]).reduce((memo, c) => {
    const type = _.find(c.types, t => t !== 'political')
    memo[type] = c.short_name
    return memo
  }, {} as Record<string, string>)

  if (!parts.street_number) throw new Error('NOT_EXACT')

  return {
    address: `${parts.street_number} ${parts.route}`,
    city: parts.locality || parts.sublocality,
    state: parts.administrative_area_level_1,
    postal_code: parts.postal_code,
    country: parts.country,
  }
}
