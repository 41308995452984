import { useMemo } from 'react'

import { Transaction } from './type'

type Payment = Omit<Transaction, 'payment'> & {
  payment: NonNullable<Transaction['payment']>
}

function isPayment(txn: Transaction): txn is Payment {
  return !!txn.payment
}

export default function useCollapsedTransactions(uncollapsedTransactions: Transaction[]): Transaction[] {
  return useMemo(() => {
    const transactions = _.cloneDeep(uncollapsedTransactions)

    const payments = _.chain(transactions)
      .filter('payment')
      .groupBy('payment.id')
      .map(txns => {
        if (txns.length === 1) {
          return txns[0]
        }

        if (!isPayment(txns[0])) throw new Error('Expected payment transaction')

        const { amount, description } = txns[0].payment

        return _.merge(txns[0], {
          amount: -amount,
          description,
        })
      })
      .value()

    const credits = _.chain(transactions)
      .filter({ txn_type: 'credit' })
      .groupBy((txn: Transaction) => `${txn.created_at.split(/T/)[0]}:${txn.description}`)
      .map(txns =>
        _.merge(txns[0], {
          amount: _.sumBy(txns, 'amount'),
        })
      )
      .value()

    const remaining = _.filter(transactions, t => !t.payment && t.txn_type !== 'credit')

    const filteredTransactions = _.concat(payments, credits, remaining)
    const sortedTransactions = _.sortBy(filteredTransactions, 'created_at')

    return sortedTransactions
  }, [uncollapsedTransactions])
}
