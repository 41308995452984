import moment from 'moment'
import { FC, useMemo } from 'react'
import { Button } from 'react-bootstrap'

import QuickPayModal from 'components/modals/quick-pay'
import { Body, Header, Money, Portlet } from 'components/utilities'
import { useLegacyMemberOrAdmin } from 'hooks/useLegacyContext'
import { openModal } from 'hooks/useModal'
import { Headers } from 'thunks/members/ledgers'

type Props = {
  balance: number
  headers: Headers
  onPayment: () => void
}

const Balance: FC<Props> = ({ balance, headers, onPayment }) => {
  const { member } = useLegacyMemberOrAdmin()

  const nextPaymentDue = useMemo(() => {
    if (!headers?.['x-next-payment-amount']) return

    return {
      amount: +headers?.['x-next-payment-amount'],
      due_on: moment(headers?.['x-next-payment-due-on']),
    }
  }, [headers])

  return (
    <>
      <Portlet boxed className="current-balance">
        <Header portlet>Current balance</Header>
        <Body>
          {nextPaymentDue && (
            <h5 className="text-center text-danger m-b-1 m-t-0 fs-125">
              <Money amount={nextPaymentDue.amount} /> due on {nextPaymentDue.due_on.format('MMMM Do')}
            </h5>
          )}

          <h3 className="text-center m-b-1">
            <Money amount={balance} plus />
          </h3>

          <Button onClick={openModal('QuickPay')} bsStyle="success" className="btn-jumbo" block>
            Quick pay
          </Button>
        </Body>
      </Portlet>

      <QuickPayModal member={member} balance={balance} onSuccess={onPayment} />
    </>
  )
}

export default Balance
