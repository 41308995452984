import { FC } from 'react'

import { Event } from '../../type'

type Props = {
  question: Event['event_questions'][number]
  value: string[]
  onChange: (val: string[]) => void
}

const CheckboxAnswer: FC<Props> = ({ question, value, onChange }) => {
  return (
    <ul className="list-unstyled">
      {question.options!.map((option, j) => (
        <li key={j} className="list-unstyled">
          <label className="form-check-label">
            <input
              name={question.question}
              type="checkbox"
              className="form-check-input"
              onChange={e => {
                if (e.target.checked) {
                  onChange([...(value || []), option])
                } else {
                  onChange(value.filter(val => val !== option))
                }
              }}
            />
            <span className="mt-1">{option}</span>
          </label>
        </li>
      ))}
    </ul>
  )
}

export default CheckboxAnswer
