export const eventQuery = `
  event {
    id
    name
    purpose
    owner_type
  }
`
export const reportQuery = `
  report {
    id
    title
  }
`
