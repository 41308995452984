import { useCallback, useEffect, useState } from 'react'

import { APIThunk, useAPI } from './useAPI'

// import { useWhatChanged } from '@simbathesailor/use-what-changed'

function useObject<Model, Req, Headers extends Record<string, string> | undefined = undefined>(
  thunk: APIThunk<Req, Model, Headers>,
  req: Req
) {
  const [retrieve, { timer }] = useAPI<Model, Req, Headers>(thunk)

  const [headers, setHeaders] = useState<Headers>()
  const [object, set] = useState<Model>()
  const [wasLoaded, setWasLoaded] = useState(false)

  const [memoizedReq, setMemoizedReq] = useState<Req>(req)
  useEffect(() => {
    setMemoizedReq(memoizedReq => {
      if (JSON.stringify(memoizedReq) === JSON.stringify(req)) return memoizedReq
      return req
    })
  }, [req])

  const reload = useCallback(async () => {
    setWasLoaded(false)
    try {
      const [data, headers] = await retrieve(memoizedReq)
      setHeaders(headers)
      set(data)
    } catch (err) {
      console.error(err)
    }
    setWasLoaded(true)
  }, [retrieve, memoizedReq])

  useEffect(() => {
    reload()
  }, [reload])

  const patch = useCallback(
    (data: Partial<Model>) => {
      if (!object) return

      set({
        ...object,
        ...data,
      })
    },
    [object, set]
  )

  // useWhatChanged(
  //   [object, headers, timer, set, reload, retrieve, memoizedReq],
  //   'object, headers, timer, set, reload, retrieve, memoizedReq'
  // )

  return [object, { headers, set, patch, timer, reload, wasLoaded }] as const
}

export default useObject
