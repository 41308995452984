import asyncThunk from 'utils/asyncThunk'

export type Request = {
  query: string
  id: number
}

export const rejectPaymentPlan = asyncThunk<Request, any>({
  name: 'payment-plans/reject',
  uri: ({ id }) => `/payment_plans/${id}/reject`,
  method: 'PUT',
})
