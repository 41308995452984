import { RefObject, useEffect } from 'react'

function useClickedOutside(ref: RefObject<HTMLElement>, onClick: () => void) {
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (!ref.current || !event.target) return
      if (!ref.current.contains(event.target as HTMLElement)) {
        onClick()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref, onClick])
}

export default useClickedOutside
