export const budgetQuery = `
  budget {
    id
    name
    starting_balance {
      amount
    }
    created_by {
      id
      first_name
      last_name
      member {
        id
      }
    }
    budget_expenses
  }
`
export const budgetExpenseQuery = `
  budget_expense {
    id
    description
    cost
    method
    code
    created_at
    created_by {
      id
      first_name
      last_name
    }
    document {
      id
      mimetype
      url
    }
    reimbursement {
      id
      amount
      description
      method
      created_at
      approved_at
      denied_at
      user {
        id
        first_name
        last_name
      }
      document {
        id
        mimetype
        url
      }
      budget {
        id
        name
      }
    }
  }
`
