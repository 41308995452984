import { useCallback, useMemo } from 'react'

import { DocumentFolder } from './type'

type Document = DocumentFolder['documents'][number]

export default function useManageDocuments(documents: Document[], setDocuments: (documents: Document[]) => void) {
  const handleAdd = useCallback(
    (document: Document) => {
      setDocuments([...documents, document])
    },
    [documents, setDocuments]
  )

  const handleUpdate = useCallback(
    (document: Document) => {
      setDocuments(documents.map(d => (d.id === document.id ? document : d)))
    },
    [documents, setDocuments]
  )

  const handleArchive = useCallback(
    (document: Document) => {
      setDocuments(documents.filter(d => d.id !== document.id))
    },
    [documents, setDocuments]
  )

  return useMemo(() => ({ handleAdd, handleUpdate, handleArchive }), [handleAdd, handleUpdate, handleArchive])
}
