import { FC, useCallback, useState } from 'react'
import { Modal, Radio } from 'react-bootstrap'
import { store } from 'store'

import { unwrapResult } from '@reduxjs/toolkit'

import { Field, Form, SubmitButton } from 'components/form'
import Uploader from 'components/uploader'
import modal from 'helpers/modal'
import { useLegacyMember } from 'hooks/useLegacyContext'
import { closeModal } from 'hooks/useModal'
import { uploadDocumentUrl } from 'thunks/documents/upload-url'
import notify from 'utils/notify'

import { Reimbursement } from './type'
// @ts-ignore
import { reimbursementQuery } from './type?query'

type Props = {
  budgetId?: number
  onSuccess?: (reimbursement: Reimbursement) => void
}

const RequestReimbursementModal: FC<Props> = ({ budgetId, onSuccess }) => {
  const user = useLegacyMember()
  const [s3Path, setS3Path] = useState<string>()

  const handleCreate = useCallback(
    ({ data }: { data: Reimbursement }) => {
      notify('Your reimbursement request has been submitted.')
      closeModal()
      onSuccess && onSuccess(data)
    },
    [onSuccess]
  )

  const handleUploadUrlCreate = useCallback(
    () => store.dispatch(uploadDocumentUrl({ organization_id: user.member.organization.id })).then(unwrapResult),
    [user.member.organization]
  )

  return (
    <Modal show onHide={closeModal}>
      <Form method="POST" action="/api/reimbursements" query={reimbursementQuery} onSuccess={handleCreate}>
        <Modal.Header closeButton>
          <Modal.Title>Request a reimbursement</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input type="hidden" name="reimbursement.user_id" value={user?.id} />
          <input type="hidden" name="reimbursement.budget_id" value={budgetId} />
          <input type="hidden" name="reimbursement.s3_path" value={s3Path || ''} />

          <hr className="spacer-xs" />

          <Field textarea name="reimbursement.description" label="Description" rows={4} inputSize={8} autoFocus />

          <Field name="reimbursement.amount" label="Amount" inputSize={5} money />

          <Field
            label={
              <span>
                Receipt <br />
                <span className="text-muted">(optional)</span>
              </span>
            }
            inputSize={8}
          >
            <div className="upload-wrapper">
              <Uploader
                noun="receipt"
                getS3Info={handleUploadUrlCreate}
                onSuccess={({ s3_path: s3Path }: { s3_path: string }) => setS3Path(s3Path)}
              />
            </div>
          </Field>

          <Field name="reimbursement.method" label="Reimbursement" noFeedbackControl inputSize={9}>
            <Radio name="reimbursement.method" value="credit" defaultChecked>
              Credit on my GCM balance
            </Radio>
            <Radio name="reimbursement.method" value="cash">
              Cash or check re-payment
            </Radio>
          </Field>
        </Modal.Body>
        <Modal.Footer>
          <SubmitButton offset={5}>Request reimbursement</SubmitButton>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default modal<Props>('RequestReimbursement', RequestReimbursementModal)
