import asyncThunk from 'utils/asyncThunk'

export type Request = {
  id: number
  calendar_event: {
    organization_id: number
  }
}

export const archiveCalendarEvent = asyncThunk<Request, any>({
  name: 'calendar-events/archive',
  uri: ({ id }) => `/calendar_events/${id}`,
  method: 'DELETE',
})
