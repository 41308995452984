import Humanize from 'humanize-plus'
import { ChangeEvent, FC, useCallback, useState } from 'react'

import ConnectedField from 'components/form/connected-field'
import objectKeys from 'utils/object-keys'

type Props = {
  balances?: Record<number, number>
  accounts: {
    id: number
    name: string
  }[]
  onChange: (amounts: Record<number, number>) => void
}

const Credit: FC<Props> = ({ balances, accounts, onChange }) => {
  const [, setCredits] = useState(() =>
    objectKeys(balances || {}).reduce((acc, id) => ({ ...acc, [id]: 0 }), {} as Record<number, number>)
  )

  const handleChange = useCallback(
    (accountId: number, value: string) => {
      const amount = value.replace(/[^0-9.]+/, '')

      setCredits(credits => {
        const updated = {
          ...credits,
          [accountId]: parseFloat(amount) || 0,
        }

        onChange(updated)
        return updated
      })
    },
    [onChange]
  )

  const getBalanceHint = useCallback(
    (accountId: number) => {
      if (!balances) return

      const balance = balances[accountId]
      if (balance === 0) return '$0 balance'

      const formatted = Humanize.formatNumber(Math.abs(balance), 2)
      return balance > 0 ? `$${formatted} balance` : `+$${formatted} credit`
    },
    [balances]
  )

  return (
    <div>
      <ConnectedField name="description" label="Description" autoFocus />

      <hr />

      {accounts.map(({ id, name }) => (
        <ConnectedField
          key={id}
          name={`amounts.${id}`}
          money
          label={name}
          hint={getBalanceHint(id)}
          onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(id, e.target.value)}
        />
      ))}
    </div>
  )
}

export default Credit
