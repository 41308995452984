import { FC } from 'react'

import VoteOption from './_VoteOption'
import s from './_VoteOption.module.scss'
import { PollOption } from './type'

type Props = {
  options: PollOption[]
  selectedOptionIds: number[]
  isMultiple: boolean
  onSelectImage: (id: number) => void
  onToggle: (id: number, checked: boolean) => void
}

const Options: FC<Props> = ({ options, selectedOptionIds, isMultiple, onSelectImage, onToggle }) => {
  return (
    <div className={s.VoteOptions}>
      {options.map(option => (
        <VoteOption
          key={option.id}
          option={option}
          isSelected={selectedOptionIds.includes(option.id)}
          isMultiple={isMultiple}
          onImageClick={() => onSelectImage(option.id)}
          onToggle={(checked: boolean) => onToggle(option.id, checked)}
        />
      ))}
    </div>
  )
}

export default Options
