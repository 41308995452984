import Container from 'container'
import moment from 'moment'
import { Button, Modal } from 'react-bootstrap'
import { HourRecord } from 'resources'
import { store } from 'store'

import { Date, Label, Loading, Name, Units } from 'components/utilities'
import modal from 'helpers/modal'
import { closeModal } from 'hooks/useModal'
import fetchRequiredActions from 'thunks/required-actions/fetch'

import { HourRecord as HourRecordType } from './type'
// @ts-ignore
import { hourRecordQuery } from './type?query'

type Props = {
  id?: number
  member: {
    id: number
    user: {
      id: number
      first_name: string
      last_name: string
    }
  }
  hourRequirementId: number
  onHourRecordApprove: (hourRecord: HourRecordType) => void
  onHourRecordDeny: (hourRecord: HourRecordType) => void
  onModalClose: (hasChanged: boolean) => void
}

type State = {
  hasChanged: boolean
}

type Resources = {
  list: HourRecordType
}

class HourRecordsModal extends Container<Props, State, Resources> {
  resources = {
    list: HourRecord,
  }

  get listScope() {
    const { hourRequirementId, member } = this.props

    return {
      hour_requirement_id: hourRequirementId,
      member_id: member.id,
    }
  }

  listQuery = hourRecordQuery

  sortListBy = (hourRecord: HourRecordType) => -moment(hourRecord.created_at).format('x')

  constructor(props: Props) {
    super(props, {
      hasChanged: false,
    })
  }

  doHourRecordApprove = (hourRecord: HourRecordType) => () => {
    HourRecord.approve({
      id: hourRecord.id,
      query: hourRecordQuery,
      onSuccess: ({ data }) => {
        this.listReplace({ data })
        this.props.onHourRecordApprove(data)
        store.dispatch(fetchRequiredActions({}))
        this.setState({ hasChanged: true })
      },
    })
  }

  doHourRecordDeny = (hourRecord: HourRecordType) => () => {
    HourRecord.deny({
      id: hourRecord.id,
      query: hourRecordQuery,
      onSuccess: ({ data }) => {
        this.listReplace({ data })
        this.props.onHourRecordDeny(data)
        store.dispatch(fetchRequiredActions({}))
        this.setState({ hasChanged: true })
      },
    })
  }

  doCloseModal = () => {
    const { onModalClose } = this.props
    const { hasChanged } = this.state
    onModalClose(hasChanged)
    closeModal()
  }

  renderState = (hourRecord: HourRecordType) => {
    if (hourRecord.hours < 0) {
      return <Label info>adjustment</Label>
    }
    if (hourRecord.approved_at) {
      return (
        <Label success>
          approved on <Date extraShort date={hourRecord.approved_at} />
        </Label>
      )
    }
    if (hourRecord.denied_at) {
      return (
        <Label danger>
          denied on <Date extraShort date={hourRecord.denied_at} />
        </Label>
      )
    }

    return (
      <div className="pull-right">
        <Button bsSize="sm" bsStyle="success" className="m-r-1" onClick={this.doHourRecordApprove(hourRecord)}>
          Approve
        </Button>
        <Button bsSize="sm" bsStyle="danger" className="m-r-1" onClick={this.doHourRecordDeny(hourRecord)}>
          Deny
        </Button>
      </div>
    )
  }

  render() {
    const { member } = this.props
    const hourRecords = this.getList()

    if (!hourRecords) return <Loading />

    const totalHours = _.chain(hourRecords)
      .filter(ht => !!ht.approved_at)
      .sumBy('hours')
      .value()

    return (
      <Modal show onHide={this.doCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            Hours by <Name user={member.user} natural />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {totalHours !== 0 ? (
            <h6 className="m-b-1 m-t-05">
              Total of <Units count={totalHours} noun="hour" /> approved
            </h6>
          ) : null}

          <table className="table table-striped">
            <tbody>
              {_.map(hourRecords, hourRecord => (
                <tr key={hourRecord.id}>
                  <td style={{ width: '70%' }}>
                    <div className="fw-semibold m-b-05">
                      <Units count={hourRecord.hours} noun="hour" />

                      {' recorded on '}

                      <Date short date={hourRecord.created_at} />

                      {' by '}

                      <Name user={hourRecord.created_by} />
                    </div>

                    {hourRecord.document ? (
                      <Button
                        bsSize="sm"
                        onClick={() => window.open(hourRecord.document?.url, '_blank')}
                        className="m-r-1"
                      >
                        documentation
                      </Button>
                    ) : null}

                    {hourRecord.description || <em className="text-muted">no description</em>}
                  </td>
                  <td className="text-center" style={{ width: '30%' }}>
                    {this.renderState(hourRecord)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {_.size(hourRecords) === 0 ? <h3 className="text-center text-muted">No hours recorded</h3> : null}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.doCloseModal} className="pull-left">
            close
          </Button>

          <Button bsStyle="secondary" onClick={this.openModal('AddHourAdjustment', member.id)}>
            Make an hour adjustment
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default modal<Props>('HourRecords', HourRecordsModal)
