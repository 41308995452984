import { FC } from 'react'
import { Field, Form, SubmitButton } from 'components/form'

import notify from 'utils/notify'
import useLegacyContext from 'hooks/useLegacyContext'

const UserPasswordForm: FC = () => {
  const { user } = useLegacyContext()

  const onUserPasswordUpdate = () => {
    notify("You've updated your password!")
  }

  return (
    <div className="tab-pane active">
      <div className="heading-block">
        <h3>Change password</h3>
      </div>

      <Form method="PUT" action={`/api/users/${user!.id}/password`} resetOnSuccess onSuccess={onUserPasswordUpdate}>
        <hr className="spacer-xs" />

        <Field type="password" name="user.password" label="New password" />
        <Field type="password" name="user.password_confirmation" label="Confirm password" />

        <hr />

        <SubmitButton>Update password</SubmitButton>
      </Form>
    </div>
  )
}

export default UserPasswordForm
