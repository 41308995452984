import Container from 'container'
import moment from 'moment'
import { ChangeEvent, MouseEvent } from 'react'
import { Checkbox, Modal, Radio } from 'react-bootstrap'
import { CalendarEvent, Group, Organization } from 'resources'

import { Field, Form, RemoteSelect, SubmitButton } from 'components/form'
import QuillEditor from 'components/quill'
import { FaButton } from 'components/utilities/font-awesome'
import LegacyContext, { LegacyContextType } from 'contexts/legacy'
import modal from 'helpers/modal'
import { closeModal, openModal } from 'hooks/useModal'
import { UnpersistedEvent } from 'pages/Member/Calendar'
import { CalendarEvent as Event } from 'thunks/calendar-events/list'
// @ts-ignore
import { calendarEventQuery } from 'thunks/calendar-events/list?query'
import { isFederation } from 'types/user-functions'
import notify from 'utils/notify'

import DateTimeSelector from './_date_time_selector'

type Props = {
  event: Event | UnpersistedEvent
  onEventSave: (event: Event) => void
}

class EventModal extends Container<Props> {
  declare context: LegacyContextType
  static contextType = LegacyContext

  constructor(props: Props) {
    super(props)

    const event = _.chain(props.event)
      .clone()
      .merge({
        start: moment(props.event.start),
        end: moment(props.event.end),
        purpose: 'everyone',
      })
      .value()

    this.state = {
      event,
      description: event.description,
      ticker: 0,
    }
  }

  onDateChange = (type: 'start' | 'end') => (strDate: string) => {
    const { event } = this.state

    const date = moment(strDate)
    event[type] = date

    if (type === 'start' && date.isAfter(event.end)) {
      event.end = date.clone().add(1, 'hour')
    } else if (type === 'end' && date.isBefore(event.start)) {
      event.start = date.clone().subtract(1, 'hour')
    }

    this.setState({ event })
  }

  doToggleAllDay = () => {
    const { event } = this.state
    event.all_day = !event.all_day
    this.setState({ event })
  }

  getDateTimeRange = () => {
    const { event } = this.state
    if (_.get(event, 'all_day')) {
      return {
        start: moment(event.start).format('YYYY-MM-DD'),
        end: moment(event.end).format('YYYY-MM-DD'),
      }
    }

    return {
      start: event.start.clone().utc().format(),
      end: event.end.clone().utc().format(),
    }
  }

  onModalClick = (e: MouseEvent<any>) => {
    if ((e.target as HTMLElement).closest('.date-picker')) return

    this.setState(state => ({
      ticker: state.ticker + 1,
    }))
  }

  resourceAction() {
    const { user } = this.context
    const {
      event: { id },
    } = this.state

    if (isFederation(user!)) {
      return id ? CalendarEvent.asyncUpdate : CalendarEvent.asyncCreate
    }

    return id ? CalendarEvent.update : CalendarEvent.create
  }

  requestData = () => {
    const {
      event: { id, purpose },
      description,
    } = this.state
    const { user, organization } = this.context

    let data: Partial<Event>

    if (isFederation(user!)) {
      if (id) {
        data = {}
      } else {
        switch (purpose) {
          case 'everyone':
            data = {
              owner_type: 'Group',
              owner_id: user!.federation!.alumni_group_id,
            }
            break
          case 'all_chapters':
            data = {
              owner_type: 'Federation',
              owner_id: user!.federation!.id,
            }
            break
          default:
            data = {}
        }
      }
    } else {
      data = {
        owner_type: 'Organization',
        owner_id: organization?.id,
        // @ts-ignore
        organization_id: organization?.id,
      }
    }

    data.description = description

    return {
      query: calendarEventQuery,
      calendar_event: _.merge(data, this.getDateTimeRange()),
    }
  }

  handlePurposeSelect = (e: ChangeEvent<Radio>) => {
    const { event } = this.state

    this.setState({
      event: { ...event, purpose: (e.target as any).value },
    })
  }

  renderEventPurposeRadio = (value: string, label: string) => (
    <Radio
      name="calendar_event.purpose"
      checked={this.state.event.purpose === value}
      onChange={this.handlePurposeSelect}
      value={value}
    >
      {label}
    </Radio>
  )

  render() {
    const { user } = this.context
    const { onEventSave } = this.props
    const { event, description, ticker } = this.state

    return (
      <Modal show onHide={closeModal} className="calendar-event-modal">
        <Form
          id={event.id}
          data={this.requestData()}
          action={this.resourceAction()}
          onSuccess={({ data }) => {
            notify('The event has been saved')
            closeModal()
            onEventSave(data)
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>{event.id ? 'Update event' : 'Add an event'}</Modal.Title>
          </Modal.Header>
          <Modal.Body onClick={this.onModalClick}>
            <hr className="spacer-xs" />

            <Field label="Event title" name="calendar_event.title" defaultValue={event.title} autoFocus inputSize={9} />

            <Field name="calendar_event.description" label="Event description" inputSize={9}>
              <QuillEditor
                onChange={this.updateStateSimple('description')}
                value={description || ''}
                // @ts-ignore
                bounds=".col-md-9"
              />
            </Field>

            {isFederation(user!) && (
              <div>
                {event.id === undefined && (
                  <Field label="Visibility" name="calendar_event.purpose">
                    {this.renderEventPurposeRadio('everyone', 'Everyone')}
                    {this.renderEventPurposeRadio('all_chapters', 'All chapters')}
                    {this.renderEventPurposeRadio('single_chapter', 'Specific chapter')}
                    {this.renderEventPurposeRadio('single_group', 'Specific group')}
                  </Field>
                )}
              </div>
            )}

            {event.purpose === 'single_chapter' && (
              <Field name="calendar_event.organization_id" label="Chapter">
                <input type="hidden" name="calendar_event.owner_type" value="Organization" />
                <RemoteSelect
                  resource={Organization}
                  name="calendar_event.owner_id"
                  scope={{ federation_id: user?.federation?.id }}
                  display="name"
                  selectedIds={[event.organization && event.organization.id]}
                  placeholder="select chapter&hellip;"
                  query="{
                    organization {
                      name
                      secondary
                    }
                  }"
                />
              </Field>
            )}

            {event.purpose === 'single_group' && (
              <Field name="calendar_event.owner_id" label="Group">
                <input type="hidden" name="calendar_event.owner_type" value="Group" />
                <RemoteSelect
                  resource={Group}
                  name="calendar_event.owner_id"
                  query="group { name }"
                  scope={{
                    owner_id: user?.federation?.id,
                    owner_type: 'Federation',
                  }}
                  display="name"
                  placeholder="select group&hellip;"
                />
              </Field>
            )}

            <Field label="Type" name="calendar_event.all_day" noFeedbackControl>
              <Checkbox
                id="all_day"
                name="calendar_event.all_day"
                checked={event.all_day}
                onChange={this.doToggleAllDay}
                className="text-near-black"
              >
                This is an all day event
              </Checkbox>
            </Field>

            <DateTimeSelector
              type="start"
              datetime={event.start}
              allDay={event.all_day}
              showCalendarTicker={ticker}
              onChange={this.onDateChange('start')}
            />

            <DateTimeSelector
              type="end"
              datetime={event.end}
              allDay={event.all_day}
              showCalendarTicker={ticker}
              onChange={this.onDateChange('end')}
            />
          </Modal.Body>
          <Modal.Footer>
            {event.id && (
              <div>
                <>
                  <FaButton icon="trash-o" className="pull-left" onClick={openModal('Confirm')} />
                  {/* @ts-ignore */}
                  {isFederation || <FaButton icon="google" href={event.link} target="_blank" className="pull-left" />}
                </>
              </div>
            )}

            <SubmitButton justButton className="pull-right">
              {event.id ? 'Save event' : 'Create event'}
            </SubmitButton>
          </Modal.Footer>
        </Form>
      </Modal>
    )
  }
}

export default modal<Props>('Event', EventModal)
