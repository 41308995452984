import React from 'react'
import PropTypes from 'prop-types'
import Container from 'container'

import { FormControl, FormGroup } from 'react-bootstrap'
import PlacesAutocomplete, { geocodeByPlaceId } from 'components/places-autocomplete'

const { func, object } = PropTypes

export default class NearLocationControl extends Container {
  static propTypes = {
    onChange: func.isRequired,
    errors: object,
  }

  constructor(props) {
    super(props, {
      location: props.location || {},
    })
  }

  onWithinChange = e => {
    const { value: within } = e.target

    this.setStateByDot('location.within', within)

    this.changeLocation()
  }

  onNearSelect = (addressStr, placeId) => {
    this.setStateByDot('location.near.value', addressStr)

    if (!placeId) {
      this.clearNear()
      return
    }

    geocodeByPlaceId(placeId).then(results => {
      const googleLocation = results[0].geometry.location

      this.setStateByDot('location.near.lat', googleLocation.lat())
      this.setStateByDot('location.near.lon', googleLocation.lng())

      this.changeLocation()
    })
  }

  clearNear = () => {
    const { location } = this.state

    delete location.near
    this.setState({ location })

    this.changeLocation()
  }

  changeLocation = () => {
    const { onChange } = this.props
    const { location } = this.state

    if (location.within || location.near) {
      onChange(location)
    } else {
      onChange(null)
    }
  }

  render() {
    const { location } = this.state
    const { errors } = this.props
    const locationNearErrors = _.get(errors, 'member.location.near')
    const locationWithinErrors = _.get(errors, 'member.location.within', [])
    const addressStr = _.get(location, 'near.value', '')

    return (
      <div className="location-form-groups">
        <FormGroup>
          <label htmlFor="within" className="control-label">
            Near address
          </label>
        </FormGroup>

        <FormGroup validationState={locationWithinErrors.length ? 'error' : null}>
          <label htmlFor="within" className="control-label text-muted">
            Within (in miles)
          </label>
          <FormControl
            id="within"
            type="text"
            defaultValue={location.within}
            onChange={this.onWithinChange}
          />
          {locationWithinErrors.map(error => (
            <span key={error} className="help-block">
              {error}
            </span>
          ))}
        </FormGroup>

        <FormGroup validationState={locationNearErrors ? 'error' : null}>
          <label htmlFor="near_location" className="control-label text-muted p-t-1">
            Address
          </label>
          <PlacesAutocomplete
            inputProps={{
              value: addressStr,
              onChange: value => this.setStateByDot('location.near.value', value),
              id: 'near_location',
              'data-coordinate-is-set': _.has(location, 'near.lat'),
            }}
            clearOnBlur
            value={addressStr}
            placeholder=""
            options={{
              types: ['address'],
              componentRestrictions: {
                country: ['us', 'ca'],
              },
            }}
            onSelect={this.onNearSelect}
            classNames={{ input: 'form-control' }}
          />
        </FormGroup>
      </div>
    )
  }
}
