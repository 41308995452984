import { FC } from 'react'
import { Grid } from 'react-bootstrap'

import rosterColumns from 'components/members-roster/_RosterColumns'
import Table from 'components/table'
import { Body, Content, EmptyState, Header, Loading, Portlet } from 'components/utilities'
import connect from 'helpers/connect'
import useActiveSidebarItem from 'hooks/useActiveSidebarItem'
import { useLegacyMember } from 'hooks/useLegacyContext'
import useList from 'hooks/useList'
import useObject from 'hooks/useObject'
import { listMembers, Request } from 'thunks/members/list'
import { Request as OrgRequest, retrieveOrganization } from 'thunks/organizations/retrieve'

import { Member, Organization } from './type'
// @ts-ignore
import { memberQuery, organizationQuery } from './type?query'

const MemberMembersIndex: FC = () => {
  useActiveSidebarItem('Roster')
  const { member } = useLegacyMember()

  const [organization] = useObject<Organization, OrgRequest>(retrieveOrganization, {
    id: member.organization.id,
    query: organizationQuery,
  })

  const [members] = useList<Member, Request>(listMembers, {
    query: memberQuery,
    organization_id: member.organization.id,
    not_in_collections: true,
  })

  if (!organization || !members) {
    return <Loading />
  }

  return (
    <Content>
      <Grid>
        <Header>Chapter Roster</Header>
        <Portlet boxed>
          <Body table>
            <Table
              csvName="Roster"
              data={members}
              noResults={<EmptyState>No members found</EmptyState>}
              csvAdditionalColumns={[
                {
                  header: 'Last name',
                  value: 'user.last_name',
                  position: 0,
                },
                {
                  header: 'First name',
                  value: 'user.first_name',
                  position: 1,
                },
              ]}
            >
              {rosterColumns({
                organization,
                admin: false,
                columnProps: { name: { dontDownload: true } },
              })}
            </Table>
          </Body>
        </Portlet>
      </Grid>
    </Content>
  )
}

export default connect(MemberMembersIndex)
