import { AppState } from 'store'

import { createAsyncThunk } from '@reduxjs/toolkit'

import apiRequest from 'utils/apiRequest'

export type Request = {
  id: number
}

export type Response = Record<string, never>

const morph = createAsyncThunk<Response, Request>('auth/morph', async ({ id }, api) => {
  const {
    auth: { user },
  } = api.getState() as AppState
  if (!user) throw new Error('no user for required actions')

  const { data } = await apiRequest<void, Response>({
    method: 'POST',
    uri: `/users/morph/${id}`,
  })

  const token = localStorage.getItem('token')
  localStorage.setItem('rootToken', token || '')
  localStorage.setItem('rootRole', user.role)
  localStorage.setItem('token', data.jwt)

  window.location.href = '/'

  return {}
})

export default morph
