import asyncThunk from 'utils/asyncThunk'

export type Request = {
  query: string
  id: number
}

export const retrievePayment = asyncThunk<Request, any>({
  name: 'payments/retrieve',
  uri: ({ id }) => `/payments/${id}`,
  method: 'GET',
})
