import { FC } from 'react'

type Props = {
  toggled: boolean
}

const MenuToggle: FC<Props> = ({ toggled }) => (
  <div className="menu-toggle-btn" data-toggled={toggled}>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
  </div>
)

export default MenuToggle
