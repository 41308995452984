import actionProps, { Actions } from 'actions'
import { ComponentClass, JSXElementConstructor } from 'react'
import { connect as _connect, ConnectedComponent, MapStateToPropsParam } from 'react-redux'
import { AppState } from 'store'

export type ConnectProps<T = {}> = T & {
  id?: number | string
} & Actions & {
  params: {
    id?: string
    page?: string
  }
  location: AppState['router']['location']
}

const connect = function <Props = any>(
  Component: JSXElementConstructor<any> | ComponentClass<any>,
  mapStateToProps?: MapStateToPropsParam<any, any, AppState>
) {
  const map = (state: AppState, ownProps: any) => {
    return {
      ...(mapStateToProps ? mapStateToProps(state, ownProps) : {}),
      params: ownProps.match ? ownProps.match.params : undefined,
      location: state.router.location,
    }
  }

  return _connect(map, actionProps, null, { pure: false })(Component) as ConnectedComponent<typeof Component, Props>
}

export default connect
