export const paymentQuery = `
  payment {
    id
    amount
    description
    external_ref
    created_at
    member {
      id
      user {
        id
        first_name
        last_name
      }
    }
  }
`
