import { useCallback, useEffect, useState } from 'react'

import { APIThunk, useAPI } from './useAPI'

type BaseItem = { id: number | string }
type BaseQuery = Record<string, any> & { query?: string }

function useList<
  Model extends BaseItem,
  Req extends BaseQuery,
  Headers extends Record<string, string> | undefined = undefined
>(thunk: APIThunk<Req, Model[], Headers>, req: Req) {
  const [list, { timer }] = useAPI<Model[], Req, Headers>(thunk)

  const [headers, setHeaders] = useState<Headers>()
  const [items, setItems] = useState<Model[]>()

  const [memoizedReq, setMemoizedReq] = useState<Req>(req)
  useEffect(() => {
    setMemoizedReq(memoizedReq => {
      if (JSON.stringify(memoizedReq) === JSON.stringify(req)) return memoizedReq
      return req
    })
  }, [req])

  useEffect(() => {
    list(memoizedReq).then(([data, headers]) => {
      setHeaders(headers)
      setItems(data)
    })
  }, [memoizedReq, list])

  const refetch = useCallback(() => {
    list(memoizedReq).then(([data, headers]) => {
      setHeaders(headers)
      setItems(data)
    })
  }, [memoizedReq, list])

  const add = useCallback((item: Model) => {
    setItems(items => {
      if (!items) return [item]
      return [...items, item]
    })
  }, [])

  const prepend = useCallback((item: Model) => {
    setItems(items => {
      if (!items) return [item]
      return [item, ...items]
    })
  }, [])

  const update = useCallback((item: Model) => {
    setItems(items => {
      if (!items) return items
      return items.map(i => (i.id === item.id ? item : i))
    })
  }, [])

  const upsert = useCallback(
    (item: Model) => {
      const i = items?.find(i => i.id === item.id)
      i ? update(item) : add(item)
    },
    [items, add, update]
  )

  const patch = useCallback((patch: Partial<Model> & { id: number | string }) => {
    setItems(items => {
      if (!items) return items
      return items.map(i => (i.id === patch.id ? { ...i, ...patch } : i))
    })
  }, [])

  const remove = useCallback((id: number) => {
    setItems(items => {
      if (!items) return items
      return items.filter(i => i.id !== id)
    })
  }, [])

  const clear = useCallback(() => {
    setItems(undefined)
  }, [])

  return [
    items,
    { headers, refetch, set: setItems, add, upsert, prepend, patch, update, remove, clear, timer },
  ] as const
}

export default useList
