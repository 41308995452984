import asyncThunk from 'utils/asyncThunk'

export type Request = {
  query: string
  contribution: {
    amount: number
    details?: string
    s3_path?: string
    payment_method_id?: number
    contribution_tracker_id: number
    owner_type: 'Member' | 'Organization'
    owner_id: number
  }
}

export const createContribution = asyncThunk<Request, any>({
  name: 'contributions/create',
  uri: '/contributions',
  method: 'POST',
})
