import { connectWithDateRange } from 'helpers'
import { FC, useCallback } from 'react'
import { Grid, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useParams } from 'react-router'

import { SubmitContributionModal } from 'components/contribution'
import {
  Action,
  ActionGroup,
  Body,
  Content,
  Date,
  ExpandableImage,
  FaButton,
  FaIcon,
  Header,
  Loading,
  Money,
  Name,
  Portlet,
  Units,
} from 'components/utilities'
import useActiveSidebarItem from 'hooks/useActiveSidebarItem'
import { useLegacyMemberOrAdmin } from 'hooks/useLegacyContext'
import { openModal } from 'hooks/useModal'
import useObject from 'hooks/useObject'
import { Request, retrieveContributionTracker } from 'thunks/contribution-trackers/retrieve'

import { Contribution, ContributionTracker } from './type'
// @ts-ignore
import { contributionTrackerQuery } from './type?query'

type RouteParams = {
  id: string
}

const MemberContributionTrackersShow: FC = () => {
  const user = useLegacyMemberOrAdmin()
  const params = useParams<RouteParams>()

  const [tracker, { patch }] = useObject<ContributionTracker, Request>(retrieveContributionTracker, {
    id: +params.id,
    query: contributionTrackerQuery,
    member_id: user.member.id,
    organization_id: user.member.organization?.id,
  })

  useActiveSidebarItem(tracker?.title || '')

  const handleContributionCreate = useCallback(
    (contribution: Contribution) => {
      if (!tracker) return

      const contributions = [...tracker.contributions, contribution]
      patch({ contributions })
    },
    [tracker, patch]
  )

  if (!tracker) {
    return <Loading />
  }

  const shouldShowSubmittedBy = tracker.specificity === 'member' && user.role === 'admin'
  const total = tracker.contributions.filter(c => !!c.approved_at).reduce((sum, c) => sum + c.amount, 0)

  return (
    <>
      <Content>
        <Grid>
          <Header
            actions={
              <ActionGroup>
                <Action
                  icon={tracker.purpose === 'money' ? 'money' : 'clock-o'}
                  onClick={openModal('SubmitContribution')}
                >
                  Submit contribution
                </Action>
              </ActionGroup>
            }
          >
            {tracker.title}
          </Header>

          <Portlet boxed>
            <Body>
              <div
                dangerouslySetInnerHTML={{
                  __html: tracker.description,
                }}
              />
            </Body>
          </Portlet>

          <Portlet boxed>
            <Header portlet>Contributions</Header>
            <Body>
              <table id="contributions" className="table">
                <thead>
                  <tr>
                    <th style={{ width: shouldShowSubmittedBy ? '25%' : '60%' }}>Date</th>
                    {shouldShowSubmittedBy && <th style={{ width: '35%' }}>Submitted by</th>}
                    <th style={{ width: '20%' }} className="text-right">
                      Status
                    </th>
                    <th style={{ width: '20%' }} className="text-right">
                      Contribution
                    </th>
                    {tracker.has_document_upload && <td />}
                  </tr>
                </thead>
                <tbody>
                  {tracker.contributions.length === 0 && (
                    <tr className="table-no-results">
                      <td colSpan={5}>
                        <p className="no-results-str">No contributions have been made yet</p>
                      </td>
                    </tr>
                  )}
                  {tracker.contributions.map((contribution, idx) => (
                    <tr key={idx} data-id={contribution.id}>
                      <td>
                        <Date date={contribution.created_at} />
                      </td>
                      {shouldShowSubmittedBy && (
                        <td>
                          <Name user={contribution.creator} full />
                        </td>
                      )}
                      <td className="text-right">
                        {(() => {
                          if (contribution.approved_at) {
                            return <strong className="text-success">approved</strong>
                          }
                          if (contribution.denied_at) {
                            return <span className="text-danger">denied</span>
                          }
                          return <strong className="text-warning">pending</strong>
                        })()}
                      </td>
                      <td className="text-right">
                        {contribution.details && (
                          <OverlayTrigger
                            placement="left"
                            overlay={
                              <Tooltip id="tooltip">
                                <pre className="unstyled">{contribution.details}</pre>
                              </Tooltip>
                            }
                          >
                            <FaIcon sticky-note className="text-warning m-r-05" />
                          </OverlayTrigger>
                        )}
                        {tracker.purpose === 'money' ? (
                          <Money amount={contribution.amount} />
                        ) : (
                          <Units count={contribution.amount} noun="hr" />
                        )}
                      </td>
                      {tracker.has_document_upload && (
                        <td className="text-right">
                          {contribution.document &&
                            (['image/jpeg', 'image/png', 'image/gif'].includes(contribution.document.mimetype) ? (
                              <ExpandableImage image={contribution.document.url} className="btn btn-default">
                                <FaIcon image />
                              </ExpandableImage>
                            ) : (
                              <FaButton
                                icon={contribution.document.mimetype === 'application/pdf' ? 'file-text-o' : 'download'}
                                bsStyle="default"
                                onClick={() => window.open(contribution.document!.url, '_blank')}
                              />
                            ))}
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
                <tfoot className="fw-semibold">
                  <tr>
                    <td colSpan={shouldShowSubmittedBy ? 3 : 2} className="text-right">
                      Approved total:
                    </td>
                    <td className="text-right">
                      {tracker.purpose === 'money' ? <Money amount={total} strip /> : <Units count={total} noun="hr" />}{' '}
                      of{' '}
                      {tracker.purpose === 'money' ? (
                        <Money amount={tracker.data.requirement} strip />
                      ) : (
                        <Units count={tracker.data.requirement} noun="hr" />
                      )}
                    </td>
                    {tracker.has_document_upload && <td />}
                  </tr>
                </tfoot>
              </table>
            </Body>
          </Portlet>
        </Grid>
      </Content>

      {user.member && (
        <SubmitContributionModal
          tracker={tracker}
          organizationId={user.member.organization.id}
          owner={
            tracker.specificity === 'organization'
              ? { type: 'Organization', id: user.member.organization.id }
              : { type: 'Member', id: user.member.id }
          }
          onCreate={handleContributionCreate}
        />
      )}
    </>
  )
}

export default connectWithDateRange(MemberContributionTrackersShow)
