import { FC, useCallback, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { FormProvider, useForm } from 'react-hook-form'

import Button from 'components/form/button'
import ConnectedField from 'components/form/connected-field'
import Uploader from 'components/uploader'
import modal from 'helpers/modal'
import { useAPI } from 'hooks/useAPI'
import { closeModal } from 'hooks/useModal'
import { createContribution, Request } from 'thunks/contributions/create'
import { organizationBillPayBalance } from 'thunks/organizations/billpay-balance'
import {
  ContributionTrackerPurpose, ContributionTrackerSpecificity,
} from 'types/contribution-tracker'
import formatMoney from 'utils/format-money'
import getS3UploadInfo from 'utils/getS3UploadInfo'
import notify from 'utils/notify'

import PaymentMethods from './_PaymentMethods'
import { Contribution } from './type'
// @ts-ignore
import { contributionQuery } from './type?query'

export type FormData = {
  amount: number
  details?: string
  s3_path?: string
  payment_method_id: number | undefined
}

type Props = {
  tracker: {
    id: number
    purpose: ContributionTrackerPurpose
    specificity: ContributionTrackerSpecificity
    has_document_upload: boolean
  }
  organizationId: number
  owner: {
    type: 'Member' | 'Organization'
    id: number
  }
  onCreate: (contribution: Contribution) => void
}

const SubmitContributionModal: FC<Props> = ({ tracker, organizationId, owner, onCreate }) => {
  const form = useForm<FormData>()

  const [billPayBalance, setBillPayBalance] = useState<number>()
  const [balance] = useAPI(organizationBillPayBalance)
  useEffect(() => {
    if (tracker.purpose !== 'money' || tracker.specificity !== 'organization') return

    balance({
      id: organizationId,
    }).then(([{ balance }]) => setBillPayBalance(balance))
  }, [organizationId, tracker, balance])

  const [create, { timer }] = useAPI<Contribution, Request>(createContribution)
  const handleSubmit = useCallback(
    async (data: FormData) => {
      const [contribution] = await create({
        query: contributionQuery,
        contribution: {
          ...data,
          payment_method_id: data.payment_method_id || 99999999,
          owner_type: owner.type,
          owner_id: owner.id,
          contribution_tracker_id: tracker.id,
        },
      })

      onCreate(contribution)
      notify('Your contribution has been submitted!')
      closeModal()
    },
    [tracker, owner, create, onCreate]
  )

  return (
    <Modal show onHide={closeModal}>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(handleSubmit)}>
          <Modal.Header closeButton>
            <Modal.Title>Submit a contribution</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <hr className="spacer-xs" />

            {tracker.purpose === 'money' ? (
              <ConnectedField
                name="amount"
                label="Contribution"
                money
                autoFocus
                hint={typeof billPayBalance === 'number' && `Current BillPay balance: ${formatMoney(billPayBalance)}`}
              />
            ) : (
              <ConnectedField name="amount" label="Hours" suffix="hours" autoFocus />
            )}

            <ConnectedField label="Details" name="details" placeholder="(optional)" textarea={4} />

            {tracker.has_document_upload && (
              <ConnectedField name="s3_path" label="Optional file" className="upload-wrapper" inputSize={9}>
                {({ onChange }) => (
                  <Uploader
                    noun="file"
                    getS3Info={getS3UploadInfo(organizationId)}
                    onSuccess={({ s3_path }) => onChange(s3_path)}
                  />
                )}
              </ConnectedField>
            )}

            {tracker.purpose === 'money' && tracker.specificity === 'member' && <PaymentMethods />}
          </Modal.Body>
          <Modal.Footer>
            <Button timer={timer}>Submit contribution</Button>
          </Modal.Footer>
        </form>
      </FormProvider>
    </Modal>
  )
}

export default modal<Props>('SubmitContribution', SubmitContributionModal)
