import { FC } from 'react'

import { useLegacyMember } from 'hooks/useLegacyContext'
import SharedSettingsPaymentMethods from 'pages/Shared/Settings/PaymentMethods'

const MemberSettingsPaymentMethods: FC = () => {
  const user = useLegacyMember()

  return <SharedSettingsPaymentMethods userId={user.id} />
}

export default MemberSettingsPaymentMethods
