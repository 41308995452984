import asyncThunk from 'utils/asyncThunk'

export type Request = {
  organization_id: number
}

export type CalendarEvent = {
  id: number
  title: string
  description?: string
  all_day: boolean
  start: string
  end: string
  owner_type: 'Group' | 'Organization' | 'Federation'
  owner_id: number
  purpose?: 'all_chapters' | 'everyone' | 'single_group' | 'single_chapter'
  link?: string
}

export const listCalendarEvents = asyncThunk<Request, CalendarEvent[]>({
  name: 'calendar-events/list',
  uri: '/calendar_events',
})
