export const eventQuery = `
  event {
    id
    name
    registration_closes_on
  }
`
export const eventRegistrationQuery = `
  event_registration {
    id
    created_at
    event {
      id
    }
  }
`
