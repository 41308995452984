import asyncThunk from 'utils/asyncThunk'

export type Request = {
  query: string
  id: number
}

export const retrieveMessageThread = asyncThunk<Request, any>({
  name: 'message-threads/retrieve',
  uri: ({ id }) => `/message_threads/${id}`,
})
