export const paymentMethodQuery = `
  payment_method {
    id
    company
    last_four
    method
    last_payment {
      id
      amount
      created_at
    }
  }
`
