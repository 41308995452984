import { Actions } from 'actions'
import { connectWithDateRange } from 'helpers'
import moment, { Moment } from 'moment'
import React, { MouseEvent } from 'react'
import { DropdownButton, MenuItem } from 'react-bootstrap'
import { AppState } from 'store'

import DateRangeModal from 'components/modals/date-range'
import { ConnectWithDateRangeProps } from 'helpers/date-range'
import { openModal } from 'hooks/useModal'
import { DateRange } from 'slices/dateRange'
import { Term } from 'slices/terms'

import styles from './selector.styles'

const isChildOf = (el: Element, className: string) => {
  while (el.tagName !== 'BODY') {
    if (el.classList.contains(className)) {
      return true
    }
    el = el.parentNode as Element
  }
  return false
}

type TermRanges = Record<
  string,
  {
    name: string
    start: Moment
    end: Moment
  }
>

type MapProps = {
  terms: Term[] | undefined
}

type OwnProps = {
  topAligned?: boolean
  rightAligned?: boolean
  halfYears?: boolean
  showAll?: boolean
  className?: string
}

type Props = Actions & ConnectWithDateRangeProps & MapProps & OwnProps

type State = {
  open: boolean
  termName?: string
}

class TermSelector extends React.Component<Props, State> {
  state = {
    open: false,
  }

  componentDidMount() {
    // @ts-ignore
    document.body.addEventListener('click', this.closeTermSelector)
  }

  componentWillUnmount() {
    // @ts-ignore
    document.body.removeEventListener('click', this.closeTermSelector)
  }

  closeTermSelector = (e: MouseEvent<Element>) => {
    if (this.state.open && !isChildOf(e.target as HTMLElement, 'popup-date-range')) {
      this.setState({ open: false })
    }
  }

  get readableDateRange() {
    const { dateRange, halfYears } = this.props
    const { start, end } = dateRange

    const todayOrFormat = (date: Moment, format: string) => {
      const formatted = date.format(format)
      const nowFormatted = moment().format(format)
      return formatted === nowFormatted ? 'Today' : formatted
    }

    const term = this.findTerm()
    if (term) {
      if (halfYears) {
        return term.name
      }
      return `${term.name} <span class="m-l-1" /> ${start.format('MMM D')} - ${todayOrFormat(end, 'MMM D')}`
    }

    const nowYear = moment().year()
    const format = start.year() === nowYear && end.year() === nowYear ? 'MMM D' : 'MMM D YYYY'

    return `${start.format(format)} - ${todayOrFormat(end, format)}`
  }

  get termRanges(): TermRanges {
    const { terms } = this.props

    return _.reduce(
      terms,
      (memo, term) =>
        _.merge(memo, {
          [term.name]: {
            name: term.name,
            start: moment(term.started_on),
            end: moment(term.ended_on).endOf('day'),
          },
        }),
      {} as TermRanges
    )
  }

  onDateRangeChange = (dateRange: DateRange, __: any, termName: string) => {
    const { halfYears, actions } = this.props

    if (!dateRange) return

    if (termName) {
      this.setState({ open: false, termName })
    }

    const func = halfYears ? 'setHalfYearDateRange' : 'setDateRange'
    actions[func]({
      start: dateRange.start.local(),
      end: dateRange.end.local(),
    })
  }

  findTerm = () => {
    const { terms, dateRange } = this.props
    const { start, end } = dateRange

    const theStart = start.format('YYYY-MM-DD')
    const theEnd = end.format('YYYY-MM-DD')

    return _.find(terms, term => theStart === term.started_on && theEnd === term.ended_on)
  }

  calendarContainerStyles = () => {
    const containerStyles = _.clone(styles.calendarContainer)

    if (this.props.topAligned) {
      _.assign(containerStyles, styles.calendarContainerTopAligned)
    }

    if (this.props.rightAligned) {
      _.assign(containerStyles, styles.calendarContainerRightAligned)
    }

    return containerStyles
  }

  handleSelector = (key: 'all-transactions' | 0 | string) => {
    if (key === 'all-transactions') {
      const keys = Object.keys(this.termRanges)
      const lastKey = keys[keys.length - 1]
      const start = this.termRanges[lastKey].start
      const end = this.termRanges[keys[0]].end
      this.onDateRangeChange({ start, end }, null, 'all-transactions')
    } else if (key === 0) {
      openModal('DateRange')()
    } else {
      this.onDateRangeChange(this.termRanges[key], null, key)
    }

    // @ts-ignore
    document.activeElement?.blur()
  }

  render = () => (
    <span className={this.props.className}>
      <DropdownButton
        title={<span dangerouslySetInnerHTML={{ __html: this.readableDateRange }} className="m-r-05" />}
        dropup={!this.props.topAligned}
        pullRight
        id="term-selector"
        // @ts-ignore
        onSelect={this.handleSelector}
        className="drop-button-no-style"
      >
        {_.chain(this.termRanges)
          .keys()
          .map((termName, i) => (
            <MenuItem key={i} eventKey={termName}>
              {termName}
            </MenuItem>
          ))
          .value()}

        <MenuItem divider />
        {this.props.showAll && <MenuItem eventKey="all-transactions">All Transactions</MenuItem>}
        <MenuItem eventKey={0}>Custom date range</MenuItem>
      </DropdownButton>

      <DateRangeModal
        onRangeChange={({ startDate, endDate }) => this.onDateRangeChange({ start: startDate, end: endDate }, null, '')}
        start={this.props.dateRange.start}
        end={this.props.dateRange.end}
        noRanges
      />
    </span>
  )
}

function mapStateToProps(state: AppState): MapProps {
  // if (ownProps.halfYears) {
  //   dateRange = state.halfYearDateRange.dateRange
  //   terms = state.halfYears
  // } else {
  const terms = state.terms.terms
  // }

  return {
    terms,
  }
}

export default connectWithDateRange(TermSelector, mapStateToProps)
