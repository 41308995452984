import { FC, ReactNode } from 'react'
// @ts-ignore
import Sortable from 'react-sortablejs'

type Props = {
  onDrop: (event: any) => void
  onChangeOrder?: (event: any) => void
  onCheckDrop?: (event: any) => void
  className?: string
  children: ReactNode
}

const Tbody: FC<Props> = ({ onChangeOrder, onCheckDrop, onDrop, children, className }) => {
  if (!onChangeOrder) {
    return <tbody className={className}>{children}</tbody>
  }

  const sortableProps = {
    tag: 'tbody',
    onChange: onDrop,
    options: {
      handle: '.drag-handle',
      dataIdAttr: 'data-id',
      // @ts-ignore
      onMove: onCheckDrop ? (event: any) => onCheckDrop(event.dragged, event.related) : nulundefined,
      // @ts-ignore
      onEnd: onCheckDrop ? () => onCheckDrop() : undefined,
    },
  }

  return <Sortable {...sortableProps}>{children}</Sortable>
}

export default Tbody
