import { FC } from 'react'
import EndedVotingContainer from 'components/voting/ended-container'
import { listPollsByUser, Request } from 'thunks/polls/list-by-user'
import useLegacyContext from 'hooks/useLegacyContext'
import useList from 'hooks/useList'

import { Poll } from 'components/voting/index-container/type'
// @ts-ignore
import { pollQuery } from 'components/voting/index-container/type?query'

const VotingEnded: FC = () => {
  const { user } = useLegacyContext()

  const [polls] = useList<Poll, Request>(listPollsByUser, {
    query: pollQuery,
    user_id: user.id,
  })

  return <EndedVotingContainer polls={polls} />
}

export default VotingEnded
