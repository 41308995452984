import moment, { Moment } from 'moment'

import { createSlice } from '@reduxjs/toolkit'

import { bootstrap } from 'thunks/auth/bootstrap'
import setDateRange from 'thunks/date-range/set'
import setTerms from 'thunks/terms/set'
import { isMember } from 'types/user-functions'

export type DateRange = {
  start: Moment
  end: Moment
}

export type DateRangeState = {
  dateRange?: DateRange
}

const initialState: DateRangeState = {}

const slice = createSlice({
  name: 'date-range',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(setDateRange.fulfilled, (_, { meta }) => ({ dateRange: meta.arg }))

    builder.addCase(setTerms.fulfilled, (_, { payload }) => {
      const dateRange = {
        start: moment(payload[0].started_on),
        end: moment(payload[0].ended_on).endOf('day'),
      }
      return { dateRange }
    })

    builder.addCase(bootstrap.fulfilled, (state, { payload }) => {
      if (state.dateRange) return state
      if (!isMember(payload.data)) return state

      return {
        dateRange: {
          start: moment(payload.data.member.organization.terms[0].started_on),
          end: moment().endOf('day'),
        },
      }
    })
  },
})

export default slice
export const actions = slice.actions
