import { isGranted } from 'helpers'

import { AnyUser } from 'types/user'

type MessageCategory = {
  owner_type: 'Organization' | 'Federation'
}

export default function canManage(user: AnyUser, category: MessageCategory): boolean {
  if (category.owner_type === 'Federation') {
    return user.role === 'federation'
  }

  if (category.owner_type === 'Organization') {
    return isGranted(user, 'Message Board')
  }

  return false
}
