import { forwardRef, useCallback, useImperativeHandle, useMemo, useState } from 'react'
import ReactBnbGallery from 'react-bnb-gallery'

import 'react-bnb-gallery/dist/style.css'

import { PollOption } from './type'

// @ts-ignore

export type ImagesRef = {
  selectImage: (id: number) => void
}

type Props = {
  options: PollOption[]
}

const Images = forwardRef<ImagesRef, Props>(({ options }, ref) => {
  useImperativeHandle(ref, () => ({
    selectImage: handleOpenImage,
  }))

  const images = useMemo(
    () =>
      options
        .filter(o => !!o.document)
        .map(o => ({
          id: o.id,
          photo: o.document!.url,
          caption: o.title,
        })),
    [options]
  )
  const [imageIdx, setImageIdx] = useState<number>()
  const handleOpenImage = useCallback(
    (id: number) => {
      if (!images) return

      const idx = images.findIndex(o => o.id === id)
      setImageIdx(idx)
    },
    [images]
  )

  return (
    <ReactBnbGallery
      show={typeof imageIdx === 'number'}
      activePhotoIndex={imageIdx}
      photos={images}
      onClose={() => setImageIdx(undefined)}
    />
  )
})

export default Images
