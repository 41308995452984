import { Feature, FederationFeature } from 'types/feature'
import { AnyUser } from 'types/user'

const isGranted = <T extends Feature | FederationFeature = Feature>(user: AnyUser, feature: T): boolean => {
  let features: T[]
  switch (user.role) {
    case 'member':
      features = user.member.data.all_features as T[]
      return features.indexOf(feature) !== -1
    case 'admin':
      return true
    case 'alumni':
      return false
    case 'federation':
      features = user.all_features as T[]
      return features.indexOf(feature) !== -1
    case 'root':
      return true
    case 'parent':
      return false
  }
}

export default isGranted
