import asyncThunk from 'utils/asyncThunk'

export type Request = {
  id: number
  query: string
  start_date?: string
  end_date?: string
}

export const retrieveBudget = asyncThunk<Request, any>({
  name: 'budgets/retrieve',
  method: 'GET',
  uri: ({ id }) => `/budgets/${id}`,
})
