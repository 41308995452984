import moment from 'moment'
import { FC, useCallback } from 'react'
import { Grid } from 'react-bootstrap'

import PaymentPlanForm from 'components/payment-plans/form'
import { Body, Content, Header, Loading, Money, Portlet } from 'components/utilities'
import { useAPI } from 'hooks/useAPI'
import useObject from 'hooks/useObject'
import { Request as RetrieveRequest, retrieveMemberNew as retrieveMember } from 'thunks/members/retrieve'
import { createPaymentPlan, Request } from 'thunks/payment-plans/create'
import notify from 'utils/notify'

import { PaymentPlan, UnsubmittedPaymentPlan } from './type'
// @ts-ignore
import { paymentPlanQuery } from './type?query'

type Props = {
  memberId: number
  onPaymentPlanCreate: (paymentPlan: PaymentPlan) => void
}

type Member = {
  data: {
    balance: number
  }
}

const PaymentPlansIndexForm: FC<Props> = ({ memberId, onPaymentPlanCreate: onPlanCreate }) => {
  const [member] = useObject<Member, RetrieveRequest>(retrieveMember, {
    query: `
      member {
        data {
          balance
        }
      }
    `,
    id: memberId,
  })

  const [create, { timer }] = useAPI<PaymentPlan, Request>(createPaymentPlan)
  const doPaymentPlanCreate = useCallback(
    async (paymentPlan: UnsubmittedPaymentPlan) => {
      if (!paymentPlan.payment_method) return

      const [data] = await create({
        query: paymentPlanQuery,
        payment_plan: {
          note: paymentPlan.note || null,
          payment_method_id: paymentPlan.payment_method.id,
          member_id: memberId,
          payment_plan_payments: paymentPlan.payment_plan_payments.map(({ amount, due_on }) => ({
            amount: +parseFloat(amount.toFixed(2)),
            due_on: moment(due_on).format('YYYY-MM-DD'),
          })),
        },
      })

      notify('Your payment plan has been submitted!')
      onPlanCreate(data)
    },
    [memberId, create, onPlanCreate]
  )

  if (!member) {
    return <Loading />
  }

  const balance = member.data.balance

  if (balance <= 0) {
    return (
      <Content>
        <Grid>
          <Header>Payment plan</Header>
          <Portlet boxed>
            <Body className="fs-125">
              You can only add a payment plan if you owe the chapter money.
              <br /> <br />
              Right now your balance is <Money amount={balance} />.
            </Body>
          </Portlet>
        </Grid>
      </Content>
    )
  }

  return (
    <Content>
      <Grid>
        <Header>Payment plan</Header>
        <Portlet boxed>
          <Body>
            <PaymentPlanForm balance={+balance} isLoading={timer.isLoading} doPaymentPlanCreate={doPaymentPlanCreate} />
          </Body>
        </Portlet>
      </Grid>
    </Content>
  )
}

export default PaymentPlansIndexForm
