import { FC, useState } from 'react'
import r from 'routes'

import { useLegacySuper } from 'hooks/useLegacyContext'

import DevMenu from './dev-menu'
import Header from './header'
import Item from './item'
import SalespersonMenu from './salesperson-menu'

const RootMenu: FC = () => {
  /*
   * This is to collapse the root menu for demos
   */
  const [show, setShow] = useState(true)
  const user = useLegacySuper()
  const isSuperDuper = [2, 3, 4].indexOf(user?.id || 0) > -1

  if (user.sales_permissions.length > 0) {
    return <SalespersonMenu />
  }

  if (!show) {
    return (
      <ul className="sidebar-menu">
        <Header>Super</Header>
        <li>
          <a onClick={() => setShow(!show)} className="c-pointer">
            <i className="fa fa-arrows-v"></i>Expand
          </a>
        </li>
      </ul>
    )
  }

  return (
    <>
      <ul className="sidebar-menu">
        <Header>Super</Header>
        <Item url={r.super.organizations.root} icon="list">
          Chapters
        </Item>
        <Item url={r.super.federations.root} icon="bank">
          Nationals
        </Item>
        <Item url={r.super.transactions.root} icon="money">
          Transactions
        </Item>
        <Item url={r.super.transfers.root} icon="exchange">
          Transfers
        </Item>
        <Item url={r.super.accounts.root} icon="table">
          Account balances
        </Item>
        <Item url={r.super.accounts.v2} icon="table">
          Balances V2
        </Item>
        <Item url={r.super.federationBalances.root} icon="table">
          National balances
        </Item>
        <Item url={r.super.billPay.pending} icon="usd" backgroundIcon="file">
          BillPay
        </Item>
        <Item url={r.super.gMoney.root} icon="credit-card">
          gMoney
        </Item>
        <Item url={r.super.collections.root} icon="gavel">
          Collections
        </Item>
        <Item url={r.super.taxes.root} icon="institution" id="Taxes">
          Tax information
        </Item>
        <Item url={r.super.endOfSemester.root} icon="book">
          End of semester
        </Item>
        {isSuperDuper && (
          <Item url={r.super.gcmFees.root} icon="thumbs-up">
            GCM Fees
          </Item>
        )}
        <Item url={r.super.messaging.root} icon="comment">
          Messaging
        </Item>
        <Item url={r.super.requiredDocuments.root} icon="file-text">
          Required documents
        </Item>
        <Item url={r.super.paymentPlans.root} icon="calculator">
          Failed payments plan
        </Item>
      </ul>

      <DevMenu />
    </>
  )
}

export default RootMenu
