import { FC, FormEvent, useState } from 'react'
import { Button, FormControl, FormGroup } from 'react-bootstrap'

import useLegacyContext from 'hooks/useLegacyContext'
import { UnsubmittedPaymentPlan } from 'pages/Shared/PaymentPlans/type'

type Props = {
  paymentPlan: UnsubmittedPaymentPlan
  goBack: () => void
  onNoteSelection: (note: string) => void
}

const Notes: FC<Props> = ({ paymentPlan, onNoteSelection, goBack }) => {
  const { user } = useLegacyContext()
  const [note, setNote] = useState(paymentPlan.note || '')

  const onNoteChange = (e: FormEvent<FormControl>) => {
    const note = (e.target as HTMLInputElement).value
    setNote(note)
  }

  return (
    <div>
      <h5>
        Include a note? &nbsp; <small className="text-muted">(optional)</small>
      </h5>

      <FormGroup>
        <FormControl
          name="note"
          value={note || ''}
          componentClass="textarea"
          rows={6}
          placeholder={
            user.role !== 'parent'
              ? 'My financial aid arrives in 2 weeks, I just bought the winning lottery ticket, etc.'
              : undefined
          }
          onChange={onNoteChange}
        />
      </FormGroup>

      <hr />
      <div>
        <Button bsStyle="secondary" className="pull-right" onClick={() => onNoteSelection(note)}>
          Next &raquo;
        </Button>

        <Button onClick={goBack}>Previous</Button>
      </div>
    </div>
  )
}

export default Notes
