import { useEffect } from 'react'
import ReactGA from 'react-ga4'
import { useHistory } from 'react-router-dom'

export default function useGoogleAnalytics() {
  const history = useHistory()

  useEffect(() => {
    if (import.meta.env.VITE_GCM_ENVIRONMENT !== 'production') return

    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname,
    })

    const unlisten = history.listen(() => {
      ReactGA.send({
        hitType: 'pageview',
        page: window.location.pathname,
      })
    })

    return () => {
      unlisten()
    }
  }, [history])
}
