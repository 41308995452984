import React, { ReactNode } from 'react'
import { Pagination } from 'react-bootstrap'

type Props = {
  activePage: number
  items: number
  maxButtons: number

  /**
   * When `true`, will display the first and the last button page when
   * displaying ellipsis.
   */
  boundaryLinks: boolean

  /**
   * When `true`, will display the default node value ('&hellip;').
   * Otherwise, will display provided node (when specified).
   */
  ellipsis: boolean | ReactNode

  /**
   * When `true`, will display the default node value ('&laquo;').
   * Otherwise, will display provided node (when specified).
   */
  first: boolean | ReactNode

  /**
   * When `true`, will display the default node value ('&raquo;').
   * Otherwise, will display provided node (when specified).
   */
  last: boolean | ReactNode

  /**
   * When `true`, will display the default node value ('&lsaquo;').
   * Otherwise, will display provided node (when specified).
   */
  prev: boolean | ReactNode

  /**
   * When `true`, will display the default node value ('&rsaquo;').
   * Otherwise, will display provided node (when specified).
   */
  next: boolean | ReactNode

  onSelect: (eventKey: number) => void
  className?: string
}

class PaginationComponent extends React.Component<Props> {
  static defaultProps = {
    activePage: 1,
    items: 1,
    maxButtons: 0,
    first: false,
    last: false,
    prev: false,
    next: false,
    ellipsis: true,
    boundaryLinks: false,
  }

  renderPageButtons(
    activePage: number,
    items: number,
    maxButtons: number,
    boundaryLinks: boolean,
    ellipsis: ReactNode
  ) {
    const pageButtons = []

    let startPage
    let endPage

    if (maxButtons && maxButtons < items) {
      startPage = Math.max(Math.min(activePage - Math.floor(maxButtons / 2), items - maxButtons + 1), 1)
      endPage = startPage + maxButtons - 1
    } else {
      startPage = 1
      endPage = items
    }

    for (let page = startPage; page <= endPage; ++page) {
      pageButtons.push(
        <Pagination.Item key={page} onClick={this.onBtnClick(page)} active={page === activePage}>
          {page}
        </Pagination.Item>
      )
    }

    if (ellipsis && boundaryLinks && startPage > 1) {
      if (startPage > 2) {
        pageButtons.unshift(<Pagination.Ellipsis key="ellipsisFirst" disabled />)
      }

      pageButtons.unshift(
        <Pagination.Item key={1} onClick={this.onBtnClick(1)} active={false}>
          1
        </Pagination.Item>
      )
    }

    if (ellipsis && endPage < items) {
      if (!boundaryLinks || endPage < items - 1) {
        pageButtons.push(<Pagination.Ellipsis key="ellipsis" disabled />)
      }

      if (boundaryLinks) {
        pageButtons.push(
          <Pagination.Item key={items} onClick={this.onBtnClick(items)} active={false}>
            {items}
          </Pagination.Item>
        )
      }
    }

    return pageButtons
  }

  onBtnClick = (eventKey: number) => () => {
    const { items, onSelect } = this.props

    if (eventKey > items || eventKey === 0) return

    onSelect(eventKey)
  }

  render() {
    const { activePage, items, maxButtons, boundaryLinks, ellipsis, first, last, prev, next, className } = this.props

    return (
      <Pagination className={className}>
        {first && <Pagination.First onClick={this.onBtnClick(1)} disabled={activePage === 1} />}
        {prev && <Pagination.Prev onClick={this.onBtnClick(activePage - 1)} disabled={activePage === 1} />}

        {this.renderPageButtons(activePage, items, maxButtons, boundaryLinks, ellipsis)}

        {next && <Pagination.Next onClick={this.onBtnClick(activePage + 1)} disabled={activePage >= items} />}
        {last && <Pagination.Last onClick={this.onBtnClick(items)} disabled={activePage >= items} />}
      </Pagination>
    )
  }
}

export default PaginationComponent
