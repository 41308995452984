import cx from 'classnames'
import Humanize from 'humanize-plus'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { Button, ButtonGroup, Modal } from 'react-bootstrap'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useLocation } from 'react-router-dom'

import * as Sentry from '@sentry/react'

import { Field } from 'components/form'
import { DatePickerModal } from 'components/modals'
import { Typeahead } from 'components/typeahead'
import modal from 'helpers/modal'
import { useAPI } from 'hooks/useAPI'
import useLegacyContext from 'hooks/useLegacyContext'
import { closeModal, openModal } from 'hooks/useModal'
import { createCharges, Request as ChargeRequest } from 'thunks/transactions/create-charges'
import { createCredits, Request as CreditRequest } from 'thunks/transactions/create-credits'
import { createPayments, Request as PaymentRequest } from 'thunks/transactions/create-payments'
import { isAPIError, processValidationErrors } from 'utils/asyncThunk'
import objectKeys from 'utils/object-keys'

import Charge from './charge'
import Credit from './credit'
import Payment from './payment'
import ToggleButton from './toggle-button'
import { Transaction } from './type'
// @ts-ignore
import { transactionQuery } from './type?query'

export type FormData = {
  txn_type: 'charge' | 'credit' | 'payment'
  description: string
  amount?: string
  amounts?: Record<number, string>
  account_id: number
  member_ids: number[]
  due_on?: string
  late_fee?: number
}

type Account = {
  id: number
  name: string
  weight: number
  scope: 'user' | 'organization'
}

function remapAmounts(amounts: Record<string, string>): Record<number, number> {
  const accountIds = objectKeys(amounts)
  return accountIds.reduce((memo, id) => {
    return {
      ...memo,
      [id]: +amounts[id],
    }
  }, {})
}

type Props = {
  memberId?: number // If memberId is set, that means it's only for that member
  memberIds: number[]
  organizationId: number
  accounts: Account[]
  accountId?: number
  members?: { id: number }[]
  balances?: Record<number, number>
  onTransactionCreate: (txns: Transaction[]) => void
}

const AddTransactionModal: FC<Props> = ({
  memberId,
  memberIds,
  members,
  organizationId,
  accountId,
  accounts: allAccounts,
  balances,
  onTransactionCreate,
}) => {
  const { user } = useLegacyContext()

  const [creditAmounts, setCreditAmounts] = useState<Record<number, number>>({})
  const [showMoreOptions, setShowMoreOptions] = useState(false)

  const accounts = useMemo(
    () =>
      allAccounts
        .sort((a, b) => a.weight - b.weight)
        .filter((a, idx) => a.scope === 'user' || idx === allAccounts.length - 1),
    [allAccounts]
  )

  const form = useForm<FormData>({
    shouldUnregister: false,
    defaultValues: {
      txn_type: 'charge',
      member_ids: memberId ? [memberId] : memberIds,
    },
    reValidateMode: 'onBlur',
  })

  useEffect(() => {
    if (!accounts) return
    form.setValue('account_id', accountId ?? accounts[accounts.length - 1].id)
  }, [accountId, accounts, form])

  const [charges] = useAPI<Transaction[], ChargeRequest>(createCharges)
  const [credits] = useAPI<Transaction[], CreditRequest>(createCredits)
  const [payments] = useAPI<Transaction[], PaymentRequest>(createPayments)
  const [isLoading, setIsLoading] = useState(false)
  const onSubmit = useCallback(
    async (transaction: FormData) => {
      try {
        setIsLoading(true)
        let transactions: Transaction[] = []
        switch (transaction.txn_type) {
          case 'charge':
            const [_charges] = await charges({
              query: transactionQuery,
              transaction: {
                ...transaction,
                amount: +transaction.amount!,
                organization_id: organizationId,
              },
            })
            transactions = _charges
            break
          case 'credit':
            if (!transaction.amounts) throw new Error('No amounts provided')

            const [_credits] = await credits({
              query: transactionQuery,
              transaction: {
                ...transaction,
                amounts: remapAmounts(transaction.amounts),
                organization_id: organizationId,
              },
            })
            transactions = _credits
            break
          case 'payment':
            const [_payments] = await payments({
              query: transactionQuery,
              transaction: {
                ...transaction,
                amount: +transaction.amount!,
                organization_id: organizationId,
              },
            })
            transactions = _payments
            break
        }
        onTransactionCreate(transactions)
        closeModal()
      } catch (err) {
        console.log('[ADD TRANSACTION ERROR]', err)
        Sentry.captureException(err)
        if (isAPIError(err) && err.transaction) {
          processValidationErrors('transaction', err as any, form.setError)
        }
      } finally {
        setIsLoading(false)
      }
    },
    [charges, credits, payments, organizationId, form.setError, onTransactionCreate]
  )

  const location = useLocation()
  const isDemo = useMemo(() => {
    const isMorphed = localStorage.getItem('rootToken') !== null
    if (isMorphed) return false
    return organizationId === 1 && location.pathname === '/admin/members'
  }, [organizationId, location])

  const creditSum = useMemo(
    () => objectKeys(creditAmounts).reduce((sum, id) => sum + creditAmounts[id], 0),
    [creditAmounts]
  )

  const type = form.watch('txn_type')

  return (
    <div>
      <Modal show onHide={closeModal}>
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <Modal.Header closeButton>
              <Modal.Title>Add a transaction</Modal.Title>
            </Modal.Header>
            <Modal.Body className="form-horizontal">
              <hr className="spacer-xs" />

              {memberId ? (
                <input {...form.register('member_ids')} type="hidden" value={memberId} />
              ) : (
                <Field name="member_ids" label="Members" inputSize={9}>
                  <Controller
                    control={form.control}
                    name="member_ids"
                    render={({ field: { value, onChange } }) => (
                      <Typeahead
                        multiple
                        labelKey="name"
                        placeholder="Select members..."
                        multiplePlaceholder="Select members..."
                        options={members || []}
                        selected={(members || []).filter(m => value.includes(m.id))}
                        onChange={(members: { id: number }[]) => onChange(members.map(m => m.id))}
                      />
                    )}
                  />
                </Field>
              )}

              <Field name="txn_type" label="Type">
                <input type="hidden" {...form.register('txn_type')} value={type} />
                <ButtonGroup id="txn_type">
                  <ToggleButton isActive={type === 'charge'} onClick={() => form.setValue('txn_type', 'charge')}>
                    Charge
                  </ToggleButton>
                  <ToggleButton isActive={type === 'credit'} onClick={() => form.setValue('txn_type', 'credit')}>
                    Credit
                  </ToggleButton>

                  {user?.role === 'root' && (
                    <Button onClick={openModal('ChargeCreditCard', memberIds[0], false)} bsStyle="default">
                      Credit card
                    </Button>
                  )}

                  {user?.role === 'root' && (
                    <Button onClick={openModal('RecordCheck', memberIds[0], false)} bsStyle="default">
                      Check
                    </Button>
                  )}

                  {user?.role !== 'root' && (
                    <ToggleButton isActive={type === 'payment'} onClick={() => form.setValue('txn_type', 'payment')}>
                      Payment
                    </ToggleButton>
                  )}
                </ButtonGroup>
              </Field>

              {accounts && (
                <>
                  {type === 'charge' && (
                    <Charge accountId={accountId} accounts={accounts} showMoreOptions={showMoreOptions} />
                  )}

                  {type === 'credit' && <Credit balances={balances} accounts={accounts} onChange={setCreditAmounts} />}

                  {type === 'payment' && <Payment accounts={allAccounts} />}
                </>
              )}
            </Modal.Body>
            <Modal.Footer>
              {type === 'charge' && (
                <Button onClick={() => setShowMoreOptions(!showMoreOptions)} className="pull-left">
                  {showMoreOptions ? 'Fewer' : 'More'} options
                </Button>
              )}

              {isDemo ? (
                <Button type="submit" disabled bsStyle="primary">
                  Disabled in Demo
                </Button>
              ) : (
                <Button
                  type="submit"
                  disabled={isLoading}
                  bsStyle="primary"
                  className={cx({
                    'btn-loading': isLoading,
                  })}
                >
                  {type === 'credit' && creditSum > 0
                    ? `Add $${Humanize.formatNumber(creditSum, 2)} credit`
                    : `Add ${type}`}
                </Button>
              )}
            </Modal.Footer>
          </form>
        </FormProvider>
      </Modal>

      <DatePickerModal onDateSelected={date => form.setValue('due_on', date?.format('YYYY-MM-DD'))} />
    </div>
  )
}

export default modal<Props>('AddTransaction', AddTransactionModal)
