import asyncThunk from 'utils/asyncThunk'

export type Request = {
  query: string
  user_id?: number
  start_date?: string
  end_date?: string
}

export const listPaymentMethods = asyncThunk<Request, any>({
  name: 'payment-methods/list',
  uri: '/payment_methods',
  method: 'GET',
})
