import moment from 'moment'
import { useMemo } from 'react'

import useList from 'hooks/useList'
import { listPaymentMethods, Request } from 'thunks/payment-methods/list'

import { PaymentMethod } from './type'
// @ts-ignore
import { paymentMethodQuery } from './type?query'

export default function usePaymentMethods(userId: number) {
  const [unsortedPaymentMethods, { prepend, add }] = useList<PaymentMethod, Request>(listPaymentMethods, {
    query: paymentMethodQuery,
    user_id: userId,
  })

  const paymentMethods = useMemo(() => {
    if (!unsortedPaymentMethods) return

    return _.sortBy(unsortedPaymentMethods, paymentMethod => {
      if (paymentMethod.method === 'bank') {
        return Infinity
      }
      const txn = paymentMethod.last_payment
      if (!txn) {
        return 0
      }
      const timestamp = moment(txn.created_at).format('x')
      return -timestamp
    })
  }, [unsortedPaymentMethods])

  return [paymentMethods, { prepend, add }] as const
}
