import asyncThunk from 'utils/asyncThunk'

export type Request = {
  id: number
  query: string
  reimbursement: {
    method?: 'cash' | 'credit'
  }
}

export const acceptReimbursement = asyncThunk<Request, any>({
  name: 'reimbursements/accept',
  uri: ({ id }) => `/reimbursements/${id}/accept`,
  method: 'PUT',
})
