import moment, { Moment } from 'moment'
import PropTypes from 'prop-types'
import React, { Component, ReactNode } from 'react'

import Calendar from './Calendar'
import PredefinedRanges from './PredefinedRanges'
import getTheme, { defaultClasses } from './styles'
import parseInput from './utils/parseInput'

type DateRangeType = {
  startDate: Moment
  endDate: Moment
}

type Props = {
  startDate: Moment
  endDate: Moment
  calendars?: number
  minDate?: Moment
  maxDate?: Moment
  format?: string
  linkedCalendars?: boolean
  theme?: any
  ranges?: Record<string, Record<keyof DateRangeType, (now: Moment) => Moment>>
  onInit: (range: DateRangeType) => void
  onChange: (range: DateRangeType) => void
}

type State = {
  range: DateRangeType
  link: Moment
  styles: any
}

class DateRange extends Component<Props, State> {
  constructor(props: Props) {
    super(props)

    const { format, linkedCalendars, theme } = props

    const startDate = parseInput(props.startDate, format, 'startOf')
    const endDate = parseInput(props.endDate, format, 'endOf')

    this.state = {
      range: { startDate, endDate },
      // @ts-ignore
      link: linkedCalendars && endDate,
      styles: getTheme(theme),
    }
  }

  componentDidMount() {
    const { onInit } = this.props
    onInit && onInit(this.state.range)
  }

  orderRange(range: DateRangeType) {
    const { startDate, endDate } = range
    const swap = startDate.isAfter(endDate)

    if (!swap) return range

    return {
      startDate: endDate,
      endDate: startDate,
    }
  }

  setRange(range: DateRangeType) {
    const { onChange } = this.props
    range = this.orderRange(range)

    this.setState({ range })

    onChange && onChange(range)
  }

  handleSelect = (identifier?: number) => (date: Moment | DateRangeType) => {
    // A pre-defined range was set
    // @ts-ignore
    if (date.startDate && date.endDate) {
      return this.setRange(date as DateRangeType)
    }

    let { startDate, endDate } = this.state.range

    if (identifier === 0) {
      startDate = date as Moment
    } else {
      endDate = date as Moment
    }

    const range = { startDate, endDate }

    this.setRange(range)
  }

  handleLinkChange(direction: number) {
    const { link } = this.state

    this.setState({
      link: link.clone().add(direction, 'months'),
    })
  }

  UNSAFE_componentWillReceiveProps(newProps: Props) {
    const format = newProps.format || this.props.format
    const startDate = newProps.startDate && parseInput(newProps.startDate, format, 'startOf')
    const endDate = newProps.endDate && parseInput(newProps.endDate, format, 'endOf')
    const oldStartDate = this.props.startDate && parseInput(this.props.startDate, format, 'startOf')
    const oldEndDate = this.props.endDate && parseInput(this.props.endDate, format, 'endOf')

    // Whenever date props changes, update state with parsed variant
    if (newProps.startDate || newProps.endDate) {
      // @ts-ignore
      if (!startDate.isSame(oldStartDate) || !endDate.isSame(oldEndDate)) {
        this.setRange({
          startDate: startDate || oldStartDate,
          endDate: endDate || oldEndDate,
        })
      }
    }
  }

  isSameMonth(dateOne: Moment, dateTwo: Moment) {
    return dateOne.clone().startOf('month').isSame(dateTwo.clone().startOf('month'))
  }

  isThisMonth(date: Moment) {
    return moment().startOf('month').isSame(date.clone().startOf('month'))
  }

  render() {
    // @ts-ignore
    // eslint-disable-next-line
    const { ranges, format, linkedCalendars, style, calendars, firstDayOfWeek, minDate, maxDate, classNames, onlyClasses, lang, disableDaysBeforeToday, offsetPositive, shownDate, showMonthArrow, } = this.props
    const { range, link, styles } = this.state

    const classes = { ...defaultClasses, ...classNames }

    const calendarMonths: Moment[] = []
    if (!this.isSameMonth(range.startDate, range.endDate)) {
      // 1. months are different (show each)
      calendarMonths.push(range.startDate.clone())
      calendarMonths.push(range.endDate.clone())
    } else if (this.isSameMonth(range.startDate, range.endDate)) {
      // 2. months are same and months is same as maxDate (show last month and maxDate month)
      calendarMonths.push(range.startDate.clone().subtract(1, 'month'))
      calendarMonths.push(range.endDate.clone())
    } else {
      // 3. everything else (show last month and this month)
      calendarMonths.push(moment().clone().subtract(1, 'month'))
      calendarMonths.push(moment().clone())
    }

    return (
      <div style={onlyClasses ? undefined : { ...styles['DateRange'], ...style }} className={classes.dateRange}>
        {ranges && (
          <PredefinedRanges
            format={format}
            ranges={ranges}
            range={range}
            theme={styles}
            onSelect={this.handleSelect()}
            onlyClasses={onlyClasses}
            classNames={classes}
          />
        )}

        <div className="rdr-Wrapper">
          {(() => {
            const _calendars: ReactNode[] = []
            _.times(2, i => {
              _calendars.push(
                <Calendar
                  showMonthArrow={showMonthArrow}
                  shownDate={calendarMonths[i]}
                  disableDaysBeforeToday={disableDaysBeforeToday}
                  lang={lang}
                  key={i}
                  link={linkedCalendars && link}
                  linkCB={this.handleLinkChange.bind(this)}
                  range={range}
                  format={format}
                  firstDayOfWeek={firstDayOfWeek}
                  theme={styles}
                  minDate={minDate}
                  maxDate={maxDate}
                  onlyClasses={onlyClasses}
                  classNames={classes}
                  onChange={this.handleSelect(i)}
                />
              )
            })
            return _calendars
          })()}
        </div>
      </div>
    )
  }
}

// @ts-ignore
DateRange.defaultProps = {
  linkedCalendars: false,
  theme: {},
  format: 'DD/MM/YYYY',
  calendars: 2,
  onlyClasses: false,
  offsetPositive: false,
  classNames: {},
}

// @ts-ignore
DateRange.propTypes = {
  format: PropTypes.string,
  firstDayOfWeek: PropTypes.number,
  calendars: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  startDate: PropTypes.oneOfType([PropTypes.object, PropTypes.func, PropTypes.string]),
  endDate: PropTypes.oneOfType([PropTypes.object, PropTypes.func, PropTypes.string]),
  minDate: PropTypes.oneOfType([PropTypes.object, PropTypes.func, PropTypes.string]),
  maxDate: PropTypes.oneOfType([PropTypes.object, PropTypes.func, PropTypes.string]),
  dateLimit: PropTypes.func,
  ranges: PropTypes.object,
  linkedCalendars: PropTypes.bool,
  theme: PropTypes.object,
  onInit: PropTypes.func,
  onChange: PropTypes.func,
  onlyClasses: PropTypes.bool,
  offsetPositive: PropTypes.bool,
  classNames: PropTypes.object,
}

export default DateRange
