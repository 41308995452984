import classNames from 'classnames'
import { FC } from 'react'

import { ExpandableImage } from 'components/utilities'

type Props = {
  event: {
    id: number
    document_folder: {
      id: number
      documents: {
        id: number
        url: string
      }[]
    }
  }
  isPublic?: boolean
}

const Photos: FC<Props> = ({ event, isPublic = false }) => {
  const documents = event.document_folder?.documents

  if (!documents) return null

  return (
    <div className={classNames('clearfix photo-wrapper', { 'is-flex': isPublic })}>
      {documents.map(({ url }, index) => (
        <ExpandableImage
          key={index}
          image={url}
          className={classNames({
            'pull-left': !isPublic,
            'p-r-1': isPublic && index !== documents.length - 1,
          })}
        >
          <img
            src={url}
            alt=""
            className={classNames('event-photo d-inline m-b-1', {
              'm-r-2': !isPublic,
            })}
          />
        </ExpandableImage>
      ))}
    </div>
  )
}

export default Photos
