import cx from 'classnames'
import { iconForMimeType, modal } from 'helpers'
import { FC, Fragment, useCallback } from 'react'
import { Button, Col, Modal, Row } from 'react-bootstrap'

import { Date, Money, Name } from 'components/utilities'
import { useAPI } from 'hooks/useAPI'
import { closeModal } from 'hooks/useModal'
import contribution from 'resources/contribution'
import { approveContribution, Request as ApproveRequest } from 'thunks/contributions/approve'
import { denyContribution, Request as DenyRequest } from 'thunks/contributions/deny'
import notify from 'utils/notify'

import { Contribution } from './type'

type Props = {
  contribution: Contribution
  onApprove: (contribution: Contribution) => void
  onDeny: (contribution: Contribution) => void
}

const ContributionDecisionModal: FC<Props> = ({ contribution, onApprove, onDeny }) => {
  const tracker = contribution.contribution_tracker

  const [approve, { timer: approveTimer }] = useAPI<Contribution, ApproveRequest>(approveContribution)
  const handleApprove = useCallback(async () => {
    const [updated] = await approve({
      id: contribution.id,
      query: 'contribution { approved_at }',
    })
    onApprove({
      ...contribution,
      ...updated,
    })
    closeModal()
    notify('The contribution has been approved!')
  }, [contribution, approve, onApprove])

  const [deny, { timer: denyTimer }] = useAPI<Contribution, DenyRequest>(denyContribution)
  const handleDeny = useCallback(async () => {
    const [updated] = await deny({
      id: contribution.id,
      query: 'contribution { denied_at }',
    })

    onDeny({
      ...contribution,
      ...updated,
    })
    closeModal()
    notify({
      type: 'warning',
      message: 'The contribution has been denied.',
    })
  }, [contribution, deny, onDeny])

  const organization = contribution.organization || contribution.member!.organization

  return (
    <Modal show onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>Contribution details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <hr className="spacer-xs" />

        <div className="pl-4">
          <p>
            {tracker.purpose === 'money' ? <Money amount={contribution.amount} /> : `${contribution.amount} hour`}{' '}
            contribution
            {contribution.details && <p className="mt-1">Details: {contribution.details}</p>}
          </p>

          {contribution.document && (
            <a target="_blank" href={contribution.document.url} className="m-y-1" rel="noreferrer">
              {contribution.document.mimetype.match(/^image/) ? (
                <img src={contribution.document.url} alt="" style={{ maxHeight: 260 }} className="m-auto d-block" />
              ) : (
                <img
                  src={iconForMimeType(contribution.document.mimetype, '')}
                  alt=""
                  height="100"
                  className="m-auto d-block m-t-2 m-b-2"
                />
              )}
            </a>
          )}

          <p className="">
            Submitted by <Name user={contribution.creator} full />
            <br />
            {organization.name} at {organization.secondary}
          </p>

          <p className="text-muted fs-90 mt-4">
            submitted <Date date={contribution.created_at} />
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          bsStyle="success"
          className={cx({
            'btn-loading': approveTimer.isLoading,
            disabled: approveTimer.isLoading,
          })}
          onClick={handleApprove}
        >
          Approve
        </Button>

        <Button
          bsStyle="danger"
          className={cx({
            'btn-loading': denyTimer.isLoading,
            disabled: denyTimer.isLoading,
          })}
          onClick={handleDeny}
        >
          Deny
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default modal<Props>('ContributionDecision', ContributionDecisionModal)
