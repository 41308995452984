export const reimbursementQuery = `
  reimbursement {
    id
    amount
    description
    status
    method
    reason
    approved_at
    denied_at
    created_at
    document {
      id
      url
      mimetype
    }
    user {
      id
      first_name
      last_name
      member {
        id
      }
    }
    created_by {
      id
      first_name
      last_name
    }
    approved_by {
      id
      first_name
      last_name
    }
    denied_by {
      id
      first_name
      last_name
    }
  }
`
