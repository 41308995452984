import classNames from 'classnames'
import { FC, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import r from 'routes'
import { useAuthSelector } from 'store'

import useClickedOutside from 'hooks/useClickedOutside'

type Props = {
  className: string
  children: ReactNode
}

const NavbarDropdown: FC<Props> = ({ className, children }) => {
  const { user } = useAuthSelector()
  const [isOpen, setIsOpen] = useState(false)

  const ref = useRef<HTMLDivElement>(null)
  const handleToggle = useCallback(() => {
    isOpen && setIsOpen(isOpen => !isOpen)
  }, [isOpen])
  useClickedOutside(ref, handleToggle)

  const location = useLocation()
  useEffect(() => {
    setIsOpen(false)
  }, [location.pathname])

  const settingsLink = useMemo((): string => {
    switch (user?.role) {
      case 'member':
      case 'admin':
        return r.member.settings.root
      case 'parent':
        return r.parent.settings.root
      case 'alumni':
        return r.alumni.settings.root
      default:
        return ''
    }
  }, [user?.role])

  return (
    <div ref={ref} className={classNames('dropdown', className)} data-open={isOpen}>
      <div className="dropdown-toggle" role="button" onClick={() => setIsOpen(!isOpen)}>
        {children}
        <i className="fa fa-caret-down"></i>
      </div>

      <ul className="dropdown-menu dropdown-menu-right" role="menu">
        {user?.role !== 'root' && (
          <>
            <li>
              <Link to={settingsLink}>
                <i className="fa fa-user"></i>
                &nbsp; My account
              </Link>
            </li>
            <li className="divider"></li>
          </>
        )}

        <li>
          <Link to={r.auth.logout}>
            <i className="fa fa-sign-out"></i>
            &nbsp; Logout
          </Link>
        </li>
      </ul>
    </div>
  )
}

export default NavbarDropdown
