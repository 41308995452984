import { FC } from 'react'

import RealtimeVotingProvider from 'components/voting/shared/RealtimeVotingProvider'
import ShowContainer from 'components/voting/show-container'

const VotingShow: FC = () => {
  return (
    <RealtimeVotingProvider>
      <ShowContainer />
    </RealtimeVotingProvider>
  )
}

export default VotingShow
