import { FC } from 'react'

import { ConfirmModal } from 'components/modals'
import PlainInput from 'components/modals/plain-input'
import { Divider, Item, SettingsDropdown } from 'components/utilities'
import { openModal } from 'hooks/useModal'

import DocumentLabelsModal from '../document-labels-modal'
import { Document } from './'
import useManageDocument from './_useManageDocument'

type Props = {
  document: Document
  onUpdate: (document: Document) => void
  onArchive: () => void
}

const Dropdown: FC<Props> = ({ document, onUpdate, onArchive }) => {
  const { handleUpdate, handleArchive, timer } = useManageDocument(document, onUpdate, onArchive)

  return (
    <>
      <SettingsDropdown>
        <Item icon="pencil" onClick={openModal('PlainInput:RenameFolder', document.id)}>
          Rename file
        </Item>
        <Item icon="tags" onClick={openModal('Labels', document.id)}>
          Change labels
        </Item>
        <Divider />
        <Item icon="trash" onClick={openModal('Confirm:DeleteDocument', document.id)}>
          Delete file
        </Item>
      </SettingsDropdown>

      <PlainInput
        id={`RenameFolder:${document.id}`}
        title="Update document"
        label="Filename"
        defaultValue={document.filename}
        action="Save"
        timer={timer}
        onSubmit={handleUpdate}
      />

      <DocumentLabelsModal
        id={document.id}
        documentId={document.id}
        labels={document.document_labels}
        onUpdate={labels => onUpdate({ ...document, document_labels: labels })}
      />

      <ConfirmModal
        id={`DeleteDocument:${document.id}`}
        prompt={`Are you sure you want to delete ${document.filename} ? `}
        yes="delete document"
        onConfirm={handleArchive}
      />
    </>
  )
}

export default Dropdown
