import { FC, useMemo } from 'react'
import Select from 'react-select'

import { useLegacyFederation } from 'hooks/useLegacyContext'
import useList from 'hooks/useList'
import { listExecutiveBoardPositions, Request } from 'thunks/executive-board-positions/list'

type ExecutiveBoardPosition = {
  id: number
  title: string
}

type Option = {
  label: string
  value: number
}

type Props = {
  value: number[]
  onChange: (positionIds: number[]) => void
}

const ExecutiveBoardPositions: FC<Props> = ({ value, onChange }) => {
  const user = useLegacyFederation()

  const [positions] = useList<ExecutiveBoardPosition, Request>(listExecutiveBoardPositions, {
    query: 'executive_board_position { title }',
    federation_id: user.federation.id,
  })

  const options = useMemo(
    (): Option[] =>
      positions
        ?.map(p => ({
          label: p.title,
          value: p.id,
        }))
        .sort((l, r) => l.label.localeCompare(r.label)) || [],
    [positions]
  )

  return (
    <Select<Option, true>
      isMulti
      placeholder="Select positions..."
      options={options}
      value={options?.filter(o => value.includes(o.value)) || []}
      onChange={options => onChange(options.map(o => o.value))}
    />
  )
}

export default ExecutiveBoardPositions
