import React from 'react'
import ReactDOM from 'react-dom/client'

import Lightbox from './lightbox'

export default class ExpandableImage extends React.Component {
  componentWillUnmount() {
    this.closeLightbox()
  }

  targetElem = null

  initLightbox = () => {
    const elem = document.createElement('div')
    elem.setAttribute('x-lightbox-container', '')

    this.targetElem = elem

    document.body.appendChild(elem)

    const root = ReactDOM.createRoot(elem)
    root.render(<Lightbox image={this.props.image} onClose={this.closeLightbox} />)
  }

  closeLightbox = () => {
    if (!this.targetElem) return

    this.targetElem.remove()
    this.targetElem = null
  }

  render() {
    const props = _.assign({}, this.props, {
      children: undefined,
      onClick: this.initLightbox,
    })

    delete props.image

    return (
      <div role="button" {...props}>
        {this.props.children}
      </div>
    )
  }
}
