import { useCallback } from 'react'
import { useAppDispatch } from 'store'

import morph from 'thunks/auth/morph'

export default function useMorph(globalId?: number | string) {
  const dispatch = useAppDispatch()

  return useCallback(
    (localId?: number | string) => {
      const id = localId || globalId
      if (!id) return

      dispatch(morph({ id: +id }))
    },
    [globalId, dispatch]
  )
}
