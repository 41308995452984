import { FC } from 'react'

import { ExpandableImage } from 'components/utilities'
import pdfIcon from 'images/file-types/pdf.svg'
import { Document } from 'types/document'

type Props = Pick<Document, 'url' | 'mimetype'>

const ReceiptPreview: FC<Props> = doc => {
  if (_.isNull(doc)) {
    return <em className="d-block m-t-2 text-center">no receipt was uploaded</em>
  }

  if (doc.mimetype === 'application/pdf') {
    return (
      <a target="_blank" href={doc.url} rel="noreferrer">
        <img src={pdfIcon} alt="" className="m-auto d-block m-t-2" style={{ width: '30%' }} />
        <span className="d-block text-center m-t-1">download PDF</span>
      </a>
    )
  }

  return (
    <ExpandableImage image={doc.url}>
      <img src={doc.url} alt="receipt" className="preview" />
      <em className="d-block text-center m-t-1 text-muted">view larger</em>
    </ExpandableImage>
  )
}

export default ReceiptPreview
