import classNames from 'classnames'
import { FC } from 'react'
import { Button, Col, FormGroup, Modal } from 'react-bootstrap'
import { FormProvider, useForm } from 'react-hook-form'

import ConnectedAddress from 'components/form/connected-address'
import ConnectedField from 'components/form/connected-field'
import modal from 'helpers/modal'
import { closeModal } from 'hooks/useModal'

import useCreateParent, { FormData } from './hooks/useCreateParent'
import { Parent } from './type'

type Props = {
  memberId: number
  onCreate: (parent: Parent) => void
}

const AddParentModal: FC<Props> = ({ memberId, onCreate }) => {
  const form = useForm<FormData>()
  const [handleSubmit, { timer }] = useCreateParent(memberId, onCreate, form.setError)

  return (
    <Modal show onHide={closeModal}>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(handleSubmit)} className="form-horizontal">
          <Modal.Header closeButton>
            <Modal.Title>Add a parent</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <input type="hidden" name="parent.member_id" value={memberId} />

            <hr className="spacer-xs" />

            <FormGroup>
              <label htmlFor="parent.user.first_name" className="col-md-3 control-label">
                Name
              </label>
              <Col md={4}>
                <input
                  {...form.register('user.first_name')}
                  id="parent.user.first_name"
                  type="text"
                  className="form-control"
                  placeholder="First"
                  autoFocus
                />
              </Col>
              <Col md={5}>
                <input {...form.register('user.last_name')} type="text" className="form-control" placeholder="Last" />
              </Col>
            </FormGroup>

            <ConnectedField name="user.email" label="Email" inputSize={9} />

            <ConnectedField name="user.phone_number" label="Phone number" mask="(999) 999 - 9999" inputSize={9} />

            <ConnectedAddress label="Address" name="user.address" inputSize={9} />
          </Modal.Body>
          <Modal.Footer>
            <Col md={7} mdPush={5}>
              <Button
                type="submit"
                bsStyle="primary"
                disabled={timer.isLoading}
                className={classNames({
                  'btn-loading': timer.isLoading,
                })}
              >
                Add parent
              </Button>
            </Col>
          </Modal.Footer>
        </form>
      </FormProvider>
    </Modal>
  )
}

export default modal<Props>('AddParent', AddParentModal)
