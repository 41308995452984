import { formatName } from 'helpers'
import { FC, ReactNode } from 'react'
import { Link } from 'react-router-dom'

type WithLinkProps = {
  to?: string
  className?: string
  children: ReactNode
  onClick?: () => void
}

const WithLink: FC<WithLinkProps> = ({ to, className, children, onClick }) => {
  if (to) {
    return (
      <Link to={to} className={className}>
        {children}
      </Link>
    )
  }

  return (
    <span onClick={onClick} className={className}>
      {children}
    </span>
  )
}

type Props = {
  user: {
    id: number
    first_name: string
    last_name: string
  }
  to?: string
  format?: 'short' | 'full' | 'natural' | 'firstInitial'
  short?: boolean
  full?: boolean
  natural?: boolean
  firstInitial?: boolean
  className?: string
  onClick?: () => void
}

const Name: FC<Props> = ({ user, to, onClick, className, format, short, full, natural, firstInitial }) => {
  // @ts-ignore
  if (user.user) {
    // @ts-ignore
    user = user.user
  }

  if (!user.last_name) {
    return (
      <WithLink to={to} className={className}>
        {user.first_name}
      </WithLink>
    )
  }

  /* eslint-disable no-param-reassign */
  format = format || 'short'
  if (short) {
    format = 'short'
  }
  if (full) {
    format = 'full'
  }
  if (natural) {
    format = 'natural'
  }
  if (firstInitial) {
    format = 'firstInitial'
  }
  /* eslint-enable no-param-reassign */

  const formatted = formatName(user, format)
  return (
    <WithLink to={to} onClick={onClick} className={className}>
      {formatted}
    </WithLink>
  )
}

export default Name
