import asyncThunk from 'utils/asyncThunk'

export type Request = {
  id: number
}

export const archiveMessageCategoryFolder = asyncThunk<Request, any>({
  name: 'message-category-folders/archive',
  uri: ({ id }) => `/message_category_folders/${id}`,
  method: 'DELETE',
})
