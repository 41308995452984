import { FC } from 'react'

import { EmptyState, Loading } from 'components/utilities'
import { openModal } from 'hooks/useModal'

import AddParentModal from '../add-parent-modal'
import useListParents from '../hooks/useListParents'
import ParentsContext from './context'
import ParentDetails from './details'

type Props = {
  member: {
    id: number
    no_physical_bills: boolean
  }
}

const ParentsList: FC<Props> = ({ member }) => {
  const [parents, { addParent, updateParent, archiveParent }] = useListParents(member.id)

  if (parents === undefined) return <Loading />

  if (parents.length === 0) {
    return (
      <>
        <EmptyState message="No parents added yet" icon action="Add a parent" onClick={openModal('AddParent')} />
        <AddParentModal memberId={member.id} onCreate={addParent} />
      </>
    )
  }

  return (
    <ParentsContext.Provider
      value={{
        parents,
        updateParent,
        archiveParent,
      }}
    >
      {parents.map(parent => (
        <ParentDetails
          key={parent.id}
          parent={parent}
          greenDeliveryEnabled={member.no_physical_bills}
          noParentsGetPhysicalBills={!parents.some(p => p.send_physical_bills)}
        />
      ))}
      <AddParentModal memberId={member.id} onCreate={addParent} />
    </ParentsContext.Provider>
  )
}

export default ParentsList
