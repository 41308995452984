import { FC } from 'react'
import { Alert as BsAlert } from 'react-bootstrap'
import { Date, FaButton, Money } from 'components/utilities'
import { openModal } from 'hooks/useModal'

import { Reimbursement } from './type'

type Props = {
  reimbursement: Reimbursement
}

const Alert: FC<Props> = ({ reimbursement }) => {
  const { id, amount, created_at: createdAt, created_by: creator } = reimbursement

  return (
    <BsAlert bsStyle="warning" className="clearfix">
      <span className="d-inline-block m-t-05">
        {creator.first_name} {creator.last_name} proposed to send you a reimbursement for <Money amount={amount} /> on{' '}
        <Date human date={createdAt} />.
      </span>
      <div className="pull-right">
        <FaButton icon="gavel" onClick={openModal('ReviewReimbursement', id)}>
          Review
        </FaButton>
      </div>
    </BsAlert>
  )
}

export default Alert
