import { FC, useCallback } from 'react'
import { Link } from 'react-router-dom'

import Units from 'components/utilities/units'
import useLegacyContext from 'hooks/useLegacyContext'
import folderIcon from 'images/file-types/folder.svg'

import { CollapsedDocumentFolder } from 'pages/Federation/ExecutiveBoards/DocumentFolders/show/type'

type Props = {
  folders: CollapsedDocumentFolder[]
}

const FolderList: FC<Props> = ({ folders }) => {
  const { user } = useLegacyContext()

  const getFolderURI = useCallback(
    (folderID: number) => {
      if (user?.role === 'federation') {
        return `/federation/document-folders/${folderID}`
      } else if (user?.role === 'admin') {
        return `/admin/document-folders/${folderID}`
      }
      return `/member/document-folders/${folderID}`
    },
    [user?.role]
  )

  if (folders.length === 0) return <></>

  return (
    <div>
      <table className="table table-documents">
        <tbody>
          {folders.map((folder, idx) => (
            <tr key={idx}>
              <td style={{ width: '60%' }}>
                <img src={folderIcon} width={36} height={36} alt="" />
                <div>
                  <Link to={getFolderURI(folder.id)}>{folder.name}</Link>
                </div>
              </td>
              <td className="text-right" style={{ width: '15%' }}>
                <Units noun="document" count={folder.data.document_count} />
              </td>
              <td className="hidden-print text-right text-muted" style={{ width: '25%' }}></td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default FolderList
