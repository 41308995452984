import { createContext } from 'react'

export type GlobalSearchContextType = {
  isOpen: boolean
  toggle: () => void
  close: () => void
}

export const GlobalSearchContext = createContext<GlobalSearchContextType>({
  isOpen: false,
  toggle: () => { },
  close: () => { },
})
