import { FC, useCallback } from 'react'
import { ButtonToolbar } from 'react-bootstrap'
import { useHistory } from 'react-router'

import { ConfirmModal } from 'components/modals'
import PlainInput from 'components/modals/plain-input'
import { Divider, FaButton, Item, SettingsDropdown } from 'components/utilities'
import { useAPI } from 'hooks/useAPI'
import useLegacyContext from 'hooks/useLegacyContext'
import { closeModal, openModal } from 'hooks/useModal'
import { archiveDocumentFolder } from 'thunks/document-folders/archive'
import { createDocumentFolder } from 'thunks/document-folders/create'
import { Request, updateDocumentFolder } from 'thunks/document-folders/update'
import { AnyUser } from 'types/user'
import { isFederation, isMember } from 'types/user-functions'
import notify from 'utils/notify'

import AddDocumentModal from '../add-document-modal'
import { Document } from '../add-document-modal/type'
import { DocumentFolder } from './type'
// @ts-ignore
import { documentFolderQuery } from './type?query'

function getOwner(user: AnyUser): Pick<DocumentFolder, 'owner_type' | 'owner_id'> {
  if (isFederation(user)) return { owner_type: 'Federation', owner_id: user.federation.id }
  if (isMember(user)) return { owner_type: 'Organization', owner_id: user.member.organization.id }
  throw new Error('unknown user role')
}

type Props = {
  folder: DocumentFolder
  onUpload: (document: Document) => void
  onUpdate: (folder: DocumentFolder) => void
  onArchive: () => void
}

const Actions: FC<Props> = ({ folder, onUpload, onUpdate, onArchive }) => {
  const { user } = useLegacyContext()
  const history = useHistory()

  const [create, { timer }] = useAPI(createDocumentFolder)
  const handleCreate = useCallback(
    async (name: string) => {
      const [created] = await create({
        query: documentFolderQuery,
        document_folder: {
          name,
          document_folder_id: folder.id,
          ...getOwner(user),
        },
      })

      onUpdate({
        ...folder,
        document_folders: [...folder.document_folders, created],
      })
      closeModal()
      notify(`${name} was created.`)
    },
    [folder, user, onUpdate, create]
  )

  const [update, { timer: updateTimer }] = useAPI<DocumentFolder, Request>(updateDocumentFolder)
  const handleUpdate = useCallback(
    async (name: string) => {
      const [updated] = await update({
        query: documentFolderQuery,
        id: folder.id,
        document_folder: { name },
      })

      onUpdate(updated)
      closeModal()
      notify('The folder has been renamed.')
    },
    [folder, onUpdate, update]
  )

  const handleTogglePrivate = useCallback(async () => {
    const [updated] = await update({
      query: documentFolderQuery,
      id: folder.id,
      document_folder: {
        name: folder.name,
        is_private: folder.is_private ? 'false' : 'true',
      },
    })

    onUpdate(updated)
    closeModal()
    notify(updated.is_private ? 'The folder is now private.' : 'The folder is now public.')
  }, [folder, onUpdate, update])

  const [archive] = useAPI(archiveDocumentFolder)
  const handleArchive = useCallback(async () => {
    await archive({
      id: folder.id,
    })

    onArchive()
    closeModal()
    notify({
      message: 'The folder has been deleted.',
      type: 'warning',
    })
  }, [folder, onArchive, archive])

  return (
    <>
      <ButtonToolbar className="hidden-print pull-right">
        <FaButton onClick={openModal('AddDocument', folder.id)} icon="cloud-upload" bsStyle="primary">
          Upload file
        </FaButton>

        {folder.purpose === 'shared' && (
          <FaButton onClick={() => history.push(`/federation/document-folders/${folder.id}/access`)} icon="key">
            Manage Access
          </FaButton>
        )}

        <SettingsDropdown>
          <Item icon="folder-open" onClick={openModal('PlainInput:CreateFolder', folder.id)}>
            Create subfolder
          </Item>
          <Item icon="pencil" onClick={openModal('PlainInput:RenameFolder', folder.id)}>
            Rename folder
          </Item>
          {(() => {
            if (folder.purpose) {
              return null
            }

            if (folder.is_private) {
              return (
                <Item icon="unlock" onClick={handleTogglePrivate}>
                  Public to members
                </Item>
              )
            }
            return (
              <Item icon="lock" onClick={handleTogglePrivate}>
                Private to admins
              </Item>
            )
          })()}

          <Divider />

          <Item icon="trash" onClick={openModal('Confirm:DeleteFolder', folder.id)}>
            Delete folder
          </Item>
        </SettingsDropdown>
      </ButtonToolbar>

      <AddDocumentModal id={folder.id} documentFolderId={folder.id} onUpload={onUpload} />

      <PlainInput
        id={`RenameFolder:${folder.id}`}
        title="Rename folder"
        label="Name"
        defaultValue={folder.name}
        action="Save"
        timer={updateTimer}
        onSubmit={handleUpdate}
      />

      <PlainInput
        id={`CreateFolder:${folder.id}`}
        title="Create a subfolder"
        label="Folder Name"
        action="Create"
        timer={timer}
        onSubmit={handleCreate}
      />

      <ConfirmModal
        id={`DeleteFolder:${folder.id}`}
        prompt={`Are you sure you want to delete ${folder.name}?`}
        yes="delete folder"
        showLoading
        onConfirm={handleArchive}
      />
    </>
  )
}

export default Actions
