import { FC } from 'react'

import { Date, Name } from 'components/utilities'
import placeholder from 'images/etc/avatar.svg'

import { Message } from './type'

type Props = {
  message: Message
}

const ThreadMessage: FC<Props> = ({ message }) => {
  return (
    <div className="media thread-message">
      <div className="media-left">
        <img
          alt=""
          className="media-object img-thumbnail"
          src={message.creator.avatar?.url || placeholder}
          style={{
            width: 48,
            height: 48,
          }}
        />
      </div>
      <div className="media-body">
        <h6>
          <Name user={message.creator} full />{' '}
          <small>
            on <Date date={message.created_at} />
          </small>
        </h6>
        <p dangerouslySetInnerHTML={{ __html: message.body }}></p>
      </div>
    </div>
  )
}

export default ThreadMessage
