export const memberQuery = `
  member {
    id
    alternate_email
    is_admin
    custom_fields
    user {
      id
      first_name
      last_name
      email
      phone_number
      avatar {
        id
        public_url
      }
      address {
        address
        address_2
        city
        state
        postal_code
      }
    }
    first_parent {
      id
      user {
        id
        first_name
        last_name
        email
        phone_number
        address {
          address
          address_2
          city
          state
          postal_code
        }
      }
    }
  }
`
export const organizationQuery = `
  organization {
    id
    name
    member_roster
    admin_roster
    cc_upcharge
    custom_fields {
      name
      slug
    }
    federation {
      id
      custom_fields {
        name
        slug
      }
    }
    primary_admin {
      id
    }
  }
`
