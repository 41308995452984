import { useMemo } from 'react'

import objectKeys from 'utils/object-keys'

export default function useWinners(voteCounts: Record<number, number> | undefined): number[] | undefined {
  return useMemo(() => {
    if (!voteCounts) return undefined

    const optionIds = objectKeys(voteCounts).map(id => parseInt(`${id}`, 10))
    const wonCount = optionIds.reduce((acc, id) => Math.max(voteCounts[id], acc), 0)

    return optionIds.filter(id => voteCounts[id] === wonCount)
  }, [voteCounts])
}
