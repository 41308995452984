import { useEffect, useState } from 'react'
import { useAppDispatch } from 'store'

import { unwrapResult } from '@reduxjs/toolkit'

import { CustomField, listCustomFields } from 'thunks/custom-fields/list'

export default function useCustomFields(
  ownerType: 'Organization' | 'Federation',
  ownerId: number | string | undefined
): CustomField[] {
  const [customFields, setCustomFields] = useState<CustomField[]>([])

  const dispatch = useAppDispatch()
  useEffect(() => {
    if (!ownerId) return

    dispatch(
      listCustomFields({
        owner_type: ownerType,
        owner_id: ownerId,
      })
    )
      .then(unwrapResult)
      .then(({ data }) => setCustomFields(data))
  }, [ownerType, ownerId, dispatch])

  return customFields
}
