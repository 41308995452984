import React, { FC } from 'react'

import FullStory from './fullstory'
import Header from './header'
import Item from './item'

const Contacts: FC = () => (
  <ul className="sidebar-menu">
    <Header>Greek Capital Management</Header>
    <Item url="tel:2085587717" icon="phone">
      (202) 558-7717
    </Item>
    <Item url="mail:info@greekcapitalmanagement.com" icon="envelope">
      <small>info@greekcapitalmanagement.com</small>
    </Item>
    <Item icon="map" multiLine>
      PO Box 41027
      <br />
      Bethesda, MD 20824
    </Item>
    <FullStory />
  </ul>
)

export default Contacts
