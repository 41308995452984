import { FC } from 'react'
import { Alert, Grid } from 'react-bootstrap'

import PaymentPlanDetails from 'components/payment-plans/details'
import { Body, Content, Header, PaymentMethod, Portlet } from 'components/utilities'
import useLegacyContext from 'hooks/useLegacyContext'

import { PaymentPlan } from './type'

type Props = {
  paymentPlan: PaymentPlan
}

const ActivePaymentPlan: FC<Props> = ({ paymentPlan }) => {
  const { user } = useLegacyContext()

  return (
    <Content>
      <Grid>
        <Header>Payment plan</Header>
        <Portlet boxed>
          <Body>
            <Alert bsStyle="success" className="fw-semibold">
              {user.id === paymentPlan.member.user.id ? 'Your' : `${paymentPlan.member.user.first_name}'s`} payment plan
              is active!
            </Alert>

            {paymentPlan.payment_method ? (
              <p>
                {user.id === paymentPlan.payment_method.user.id
                  ? 'Your'
                  : `${paymentPlan.payment_method.user.first_name} ${paymentPlan.payment_method.user.last_name}'s`}
                &nbsp;
                <PaymentMethod paymentMethod={paymentPlan.payment_method} className="fw-semibold" />
                &nbsp;will be {paymentPlan.payment_method.method === 'bank' ? 'debited' : 'charged'} on this schedule:
              </p>
            ) : (
              <p className="m-l-1 fw-semibold text-danger">The payment method has been deleted</p>
            )}

            <PaymentPlanDetails paymentPlan={paymentPlan} className="max-w-250" />
          </Body>
        </Portlet>
      </Grid>
    </Content>
  )
}

export default ActivePaymentPlan
