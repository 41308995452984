import { FC } from 'react'
import { Body, Date, Money, Portlet } from 'components/utilities'

import { Acceptance } from './index'

type Payment = {
  amount: number
  due_on: string
}

type Props = {
  document: {
    payment_schedule: {
      options: Array<Payment[]>
    }
  }
  acceptance: {
    payment_plan_option: number
  }
  onUpdateAcceptance: (key: 'payment_plan_option', value: Acceptance[keyof Acceptance]) => void
}

const PaymentOption: FC<Props> = ({ document, acceptance, onUpdateAcceptance }) => (
  <Portlet boxed>
    <Body>
      <h5>Please pick a payment plan:</h5>
      {document.payment_schedule.options.map((option, idx) => (
        <div
          key={idx}
          className="m-b-1 c-pointer payment-plan-option"
          onClick={() => onUpdateAcceptance('payment_plan_option', idx)}
        >
          <h6>
            <input
              type="radio"
              name="payment_plan.option_id"
              className="m-r-1"
              value={idx}
              checked={idx === acceptance.payment_plan_option}
              onChange={() => {}}
            />
            Option {idx + 1}:
          </h6>
          <ul className="list-unstyled m-l-2">
            {option.map(payment => (
              <li key={`${payment.due_on}-${payment.amount}`} className="m-b-025">
                -{' '}
                <strong>
                  <Money amount={payment.amount} />
                </strong>{' '}
                on <Date date={payment.due_on} short />
              </li>
            ))}
          </ul>
        </div>
      ))}
    </Body>
  </Portlet>
)

export default PaymentOption
