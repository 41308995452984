export const contributionQuery = `
  contribution {
    id
    amount
    details
    approved_at
    created_at
    document {
      id
      mimetype
      url
    }
    creator {
      id
      first_name
      last_name
    }
  }
`
export const paymentMethodQuery = `
  payment_method {
    id
    method
    company
    last_four
  }
`
