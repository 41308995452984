import React from 'react'
import { object, string } from 'prop-types'

export default class FieldError extends React.Component {
  static _contextTypes = {
    errors: object,
  }

  static propTypes = {
    name: string.isRequired,
  }

  render() {
    const { errors } = this.context
    const { name } = this.props

    const error = _.get(errors, name)
    if (!error) {
      return null
    }

    return <span className="help-block m-t-05 m-b-0 text-danger fw-semibold">{error}</span>
  }
}
