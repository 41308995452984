import { FC, useEffect } from 'react'
import { Radio } from 'react-bootstrap'
import { useFormContext } from 'react-hook-form'

import ConnectedField from 'components/form/connected-field'
import { PaymentMethod as PayMethod } from 'components/utilities'
import useLegacyContext from 'hooks/useLegacyContext'
import useList from 'hooks/useList'
import { listPaymentMethods, Request } from 'thunks/payment-methods/list'

import { FormData } from './'
import { PaymentMethod } from './type'
// @ts-ignore
import { paymentMethodQuery } from './type?query'

const PaymentMethods: FC = () => {
  const { user } = useLegacyContext()
  const { setValue } = useFormContext<FormData>()

  const [paymentMethods] = useList<PaymentMethod, Request>(listPaymentMethods, {
    query: paymentMethodQuery,
    user_id: user.id,
  })

  useEffect(() => {
    if (!paymentMethods || paymentMethods.length === 0) return
    setValue('payment_method_id', paymentMethods[0].id)
  }, [paymentMethods, setValue])

  return (
    <ConnectedField label="Payment method" name="payment_method_id">
      {({ value, onChange }) => (
        <>
          {paymentMethods?.map((method, i) => (
            <Radio
              key={i}
              name="payment_method_id"
              value={method.id}
              checked={method.id === value}
              onChange={() => onChange(method.id)}
            >
              <PayMethod paymentMethod={method} />
            </Radio>
          ))}
        </>
      )}
    </ConnectedField>
  )
}

export default PaymentMethods
