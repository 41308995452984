import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import ReactQuill from 'react-quill'

import { openModal } from 'hooks/useModal'
import 'react-quill/dist/quill.snow.css'

import './break-blot'
import ImageHandler from './image-handler'
import './styles.less'

type Props = {
  value?: string
  bounds?: string
  defaultValue?: string
  placeholder?: string
  minHeight?: number
  enableImages?: boolean
  onChange: (value: string) => void
}

const QuillEditor: FC<Props> = ({ value, defaultValue, placeholder, minHeight, enableImages = false, onChange }) => {
  const [rawInput, setRawInput] = useState(() => value || defaultValue || '')
  const editorRef = useRef<ReactQuill>(null)

  const handleChange = useCallback(
    (rawInput: string) => {
      const clean = rawInput.replaceAll(/<p><br><\/p>/g, '<br>')
      setRawInput(clean)
      onChange(clean)
    },
    [onChange]
  )

  const modules = useMemo(
    () => ({
      toolbar: {
        container: _.concat(
          [['bold', 'italic', 'underline'], [{ list: 'ordered' }, { list: 'bullet' }], ['link']],
          enableImages ? [['image']] : [],
          [['clean']]
        ),
        handlers: {
          image: openModal('UploadFile', 'editor'),
        },
      },
      keyboard: {
        bindings: {
          enter: {
            key: 13,
            handler: () => {
              const editor = editorRef.current!.getEditor()
              const range = editor.getSelection()!

              editor.insertText(range.index, '\n', 'user')
              editor.setSelection(range.index + 1, 0, 'user')

              return false
            },
          },
        },
      },
    }),
    [enableImages]
  )

  return (
    <>
      {editorRef.current && enableImages && <ImageHandler getEditor={() => editorRef.current!.getEditor()} />}

      <ReactQuill
        ref={editorRef}
        theme="snow"
        modules={modules}
        formats={['bold', 'italic', 'underline', 'list', 'bullet', 'link', 's3Image']}
        placeholder={placeholder}
        onChange={handleChange}
        style={{ minHeight }}
        bounds=".form-group"
        value={rawInput}
        preserveWhitespace={true}
        className="asdasd"
      />
    </>
  )
}

export default QuillEditor
