import asyncThunk from 'utils/asyncThunk'

export type Request = {
  query: string
  transaction: {
    amount: number
    description: string
    due_on?: string
    late_fee?: number
    member_ids: number[]
    account_id: number
    organization_id: number
  }
}

export const createCharges = asyncThunk<Request, any>({
  name: 'transactions/create-charges',
  uri: '/transactions/charges',
  method: 'POST',
})
