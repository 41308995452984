import QueryString from 'qs'

export type Response<Response, Headers extends Record<string, string> = Record<string, string>> = {
  data: Response
  headers: Headers
}

export type Request<Req extends any = any, Resp extends any = any> = {
  method: 'GET' | 'POST' | 'PUT' | 'DELETE'
  id?: number
  uri: string
  query?: string
  data?: Req
  on?: Record<number, ((payload: Response<Resp>) => void) | undefined>
  onSuccess?: (payload: Response<Resp>) => void
  onFailure?: (error: Error) => void
}

export type Error = {
  status: number
  data: any
}

const apiRequest = async <Req, Resp>({
  method,
  uri,
  data: body,
  on,
  onSuccess,
  onFailure,
}: Request<Req, Resp>): Promise<Response<Resp>> => {
  return new Promise<Response<Resp>>(async (resolve, reject) => {
    try {
      const headers = new Headers()
      headers.append('Authorization', `Bearer ${localStorage.getItem('token')}`)

      if (method === 'POST' || method === 'PUT' || method === 'DELETE') {
        headers.append('Content-Type', 'application/json')
      }

      const opts: RequestInit = {
        method,
        headers,
      }

      var appUri = uri
      if (body) {
        if (method === 'GET') {
          const query = QueryString.stringify(body, { arrayFormat: 'brackets' })
          appUri = `${uri}?${query}`
        } else {
          opts.body = JSON.stringify(body)
        }
      }
      appUri = appUri.replace(/^\/api/, '')

      const url = `https://${import.meta.env.VITE_API_ORIGIN}${appUri}`
      const resp = await fetch(url, opts)

      // Site went into maintenance mode
      if (resp.status === 503) {
        window.location.reload()
        return {}
      }

      // JWT token has expired or is invalid
      if (resp.status === 417) {
        window.location.reload()
        return {}
      }

      let data
      try {
        data = await resp.json()
      } catch { }

      if (resp.status >= 400) {
        // eslint-disable-next-line no-throw-literal
        throw {
          status: resp.status,
          data,
        }
      }

      const h: Record<string, string> = {}
      for (const [key, val] of resp.headers.entries()) {
        if (key.match(/^x-/)) {
          const k = _.camelCase(key.substring(2))
          h[k] = val
        } else {
          h[key] = val
        }
      }

      const payload = { data, headers: h }

      const fn = on && on[resp.status]
      fn && fn(payload)

      onSuccess && onSuccess(payload)
      resolve(payload)
    } catch (err) {
      console.log('[API ERROR]', method, uri, err)
      onFailure && onFailure(err as Error)
      reject(err)
    }
  })
}

export default apiRequest
