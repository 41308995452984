import asyncThunk from 'utils/asyncThunk'

export type Request = {
  query: string
  message_category: {
    name: string
  }
}

export const createMessageCategory = asyncThunk<Request, any>({
  name: 'message-categories/create',
  uri: '/message_categories',
  method: 'POST',
})
