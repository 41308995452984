export const paymentPlanQuery = `
  payment_plan {
    id
    note
    status
    reason
    updated_at
    created_at
    created_by {
      id
      first_name
      last_name
      member {
        id
      }
    }
    member {
      user {
        id
        first_name
        last_name
      }
    }
    payment_method {
      id
      method
      company
      last_four
      user {
        id
        first_name
        last_name
      }
    }
    payment_plan_payments {
      id
      amount
      due_on
      payment_id
      updated_at
      created_at
    }
  }
`
export const unsubmittedPaymentPlanQuery = `
  unsubmitted_payment_plan {
    note
    payment_method
    payment_plan_payments {
      amount
      due_on
    }
  }
`
