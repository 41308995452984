import { FC, useMemo } from 'react'
import { Col, Grid, Row } from 'react-bootstrap'

import { Content, Loading } from 'components/utilities'
import useActiveSidebarItem from 'hooks/useActiveSidebarItem'
import { useLegacyMemberOrAdmin } from 'hooks/useLegacyContext'
import useObject from 'hooks/useObject'
import { memberLedgers } from 'thunks/members/ledgers'

import Balance from './_Balance'
import Ledger from './_Ledger'
import PaymentPlan from './_PaymentPlan'

const MemberBalance: FC = () => {
  const { member } = useLegacyMemberOrAdmin()
  useActiveSidebarItem('Balance')

  const [ledgers, { headers, reload }] = useObject(memberLedgers, {
    id: member.id,
  })

  const balance = useMemo(() => {
    if (!ledgers) return

    return Object.values(ledgers).reduce((sum, { ledger }) => {
      return sum + ledger.reduce((sum, t) => sum + t.amount, 0)
    }, 0)
  }, [ledgers])

  if (!ledgers || !headers || typeof balance === 'undefined') return <Loading />

  return (
    <Content>
      <Grid>
        <Row className="mb-8">
          <Col sm={8}>
            <Ledger memberId={member.id} ledgers={ledgers} />
          </Col>

          <Col sm={4} className="hidden-print">
            <Balance balance={balance} headers={headers} onPayment={reload} />
            <PaymentPlan memberId={member.id} />
          </Col>
        </Row>
      </Grid>
    </Content>
  )
}

export default MemberBalance
