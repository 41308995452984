import asyncThunk from 'utils/asyncThunk'

export type Request = {
  query: string
  user_id: number
}
export const listContributionTrackersByUser = asyncThunk<Request, any>({
  name: 'contribution-trackers/list-by-user',
  uri: '/contribution_trackers/list_by_user',
})
