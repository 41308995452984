import { useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import r from 'routes'
import { useAuthSelector } from 'store'

import { useAPI } from 'hooks/useAPI'
import { rootPathFromRole } from 'pages/Unauthenticated/RootRedirect'
import { bootstrap as bootstrapFn } from 'thunks/auth/bootstrap'
import query from 'thunks/auth/bootstrap-query'

const roles = ['member', 'admin', 'federation', 'alumni', 'parent', 'root', 'super'] as const

function needsRedirect(role: string | undefined, pathRole: string): boolean {
  if (!(roles as readonly string[]).includes(pathRole)) return false
  if (role === pathRole) return false
  if (role === 'root' && pathRole === 'super') return false
  if (role === 'admin' && pathRole === 'member') return false
  return true
}

export default function useAuth() {
  const { token, user } = useAuthSelector()
  const [isLoaded, setIsLoaded] = useState(false)

  const { pathname } = useLocation()
  const pathRole = useMemo(() => pathname.split('/')[1], [pathname])

  const history = useHistory()
  const [bootstrap, { timer }] = useAPI(bootstrapFn)
  useEffect(() => {
    if (timer.isLoading) return

    if (!token || user) {
      setIsLoaded(true)
      return
    }

    bootstrap({
      query,
      morphed: !!localStorage.getItem('rootToken'),
    })
      .then(([user]) => {
        if (!needsRedirect(user.role, pathRole)) return

        if (user.role === 'member' || user.role === 'admin') {
          if (user.data.has_required_documents) {
            if (!pathname.startsWith('/required-documents') && pathname !== 'logout') {
              history.push('/required-documents')
              return
            }
          }
        }

        const path = rootPathFromRole(user.role)
        history.push(path)
      })
      .catch(err => {
        console.error('[BOOTSTRAP ERROR]', err)
        history.push(r.auth.logout)
      })
      .finally(() => setIsLoaded(true))
  }, [token, user, pathRole, pathname, timer, bootstrap, history])

  return { isLoaded, user }
}
