import classNames from 'classnames'
import { FC, useCallback } from 'react'
import { Modal } from 'react-bootstrap'

import SubmitButton from 'components/form/button'
import Uploader, { File } from 'components/uploader'
import { allMimeTypes } from 'helpers/ext-helpers'
import modal from 'helpers/modal'
import { useAPI } from 'hooks/useAPI'
import { closeModal } from 'hooks/useModal'
import { createDocument } from 'thunks/documents/create'
import getS3UploadInfo from 'utils/getS3UploadInfo'
import notify from 'utils/notify'

import { Document } from './type'
// @ts-ignore
import { documentQuery } from './type?query'

type Props = {
  documentFolderId: number
  onUpload: (document: Document) => void
}

const AddDocumentModal: FC<Props> = ({ documentFolderId, onUpload }) => {
  const [create, { timer }] = useAPI(createDocument)
  const handleUpload = useCallback(
    async ({ s3_path, name }: File) => {
      const [document] = await create({
        query: documentQuery,
        document: {
          filename: name,
          orig_path: s3_path,
          document_folder_id: documentFolderId,
        },
      })

      notify(`${document.filename} was uploaded!`)
      closeModal()
      onUpload(document)
    },
    [documentFolderId, create, onUpload]
  )

  const errors: any = {}

  return (
    <Modal show onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>Upload a document</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div
          className={classNames('upload-wrapper', {
            'has-error': errors?.document?.orig_path,
          })}
        >
          <Uploader noun="file" mimeTypes={allMimeTypes} getS3Info={getS3UploadInfo()} onSuccess={handleUpload} />

          {errors?.document?.orig_path && (
            <p className="help-block text-center">{errors.document.orig_path.join(', ')}</p>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <SubmitButton timer={timer}>Upload document</SubmitButton>
      </Modal.Footer>
    </Modal>
  )
}

export default modal<Props>('AddDocument', AddDocumentModal)
