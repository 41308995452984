export const budgetQuery = `
  budget {
    id
    name
    created_by {
      id
      first_name
      last_name
      member {
        id
      }
    }
    starting_balance {
      amount
    }
    budget_expenses {
      id
      description
      cost
      method
      code
      created_at
      document {
        id
        mimetype
        url
      }
      issued_card_transaction {
        id
        issued_card {
          id
        }
      }
      created_by {
        id
        first_name
        last_name
        member {
          id
        }
      }
    }
    users {
      id
      first_name
      last_name
    }
    issued_cards {
      id
      name_on_card
      company
      last_four
    }
  }
`
