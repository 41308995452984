import { FC, useCallback, useContext, useMemo } from 'react'
import { Button } from 'react-bootstrap'
import { useAppDispatch } from 'store'

import LegacyContext from 'contexts/legacy'
import useActionTimer from 'hooks/useActionTimer'
import unmorph from 'thunks/auth/unmorph'

const Unmorph: FC = () => {
  const { user } = useContext(LegacyContext)

  const uri = useMemo(() => {
    const role = localStorage.getItem('rootRole')

    if (role === 'federation' && (user?.role === 'member' || user?.role === 'admin')) {
      return `/federation/members/${user.member.id}`
    } else if (role === 'federation' && user?.role === 'alumni') {
      return `/federation/alumni/${user.alumni.id}`
    } else if (user?.role === 'member' || user?.role === 'admin') {
      return `/super/members/${user.member.id}/transactions`
    } else if (user?.role === 'parent') {
      return `/super/members/${user.parents[0].member.id}/access`
    } else if (user?.role === 'federation') {
      return `/super/federations/${user.federation.id}`
    }
    return '/'
  }, [user])

  const copied = useActionTimer()
  const handleCopyLink = useCallback(() => {
    if (!user) return
    const url = `${window.location.origin}/quick/${user.id}${window.location.pathname}`
    navigator.clipboard.writeText(url)
    copied.succeeded()
  }, [user, copied])

  const dispatch = useAppDispatch()
  const handleUnmorph = useCallback(() => {
    dispatch(unmorph({ uri }))
  }, [uri, dispatch])

  return (
    <div className="is-morphed">
      <div className="col-sm-7 user">
        <div>
          {user?.first_name} {user?.last_name}
        </div>
        <div className="text-sm opacity-70 cursor-pointer" onClick={handleCopyLink}>
          {copied.didSucceed ? 'copied!' : 'copy quick link'}
        </div>
      </div>
      <div className="col-sm-5">
        <Button bsStyle="default" bsSize="sm" block onClick={handleUnmorph}>
          unmorph
        </Button>
      </div>
    </div>
  )
}

export default Unmorph
