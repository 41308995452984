import classNames from 'classnames'
import React, { FC } from 'react'

import loadingGif from 'images/etc/infinity.gif'

type Props = {
  className?: string
}

const Loading: FC<Props> = ({ className }) => {
  return (
    <img
      src={loadingGif}
      className={classNames('loading-indicator', className)}
      alt=""
      style={{ margin: '0 auto', display: 'block' }}
    />
  )
}

export default Loading
