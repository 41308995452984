import { FC } from 'react'
import { Tooltip, OverlayTrigger } from 'react-bootstrap'
import { FaIcon, Units } from 'components/utilities'

type Props = {
  paymentPlans?: {
    waiting: number
  }
}

const PaymentPlansAwaitingTooltip: FC<Props> = ({ paymentPlans }) => {
  return paymentPlans && paymentPlans.waiting > 0 ? (
    <OverlayTrigger
      placement="right"
      overlay={
        <Tooltip id="payment-plans-tooltip">
          <Units count={paymentPlans.waiting} noun="payment plan is" plural="payment plans are" /> awaiting your
          approval
        </Tooltip>
      }
    >
      <FaIcon exclamation-circle warning className="pull-right" />
    </OverlayTrigger>
  ) : null
}

export default PaymentPlansAwaitingTooltip
