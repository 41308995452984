import { FC, useCallback } from 'react'
import { ButtonToolbar, Grid } from 'react-bootstrap'
import { useHistory, useParams } from 'react-router-dom'
import r from 'routes'

import MessageBoardCategory from 'components/message-board/category'
import { ConfirmModal } from 'components/modals'
import PlainInput from 'components/modals/plain-input'
import {
  BreadcrumbHeader,
  BreadcrumbItem,
  Content,
  Divider,
  Item,
  Loading,
  SettingsDropdown,
} from 'components/utilities'
import useActiveSidebarItem from 'hooks/useActiveSidebarItem'
import { useAPI } from 'hooks/useAPI'
import { openModal } from 'hooks/useModal'
import useObject from 'hooks/useObject'
import { archiveMessageCategoryFolder } from 'thunks/message-category-folders/archive'
import { Request, retrieveMessageCategoryFolder } from 'thunks/message-category-folders/retrieve'
import { updateMessageCategoryFolder } from 'thunks/message-category-folders/update'
import notify from 'utils/notify'

import { MessageCategoryFolder } from './type'
// @ts-ignore
import { messageCategoryFolderQuery } from './type?query'

type RouteParams = {
  id: string
  folderId: string
}

type Props = {
  role: 'member' | 'federation' | 'alumni'
}

const SharedMessageBoardCategoryFolder: FC<Props> = ({ role }) => {
  const params = useParams<RouteParams>()
  useActiveSidebarItem('Message Board')

  const [folder, { patch }] = useObject<MessageCategoryFolder, Request>(retrieveMessageCategoryFolder, {
    query: messageCategoryFolderQuery,
    id: +params.folderId,
  })

  const [update, { timer }] = useAPI(updateMessageCategoryFolder)
  const handleUpdate = useCallback(
    async (name: string) => {
      if (!folder) return

      const [updated] = await update({
        id: folder.id,
        message_category_folder: {
          name,
        },
      })

      patch({
        name: updated.name,
      })
    },
    [patch, folder, update]
  )

  const [archive] = useAPI(archiveMessageCategoryFolder)
  const history = useHistory()
  const handleArchive = useCallback(async () => {
    await archive({
      id: +params.id,
    })

    notify('The folder has been archived')
    history.push(r[role].messageCategories.show(folder?.message_category.id))
  }, [role, folder, archive, params, history])

  const handleNewThread = useCallback(
    (thread: { id: number }) => {
      history.push(r[role].messageCategories.folder(folder?.id, thread.id))
    },
    [role, folder, history]
  )

  if (!folder) return <Loading />

  return (
    <>
      <Content>
        <Grid>
          <BreadcrumbHeader
            actions={
              <ButtonToolbar>
                <SettingsDropdown>
                  <Item icon="pencil" onClick={openModal('PlainInput')}>
                    Rename folder
                  </Item>
                  <Divider />
                  <Item icon="trash" onClick={openModal('Confirm')}>
                    Delete folder
                  </Item>
                </SettingsDropdown>
              </ButtonToolbar>
            }
          >
            <BreadcrumbItem link={r[role].messageCategories.root}>Message Board</BreadcrumbItem>
            <BreadcrumbItem link={r[role].messageCategories.show(folder.message_category.id)}>
              {folder.message_category?.name}
            </BreadcrumbItem>
            <BreadcrumbItem>{folder.name}</BreadcrumbItem>
          </BreadcrumbHeader>

          <MessageBoardCategory
            role={role}
            folder={folder}
            messageCategory={folder.message_category}
            messageThreads={folder.message_threads}
            onCreateThread={handleNewThread}
          />
        </Grid>
      </Content>

      <PlainInput
        title="Rename folder"
        label="Name"
        defaultValue={folder.name}
        action="Save"
        timer={timer}
        onSubmit={handleUpdate}
      />

      <ConfirmModal
        prompt={`Are you sure you want to archive the ${folder.name} folder?`}
        yes={`archive the ${folder.name} folder`}
        onConfirm={handleArchive}
      />
    </>
  )
}

export default SharedMessageBoardCategoryFolder
