import { orgHelpers } from 'helpers'
import moment from 'moment'
import { FC, useEffect, useMemo, useState } from 'react'
import r from 'routes'

import { useAPI } from 'hooks/useAPI'
import { useLegacyMemberOrAdmin } from 'hooks/useLegacyContext'
import useList from 'hooks/useList'
import { listEventsByUser, Request } from 'thunks/events/list-by-user'
import { listReports, Request as ReportRequest } from 'thunks/reports/list'

import ContributionTrackers from './contribution-trackers'
import Header from './header'
import Item from './item'
import KappaSigma from './kappa-sigma'
import { Event, Report } from './national-type'
// @ts-ignore
import { eventQuery, reportQuery } from './national-type?query'

const NationalMenu: FC = () => {
  const user = useLegacyMemberOrAdmin()

  const [allEvents] = useList<Event, Request>(listEventsByUser, {
    query: eventQuery,
    user_id: user.id,
    only_open: true,
  })

  const events = useMemo(
    () => allEvents?.sort((a, b) => a.purpose.localeCompare(b.purpose)).filter(e => e.owner_type !== 'Organization'),
    [allEvents]
  )

  const [reports, setReports] = useState<Report[]>()
  const [list] = useAPI<Report[], ReportRequest>(listReports)
  useEffect(() => {
    if (user.role !== 'admin') return

    list({
      query: reportQuery,
      start_date: moment().format('YYYY-MM-DD'),
      end_date: moment().format('YYYY-MM-DD'),
      federation_id: user.member.organization.federation.id,
    }).then(([reports]) => setReports(reports))
  }, [user, list])

  const groups = useMemo(() => {
    if (!events) return

    return events.reduce((acc, event) => {
      if (!acc[event.purpose]) acc[event.purpose] = []
      acc[event.purpose].push(event)
      return acc
    }, {} as Record<Event['purpose'], Event[]>)
  }, [events])

  if (!events || !groups) return <></>

  return (
    <ul className="sidebar-menu">
      <Header>{user.member.organization.federation.name}</Header>

      <KappaSigma />

      {reports &&
        reports.map(({ id, title }) => (
          <Item key={id} url={`/admin/reports/${id}`} icon="file-text-o">
            {title}
          </Item>
        ))}

      {groups['event'] && (
        <>
          {groups['event'].length > 6 ? (
            <Item
              url="/member/event-registrations/federation"
              icon="calendar"
            >{`View all ${groups['event'].length} events here`}</Item>
          ) : (
            <>
              {groups['event'].map(({ id, name }) => (
                <Item key={id} url={r.member.eventRegistrations.new(id)} icon="calendar">
                  {name}
                </Item>
              ))}
            </>
          )}
        </>
      )}

      {groups['form'] && (
        <>
          {groups['form'].length > 6 ? (
            <Item url="/member/form-submissions" icon="file-text-o">{`${groups['form'].length} forms to submit`}</Item>
          ) : (
            <>
              {groups['form'].map(({ id, name }) => (
                <Item key={id} url={r.member.eventRegistrations.new(id)} icon="file-text-o">
                  {name}
                </Item>
              ))}
            </>
          )}
        </>
      )}

      <ContributionTrackers />

      {orgHelpers.isNationalOrganization(user.member.organization) && (
        <Item url="/member/important-contacts" icon="address-book">
          Important Contacts
        </Item>
      )}
    </ul>
  )
}

export default NationalMenu
