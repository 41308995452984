import { FC } from 'react'

import { FaIcon, Money } from 'components/utilities'

import { PollOption } from '../show-container/type'
import s from './_VoteOption.module.scss'

type Props = {
  option: PollOption
  isWinner: boolean
  onImageClick: () => void
}

const VoteOption: FC<Props> = ({ option, isWinner, onImageClick }) => {
  return (
    <section data-id={option.id} className={s.VoteOption}>
      <header>
        <label>{option.title}</label>

        <aside>
          {typeof option.cost === 'number' && option.cost > 0 && <Money amount={option.cost} strip />}
          {isWinner && (
            <span className={s.Winner}>
              <FaIcon icon="check" /> <strong>winner</strong>
            </span>
          )}
        </aside>
      </header>
      {option.document && <main style={{ backgroundImage: `url(${option.document.url})` }} onClick={onImageClick} />}
    </section>
  )
}

export default VoteOption
