import { FC } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

import { FaIcon, Units } from 'components/utilities'

type Props = {
  billpay: {
    waiting: number
  }
}

const BillPayTooltip: FC<Props> = ({ billpay }) => {
  return billpay && billpay.waiting > 0 ? (
    <OverlayTrigger
      placement="right"
      overlay={
        <Tooltip id="billpay-tooltip">
          <Units count={billpay.waiting} noun="bill is" plural="bills are" /> awaiting your approval
        </Tooltip>
      }
    >
      <FaIcon exclamation-circle warning className="pull-right" />
    </OverlayTrigger>
  ) : null
}

export default BillPayTooltip
