import asyncThunk from 'utils/asyncThunk'

export type Request = {
  id: number
}

export const archivePaymentMethod = asyncThunk<Request, void>({
  name: 'payment-methods/archive',
  uri: ({ id }) => `/payment_methods/${id}`,
  method: 'DELETE',
})
