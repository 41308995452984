import { FC } from 'react'

import { ExpandableImage, FaButton, FaIcon } from 'components/utilities'

import { Document } from './'

type Props = {
  document: Document
}

const DownloadButton: FC<Props> = ({ document }) => {
  if (['image/jpeg', 'image/png', 'image/gif'].includes(document.mimetype)) {
    return (
      <ExpandableImage image={document.url} className="btn btn-secondary">
        <FaIcon image />
      </ExpandableImage>
    )
  }

  const icon = document.mimetype === 'application/pdf' ? 'file-text-o' : 'download'
  return <FaButton icon={icon} bsStyle="secondary" onClick={() => window.open(document.url, '_blank')} />
}

export default DownloadButton
