import classNames from 'classnames'
import PropTypes from 'prop-types'
import React, { ChangeEvent, FormEventHandler, HTMLProps, ReactNode, useContext } from 'react'
import { Col, FormControl, FormGroup, InputGroup } from 'react-bootstrap'

import { faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { FilenameInput, MaskedInput, MoneyInput } from 'components/utilities'

import { FormContext } from './context'

type InputProps = {
  autoComplete?: 'off'
  autoFocus?: boolean
  defaultValue?: string | number
  name?: string
  placeholder?: string
  textarea?: true | number
  type?: HTMLProps<HTMLInputElement>['type']
  maxLength?: number
  value?: string
  className?: string
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
}

export function Input(props: InputProps) {
  const { name } = props

  if ('mask' in props) {
    return <MaskedInput id={name} className="form-control" {...props} />
  }

  const propsSubset = _.omit(props, ['textarea'])

  if ('textarea' in props) {
    return (
      <FormControl
        componentClass="textarea"
        rows={props.textarea === true ? undefined : props.textarea}
        id={name}
        {...propsSubset}
        onChange={propsSubset.onChange as FormEventHandler<FormControl> | undefined}
      />
    )
  }

  return (
    <FormControl
      type="text"
      id={name}
      {...propsSubset}
      onChange={propsSubset.onChange as FormEventHandler<FormControl> | undefined}
    />
  )
}

type Props = {
  autoComplete?: 'off'
  autoFocus?: boolean
  disabled?: boolean
  errorKey?: string
  filename?: boolean
  hint?: React.ReactNode
  id?: number | string
  inputSize?: number
  inputWrapClassName?: string
  label?: ReactNode
  labelClassName?: string
  labelFor?: string
  labelSize?: number
  mask?: string
  maxLength?: number
  name?: string
  noFeedbackControl?: boolean
  placeholder?: string
  prefix?: string
  readOnly?: boolean
  rows?: number
  showAllErrors?: boolean
  suffix?: string
  tabIndex?: number
  textarea?: true | number
  type?: HTMLProps<HTMLInputElement>['type']
  validationState?: 'success' | 'warning' | 'error' | '' | null | undefined
  value?: string
  className?: string
  children?: React.ReactNode
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void
} & (
    | {
      money: true
      defaultValue?: number
      onChange: (value: string) => void
    }
    | {
      defaultValue?: string | number
      onChange?: (e: ChangeEvent<HTMLInputElement>) => void
    }
  )

export default function Field(props: Props) {
  const { errors } = useContext(FormContext)
  const { name, label, labelFor, validationState, children, errorKey, showAllErrors, className, inputWrapClassName } =
    props
  const inputSize = _.defaultTo(props.inputSize, 7)
  const labelSize = _.defaultTo(props.labelSize, 3)
  const labelClassName = _.defaultTo(props.labelClassName, `col-md-${labelSize} control-label`)

  const propsSubset = _.omit(props, [
    'label',
    'labelFor',
    'labelClassName',
    'labelSize',
    'children',
    'inputSize',
    'prefix',
    'suffix',
    'hint',
    'validationState',
    'noFeedbackControl',
    'money',
    'disabled',
    'filename',
    'errorKey',
    'showAllErrors',
    'className',
  ])

  let input = children
  if (!input) {
    if ('prefix' in props || 'suffix' in props) {
      input = (
        <InputGroup>
          {'prefix' in props ? <InputGroup.Addon>{props.prefix}</InputGroup.Addon> : null}
          {/* @ts-ignore */}
          <Input {...propsSubset} />
          {'suffix' in props ? <InputGroup.Addon>{props.suffix}</InputGroup.Addon> : null}
        </InputGroup>
      )
    } else if ('money' in props && props.money) {
      // @ts-ignore
      input = <MoneyInput {...propsSubset} />
    } else if ('filename' in props) {
      input = <FilenameInput {...propsSubset} />
    } else {
      // @ts-ignore
      input = <Input {...propsSubset} />
    }
  }

  let hint = _.isString(props.hint) ? [props.hint] : []
  if (React.isValidElement(props.hint)) {
    // @ts-ignore
    hint = [props.hint]
  }
  const error = _.get(errors || {}, errorKey ?? name ?? '')
  if (error) {
    hint = showAllErrors ? error : [error[0]]
  }
  // @ts-ignore
  hint =
    hint.length > 0 ? (
      hint.map(h => (
        <span key={h} className="help-block">
          {h}
        </span>
      ))
    ) : (
      <></>
    )

  const validState = validationState || (error ? 'error' : null)

  return (
    <FormGroup
      validationState={validState}
      className={`clearfix ${props.type === 'hidden' && 'hidden'} ${className || ''}`}
    >
      {label ? (
        <label htmlFor={labelFor || name} className={labelClassName}>
          {label}
        </label>
      ) : null}

      <Col md={inputSize} className={classNames(inputWrapClassName, 'relative')}>
        {input}
        {!('noFeedbackControl' in props) && validState === 'error' && (
          <FontAwesomeIcon icon={faExclamationTriangle} className="text-danger absolute h-6 right-12 top-4" />
        )}
        {hint}
      </Col>
    </FormGroup>
  )
}

Field.contextTypes = {
  errors: PropTypes.object,
}
