import { FC, useState } from 'react'
import { Button } from 'react-bootstrap'

import QuillEditor from 'components/quill'

type Props = {
  onSubmit: (message: string) => void
}

const NewComment: FC<Props> = ({ onSubmit }) => {
  const [message, setMessage] = useState('')

  return (
    <div className="hidden-print thread-form">
      <div className="share-widget clearfix">
        <QuillEditor onChange={setMessage} placeholder="Add your message..." value={message} />

        <div className="share-widget-actions">
          <div className="pull-right">
            <Button bsStyle="primary" onClick={() => onSubmit(message)}>
              Post
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NewComment
