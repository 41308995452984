import moment from 'moment'
import { FC } from 'react'
import { FormControl, InputGroup } from 'react-bootstrap'
import { useFormContext } from 'react-hook-form'

import ConnectedField from 'components/form/connected-field'
import { FaIcon } from 'components/utilities/font-awesome'
import { openModal } from 'hooks/useModal'

import { FormData } from './'
import ChargeAccounts from './charge-accounts'

type Account = {
  id: number
  name: string
}

type Props = {
  accountId?: number
  accounts: Account[]
  showMoreOptions: boolean
}

const Charge: FC<Props> = ({ accountId, accounts, showMoreOptions }) => {
  const { watch } = useFormContext<FormData>()

  const dueOn = watch('due_on')

  return (
    <>
      <ConnectedField label="Description" name="description" autoFocus />

      <ConnectedField label="Amount" name="amount" inputSize={5} money />

      <ChargeAccounts accounts={accounts} accountId={accountId} />

      {showMoreOptions && (
        <div>
          <hr />

          <ConnectedField name="due_on" label="Due date" inputSize={5}>
            <InputGroup className="w-full">
              <FormControl
                id="transaction.due_on"
                type="text"
                value={dueOn ? moment(dueOn).format('MMM D, YYYY') : ''}
                readOnly
              />

              <InputGroup.Addon onClick={openModal('DatePicker')} className="btn bg-white">
                <FaIcon calendar />
              </InputGroup.Addon>
            </InputGroup>
          </ConnectedField>

          <ConnectedField name="late_fee" label="Late fee" inputSize={5} disabled={!dueOn} money />
        </div>
      )}
    </>
  )
}

export default Charge
