import Container from 'container'
import { func, number, shape, string } from 'prop-types'
import React, { FC, useCallback, useState } from 'react'
import { Button } from 'react-bootstrap'
import { User } from 'resources'

import Date from 'components/utilities/date'
import connect from 'helpers/connect'
import notify from 'utils/notify'

type UserType = {
  id: number
  first_name: string
  unsubscribed_at: string | undefined
}

type Props = {
  user: UserType
  onUpdate: (unsubscribedAt: string | undefined) => void
}

const ReSubscribe: FC<Props> = ({ user, onUpdate }) => {
  const [isSaving, setIsSaving] = useState(false)

  const handleSubscribe = useCallback(() => {
    setIsSaving(true)

    User.subscribeById({
      id: user.id,
      query: 'user { first_name unsubscribed_at }',
      onSuccess: ({ data }: { data: UserType }) => {
        notify(`${user.first_name} has been subscribed!`)
        onUpdate(data.unsubscribed_at)
        setIsSaving(false)
      },
    })
  }, [user, onUpdate])

  const handleUnsubscribe = useCallback(() => {
    setIsSaving(true)

    User.unsubscribeById({
      id: user.id,
      onSuccess: ({ data }: { data: UserType }) => {
        notify(`${user.first_name} has been unsubscribed!`)
        onUpdate(data.unsubscribed_at)
        setIsSaving(false)
      },
      query: 'user { first_name unsubscribed_at }',
    })
  }, [user, onUpdate])

  return (
    <div>
      {user.unsubscribed_at ? (
        <div>
          <p>
            {user.first_name} unsubscribed from emails on <Date date={user.unsubscribed_at} short />.
          </p>
          <p>
            <Button bsSize="sm" className="m-t-1" onClick={handleSubscribe} disabled={isSaving}>
              re-subscribe {user.first_name} to messages
            </Button>
          </p>
        </div>
      ) : (
        <Button bsStyle="secondary" onClick={handleUnsubscribe} disabled={isSaving}>
          Unsubscribe {user.first_name} from messages
        </Button>
      )}
    </div>
  )
}

export default connect<Props>(ReSubscribe)
