import { FC } from 'react'
import { useAuthSelector } from 'store'

import { Avatar } from 'components/utilities'
import { hasAvatar } from 'types/user-functions'

import NavbarDropdown from './dropdown'

type Props = {
  onToggleSearch?: () => void
}

const NavbarMobile: FC<Props> = ({ onToggleSearch }) => {
  const { user } = useAuthSelector()

  return (
    <div className="navbar-header-small">
      {user && (
        <>
          <NavbarDropdown className="header-nav__user-dropdown">
            <Avatar
              avatar={hasAvatar(user) ? user.avatar : undefined}
              className="navbar-profile-avatar m-r-1"
            />
          </NavbarDropdown>

          <div className="navbar-toggle" role="button" onClick={onToggleSearch}>
            <span className="sr-only">Toggle search</span>
            <i className="fa fa-cog"></i>
          </div>
        </>
      )}
    </div>
  )
}

export default NavbarMobile
