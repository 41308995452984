import asyncThunk from 'utils/asyncThunk'

export type Request = {
  query: string
  id: number
  document: {
    labels: string[]
  }
}

export const updateDocumentLabels = asyncThunk<Request, any>({
  name: 'documents/labels',
  uri: ({ id }) => `/documents/${id}/labels`,
  method: 'PUT',
})
