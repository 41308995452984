import moment from 'moment'
import { FC, useMemo, useState } from 'react'
import { Col, Grid, Row } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import r from 'routes'

import { AddHourRecordModal, HourRecordModal } from 'components/hour-tracker'
import {
  Body, BreadcrumbHeader, BreadcrumbItem, Content, FaButton, Header, Loading, Portlet,
} from 'components/utilities'
import { ActionGroup } from 'components/utilities/header'
import useActiveSidebarItem from 'hooks/useActiveSidebarItem'
import { useLegacyMember } from 'hooks/useLegacyContext'
import useList from 'hooks/useList'
import { openModal } from 'hooks/useModal'
import useObject from 'hooks/useObject'
import { listHourRecords, Request as ListRequest } from 'thunks/hour-records/list'
import { Request, retrieveHourRequirement } from 'thunks/hour-requirements/retrieve'

import RecordsTable from './_Table'
import Thermometer from './_Thermometer'
import { HourRecord, HourRequirement } from './type'
// @ts-ignore
import { hourRecordQuery, hourRequirementQuery } from './type?query'

type RouteParams = {
  id: string
}

const MemberHourTrackerShow: FC = () => {
  useActiveSidebarItem('hour-tracker#member')
  const user = useLegacyMember()

  const params = useParams<RouteParams>()

  const [hourRequirement] = useObject<HourRequirement, Request>(retrieveHourRequirement, {
    query: hourRequirementQuery,
    id: +params.id,
  })

  const [allHourRecords, { add }] = useList<HourRecord, ListRequest>(listHourRecords, {
    query: hourRecordQuery,
    member_id: user.member.id,
    hour_requirement_id: +params.id,
  })
  const hourRecords = useMemo(() => allHourRecords?.sort(r => -moment(r.created_at).format('x')), [allHourRecords])

  const hoursCompleted = useMemo(
    (): number => hourRecords?.filter(r => r.approved_at).reduce((sum, r) => sum + r.hours, 0) || 0,
    [hourRecords]
  )

  const [modalRecord, setModalRecord] = useState<HourRecord>()

  if (!hourRequirement || !hourRecords) return <Loading />

  return (
    <>
      <Content>
        <Grid>
          <BreadcrumbHeader
            actions={
              <ActionGroup>
                <FaButton icon="clock-o" onClick={openModal('AddHourRecord')}>
                  Record hours
                </FaButton>
              </ActionGroup>
            }
          >
            <BreadcrumbItem link={r.member.hourTracker.root}>Hour tracker</BreadcrumbItem>
            <BreadcrumbItem>{hourRequirement.title}</BreadcrumbItem>
          </BreadcrumbHeader>
          <Row>
            <Col md={8}>
              <Portlet boxed>
                <Header portlet>{hourRequirement.title}</Header>
                <Body className="lh-18">{hourRequirement.description}</Body>
              </Portlet>

              <Portlet boxed>
                <Header portlet>Hours submitted</Header>
                <Body>
                  <RecordsTable
                    records={hourRecords}
                    onSelect={record => {
                      setModalRecord(record)
                      openModal('HourRecord', record.id)()
                    }}
                  />
                </Body>
              </Portlet>
            </Col>
            <Col md={4}>
              <Thermometer hoursCompleted={hoursCompleted} hourRequirement={hourRequirement} />
            </Col>
          </Row>
        </Grid>
      </Content>

      <AddHourRecordModal
        hourRequirementId={hourRequirement.id}
        organizationId={hourRequirement.organization.id}
        onCreate={add}
      />

      {modalRecord && (
        <HourRecordModal id={modalRecord.id} hourRecord={modalRecord} hourRequirement={hourRequirement} />
      )}
    </>
  )
}

export default MemberHourTrackerShow
