export const documentFolderQuery = `
  document_folder {
    id
    name
    is_private
    owner_type
    owner_id
    purpose
    data {
      document_count
    }
    document_folder {
      id
      name
    }
    recent_documents {
      id
      filename
      url
      mimetype
      size
      created_at
      document_labels {
        id
        label
      }
    }
    documents {
      id
      filename
      url
      mimetype
      size
      created_at
      document_labels {
        id
        label
      }
    }
    document_folders {
      id
      name
      data {
        document_count
      }
    }
    organization {
      id
      name
      secondary
    }
    executive_board_position {
      id
      title
    }
  }
`
