import asyncThunk from 'utils/asyncThunk'

export type Request = {
  id: number
  query: string
}

export const retrieveOrganization = asyncThunk<Request, any>({
  name: 'organizations/retrieve',
  method: 'GET',
  uri: ({ id }) => `/organizations/${id}`,
})
