import { FC } from 'react'

type Props = {
  address: {
    address: string
    address_2: string | undefined
    city: string
    state: string
    postal_code: string
    country: string
  }
  noLink?: boolean
  className?: string
}

const Address: FC<Props> = ({ address, noLink, className }) => {
  const formatted = encodeURI(`${address.address}, ${address.city} ${address.state} ${address.postal_code}`)

  const addressParts = []
  addressParts.push(<span key={0}>{address.address}</span>)
  if (address.address_2) {
    addressParts.push(<br key={1} />)
    addressParts.push(<span key={2}>{address.address_2}</span>)
  }
  addressParts.push(<br key={3} />)
  addressParts.push(
    <span key={4}>
      {address.city}, {address.state} {address.postal_code}
    </span>
  )

  if (noLink) {
    return <span className={className}>{addressParts}</span>
  }

  return (
    <a href={`geo://${formatted}`} className={className}>
      {addressParts}
    </a>
  )
}

export default Address
