import React from 'react'
import PropTypes from 'prop-types'

const { string, arrayOf, oneOfType, number } = PropTypes

export default class HiddenMultipleInput extends React.Component {
  static propTypes = {
    name: string,
    values: arrayOf(oneOfType([number, string])),
  }

  componentDidUpdate = prevProps => {
    const { values } = this.props

    if (values !== prevProps.values) {
      // trigger input event on new value because form reset errors on input change
      const event = document.createEvent('HTMLEvents')
      event.initEvent('input', true, true)
      this.inputRef.dispatchEvent(event)
    }
  }

  render = () => {
    const { name, values } = this.props
    const inputName = name.match(/\[\]$/) ? name : `${name}[]`

    return (
      <select
        ref={ref => (this.inputRef = ref)}
        name={inputName}
        className="hidden"
        value={values}
        onChange={() => {}}
        multiple
      >
        {_.map(values, v => (
          <option key={v} value={v} />
        ))}
      </select>
    )
  }
}
