import { FC } from 'react'
import { Col, Row } from 'react-bootstrap'

import { Date, Money } from 'components/utilities'

import PaymentStatus from './payment-status'

type PaymentPlan = {
  note?: string
  payment_plan_payments: {
    due_on: string
    amount: number
  }[]
}

type Props = {
  paymentPlan: PaymentPlan
  noStatus?: boolean
  className?: string
}

const Details: FC<Props> = ({ paymentPlan, noStatus, className }) => (
  <div className={`payment-plan-details ${className}`}>
    <div className="lh-2 m-t-1 m-b-2 m-l-1">
      {paymentPlan.payment_plan_payments.map((payment, i) => (
        <Row key={i}>
          {noStatus ? null : (
            <Col xs={2}>
              <PaymentStatus {...payment} />
            </Col>
          )}
          <Col xs={6} className="fw-semibold">
            <Date humanShort date={payment.due_on} />
          </Col>
          <Col xs={4} className="text-right">
            <Money amount={payment.amount} />
          </Col>
        </Row>
      ))}
    </div>

    {paymentPlan.note ? (
      <div>
        <p className="m-t-1">Note included with the request:</p>
        <blockquote className="fs-100">{paymentPlan.note}</blockquote>
      </div>
    ) : null}
  </div>
)

export default Details
