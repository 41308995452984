export const CC_UPCHARGE = 3.0 // Percent
export const ACH_UPCHARGE = 1.5 // Dollars
export const ACH_NO_UPCHARGE = 1.5 // Dollars

type Method = 'card' | 'bank' | 'paypal'

export default function getChargeAmount(amount: number, method: Method, ccUpcharge: boolean): string {
  const upcharge = getUpchargeAmount(amount, method, ccUpcharge)
  return (amount + upcharge).toFixed(2)
}

export function getUpchargeAmount(amount: number, method: Method, ccUpcharge: boolean) {
  if (amount === 0) return 0

  if (['card', 'paypal'].includes(method)) {
    if (ccUpcharge) {
      return amount * (100 / (100.0 - CC_UPCHARGE) - 1)
    }
    return 0
  }

  if (method === 'bank') {
    if (ccUpcharge) {
      return ACH_UPCHARGE
    }
    return ACH_NO_UPCHARGE
  }

  throw new Error(`Unable to determine upcharge: ${method}`)
}
