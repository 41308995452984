import moment, { Moment } from 'moment'

function parseInput(input: any, format: string | undefined, timeOfDay: keyof Moment): Moment {
  let output: Moment | undefined = undefined

  if (typeof input === 'undefined' || !input || input === '') {
    // @ts-ignore
    output = moment()[timeOfDay]('day')
  } else if (typeof input === 'string') {
    // @ts-ignore
    output = moment(input, format)[timeOfDay]('day')
  } else if (typeof input === 'function') {
    // @ts-ignore
    output = parseInput(input(moment()[timeOfDay]('day')), format, timeOfDay)
  } else if (input._isAMomentObject) {
    output = input[timeOfDay]('day').clone()
  }

  if (!output) {
    throw new Error('invalid date to parse')
  }

  return output
}

export default parseInput
