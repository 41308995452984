import { FC } from 'react'

type Props = {
  event: {
    id: number
    name: string
    maximum_tickets: number
  }
}

const NoMoreTickets: FC<Props> = ({ event }) => (
  <div className="account-wrapper account-wrapper-wide">
    <h3>{event.name} registration</h3>

    <div className="account-form account-body align-center">
      <div className="fs-125 m-b-1">All tickets were sold</div>
    </div>
  </div>
)

export default NoMoreTickets
