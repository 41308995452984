import classNames from 'classnames'
import { FC, useCallback } from 'react'
import { Button } from 'react-bootstrap'

import { ConfirmModal } from 'components/modals'
import { useAPI } from 'hooks/useAPI'
import { closeModal, openModal } from 'hooks/useModal'
import { refundPayment } from 'thunks/payments/refund'
import formatMoney from 'utils/format-money'
import notify, { notifyError } from 'utils/notify'

type Props = {
  payment: {
    id: number
    amount: number
    external_ref: string
    payment_method: {
      method: 'card' | 'bank' | 'paypal'
    }
    account_payments: {
      transfer_id: number
    }[]
    member: {
      user: {
        first_name: string
      }
    }
  }
  onRefund: () => void
}

const RefundButton: FC<Props> = ({ payment, onRefund }) => {
  const [refund, { timer }] = useAPI(refundPayment)
  const handleRefund = useCallback(async () => {
    try {
      await refund({
        id: payment.id,
      })
      notify({
        type: 'warning',
        message: 'The payment has been refunded',
      })
      closeModal({ closeAll: true })
      onRefund()
    } catch {
      notifyError('Unable to refund the payment')
    }
  }, [payment.id, refund, onRefund])

  const isBraintree = payment.external_ref && payment.external_ref.match(/^bt:/)
  if (!isBraintree) {
    return null
  }

  const nonRefundable = payment.account_payments.some(ap => !!ap.transfer_id)
  if (nonRefundable) {
    return (
      <Button disabled className="pull-left">
        Non-refundable
      </Button>
    )
  }

  return (
    <>
      <Button
        disabled={timer.isLoading}
        className={classNames('pull-left', { 'btn-loading': timer.isLoading })}
        onClick={openModal('Confirm', payment.id)}
      >
        Refund payment
      </Button>

      <ConfirmModal
        id={payment.id}
        prompt={`Are you sure you want to refund ${payment.member.user.first_name}'s ${formatMoney(
          payment.amount
        )} payment?`}
        yes="refund payment"
        showLoading
        onConfirm={handleRefund}
      />
    </>
  )
}

export default RefundButton
