import { FC } from 'react'
import { Modal } from 'react-bootstrap'
import { FormProvider } from 'react-hook-form'

import Button from 'components/form/button'
import ConnectedField from 'components/form/connected-field'
import modal from 'helpers/modal'
import { useAPIFormHandler } from 'hooks/useAPIFormHandler'
import { closeModal } from 'hooks/useModal'
import { createDocumentFolder, Request } from 'thunks/document-folders/create'
import notify from 'utils/notify'

import { DocumentFolder } from './type'
// @ts-ignore
import { documentFolderQuery } from './type?query'

type FormData = {
  name: string
}

type Props = {
  ownerId: number
  ownerType: 'Organization'
  onCreate: (data: DocumentFolder) => void
}

const AddDocumentFolderModal: FC<Props> = ({ ownerId, ownerType, onCreate }) => {
  const [create, { form, timer }] = useAPIFormHandler<FormData, DocumentFolder, Request>({
    model: 'document_folder',
    thunk: createDocumentFolder,
    request: data => ({
      query: documentFolderQuery,
      document_folder: {
        ...data,
        owner_type: ownerType,
        owner_id: ownerId,
      },
    }),
    onSuccess: folder => {
      notify(`${folder.name} was created`)
      closeModal()
      onCreate(folder)
    },
  })

  return (
    <Modal show onHide={closeModal}>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(create)}>
          <Modal.Header closeButton>
            <Modal.Title>Add a document folder</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <hr className="spacer-xs" />
            <ConnectedField name="name" label="Name" autoFocus />
          </Modal.Body>
          <Modal.Footer>
            <Button timer={timer}>Create folder</Button>
          </Modal.Footer>
        </form>
      </FormProvider>
    </Modal>
  )
}

export default modal<Props>('AddDocumentFolder', AddDocumentFolderModal)
