import asyncThunk from 'utils/asyncThunk'

export type Request = {
  query: string
  id: number
}

export const archivePoll = asyncThunk<Request, any>({
  name: 'polls/archive',
  uri: ({ id }) => `/polls/${id}`,
  method: 'DELETE',
})
