import { FC, Fragment, useCallback, useState } from 'react'
import { Col, Grid, Row } from 'react-bootstrap'
import {
  Action,
  ActionGroup,
  Body,
  ButtonBar,
  Content,
  EmptyState,
  Header,
  Loading,
  Portlet,
  PrintAction,
  TableAction,
} from 'components/utilities'
import Table, { Column, downloadCSV } from 'components/table'
import Status from 'components/reimbursements/status'
import ReviewProposedReimbursementModal from 'components/reimbursements/review-proposed-modal'
import RejectReimbursementModal from 'components/reimbursements/reject-modal'
import { FaButton } from 'components/utilities/font-awesome'
import { listReimbursements, Request } from 'thunks/reimbursements/list'
import useLegacyContext, { useLegacyMember } from 'hooks/useLegacyContext'
import useActiveSidebarItem from 'hooks/useActiveSidebarItem'
import { openModal } from 'hooks/useModal'
import useList from 'hooks/useList'

import RequestReimbursementModal from './request-reimbursement-modal'
import ReimbursementDetailsModal from './details-reimbursement-modal'

import Alerts from './_Alerts'

import { Reimbursement } from './type'
// @ts-ignore
import { reimbursementQuery } from './type?query'

const MemberReimbursements: FC = () => {
  useActiveSidebarItem('reimbursements#member')

  const { organization } = useLegacyContext()

  const user = useLegacyMember()

  const [reimbursementToView, setReimbursementToView] = useState<Reimbursement>()

  const [reimbursements, { refetch, timer }] = useList<Reimbursement, Request>(listReimbursements, {
    query: reimbursementQuery,
    user_id: user.id,
  })

  const [pendingReimbursements, { remove }] = useList<Reimbursement, Request>(listReimbursements, {
    query: reimbursementQuery,
    user_id: user.id,
    pending: true,
    order_column: 'created_at',
    order_direction: 'desc',
  })

  const handleReviewedReimbursement = useCallback(
    (reimbursement: Reimbursement) => {
      remove(reimbursement.id)
      refetch()
    },
    [refetch, remove]
  )

  const proposedReimbursements = (pendingReimbursements || []).filter(({ status }) => status === 'proposed')

  if (!reimbursements || !pendingReimbursements) return <Loading />

  return (
    <div>
      <Content>
        <Grid>
          <div>
            <Alerts reimbursements={proposedReimbursements} />

            <Header
              actions={
                <ActionGroup>
                  <Action icon="bullhorn" disabled={timer.isLoading} onClick={openModal('RequestReimbursement')}>
                    Request a reimbursement
                  </Action>
                </ActionGroup>
              }
              disabled={timer.isLoading}
            >
              Reimbursements
            </Header>

            <Portlet boxed>
              <Body table>
                <Table
                  csvName="Reimbursements"
                  data={reimbursements}
                  defaultSort={1}
                  defaultSortDir={false}
                  noResults={
                    <div>
                      <EmptyState
                        message="No reimbursements submitted yet"
                        action="Request a reimbursement"
                        modal="RequestReimbursement"
                      />
                    </div>
                  }
                >
                  <Column
                    width={20}
                    value="status"
                    formatter={(__: any, reimbursement: Reimbursement) => <Status {...reimbursement} />}
                  >
                    Status
                  </Column>

                  <Column width={14} value="created_at" date="MMM D, YYYY">
                    Requested
                  </Column>

                  <Column width={23} value="description">
                    Description
                  </Column>

                  <Column width={18} value="amount" align="right" money>
                    Amount
                  </Column>

                  <Column
                    width={25}
                    value="document"
                    align="right"
                    formatter={(document: Reimbursement['document']) =>
                      document ? (
                        <FaButton icon="cloud-download" onClick={() => window.open(document.url)}>
                          Download receipt
                        </FaButton>
                      ) : (
                        <em className="text-muted">no receipt uploaded</em>
                      )
                    }
                    noSort
                  />

                  <Column width={15} actions>
                    <FaButton
                      icon="search"
                      data-map-on-click
                      onClick={({ id }) => {
                        setReimbursementToView(reimbursements.find(r => r.id === id))
                        openModal('ReimbursementDetails', id)()
                      }}
                    >
                      Details
                    </FaButton>
                  </Column>
                </Table>
              </Body>
            </Portlet>

            <Row>
              <Col sm={9} lg={9} />
              <Col sm={3} lg={3}>
                <ButtonBar className="footer-toolbar">
                  <PrintAction disabled={timer.isLoading} />
                  <TableAction icon="download" disabled={timer.isLoading} onClick={downloadCSV('Reimbursements')} />
                </ButtonBar>
              </Col>
            </Row>
          </div>
        </Grid>
      </Content>

      {reimbursementToView && (
        <ReimbursementDetailsModal id={reimbursementToView.id} reimbursement={reimbursementToView} />
      )}

      {proposedReimbursements.map((reimbursement, i) => (
        <Fragment key={i}>
          <ReviewProposedReimbursementModal
            id={reimbursement.id}
            organizationId={organization!.id}
            reimbursement={reimbursement}
            onSuccess={handleReviewedReimbursement}
          />

          <RejectReimbursementModal
            id={reimbursement.id}
            reimbursement={reimbursement}
            onReject={handleReviewedReimbursement}
          />
        </Fragment>
      ))}

      <RequestReimbursementModal onSuccess={() => refetch()} />
    </div>
  )
}

export default MemberReimbursements
