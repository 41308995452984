import { useCallback, useMemo } from 'react'

import { useAPI } from 'hooks/useAPI'
import { closeModal } from 'hooks/useModal'
import { archiveDocument } from 'thunks/documents/archive'
import { Request, updateDocument } from 'thunks/documents/update'
import notify from 'utils/notify'

import { Document } from './'

export default function useManageDocument(
  document: Document,
  onUpdate: (document: Document) => void,
  onArchive: () => void
) {
  const [update, { timer }] = useAPI<Document, Request>(updateDocument)
  const handleUpdate = useCallback(
    async (filename: string) => {
      await update({
        query: 'document { id }',
        id: document.id,
        document: { filename },
      })

      onUpdate({
        ...document,
        filename,
      })
      notify('The file has been renamed.')
      closeModal()
    },
    [document, onUpdate, update]
  )

  const [archive] = useAPI(archiveDocument)
  const handleArchive = useCallback(async () => {
    await archive({
      id: document.id,
    })

    notify({
      message: `${document.filename} has been deleted.`,
      type: 'warning',
    })
    onArchive()
  }, [document, onArchive, archive])

  return useMemo(() => ({ handleUpdate, handleArchive, timer }), [handleUpdate, handleArchive, timer])
}
