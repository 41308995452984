import cx from 'classnames'
import React, { FC, ReactNode } from 'react'
import { OverlayTrigger, Popover } from 'react-bootstrap'

import Avatar from 'components/utilities/avatar'

type Props = {
  avatar?: {
    id: number
    public_url: string
  }
  popoverTitle: string
  popoverId: string
  onImageClick?: () => void
  children?: ReactNode
}

const Thumbnail: FC<Props> = ({ avatar, popoverTitle, popoverId, onImageClick, children }) => {
  const popover = (
    <Popover id={popoverId} title={popoverTitle}>
      <Avatar avatar={avatar} width={128} height={128} className="avatar" />
    </Popover>
  )

  return (
    <span>
      <OverlayTrigger trigger={['focus', 'hover']} placement="left" overlay={popover}>
        <div className={cx('d-inline-block c-pointer', { 'm-r-1': children })}>
          <Avatar avatar={avatar} width={32} height={32} onClick={onImageClick} />
        </div>
      </OverlayTrigger>

      {children}
    </span>
  )
}

export default Thumbnail
