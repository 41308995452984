import asyncThunk from 'utils/asyncThunk'

export type Request = {
  id: number
  query: string
  member_id?: number
  organization_id?: number
}

export const retrieveContributionTracker = asyncThunk<Request, any>({
  name: 'contribution-trackers/retrieve',
  uri: ({ id }) => `/contribution_trackers/${id}`,
})
