import { isGranted } from 'helpers'
import { FC, useCallback, useMemo } from 'react'
import { Grid } from 'react-bootstrap'
import { useHistory, useParams } from 'react-router-dom'
import r from 'routes'

import { BreadcrumbHeader, BreadcrumbItem, Content, Loading } from 'components/utilities'
import DocumentFolder from 'components/documents/folder'
import ManagedDocumentFolder from 'components/documents/folder/managed'
import { Request, retrieveDocumentFolder } from 'thunks/document-folders/retrieve'
import useActiveSidebarItem from 'hooks/useActiveSidebarItem'
import useLegacyContext from 'hooks/useLegacyContext'
import useObject from 'hooks/useObject'
import { isAlumni, isFederation } from 'types/user-functions'

import { DocumentFolder as DocumentFolderType } from 'components/documents/folder/type'
// @ts-ignore
import { documentFolderQuery } from 'components/documents/folder/type?query'

type RouteParams = {
  id: string
}

const SharedDocumentsSearch: FC = () => {
  useActiveSidebarItem('Documents')

  const params = useParams<RouteParams>()
  const history = useHistory()

  const { user } = useLegacyContext()

  const [folder, { patch }] = useObject<DocumentFolderType, Request>(retrieveDocumentFolder, {
    query: documentFolderQuery,
    id: +params.id,
  })

  const canManage = useMemo(() => isGranted(user, 'Documents'), [user])

  const linkToFolders = useMemo(() => {
    if (isFederation(user)) {
      return r.federation.documentFolders.root
    } else if (isAlumni(user)) {
      return r.alumni.documentFolders.root
    } else {
      return r.member.documentFolders.root
    }
  }, [user])

  const linkToFolder = useCallback(
    (id: number) => {
      if (isFederation(user)) {
        return r.federation.documentFolders.show(id)
      } else if (isAlumni(user)) {
        return r.alumni.documentFolders.show(id)
      } else {
        return r.member.documentFolders.show(id)
      }
    },
    [user]
  )

  if (!folder) {
    return <Loading />
  }

  return (
    <Content>
      <Grid>
        <BreadcrumbHeader>
          <BreadcrumbItem link={linkToFolders}>Documents</BreadcrumbItem>

          {folder.document_folder && (
            <BreadcrumbItem link={linkToFolder(folder.document_folder.id)}>
              {folder.document_folder.name}
            </BreadcrumbItem>
          )}

          <BreadcrumbItem>{folder.name}</BreadcrumbItem>
        </BreadcrumbHeader>

        {canManage ? (
          <ManagedDocumentFolder
            folder={folder}
            onUpdate={patch}
            onArchive={() => history.push(linkToFolders)}
            onSubfolderCreated={folder => {
              patch({
                id: folder.id,
                document_folders: [...folder.document_folders, folder],
              })
            }}
          />
        ) : (
          <DocumentFolder folder={folder} canUpload />
        )}
      </Grid>
    </Content>
  )
}

export default SharedDocumentsSearch
