import { FC, useEffect, useState } from 'react'
import { Grid } from 'react-bootstrap'
import { Link, useParams } from 'react-router-dom'
import r from 'routes'
import { useDateRangeSelector } from 'store'

import AddBudgetExpenseModal from 'components/budgets/add-budget-expense-modal'
import BudgetExpensesList from 'components/budgets/budget-expenses-list'
import Selector from 'components/terms/selector'
import {
  Action,
  ActionGroup,
  Body,
  BreadcrumbHeader,
  BreadcrumbItem,
  Content,
  EmptyState,
  Loading,
  Portlet,
} from 'components/utilities'
import useActiveSidebarItem from 'hooks/useActiveSidebarItem'
import { useLegacyMember } from 'hooks/useLegacyContext'
import { openModal } from 'hooks/useModal'
import useObject from 'hooks/useObject'
import { Request, retrieveBudget } from 'thunks/budgets/retrieve'

import RequestReimbursementModal from '../Reimbursements/request-reimbursement-modal'
import { Budget } from './type'
// @ts-ignore
import { budgetQuery } from './type?query'

type RouteParams = {
  id: string
}

const MemberBudgetsShow: FC = () => {
  useActiveSidebarItem('Budgets')
  const params = useParams<RouteParams>()
  const { dateRange } = useDateRangeSelector()
  const { member } = useLegacyMember()

  const [budget] = useObject<Budget, Request>(retrieveBudget, {
    id: +params.id,
    query: budgetQuery,
    start_date: dateRange?.start.format('YYYY-MM-DD'),
    end_date: dateRange?.end.format('YYYY-MM-DD'),
  })

  const [expenses, setExpenses] = useState<Budget['budget_expenses']>()
  useEffect(() => {
    setExpenses(budget?.budget_expenses)
  }, [budget])

  if (!budget || !expenses) return <Loading />

  return (
    <>
      <Content>
        <Grid>
          <BreadcrumbHeader
            actions={
              <ActionGroup>
                {budget.issued_cards.length > 0 && (
                  <Link to={r.member.cards.show(budget.issued_cards[0].id)} className="flex items-center mr-4">
                    {budget.issued_cards[0].name_on_card} ({budget.issued_cards[0].company} *
                    {budget.issued_cards[0].last_four})
                  </Link>
                )}
                <Action icon="exchange" onClick={openModal('RequestReimbursement')}>
                  Request reimbursement
                </Action>
              </ActionGroup>
            }
          >
            <BreadcrumbItem link={r.member.budgets.root}>Budgets</BreadcrumbItem>
            <BreadcrumbItem>{budget.name}</BreadcrumbItem>
          </BreadcrumbHeader>

          <Portlet boxed>
            <Body table>
              <div>
                <p>
                  <span className="fw-semibold">Budget Committee: </span>
                  {_.chain(budget.users)
                    .map(user => `${user.first_name} ${user.last_name}`)
                    .sort()
                    // @ts-ignore
                    .formatList()
                    .value()}
                  <br />
                </p>
                <hr />
              </div>

              <BudgetExpensesList
                budget={budget}
                budgetStartDate={dateRange!.start.format()}
                noResults={<EmptyState>No budget expenses recorded for {budget.name}</EmptyState>}
              />
            </Body>
          </Portlet>
          <Selector />
        </Grid>
      </Content>

      <AddBudgetExpenseModal
        id={budget.id}
        budgetId={budget.id}
        organizationId={member.organization.id}
        onCreate={expense => setExpenses([...expenses, expense])}
      />

      <RequestReimbursementModal budgetId={budget.id} />
    </>
  )
}

export default MemberBudgetsShow
