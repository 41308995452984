import { FC } from 'react'
import { Button } from 'react-bootstrap'

type Props = {
  isReady: boolean
  isSaving: boolean
  doSubmit: () => void
}

const AcceptButton: FC<Props> = ({ isReady, isSaving, doSubmit }) => (
  <Button onClick={doSubmit} disabled={!isReady || isSaving} bsStyle="secondary" className="pull-right">
    {isSaving ? 'Please wait...' : 'Accept'}
  </Button>
)

export default AcceptButton
