import { FC } from 'react'

import { Money } from 'components/utilities'

import s from './_VoteOption.module.scss'
import { PollOption } from './type'

type Props = {
  option: PollOption
  isSelected: boolean
  isMultiple: boolean
  onImageClick: () => void
  onToggle: (checked: boolean) => void
}

const VoteOption: FC<Props> = ({ option, isSelected, isMultiple, onImageClick, onToggle }) => {
  return (
    <section data-id={option.id} className={s.VoteOption}>
      <header>
        <label>
          <input type={isMultiple ? 'checkbox' : 'radio'} checked={isSelected} onChange={() => onToggle(!isSelected)} />
          {option.title}
        </label>
        {typeof option.cost === 'number' && option.cost > 0 && (
          <aside>
            <span>
              <Money amount={option.cost} strip />
            </span>
          </aside>
        )}
      </header>
      {option.document && (
        <main>
          <img style={{ backgroundImage: `url(${option.document.url})` }} onClick={onImageClick} alt="" />
        </main>
      )}
    </section>
  )
}

export default VoteOption
