import asyncThunk from 'utils/asyncThunk'

export type Request = {
  query: string
  payment_method: {
    stripe_intent: string
  }
}

export const createBankAccount = asyncThunk<Request, any>({
  name: 'payment-methods/create-bank-account',
  uri: '/payment_methods/create_bank_account',
  method: 'POST',
})
