import asyncThunk from 'utils/asyncThunk'

export type Request = {
  query: string
  id: number
  document: {
    filename: string
  }
}

export const updateDocument = asyncThunk<Request, any>({
  name: 'documents/update',
  uri: ({ id }) => `/documents/${id}`,
  method: 'PUT',
})
