import { FC } from 'react'
import r from 'routes'

import useLegacyContext from 'hooks/useLegacyContext'
import useList from 'hooks/useList'
import { listContributionTrackersByUser, Request } from 'thunks/contribution-trackers/list-by-user'

import Item from './item'

type ContributionTracker = {
  id: number
  title: string
}

const ContributionTrackersMenu: FC = () => {
  const { user } = useLegacyContext()

  const [trackers] = useList<ContributionTracker, Request>(listContributionTrackersByUser, {
    query: 'contribution_tracker { title purpose }',
    user_id: user.id,
  })

  if (!trackers) {
    return <></>
  }

  return (
    <>
      {trackers.map((tracker, idx) => (
        <Item key={idx} url={r.member.contributionTrackers.show(tracker.id)} icon="thermometer-three-quarters">
          {tracker.title}
        </Item>
      ))}
    </>
  )
}

export default ContributionTrackersMenu
