import queryString from 'qs'

import { Role } from 'types/user'

import T from './types'

export function downloadRequiredDocumentAcceptance(id: number) {
  window.openRef = window.open
  return {
    type: T.DOWNLOAD_REQUIRED_DOCUMENT_ACCEPTANCE_PDF,
    id,
  }
}

export function downloadAllTransfers(federationId: number) {
  window.openRef = window.open
  return {
    type: T.DOWNLOAD_ALL_TRANSFERS,
    federationId,
  }
}

export function downloadFederationMembershipReport(reportSubmissionId: number) {
  window.openRef = window.open
  return {
    type: T.DOWNLOAD_FEDERATION_MEMBERSHIP_REPORT,
    reportSubmissionId,
  }
}

export function registrationCSV({ organizationId, id }: { organizationId: number; id: number }) {
  window.openRef = window.open
  return {
    type: T.DOWNLOAD_REGISTRATION_CSV,
    organizationId,
    id,
  }
}

const TRANSACTIONS_LIMIT = 10 * 1000

export function transactionsCSV(options: { txn_type: string[]; created_by_role: Role }) {
  const txnTypeOptions = _.isNil(options.txn_type) ? {} : { txn_type: _.castArray(options.txn_type).join(',') }
  const createdByRoleOptions = _.isNil(options.created_by_role)
    ? {}
    : { created_by_role: _.castArray(options.created_by_role).join(',') }

  const processedOptions = Object.assign({}, options, txnTypeOptions, createdByRoleOptions, {
    page_size: TRANSACTIONS_LIMIT,
    page: 1,
  })

  const serializedQuery = queryString.stringify(processedOptions)

  return {
    type: T.DOWNLOAD_TRANSACTIONS_CSV,
    serializedQuery,
  }
}

export function membersCSV(options: Record<string, any>) {
  const serializedQuery = queryString.stringify(options)

  return {
    type: T.DOWNLOAD_MEMBERS_CSV,
    serializedQuery,
  }
}

export function donationsCSV() {
  return {
    type: T.DOWNLOAD_DONATIONS_CSV,
  }
}

export function requiredDocumentCSV(requiredDocumentId: number) {
  window.openRef = window.open
  return {
    type: T.DOWNLOAD_REQUIRED_DOCUMENT_CSV,
    requiredDocumentId,
  }
}

export function openNewBrowserTab(url: string) {
  window.openRef = window.open
  return {
    type: T.DOWNLOAD_OPEN_NEW_BROWSER_TAB,
    url,
  }
}
