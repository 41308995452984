import Humanize from 'humanize-plus'

type Options = {
  showPositive?: boolean
  showNegative?: boolean
  strip?: boolean
}

const formatMoney = (number: number, opts: Options = {}) => {
  if (number === undefined) return '$0.00'

  const absolute = Math.abs(number)
  const formatted = Humanize.formatNumber(absolute, 2)

  if (formatted === '0.00') {
    return '$0.00'
  }

  const prefixPositive = opts.showPositive && number > 0 ? '+' : ''
  const prefixNegative = opts.showNegative && number < 0 ? '-' : ''

  const complete = `${prefixPositive}${prefixNegative}$${formatted}`

  if (opts.strip) {
    return complete.replace(/\.00$/, '')
  }

  return complete
}

export default formatMoney
