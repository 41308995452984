import classNames from 'classnames'
import moment from 'moment'
import { FC, useCallback, useMemo, useState } from 'react'
import { DropdownButton, MenuItem } from 'react-bootstrap'
import { useAppDispatch, useHalfYearDateRangeSelector, useHalfYearsSelector } from 'store'

import DateRangeModal from 'components/modals/date-range'
import { openModal } from 'hooks/useModal'
import setHalfYearDateRange from 'thunks/half-year-date-range/set'
import { readableDateRange } from 'utils/readable-date-range'

type Props = {
  showAll?: boolean
  align?: 'top' | 'bottom'
  customRange?: true
  className?: string
}

const HalfYearsSelector: FC<Props> = ({ showAll = true, align = 'top', customRange = false, className }) => {
  const halfYears = useHalfYearsSelector()
  const { dateRange } = useHalfYearDateRangeSelector()

  const dispatch = useAppDispatch()
  const handleChange = useCallback(
    (eventKey: any) => {
      if (eventKey === 'all-time') {
        const dateRange = {
          start: moment(halfYears[halfYears.length - 1].started_on),
          end: moment(halfYears[0].ended_on).endOf('day'),
        }
        dispatch(setHalfYearDateRange(dateRange))
        return
      }

      const halfYear = halfYears.find(halfYear => halfYear.name === eventKey)
      if (!halfYear) return

      const dateRange = {
        start: moment(halfYear.started_on),
        end: moment(halfYear.ended_on).endOf('day'),
      }

      dispatch(setHalfYearDateRange(dateRange))
    },
    [halfYears, dispatch]
  )

  const dateRanges = useMemo(
    () =>
      halfYears.map(halfYear => ({
        name: halfYear.name,
        start: moment(halfYear.started_on),
        end: moment(halfYear.ended_on),
      })),
    [halfYears]
  )

  const [showAllOptions, setShowAllOptions] = useState(false)

  if (!dateRange) return <></>

  return (
    <>
      <DropdownButton
        title={<span className="m-r-05">{readableDateRange(dateRange, dateRanges)}</span>}
        pullRight
        dropup={align === 'top'}
        id="term-selector"
        onSelect={handleChange}
        className={classNames('drop-button-no-style', className)}
      >
        {halfYears.slice(0, showAllOptions ? halfYears.length : 4).map(halfYear => (
          <MenuItem key={halfYear.started_on} eventKey={halfYear.name}>
            {halfYear.name}
          </MenuItem>
        ))}

        {showAll && <MenuItem divider />}

        <>
          <MenuItem eventKey="show-all" onClick={() => setShowAllOptions(!showAllOptions)}>
            {showAllOptions ? 'Show fewer options' : 'Show all options'}
          </MenuItem>
        </>

        {customRange && (
          <MenuItem eventKey="custom-range" onClick={openModal('DateRange')}>
            Custom date range
          </MenuItem>
        )}

        {showAll && <MenuItem eventKey="all-time">All time</MenuItem>}
      </DropdownButton>
      <DateRangeModal
        start={dateRange.start}
        end={dateRange.end}
        onRangeChange={dateRange =>
          dispatch(
            setHalfYearDateRange({
              start: dateRange.startDate,
              end: dateRange.endDate,
            })
          )
        }
      />
    </>
  )
}

export default HalfYearsSelector
