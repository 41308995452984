import asyncThunk from 'utils/asyncThunk'

export type CustomField = {
  id: number
  name: string
  slug: string
  field_type: 'text' | 'checkbox' | 'select'
  is_readonly: boolean

  owner_type: 'Organization' | 'Federation'
  owner_id: number

  custom_field_options: {
    id: number
    value: string
  }[]
}

const query = `
  custom_field {
    id
    name
    slug
    field_type
    is_readonly
    owner_type
    custom_field_options {
      id
      value    
    }
  }
`

export type Request = {
  query?: string
  owner_type: 'Organization' | 'Federation'
  owner_id: number | string
  role?: 'member' | 'organization' | 'alumni'
}

export type Response = CustomField[]

export const listCustomFields = asyncThunk<Request, Response>({
  name: 'custom-fields/list',
  method: 'GET',
  uri: '/custom_fields',
  data: { query },
})
