import asyncThunk from 'utils/asyncThunk'

export type Request = {
  query: string
  federation_id?: number
  required_document_id?: number
  unscope?: boolean
}

export const listOrganizations = asyncThunk<Request, any>({
  name: 'organizations/list',
  uri: '/organizations',
  method: 'GET',
})
