import { FC } from 'react'
import { Col, Row } from 'react-bootstrap'

import Money from 'components/utilities/money'

type Props = {
  method: 'card' | 'bank' | 'paypal'
  chargeAmount: number
  balance: number
}

const UpCharge: FC<Props> = ({ method, chargeAmount, balance }) => {
  const settleAmount = Math.max(0, balance)

  return (
    <div>
      <Row>
        <Col md={9} mdPush={3}>
          <em>
            Your {method === 'card' ? 'card' : 'account'} will be {method === 'card' ? 'charged' : 'debited'}{' '}
            <Money amount={chargeAmount} />.
          </em>
        </Col>
      </Row>
      {settleAmount > 0 ? (
        <Row>
          <Col md={9} mdPush={3} className="m-t-05">
            <em>
              Paying by cash or check will settle your balance for <Money amount={settleAmount} />.
            </em>
          </Col>
        </Row>
      ) : null}
    </div>
  )
}

export default UpCharge
