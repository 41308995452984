import { FC } from 'react'
import Humanize from 'humanize-plus'

type Props = {
  size: number
}
const Size: FC<Props> = ({ size }) => {
  const formatted = Humanize.fileSize(size)
  return <span>{formatted}</span>
}

export default Size
