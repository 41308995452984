import { FC } from 'react'
import { Grid } from 'react-bootstrap'

import MessageBoardCategory from 'components/message-board/category'
import NewCategoryModal from 'components/message-board/new-category-modal'
import { MessageThread } from 'components/message-board/type'
import { Action, Content, EmptyState, Header, Loading } from 'components/utilities'
import useActiveSidebarItem from 'hooks/useActiveSidebarItem'
import { openModal } from 'hooks/useModal'

import { MessageCategory } from './type'

type Props = {
  role: 'member' | 'federation' | 'alumni'
  categories: MessageCategory[] | undefined
  onCreateCategory?: (category: Omit<MessageCategory, 'recently_active_message_threads'>) => void
  onCreateThread?: (categoryId: number, thread: MessageThread) => void
}

const SharedMessageCategoryIndex: FC<Props> = ({ role, categories, onCreateCategory, onCreateThread }) => {
  useActiveSidebarItem('Message Board')

  return (
    <>
      <Content>
        <Grid>
          <Header
            actions={
              onCreateCategory && (
                <Action bsStyle="default" icon="plus" onClick={openModal('NewCategory')}>
                  New Category
                </Action>
              )
            }
          >
            Message Board
          </Header>
          {categories?.length ? (
            <ul className="list-unstyled">
              {categories.map(category => (
                <li key={category.id} data-id={category.id}>
                  <MessageBoardCategory
                    role={role}
                    limit={3}
                    messageThreads={category.recently_active_message_threads || []}
                    messageCategory={category}
                    onCreateThread={thread => onCreateThread && onCreateThread(category.id, thread)}
                  />
                </li>
              ))}
            </ul>
          ) : categories ? (
            <EmptyState
              message="No categories have been created"
              action={onCreateCategory ? 'Create a category' : undefined}
              onClick={openModal('NewCategory')}
            />
          ) : (
            <Loading />
          )}
        </Grid>
      </Content>

      {onCreateCategory && <NewCategoryModal onCreate={category => onCreateCategory(category)} />}
    </>
  )
}

export default SharedMessageCategoryIndex
