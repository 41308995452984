import { useCallback } from 'react'
import { UseFormSetError } from 'react-hook-form'

import * as Sentry from '@sentry/react'

import { useAPI } from 'hooks/useAPI'
import { closeModal } from 'hooks/useModal'
import { createParent, Request } from 'thunks/parents/create'
import { Address } from 'types/address'
import { isAPIError, processValidationErrors } from 'utils/asyncThunk'
import notify from 'utils/notify'

import { Parent } from '../type'
// @ts-ignore
import { parentQuery } from '../type?query'

export type FormData = {
  user: {
    first_name: string
    last_name: string
    email: string
    phone_number: string

    address: Address
  }
}

export default function useCreateParent(
  memberId: number,
  onCreate: (parent: Parent) => void,
  setError: UseFormSetError<FormData>
) {
  const [create, { timer }] = useAPI<Parent, Request>(createParent)
  const onSubmit = useCallback(
    async (data: FormData) => {
      try {
        const [parent] = await create({
          query: parentQuery,
          parent: {
            member_id: memberId,
            user: {
              first_name: data.user.first_name || undefined,
              last_name: data.user.last_name || undefined,
              email: data.user.email || undefined,
              phone_number: data.user.phone_number || undefined,
              address: data.user.address,
            },
          },
        })
        Sentry.captureMessage('Parent created')
        onCreate(parent)

        if (parent.user.first_name) {
          notify(`${parent.user.first_name} was added!`)
        } else {
          notify('Parent was added!')
        }

        closeModal()
      } catch (err) {
        console.log('[useCreateParent ERROR]', err)
        if (isAPIError<'parent'>(err)) {
          processValidationErrors('parent', err, setError)
        }
      }
    },
    [memberId, create, setError, onCreate]
  )

  return [onSubmit, { timer }] as const
}
