import { FC, ReactNode } from 'react'

type Props = {
  className?: string
  children: ReactNode
}

const Phone: FC<Props> = ({ children, className }) => {
  if (!_.isString(children)) {
    return null
  }
  const parts = children.match(/^(\d{3})(\d{3})(\d{4})$/)
  const formatted = parts ? `(${parts[1]}) ${parts[2]}-${parts[3]}` : children
  return (
    <a href={`tel:${children}`} className={className}>
      {formatted}
    </a>
  )
}

export default Phone
