import moment from 'moment'
import { FC, useCallback, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { HourRequirement } from 'resources'

import Calendar from 'components/date-range/Calendar'
import { Field, Form, SubmitButton } from 'components/form'
import modal from 'helpers/modal'
import { closeModal } from 'hooks/useModal'
import notify from 'utils/notify'

import { HourRequirement as HourRequirementType } from './type'
// @ts-ignore
import { hourRequirementQuery } from './type?query'

type Props = {
  hourRequirement: HourRequirementType
  onUpdate: (hourRequirement: HourRequirementType) => void
}

const UpdateHourRequirementModal: FC<Props> = ({ hourRequirement, onUpdate }) => {
  const [date, setDate] = useState(() => moment(hourRequirement.completion_date))

  const handleUpdate = useCallback(
    ({ data }: { data: HourRequirementType }) => {
      notify('Requirement has been updated.')
      closeModal()
      onUpdate(data)
    },
    [onUpdate]
  )

  return (
    <Modal show onHide={closeModal}>
      <Form
        action={HourRequirement.update}
        id={hourRequirement.id}
        data={{
          hour_requirement: {
            completion_date: date.format('YYYY-MM-DD'),
          },
        }}
        query={hourRequirementQuery}
        onSuccess={handleUpdate}
      >
        <Modal.Header closeButton>
          <Modal.Title>Update hour requirement</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <hr className="spacer-xs" />

          <Field
            label="Title"
            name="hour_requirement.title"
            inputSize={8}
            defaultValue={hourRequirement.title}
            autoFocus
          />

          <Field
            label="Description"
            name="hour_requirement.description"
            textarea={6}
            inputSize={8}
            defaultValue={hourRequirement.description}
          />

          <Field
            label="Hours required"
            name="hour_requirement.hours_required"
            inputSize={3}
            defaultValue={hourRequirement.hours_required}
          />

          <Field label="Completed by" name="hour_requirement.completion_date" noFeedbackControl>
            <Calendar date={date} onChange={setDate} />
          </Field>
        </Modal.Body>
        <Modal.Footer>
          <SubmitButton offset={5}>Update requirement</SubmitButton>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default modal<Props>('UpdateHourRequirement', UpdateHourRequirementModal)
