import { formatName } from 'helpers'
import moment from 'moment'
import { forwardRef, useCallback, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { useDateRangeSelector } from 'store'

import { InputModal } from 'components/modals'
import createTable, { TableRef } from 'components/table-v2'
import PaymentDetailsModal from 'components/transactions/payment-details-modal'
import { Money, Name } from 'components/utilities'
import EmptyState from 'components/utilities/empty-state'
import useLegacyContext from 'hooks/useLegacyContext'
import { closeModal, openModal } from 'hooks/useModal'
import { isSuper } from 'types/user-functions'
import formatMoney from 'utils/format-money'
import notify from 'utils/notify'

import Description from './_Description'
import useFilteredTransactions from './_useFilteredTransactions'
import { Transaction } from './type'

type Props = {
  transactions: Transaction[]
  highlightedTransactionId?: number
  highlightedPaymentId?: number
  onPaymentRefund?: () => void
}

const { Table, Header, Column, Footer, Body, Row, Cell, FirstRow } = createTable<Transaction>()

const TransactionTable = forwardRef<TableRef, Props>(
  ({ transactions: allTransactions, highlightedTransactionId, highlightedPaymentId, onPaymentRefund }, ref) => {
    const { user } = useLegacyContext()
    const { dateRange } = useDateRangeSelector()
    const [selectedTransaction, setSelectedTransaction] = useState<Transaction>()
    const { outstandingBalance, transactions } = useFilteredTransactions(allTransactions)

    const handleTransactionUpdated = useCallback(
      ({ data }: { data: Transaction }) => {
        notify('Description updated')
        closeModal()

        const txn = transactions.find(t => t.id === `T${data.id}`)
        if (txn) {
          txn.description = data.description
        }
      },
      [transactions]
    )

    const handleClickDescription = useCallback((transaction: Transaction) => {
      setSelectedTransaction(transaction)
      openModal('Input')()
    }, [])

    const paymentIds = useMemo(
      () =>
        _.chain(transactions)
          .filter('payment')
          .groupBy('payment.id')
          .keys()
          .value()
          .map(id => +id),
      [transactions]
    )

    const hasOutstandingBalance = useMemo(
      () => formatMoney(Math.abs(outstandingBalance)) !== '$0.00',
      [outstandingBalance]
    )
    const endingBalance = useMemo(
      () => transactions.reduce((memo, transaction) => memo + transaction.amount, outstandingBalance),
      [outstandingBalance, transactions]
    )

    const balances = useMemo(() => {
      let balance = outstandingBalance
      return transactions.reduce((memo, transaction) => {
        balance += transaction.amount
        memo[transaction.id] = balance
        return memo
      }, {} as Record<string, number>)
    }, [outstandingBalance, transactions])

    const emptyState = useMemo(() => {
      if (!dateRange) return <EmptyState message="No transactions" />

      const startDate = dateRange.start.format('MMM D, YYYY')
      const endDate = dateRange.end.format('MMM D, YYYY')
      return <EmptyState message={`No transactions between ${startDate} and ${endDate}`} />
    }, [dateRange])

    return (
      <>
        <Table ref={ref} data={transactions} noSort empty={emptyState}>
          <Header>
            <Column width={14} noSort>
              Date
            </Column>
            <Column width={16} noSort>
              Created by
            </Column>
            <Column width={42} noSort>
              Description
            </Column>
            <Column width={14} align="right" noSort>
              Amount
            </Column>
            <Column width={14} align="right" noSort>
              Balance
            </Column>
          </Header>
          {hasOutstandingBalance && (
            <FirstRow>
              <tr>
                <td>{moment(dateRange?.start).format("MMM D 'YY")}</td>
                <td>GCM Office</td>
                <td>Outstanding balance</td>
                <td className="text-right">
                  <Money praise plus amount={outstandingBalance} />
                </td>
                <td className="text-right">
                  <Money praise plus amount={outstandingBalance} />
                </td>
              </tr>
            </FirstRow>
          )}
          <Body>
            {transaction => (
              <Row key={transaction.id} id={transaction.id}>
                <Cell>{moment(transaction.created_at).format("MMM D 'YY")}</Cell>
                <Cell
                  value={
                    transaction.created_by.last_name
                      ? formatName(transaction.created_by, 'short')
                      : transaction.created_by.first_name
                  }
                >
                  {transaction.created_by.member && user.role === 'admin' ? (
                    <Link to={`/admin/members/${transaction.created_by.member.id}`}>
                      <Name user={transaction.created_by} />
                    </Link>
                  ) : (
                    <Name user={transaction.created_by} />
                  )}
                </Cell>
                <Cell value={transaction.description}>
                  <Description
                    transaction={transaction}
                    isHighlighted={
                      transaction.id === `AP${highlightedPaymentId}` ||
                      transaction.id === `T${highlightedTransactionId}`
                    }
                    onClick={() => handleClickDescription(transaction)}
                  />
                </Cell>
                <Cell value={`$${transaction.amount.toFixed(2)}`}>
                  <Money amount={transaction.amount} praise plus />
                </Cell>
                <Cell value={`$${balances[transaction.id]?.toFixed(2)}`}>
                  <Money amount={balances[transaction.id]} praise plus />
                </Cell>
              </Row>
            )}
          </Body>
          {transactions.length > 0 && (
            <Footer className="table-footer-totals">
              <td colSpan={4} className="text-right">
                Total:
              </td>
              <td className="text-right">
                <Money amount={endingBalance} praise plus />
              </td>
            </Footer>
          )}
        </Table>

        {isSuper(user!) &&
          onPaymentRefund &&
          paymentIds.map(id => <PaymentDetailsModal key={id} id={id} onRefund={onPaymentRefund} />)}

        {selectedTransaction && (
          <InputModal
            title="Update description"
            button="Update description"
            label="Description"
            defaultValue={_.get(selectedTransaction, 'description')}
            method="PUT"
            action={`/api/transactions/${`${selectedTransaction.id}`.substring(1)}`}
            name="transaction.description"
            onSuccess={handleTransactionUpdated}
          />
        )}
      </>
    )
  }
)

export default TransactionTable
