import { FC } from 'react'
import { Modal } from 'react-bootstrap'

import Uploader, { File } from 'components/uploader'
import { ImageCropper } from 'components/utilities'
import modal from 'helpers/modal'
import { closeModal } from 'hooks/useModal'
import getS3UploadInfo from 'utils/getS3UploadInfo'

type Props = {
  onSuccess: (file: File) => void
}

const UploadAvatarModal: FC<Props> = ({ onSuccess }) => {
  return (
    <Modal show onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>Upload an avatar</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="upload-wrapper">
          <Uploader
            noun="pic"
            getS3Info={getS3UploadInfo()}
            onSuccess={onSuccess}
            preprocessor={ImageCropper}
            mimeTypes={['image/jpeg', 'image/png']}
          />
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default modal<Props>('UploadAvatar', UploadAvatarModal)
