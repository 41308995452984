import { useCallback } from 'react'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { DefaultValues, Path, useForm, UseFormReturn } from 'react-hook-form'

import { isAPIError, isGenericAPIError, processValidationErrors } from 'utils/asyncThunk'
import { notifyError } from 'utils/notify'
import objectKeys from 'utils/object-keys'

import { ActionTimer } from './useActionTimer'
import { APIThunk, Headers, useAPI } from './useAPI'

export type ErrorCallback = (statusCode: number, error: any) => void

export type FormHandlerParams<
  FormData extends Record<string, any>,
  Response extends any = any,
  Request extends Record<string, any> = any
> = {
  model: string
  thunk: APIThunk<Request, Response>
  defaultValues?: DefaultValues<FormData>
  validate?: (data: FormData) => Partial<Record<Path<FormData>, string>> | undefined
  resolver?: (data: any) => Promise<{ values: any; errors: {} } | { values: {}; errors: any }>
  request: (
    data: FormData,
    form: UseFormReturn<FormData>,
    { timer }: { timer: ActionTimer }
  ) => Request | Promise<Request>
  onSuccess: (
    resp: Response,
    { request, headers, form }: { request: Request; headers: Headers; form: UseFormReturn<FormData> }
  ) => void
  onError?: ErrorCallback
}

export function useAPIFormHandler<
  FormData extends Record<string, any>,
  Response extends any,
  Request extends Record<string, any>
>({
  model,
  thunk,
  defaultValues,
  resolver,
  validate,
  request,
  onSuccess,
  onError,
}: FormHandlerParams<FormData, Response, Request>) {
  const form = useForm<FormData>({ defaultValues, resolver })
  const [api, { timer }] = useAPI<Response, Request>(thunk)

  // Re-render form when errors change
  useDeepCompareEffect(() => {}, [form.formState.errors])

  const handler = useCallback(
    async (formData: FormData) => {
      try {
        form.clearErrors()

        if (validate) {
          const errors = validate(formData)
          if (errors && Object.keys(errors).length > 0) {
            objectKeys(errors).forEach(field => {
              form.setError(field, { message: errors[field] })
            })
            return
          }
        }

        const req = await request(formData, form, { timer })
        const [data, headers] = await api(req)

        onSuccess && onSuccess(data, { request: req, headers, form })

        return data
      } catch (err) {
        if (isAPIError(err)) {
          processValidationErrors(model, err, form.setError)
          onError && onError(err.statusCode, err.error)
        } else if (isGenericAPIError(err)) {
          notifyError(err.error ?? 'unable to save changes')
        } else {
          console.log('[useAPIFormHandler ERROR]', err)
          notifyError('unable to save changes')
        }
      }
    },
    [api, validate, request, onSuccess, onError, form, timer, model]
  )

  return [handler, { timer, form }] as const
}
