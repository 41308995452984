import { createAsyncThunk } from '@reduxjs/toolkit'

import { download } from './'

export type Request = {
  memberId: number
}

export type Response = void

const downloadPDFBill = createAsyncThunk<Response, Request>('downloads/pdf-bill', async req => {
  window.openRef = window.open
  download(`/${req.memberId}/bill.pdf`)
})

export default downloadPDFBill
