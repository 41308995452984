import classNames from 'classnames'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { Button, Col, FormGroup, Modal, Row } from 'react-bootstrap'

import BraintreeBadge from 'components/braintree/badge'
import BraintreeForm from 'components/braintree/form'
import { hasErrors } from 'components/form'
import { Loading, Money, MoneyInput, PaymentMethodIcon, Toggle } from 'components/utilities'
import modal from 'helpers/modal'
import { useAPI } from 'hooks/useAPI'
import useLegacyContext from 'hooks/useLegacyContext'
import { closeModal } from 'hooks/useModal'
import { braintreeAuthorization } from 'thunks/braintree/authorization'
import { chargeCreditCard } from 'thunks/payments/credit-card'
import notify from 'utils/notify'

import { Payment } from './charge-credit-card-type'
// @ts-ignore
import { paymentQuery } from './charge-credit-card-type?query'

type Props = {
  memberId: number
  ccUpcharge: boolean
  onPayment: (payment: Payment) => void
}

const ChargeCreditCardModal: FC<Props> = ({ memberId, ccUpcharge, onPayment }) => {
  const { user } = useLegacyContext()

  const [isReady, setIsReady] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [cardType, setCardType] = useState<string>()

  const [authorization, setAuthorization] = useState<string>()
  const [authorize] = useAPI(braintreeAuthorization)
  useEffect(() => {
    authorize({}).then(([{ authorization }]) => setAuthorization(authorization))
  }, [authorize])

  const [errors, setErrors] = useState<Record<string, string>>({})
  const handleError = (errors: Record<string, string>) => {
    setErrors(errors)
    setIsLoading(false)
  }

  const [validityErrors, setValidityErrors] = useState<Record<string, boolean>>({})
  const handleValidityErrorChange = useCallback(
    (field: string, validity: boolean) => {
      const errors = { ...validityErrors }
      if (validity) {
        delete errors[field]
      } else {
        errors[field] = true
      }
      setValidityErrors(errors)
    },
    [validityErrors]
  )

  const [amount, setAmount] = useState('')
  const chargeAmount = useMemo((): number => {
    if (!ccUpcharge) return +amount

    const truncated = (+amount / 0.97).toFixed(2)
    return parseFloat(truncated)
  }, [amount, ccUpcharge])

  const [charge] = useAPI(chargeCreditCard)
  const handlePayment = useCallback(
    async ({ nonce }: { nonce: string }) => {
      setIsLoading(true)
      try {
        const [payment] = await charge({
          query: paymentQuery,
          payment: {
            amount: chargeAmount,
            source: `web/${user!.role}`,
            member_id: memberId,
            braintree_nonce: nonce,
          },
        })

        onPayment(payment)
        notify('The card has been successfully charged.')
        closeModal()
        setIsLoading(false)
      } catch (err) {
        setErrors(err as Record<string, string>)
        setIsLoading(false)
      }
    },
    [charge, chargeAmount, memberId, onPayment, user]
  )

  const isDisabled = useMemo((): boolean => {
    if (!isReady) return true
    if (cardType === 'american-express') return true
    if (Object.keys(validityErrors).length) return true
    return +amount <= 0
  }, [amount, cardType, isReady, validityErrors])

  return (
    <Modal show onHide={closeModal} dialogClassName="credit-card-modal">
      <BraintreeForm
        authorization={authorization}
        onBraintreeReady={() => setIsReady(true)}
        onError={handleError}
        onProcessPayment={handlePayment}
        onCardTypeChange={setCardType}
        onValidityErrorChange={handleValidityErrorChange}
      >
        <Modal.Header closeButton>
          <Modal.Title>Charge their credit card</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <hr className="spacer-xs" />

          {!isReady && (
            <div className="loading">
              <Loading />
            </div>
          )}

          <div className={classNames({ hidden: !isReady })}>
            <FormGroup
              validationState={hasErrors(errors, 'payment', 'number') || validityErrors.number ? 'error' : null}
            >
              <Row>
                <Col sm={12}>
                  <div id="number" className="form-control" />

                  <PaymentMethodIcon icon={cardType || 'unknown'} size={36} />

                  <Toggle show={cardType === 'american-express'}>
                    <span className="help-block text-danger m-l-1">Sorry, we don&apos;t accept American Express</span>
                  </Toggle>
                </Col>
              </Row>
            </FormGroup>

            <Row>
              <Col sm={4}>
                <FormGroup
                  validationState={
                    hasErrors(errors, 'payment', 'expirationDate') || validityErrors.expirationDate ? 'error' : null
                  }
                >
                  <div id="expirationDate" className="form-control" />
                </FormGroup>
              </Col>
              <Col sm={3}>
                <FormGroup validationState={hasErrors(errors, 'payment', 'cvv') || validityErrors.cvv ? 'error' : null}>
                  <div id="cvv" className="form-control" />
                </FormGroup>
              </Col>
              <Col sm={5}>
                <FormGroup validationState={hasErrors(errors, 'payment', 'avs') || validityErrors.avs ? 'error' : null}>
                  <div id="postalCode" className="form-control" />
                </FormGroup>
              </Col>
            </Row>

            {hasErrors(errors, 'payment', '_') && (
              <Row className="m-b-2">
                <Col sm={12} className="text-danger fw-semibold">
                  {_.get(errors, 'payment._')[0]}
                </Col>
              </Row>
            )}

            <MoneyInput
              name="payment.amount"
              placeholder="Amount to pay"
              onChange={val => setAmount(val.replace(/[^0-9.]+/, ''))}
              groupClassName={classNames({
                'm-t-1': true,
                'has-error': hasErrors(errors, 'payment', 'amount'),
              })}
            />

            {hasErrors(errors, 'payment', 'amount') && (
              <Row>
                <Col sm={12} className="text-danger m-t-05 m-b-05">
                  Please enter a valid amount
                </Col>
              </Row>
            )}

            {ccUpcharge && (
              <em className="help-block">
                The card will be charged <Money amount={chargeAmount} />.
              </em>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <BraintreeBadge height={28} className="pull-left" />

          <Button
            type="submit"
            bsStyle="primary"
            disabled={isDisabled}
            className={classNames({
              'btn-loading': isLoading,
            })}
          >
            Make payment
          </Button>
        </Modal.Footer>
      </BraintreeForm>
    </Modal>
  )
}

export default modal<Props>('ChargeCreditCard', ChargeCreditCardModal)
