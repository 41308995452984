import asyncThunk from 'utils/asyncThunk'

export type Request = {
  id: number
}

export type Ledgers = Record<
  number,
  {
    id: number
    name: string
    is_active: boolean
    is_default_account: boolean
    scope: 'user' | 'organization'
    weight: number
    ledger: {
      id: number
      description: string
      amount: number
      txn_type: 'charge' | 'credit' | 'payment'
      due_on?: string
      late_fee?: number
      created_at: string
      created_by: {
        id: number
        first_name: string
        last_name: string
      }
    }[]
  }
>

export type Headers = {
  'x-next-payment-amount': string
  'x-next-payment-due-on': string
}

export const memberLedgers = asyncThunk<Request, Ledgers, Headers>({
  name: 'members/ledgers',
  uri: ({ id }) => `/members/${id}/ledgers`,
  method: 'GET',
})
