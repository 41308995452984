import { FC, useCallback, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useHalfYearsSelector } from 'store'

import { Field } from 'components/form'
import modal, { ModalProps } from 'helpers/modal'
import { closeModal } from 'hooks/useModal'
import { HalfYear } from 'slices/halfYears'

type OwnProps = {
  onSelect: (halfYear: HalfYear) => void
}

type Props = ModalProps<OwnProps>

const HalfYearSelectModal: FC<Props> = ({ onSelect }) => {
  const halfYears = useHalfYearsSelector()
  const [halfYearIndex, setHalfYearIndex] = useState(1)

  const handleSelect = useCallback(() => {
    onSelect(halfYears[halfYearIndex])
  }, [halfYearIndex, halfYears, onSelect])

  return (
    <Modal show onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>Select which half-year</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <hr className="spacer-xs" />

        <Field label="Half-year" name="half_year.half">
          <select
            value={halfYearIndex}
            className="form-control"
            onChange={e => setHalfYearIndex(e.target.value as any)}
            id="half_year.half"
          >
            {halfYears.map((halfYear, index) => (
              <option key={halfYear.name} value={index}>
                {halfYear.name}
              </option>
            ))}
          </select>
        </Field>
      </Modal.Body>
      <Modal.Footer>
        <Button bsStyle="secondary" onClick={handleSelect} disabled={!halfYearIndex}>
          Select
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default modal<OwnProps>('HalfYearSelect', HalfYearSelectModal)
