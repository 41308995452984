import { FC } from 'react'
import { Grid } from 'react-bootstrap'

import { Body, Content, Header, Loading, Name, Portlet } from 'components/utilities'
import useActiveSidebarItem from 'hooks/useActiveSidebarItem'
import { useLegacyMember } from 'hooks/useLegacyContext'
import useList from 'hooks/useList'
import {
  listExecutiveBoardPositions, Request as ExecutiveBoardPositionRequest,
} from 'thunks/executive-board-positions/list'

import { ExecutiveBoardPosition } from './type'
// @ts-ignore
import { executiveBoardPositionQuery } from './type?query'

const MemberExecutiveBoard: FC = () => {
  useActiveSidebarItem('Executive Board')

  const {
    member: { organization },
  } = useLegacyMember()

  const [boardPositions] = useList<ExecutiveBoardPosition, ExecutiveBoardPositionRequest>(listExecutiveBoardPositions, {
    query: executiveBoardPositionQuery,
    organization_id: organization.id,
    federation_id: organization.federation.id,
  })

  if (!boardPositions) return <Loading />

  return (
    <Content>
      <Grid>
        <div>
          <Header>Executive Board</Header>

          <Portlet boxed>
            <Body>
              <dl className="dl-horizontal">
                {boardPositions.map((position, i) => (
                  <div key={i} className="m-b-2">
                    <div>
                      <dt>{position.title}</dt>
                      <dd>
                        {position.member ? (
                          <Name user={position.member.user} natural />
                        ) : (
                          <em className="text-muted">no one</em>
                        )}
                      </dd>
                    </div>
                  </div>
                ))}
              </dl>
            </Body>
          </Portlet>
        </div>
      </Grid>
    </Content>
  )
}

export default MemberExecutiveBoard
