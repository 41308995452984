import { FC, Fragment, useCallback, useEffect, useMemo, useState } from 'react'
import { Col } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import r from 'routes'
import * as yup from 'yup'

import { Body, Loading, Portlet } from 'components/utilities'
import { useAPI } from 'hooks/useAPI'
import useLegacyContext from 'hooks/useLegacyContext'
import useList from 'hooks/useList'
import useYupValidationResolver, { ValidationErrors } from 'hooks/useYupValidationResolver'
import { bootstrap as bootstrapFn } from 'thunks/auth/bootstrap'
import bootstrapQuery from 'thunks/auth/bootstrap-query'
import {
  createRequiredDocumentAcceptance as createReqDocAcceptance,
  Request as ReqDocRequest,
} from 'thunks/required-document-acceptances/create'
import { listRequiredDocuments, Request as RequiredDocumentRequest } from 'thunks/required-documents/list'
import { isMember, isParent } from 'types/user-functions'
import notify from 'utils/notify'

import AcceptButton from './_accept-button'
import PaymentMethod from './_payment-method'
import PaymentOption from './_payment-option'
import Questions, { Answer } from './_questions'
import Signature from './_signature'
import { RequiredDocument as RequiredDocumentType } from './type'
// @ts-ignore
import { requiredDocumentQuery } from './type?query'

export type Acceptance = {
  payment_plan_option: number
  has_signature: boolean
  payment_method_id?: number
  answers?: Answer[]
}

const initialAcceptance: Acceptance = {
  payment_plan_option: 0,
  has_signature: false,
  answers: [],
}

const schema = yup.object().shape({
  payment_plan_option: yup.number().required(),
  has_signature: yup.boolean().required(),
  payment_method_id: yup.number(),

  answers: yup.array().of(
    yup.object().shape({
      answer: yup.string().when('mandatory', ([mandatory], schema) => {
        return mandatory ? schema.min(1).required('this answer is a required field') : schema
      }),
      mandatory: yup.boolean(),
    })
  ),
})

type ValidationData = yup.InferType<typeof schema>
export type Errors = ValidationErrors<ValidationData>

const RequiredDocument: FC = () => {
  const history = useHistory()

  const { user } = useLegacyContext()

  const [acceptance, setAcceptance] = useState<Acceptance>(initialAcceptance)
  const [errors, setErrors] = useState<Errors>({})
  const [isSaving, setIsSaving] = useState(false)

  const [requiredDocuments, { remove: removeRequiredDocument }] = useList<
    RequiredDocumentType,
    RequiredDocumentRequest
  >(listRequiredDocuments, {
    query: requiredDocumentQuery,
  })
  const doc = useMemo(() => {
    if (requiredDocuments) return requiredDocuments[0]
  }, [requiredDocuments])
  useEffect(() => {
    const answers = doc?.custom_questions.map(q => ({ answer: '', mandatory: q.mandatory }))

    setAcceptance({ ...initialAcceptance, answers: answers })
  }, [doc])

  const [bootstrap, { timer }] = useAPI(bootstrapFn)

  // if no required documents refetch user to get updated has_required_documents
  useEffect(() => {
    if (!user || !requiredDocuments) return

    if (!doc) {
      bootstrap({
        query: bootstrapQuery,
        morphed: !!localStorage.getItem('rootToken'),
      })
      return
    }
  }, [bootstrap, doc, requiredDocuments, user])

  // redirect if user has completed all required documents
  useEffect(() => {
    if (!user || !requiredDocuments) return

    if (!isMember(user) && !isParent(user)) {
      history.push(r.root)
      return
    }

    if (!user.data.has_required_documents) {
      const role = user.role
      history.push(r[role].root)
    }
  }, [history, requiredDocuments, user])

  const initCurrentDocument = useCallback(() => {
    if (!doc) {
      bootstrap({
        query: bootstrapQuery,
        morphed: !!localStorage.getItem('rootToken'),
      })
      return
    }

    const answers = doc.custom_questions.map(q => ({ answer: '', mandatory: q.mandatory }))

    setAcceptance({ ...initialAcceptance, answers: answers })
    setErrors({})
    setIsSaving(false)
  }, [bootstrap, doc])

  const onUpdateAcceptance = useCallback(
    (key: string, val: Acceptance[keyof Acceptance]) => {
      setAcceptance({ ...acceptance, [key]: val })
      setErrors({})
    },
    [acceptance]
  )

  const isReady = useMemo(() => {
    if (!doc) return false

    if (doc.payment_schedule) {
      if (!acceptance.payment_method_id) return false

      const option = _.get(doc, `payment_schedule.options[${acceptance.payment_plan_option}]`)
      if (!option) return false
    }

    return !(doc.acceptance_type === 'signature' && !acceptance.has_signature)
  }, [acceptance.has_signature, acceptance.payment_method_id, acceptance.payment_plan_option, doc])

  const resolver = useYupValidationResolver<ValidationData>(schema)
  const [createDocAcceptance] = useAPI<{ id: number }, ReqDocRequest>(createReqDocAcceptance)
  const doSubmit = useCallback(async () => {
    if (!doc) return

    setIsSaving(true)

    try {
      const validationErrors = await resolver({ ...acceptance })

      if (Object.keys(validationErrors.errors).length > 0) {
        setErrors(validationErrors.errors)
        setIsSaving(false)
        return
      }

      await createDocAcceptance({
        query: 'required_document_acceptance { id }',
        required_document_acceptance: { ...acceptance, required_document_id: doc.id },
      })

      removeRequiredDocument(doc.id)

      initCurrentDocument()

      setErrors({})

      const answers = doc?.custom_questions.map(q => ({ answer: '', mandatory: q.mandatory }))

      setAcceptance({ ...initialAcceptance, answers: answers })
      setIsSaving(false)

      window.scrollTo(0, 0)

      const verb = doc.acceptance_type === 'signature' ? 'signing' : 'accepting'
      notify(`Thank you for ${verb}!`)
    } catch (err) {
      const errors = _.get(err, 'required_document_acceptance', {})

      setErrors(errors)
      setIsSaving(false)
    }
  }, [acceptance, createDocAcceptance, doc, initCurrentDocument, removeRequiredDocument, resolver])

  if (!doc || timer.isLoading) return <Loading />

  return (
    <Col md={8} lg={6} mdOffset={2} lgOffset={3} className="content required-document-content">
      <div className="page-header">
        <h3 className="page-title">{doc.title}</h3>
      </div>

      <Portlet boxed>
        <Body>
          <div dangerouslySetInnerHTML={{ __html: doc.body }} className="required-document-preview-wrapper" />
        </Body>
      </Portlet>

      {doc.custom_questions && doc.custom_questions.length > 0 && (
        <Questions
          document={doc}
          answers={acceptance.answers}
          errors={errors}
          onUpdateAcceptance={onUpdateAcceptance}
        />
      )}

      {doc.payment_schedule && !doc.was_already_paid && (
        <Fragment>
          <PaymentOption document={doc} acceptance={acceptance} onUpdateAcceptance={onUpdateAcceptance} />
          <PaymentMethod
            acceptance={acceptance}
            hasUpcharge={_.get(user, 'member.organization.cc_upcharge', false)}
            userId={user?.id}
            errors={errors}
            onUpdateAcceptance={onUpdateAcceptance}
          />
        </Fragment>
      )}

      {doc.acceptance_type === 'signature' ? (
        <Signature
          acceptance={acceptance}
          isReady={isReady}
          isSaving={isSaving}
          errors={errors}
          onUpdateAcceptance={onUpdateAcceptance}
          doSubmit={doSubmit}
        />
      ) : (
        <AcceptButton isReady={isReady} isSaving={isSaving} doSubmit={doSubmit} />
      )}
    </Col>
  )
}

export default RequiredDocument
