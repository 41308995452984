import { FC, useMemo } from 'react'
import { Label } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import useLegacyContext from 'hooks/useLegacyContext'
import federation from 'resources/federation'
import organization from 'resources/organization'

import { DocumentFolder } from './type'

type Props = {
  folder: DocumentFolder
  folderUri: string
}

const Header: FC<Props> = ({ folder, folderUri }) => {
  const { user } = useLegacyContext()
  const chapterFolder = federation && organization

  const folderLabel = useMemo(() => {
    if (folder.executive_board_position) {
      return 'From National Office: Exec Board'
    } else if (folder.purpose === 'federation' && user.role !== 'federation') {
      return 'From National Office'
    } else if (folder.purpose === 'shared') {
      return 'From National Office'
    } else if (chapterFolder && folder.purpose !== 'alumni') {
      return 'Chapter Folder'
    } else if (user.role === 'federation' && folder.purpose === 'internal') {
      return 'Nationals Only'
    } else if (folder.purpose === 'federation') {
      // no label
    } else if (!folder.is_private && folder.purpose !== 'alumni') {
      return 'Chapter Only'
    }
  }, [folder, user, chapterFolder])

  return (
    <div>
      <Link to={folderUri} className="no-style">
        {folder.name}
      </Link>

      {folderLabel && (
        <Label bsStyle="primary" className="m-l-1 label-white label-portlet-title">
          {folderLabel}
        </Label>
      )}

      {folder.is_private && folder.owner_type === 'Organization' && (
        <Label bsStyle="primary" className="m-l-1 label-white label-portlet-title">
          Admins only
        </Label>
      )}
    </div>
  )
}

export default Header
