import cx from 'classnames'
import { FC } from 'react'
import { Alert, Button } from 'react-bootstrap'

import PaymentPlanDetails from 'components/payment-plans/details'
import PaymentMethod from 'components/utilities/payment-method'
import { UnsubmittedPaymentPlan } from 'pages/Shared/PaymentPlans/type'

type Props = {
  paymentPlan: UnsubmittedPaymentPlan
  isLoading: boolean
  goBack: () => void
  onConfirmation: () => void
}

const Confirmation: FC<Props> = ({ paymentPlan, isLoading, onConfirmation, goBack }) => (
  <div>
    <h5>Details of your payment plan</h5>

    <Alert bsStyle="warning">
      <strong className="d-block m-b-05">Your payment plan has not been submitted for approval yet!</strong>
      All payment plans <u>must be approved</u> before they are active.
    </Alert>

    <h6>
      If approved, your&nbsp;
      <PaymentMethod paymentMethod={paymentPlan.payment_method!} />
      &nbsp;will be charged:
    </h6>

    <PaymentPlanDetails paymentPlan={paymentPlan} noStatus className="max-w-250" />

    <hr />
    <div>
      <Button
        bsStyle="primary"
        onClick={onConfirmation}
        disabled={isLoading}
        className={cx('pull-right', {
          'btn-loading': isLoading,
        })}
      >
        Submit request
      </Button>

      <Button onClick={goBack}>Previous</Button>
    </div>
  </div>
)

export default Confirmation
