import { IssuedCardTransferStatus } from 'types/issued_card_transfer'
import asyncThunk from 'utils/asyncThunk'

export type Request = {
  id: number
  start_date?: string
  end_date?: string
}

export type LedgerItem = {
  id: string
  amount: number
  description: string
  status?: IssuedCardTransferStatus
  created_at: string
  created_by?: {
    id: number
    first_name: string
    last_name: string
  }
}

export type Headers = {
  'x-starting-date': string
  'x-starting-balance': string
}

type Response = LedgerItem[]

export const issuedCardLedger = asyncThunk<Request, Response, Headers>({
  name: 'issued-cards/ledger',
  uri: ({ id }) => `/issued_cards/${id}/ledger`,
  method: 'GET',
})
