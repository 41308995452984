import { Actions } from 'actions'
import { FC } from 'react'

import MemberProfile from 'components/member/profile'
import connect from 'helpers/connect'
import { useLegacyMember } from 'hooks/useLegacyContext'

const MemberSettingsProfile: FC<Actions> = ({ actions }) => {
  const user = useLegacyMember()

  return (
    <div className="tab-pane active">
      <div className="heading-block">
        <h3>My profile</h3>
      </div>

      <MemberProfile memberId={user.member.id} onUpdate={() => actions.refreshUser()} />
    </div>
  )
}

export default connect(MemberSettingsProfile)
