import { FC } from 'react'

import { Event } from '../../type'

type Props = {
  question: Event['event_questions'][number]
}

const Label: FC<Props> = ({ question }) => {
  return (
    <span>
      {question.mandatory && <span className="text-danger">* </span>}
      {question.question}
    </span>
  )
}

export default Label
