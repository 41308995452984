import { forwardRef } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

// @ts-ignore
import createTable, { TableRef } from 'components/table-v2'
import { Date, EmptyState, FaButton, FaIcon, Units } from 'components/utilities'

import Status, { getStatus } from './_Status'
import { HourRecord } from './type'

type Props = {
  records: HourRecord[]
  onSelect: (record: HourRecord) => void
}

const { Table, Header, Column, Actions, Body, Row, Cell } = createTable<HourRecord>()

const RecordsTable = forwardRef<TableRef, Props>(({ records, onSelect }, ref) => {
  return (
    <Table ref={ref} data={records} noSort empty={<EmptyState>You haven't recorded any hours yet</EmptyState>}>
      <Header>
        <Column width={20}>Date</Column>
        <Column width={30}>Status</Column>
        <Column width={20} align="right">
          Hours
        </Column>
        <Actions width={30} />
      </Header>
      <Body>
        {record => (
          <Row key={record.id} data-id={record.id}>
            <Cell>
              <Date date={record.created_at} extraShort />
            </Cell>

            <Cell value={getStatus(record)}>
              <Status record={record} />
            </Cell>

            <Cell>
              <Units noun="hour" count={record.hours} />
            </Cell>

            <Actions width={20}>
              {record.description && (
                <OverlayTrigger
                  placement="left"
                  overlay={<Tooltip id={`hour-record-${record.id}`}>{record.description}</Tooltip>}
                >
                  <FaIcon
                    icon="sticky-note"
                    className="text-muted o-50 mr-4 cursor-pointer"
                    onClick={() => onSelect(record)}
                  />
                </OverlayTrigger>
              )}
              <FaButton icon="search" onClick={() => onSelect(record)}>
                Details
              </FaButton>
            </Actions>
          </Row>
        )}
      </Body>
    </Table>
  )
})

export default RecordsTable
