import asyncThunk from 'utils/asyncThunk'

export type Request = {
  query: string
  term?: string
}

export const searchDocumentsByLabel = asyncThunk<Request, any>({
  name: 'documents/search-documents-by-label',
  uri: '/documents/search',
})

export type SearchRequest = {
  term: string
}

export const searchDocuments = asyncThunk<SearchRequest, any>({
  name: 'documents/search',
  uri: '/documents/search',
})
