export const budgetQuery = `
  budget {
    id
    name
    starting_balance {
      amount
    }
    budget_expenses {
      id
      cost
    }
    users {
      id
      first_name
      last_name
    }
  }
`
