import classnames from 'classnames'
import { FC, useContext, useState } from 'react'
import { useAuthSelector } from 'store'

import GlobalSearch from 'components/global-search'
import { GlobalSearchContext } from 'components/global-search/context'
import LegacyContext from 'contexts/legacy'

import NavbarDropdown from './dropdown'
import NavbarName from './name'
import Next from './Next'

type Props = {}

const NavbarDesktop: FC<Props> = () => {
  const { user } = useAuthSelector()
  const { organization } = useContext(LegacyContext)
  const [isOpen, setIsOpen] = useState(false)
  const globalSearch = useContext(GlobalSearchContext)

  // @ts-ignore
  const logoFederation = user?.federation || _.get(user?.alumni, 'organization.federation')
  const primaryLogo = organization?.primary_logo || (logoFederation && logoFederation.primary_logo)
  const secondaryLogo = organization?.secondary_logo || (logoFederation && logoFederation.secondary_logo)

  return (
    <div className={classnames('navbar-collapse', isOpen && 'navbar-collapse__is-visible')}>
      {['root', 'admin', 'federation'].includes(user?.role || '') && (
        <>
          <form
            className="navbar-form navbar-search-form navbar-left cursor-pointer"
            onClick={() => globalSearch.toggle()}
          >
            <div className="form-group">
              <input className="form-control bootstrap-typeahead-input-main cursor-pointer" readOnly />
            </div>
          </form>
          <GlobalSearch />
        </>
      )}

      <ul className="nav navbar-nav navbar-right navbar-collapse__user">
        <li className="divider"></li>

        {user?.role === 'member' ? (
          <li>
            <a className="member-id no-style">Member ID: {user.member.unique_id}</a>
          </li>
        ) : null}

        {(user?.id === 2 || user?.id === 3) && (
          <li>
            <Next />
          </li>
        )}

        <li>
          <NavbarDropdown className="navbar-profile">
            <NavbarName />
          </NavbarDropdown>
        </li>
      </ul>

      {(secondaryLogo || primaryLogo) && (
        <ul key={1} className="nav navbar-nav navbar-right navbar-collapse__logos">
          {secondaryLogo && (
            <li className="navbar-logo">
              <img alt="" src={secondaryLogo.public_url} width={48} height={48} />
            </li>
          )}
          {primaryLogo && (
            <li className="navbar-logo" style={{ marginRight: 12 }}>
              <img alt="" src={primaryLogo.public_url} width={48} height={48} />
            </li>
          )}
        </ul>
      )}

      {user?.role === 'member' && (
        <ul key={2} className="nav navbar-nav navbar-right navbar-collapse__user-id">
          <li className="divider" />
          <li>
            <a className="member-id p-r-0 no-style">Member ID: {user.member.unique_id}</a>
          </li>
        </ul>
      )}
    </div>
  )
}

export default NavbarDesktop
