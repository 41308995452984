import { FC, useCallback, useMemo } from 'react'
import { Grid } from 'react-bootstrap'
import { useParams } from 'react-router-dom'

import isTicketsExist from 'components/event/helpers/are-tickets-available'
import isRegistrationClosed from 'components/event/helpers/is-registration-closed'
import RegistrationForm from 'components/event/registration-form'
import RegistrationAlert from 'components/event/registration-form/_RegistrationAlert'
import RegistrationDetails from 'components/event/registration-form/_RegistrationDetails'
import EventDetails from 'components/event/registration-form/shared/_EventDetails'
import { Body, BreadcrumbHeader, BreadcrumbItem, Content, Header, Loading, Portlet } from 'components/utilities'
import useActiveSidebarItem from 'hooks/useActiveSidebarItem'
import useLegacyContext from 'hooks/useLegacyContext'
import useList from 'hooks/useList'
import useObject from 'hooks/useObject'
import { listEventRegistrations, Request as EventRegistrationRequest } from 'thunks/event-registrations/list'
import { Request as EventRequest, retrieveEvent } from 'thunks/events/retrieve'
import { isAlumni } from 'types/user-functions'
import notify from 'utils/notify'

import { Event, EventRegistration } from './type'
// @ts-ignore
import { eventQuery, eventRegistrationQuery } from './type?query'

type RouteParams = {
  id: string
}

const SharedEventRegistrationsNew: FC = () => {
  const { user } = useLegacyContext()
  const { id } = useParams<RouteParams>()

  const [event] = useObject<Event, EventRequest>(retrieveEvent, {
    query: eventQuery,
    id: +id,
  })
  useActiveSidebarItem(event?.name || '')

  const [eventRegistrations, { add }] = useList<EventRegistration, EventRegistrationRequest>(listEventRegistrations, {
    query: eventRegistrationQuery,
    purpose: 'all',
    user_id: user?.id,
    event_id: Number(id),
  })

  const isRegisterable = useMemo(() => {
    if (!event || !eventRegistrations) return false
    if (isRegistrationClosed(event)) return false
    if (!isTicketsExist(event)) return false
    if (event.multiple_submissions) return true
    if (!eventRegistrations) return false
    return eventRegistrations.length === 0
  }, [event, eventRegistrations])

  const handleRegistration = useCallback(
    (registration: EventRegistration) => {
      const purpose = event?.purpose
      notify(`Your ${purpose === 'event' ? 'registration' : 'submission'} has been saved!`)
      add(registration)
    },
    [add, event?.purpose]
  )

  const prefix = useMemo(() => {
    if (isAlumni(user)) return '/alumni'
    return '/member'
  }, [user])

  if (!event || !eventRegistrations) return <Loading />

  return (
    <div>
      <Content>
        <Grid>
          <div>
            <RegistrationAlert event={event} eventRegistration={eventRegistrations[0]} />

            {event.purpose === 'event' ? (
              <BreadcrumbHeader>
                <BreadcrumbItem link={`${prefix}/event-registrations`}>Events</BreadcrumbItem>
                <BreadcrumbItem>{event.name}</BreadcrumbItem>
              </BreadcrumbHeader>
            ) : (
              <BreadcrumbHeader>
                <BreadcrumbItem link={isAlumni(user) ? '/alumni/forms' : '/member/form-submissions'}>
                  Forms
                </BreadcrumbItem>
                <BreadcrumbItem>{event.name}</BreadcrumbItem>
              </BreadcrumbHeader>
            )}

            <Portlet boxed>
              <Body>
                <EventDetails event={event} />

                {isRegisterable && (
                  <RegistrationForm key={eventRegistrations.length} event={event} onRegister={handleRegistration} />
                )}
              </Body>
            </Portlet>

            {eventRegistrations.length > 0 && (
              <Portlet boxed>
                <Header portlet>Your {event.purpose === 'event' ? 'registration' : 'submission'} details</Header>
                <Body>
                  <RegistrationDetails event={event} eventRegistrations={eventRegistrations} />
                </Body>
              </Portlet>
            )}
          </div>
        </Grid>
      </Content>
    </div>
  )
}

export default SharedEventRegistrationsNew
