import { Typeahead } from 'components/typeahead'
import { arrayOf, func, number, shape, string } from 'prop-types'
import React from 'react'
import { Radio } from 'react-bootstrap'
import { ExecutiveBoardPosition, Group, Organization } from 'resources'

class RecipientPickerOnChange extends React.Component {
  static propTypes = {
    federationId: number.isRequired,
    visibility: string.isRequired,
    organizations: arrayOf(
      shape({
        id: number.isRequired,
        name: string.isRequired,
      })
    ),
    positions: arrayOf(
      shape({
        id: number.isRequired,
        title: string.isRequired,
      })
    ),
    groups: arrayOf(
      shape({
        id: number.isRequired,
        name: string.isRequired,
      })
    ),
    customList: string,
    onChange: func.isRequired,
  }

  static defaultPropTypes = {
    organizations: [],
    positions: [],
    groups: [],
  }

  state = {
    organizations: null,
    executiveBoardPositions: null,
    groups: null,
  }

  componentDidMount() {
    const { federationId } = this.props

    Organization.list({
      data: {
        federation_id: federationId,
      },
      query: 'organization { name }',
      onSuccess: this.setData('allOrganizations', 'name'),
    })

    ExecutiveBoardPosition.list({
      data: {
        federation_id: federationId,
      },
      query: `
        executive_board_position {
          title
          weight
        }
      `,
      onSuccess: this.setData('allExecutiveBoardPositions', 'weight'),
    })

    Group.alumniGroupsByFederation({
      data: {
        federation_id: federationId,
        include_organization_groups: true,
      },
      query: 'group { name }',
      onSuccess: this.setData('allGroups', 'name'),
    })
  }

  setData =
    (type, sortBy) =>
    ({ data }) =>
      this.setState({
        [type]: _.sortBy(data, sortBy),
      })

  onVisibilityChange = e => {
    const { onChange } = this.props

    onChange('visibility', e.target.value)

    // TODO select with this type is broken
    // onChange('permitted_organizations', undefined)

    // onChange({
    //   visibility: e.target.value,
    //   permitted_organizations: undefined,
    //   permitted_positions: undefined,
    //   permitted_groups: undefined,
    // })
  }

  onRecipientChange = keyName => values => {
    const { onChange } = this.props
    onChange(keyName, values.length > 0 ? values : undefined)
  }

  onCustomListChange = e => {
    const { value } = e.target
    this.props.onChange('custom_list', value)
  }

  render = () => {
    const { visibility, positions, organizations, groups, customList } = this.props
    const { allOrganizations, allExecutiveBoardPositions, allGroups } = this.state

    if (_.anyAreNil(allOrganizations, allExecutiveBoardPositions, allGroups)) {
      return null
    }

    return (
      <div>
        <Radio
          checked={visibility === 'members_and_alumni' || _.isEmpty(visibility)}
          value="members_and_alumni"
          onChange={this.onVisibilityChange}
        >
          All members (active + alumni)
        </Radio>

        <Radio
          checked={visibility === 'federation_members'}
          value="federation_members"
          onChange={this.onVisibilityChange}
        >
          All active undergraduate members
        </Radio>

        <Radio
          checked={visibility === 'federation_alumni'}
          value="federation_alumni"
          onChange={this.onVisibilityChange}
        >
          All alumni
        </Radio>

        <Radio
          checked={visibility === 'federation_admins'}
          value="federation_admins"
          onChange={this.onVisibilityChange}
        >
          Only chapter admins
        </Radio>

        <Radio
          checked={visibility === 'executive_board_positions'}
          value="executive_board_positions"
          onChange={this.onVisibilityChange}
        >
          One or more executive board positions
        </Radio>

        {visibility === 'executive_board_positions' && (
          <div className="p-l-2">
            <Typeahead
              multiple
              labelKey="title"
              placeholder="Select positions..."
              multiplePlaceholder="Select positions..."
              options={allExecutiveBoardPositions}
              selected={positions}
              onChange={this.onRecipientChange('permitted_positions')}
              className="m-t-1"
            />
          </div>
        )}

        <Radio
          checked={visibility === 'executive_board_chapters'}
          value="executive_board_chapters"
          onChange={this.onVisibilityChange}
        >
          One or more chapter exec boards
        </Radio>

        {visibility === 'executive_board_chapters' && (
          <div className="p-l-2">
            <Typeahead
              multiple
              labelKey="name"
              placeholder="Select chapters..."
              multiplePlaceholder="Select chapters..."
              options={allOrganizations}
              selected={organizations}
              onChange={this.onRecipientChange('permitted_organizations')}
              className="m-t-1"
            />
          </div>
        )}

        <Radio
          checked={visibility === 'organization_members'}
          value="organization_members"
          onChange={this.onVisibilityChange}
        >
          One or more chapters
        </Radio>

        {visibility === 'organization_members' && (
          <div className="p-l-2">
            <Typeahead
              multiple
              labelKey="name"
              placeholder="Select chapters..."
              multiplePlaceholder="Select chapters..."
              options={allOrganizations}
              selected={organizations}
              onChange={this.onRecipientChange('permitted_organizations')}
              className="m-t-1"
            />
          </div>
        )}

        <Radio checked={visibility === 'alumni_groups'} value="alumni_groups" onChange={this.onVisibilityChange}>
          One or more alumni groups
        </Radio>

        {visibility === 'alumni_groups' && (
          <div className="p-l-2">
            <Typeahead
              multiple
              labelKey="name"
              placeholder="Select groups..."
              multiplePlaceholder="Select groups..."
              options={allGroups}
              selected={groups}
              onChange={this.onRecipientChange('permitted_groups')}
              className="m-t-1"
            />
          </div>
        )}

        <Radio checked={visibility === 'custom_list'} value="custom_list" onChange={this.onVisibilityChange}>
          Specify a custom list of recipients
        </Radio>

        {visibility === 'custom_list' && (
          <div className="p-l-2">
            <textarea rows={6} className="form-control" value={customList} onChange={this.onCustomListChange} />
            <em className="text-muted m-t-1 d-block">Separate the email addresses by either commas or new lines.</em>
          </div>
        )}
      </div>
    )
  }
}

export default RecipientPickerOnChange
