import classNames from 'classnames'
import { FC } from 'react'

import badge from 'images/etc/braintree-badge-wide-light.png'

type Props = {
  height: number
  className?: string
}

const Badge: FC<Props> = ({ height, className }) => {
  return (
    <a
      href="https://www.braintreepayments.com/features/data-security"
      target="_blank"
      tabIndex={-1}
      className={classNames('braintree-badge', className)}
      style={{ marginTop: 3 }}
      rel="noreferrer"
    >
      <img src={badge} width={height * (280.0 / 44.0)} height={height} alt="" />
    </a>
  )
}

export default Badge
