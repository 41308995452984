import { FC, ReactNode, useCallback } from 'react'
import { Button, Modal } from 'react-bootstrap'

import modal from 'helpers/modal'
import { closeModal } from 'hooks/useModal'

type Props = {
  id?: number
  title?: string
  children?: ReactNode
  onClose?: () => void
}

const NoticeModal: FC<Props> = ({ title, children, onClose }) => {
  const handleCloseModal = useCallback(() => {
    if (onClose) onClose()
    closeModal()
  }, [onClose])

  return (
    <Modal show onHide={handleCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      {children && (
        <Modal.Body>
          <hr className="spacer-xs" />
          <h6 className="text-center m-b-1 lh-15">{children}</h6>
        </Modal.Body>
      )}
      <Modal.Footer>
        <Button bsStyle="primary" onClick={handleCloseModal}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default modal<Props>('Notice', NoticeModal)
