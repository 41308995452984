import moment from 'moment'
import { FC, useCallback, useMemo } from 'react'
import { ButtonToolbar, Col, Grid, Row } from 'react-bootstrap'
import { useHistory, useParams } from 'react-router-dom'
import r from 'routes'

import canManage from 'components/message-board/can-manage'
import NewComment from 'components/message-board/new-comment'
import ThreadMessage from 'components/message-board/thread-message'
import { Message } from 'components/message-board/type'
// @ts-ignore
import { messageQuery } from 'components/message-board/type?query'
import { ConfirmModal } from 'components/modals'
import PlainInput from 'components/modals/plain-input'
import {
  Body,
  BreadcrumbHeader,
  BreadcrumbItem,
  Content,
  Divider,
  Item,
  Loading,
  Name,
  Portlet,
  SettingsDropdown,
} from 'components/utilities'
import useActiveSidebarItem from 'hooks/useActiveSidebarItem'
import { useAPI } from 'hooks/useAPI'
import useLegacyContext from 'hooks/useLegacyContext'
import { closeModal, openModal } from 'hooks/useModal'
import useObject from 'hooks/useObject'
import { archiveMessageThread } from 'thunks/message-threads/archive'
import { Request, retrieveMessageThread } from 'thunks/message-threads/retrieve'
import { updateMessageThread } from 'thunks/message-threads/update'
import { createMessage, Request as CreateRequest } from 'thunks/messages/create'
import notify from 'utils/notify'

import { MessageThread } from './type'
// @ts-ignore
import { messageThreadQuery } from './type?query'

type RouteParams = {
  id: string
  threadId: string
}

type Props = {
  role: 'member' | 'federation' | 'alumni'
}

const SharedMessageThreadsShow: FC<Props> = ({ role }) => {
  const params = useParams<RouteParams>()
  useActiveSidebarItem('Message Board<MessageThread, Request>')

  const [thread, { patch }] = useObject<MessageThread, Request>(retrieveMessageThread, {
    query: messageThreadQuery,
    id: +params.threadId,
  })

  const [create] = useAPI<Message, CreateRequest>(createMessage)
  const handleCommentSubmit = useCallback(
    async (body: string) => {
      if (!thread) return

      const [message] = await create({
        query: messageQuery,
        message: {
          body,
          message_thread_id: thread.id,
        },
      })

      notify('Your comment was posted')
      patch({
        messages: [...thread.messages, message],
      })
      closeModal()
    },
    [thread, patch, create]
  )

  const [update, { timer }] = useAPI(updateMessageThread)
  const handleUpdate = useCallback(
    async (name: string) => {
      if (!thread) return

      const [updated] = await update({
        query: 'message_thread { name }',
        id: thread.id,
        message_thread: {
          name,
        },
      })

      patch({
        name: updated.name,
      })
      notify('The thread has been renamed')
      closeModal()
    },
    [patch, thread, update]
  )

  const [archive] = useAPI(archiveMessageThread)
  const history = useHistory()
  const handleArchive = useCallback(async () => {
    if (!thread) return

    await archive({
      id: thread.id,
    })

    notify({
      type: 'warning',
      message: 'The thread has been archived.',
    })

    history.push(r[role].messageCategories.show(thread.message_category.id))
  }, [archive, history, thread, role])

  const { user } = useLegacyContext()
  const isManageable = useMemo((): boolean => !!thread && canManage(user, thread?.message_category), [user, thread])

  if (!thread) return <Loading />

  return (
    <>
      <Content>
        <Grid>
          <BreadcrumbHeader
            actions={
              isManageable && (
                <ButtonToolbar>
                  <SettingsDropdown>
                    <Item icon="pencil" onClick={openModal('PlainInput')}>
                      Edit name
                    </Item>

                    <Divider />

                    <Item icon="archive" onClick={openModal('Confirm')}>
                      Archive thread
                    </Item>
                  </SettingsDropdown>
                </ButtonToolbar>
              )
            }
          >
            <BreadcrumbItem link={r[role].messageCategories.root}>Message Board</BreadcrumbItem>
            <BreadcrumbItem link={r[role].messageCategories.show(thread.message_category.id)}>
              {thread.message_category.name}
            </BreadcrumbItem>
            <BreadcrumbItem>{thread.name}</BreadcrumbItem>
          </BreadcrumbHeader>

          <Row>
            <Col md={12}>
              <Portlet className="portlet-boxed portlet-thread">
                <div className="portlet-header clearfix">
                  <h4 className="portlet-thread-title">{thread.name}</h4>
                  <span>
                    <span className="text-muted"> Posted </span>
                    {moment(thread.created_at).fromNow()}
                    <span className="text-muted"> by </span>
                    <Name user={thread.creator} />
                  </span>
                </div>

                <Body>
                  <Row>
                    <Col md={8}>
                      <div className="thread-messages">
                        {thread.messages.map(message => (
                          <ThreadMessage key={message.id} message={message} />
                        ))}
                      </div>

                      <hr />

                      <NewComment key={thread.messages.length} onSubmit={handleCommentSubmit} />
                    </Col>
                  </Row>
                </Body>
              </Portlet>
            </Col>
          </Row>
        </Grid>
      </Content>

      <PlainInput
        title="Update message thread"
        label="Name"
        defaultValue={thread.name}
        action="Save"
        timer={timer}
        onSubmit={handleUpdate}
      />

      <ConfirmModal
        showLoading
        prompt="Are you sure you want to archive this thread?"
        yes="archive it"
        onConfirm={handleArchive}
      />
    </>
  )
}

export default SharedMessageThreadsShow
