import { FormEvent, useCallback } from 'react'
import { useAppDispatch } from 'store'

import { AsyncThunk, unwrapResult } from '@reduxjs/toolkit'

import useActionTimer from 'hooks/useActionTimer'
import { isGenericAPIError } from 'utils/asyncThunk'
import { notifyError } from 'utils/notify'

export type ErrorCallback = (statusCode: number, error: any) => void

export type Headers = Record<string, string> | undefined

export type APIResponse<Resp, Headers> = {
  headers: Headers
  data: Resp
}

export type APIThunk<Req, Resp, Headers = undefined> = AsyncThunk<APIResponse<Resp, Headers>, Req, { rejectValue: any }>

export function useAPI<Response, Request, Headers extends Record<string, string> | undefined = undefined>(
  thunk: APIThunk<Request, Response, Headers>
) {
  const timer = useActionTimer()
  const dispatch = useAppDispatch()

  const execute = useCallback(
    async (req: Request): Promise<[Response, Headers]> => {
      try {
        timer.start()

        const resp = await dispatch(thunk(req)).then(unwrapResult)

        timer.succeeded()

        return [resp.data, resp.headers]
      } catch (err) {
        console.log('[API ERROR]', err)

        timer.failed()
        throw err
      }
    },
    [thunk, timer, dispatch]
  )

  return [execute, { timer }] as const
}

export function useAPIHandler<Response, Request, Headers extends Record<string, string> | undefined = undefined>(
  thunk: APIThunk<Request, Response, Headers>,
  request: () => Request,
  onSuccess?: (resp: Response, req: Request, headers: Headers) => void
) {
  const [api, { timer }] = useAPI<Response, Request, Headers>(thunk)

  const handler = useCallback(
    async (e?: FormEvent) => {
      e && e.preventDefault()
      try {
        const req = request()
        const [data, headers] = await api(req)

        onSuccess && onSuccess(data, req, headers)
      } catch (err) {
        if (isGenericAPIError(err) && err.error) {
          notifyError(err.error)
        } else {
          notifyError('unable to save changes')
        }
      }
    },
    [api, request, onSuccess]
  )

  return [handler, { timer }] as const
}
