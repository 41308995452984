export const paymentMethodQuery = `
  payment_method {
    id
    method
    company
    last_four
    last_payment {
      id
      amount
      created_at
    }
    payment_plans {
      id
      status
    }
  }
`
