import { isGranted } from 'helpers'
import { FC, useCallback, useMemo } from 'react'
import { Link } from 'react-router-dom'
import r from 'routes'

import { Body, Date, FaIcon, Header, Portlet, Units } from 'components/utilities'
import { Poll } from 'components/voting/index-container/type'
import useLegacyContext from 'hooks/useLegacyContext'
import { isAlumni, isFederation } from 'types/user-functions'

type Props = {
  polls: Poll[]
  linksPrefix: string
  emptyState: any
  canVote: boolean
}

const ActivePollsPortlet: FC<Props> = ({ polls, linksPrefix, emptyState, canVote }) => {
  const { user } = useLegacyContext()

  const adminAccess = useMemo(() => isGranted(user, 'Voting'), [user])

  const linkToShow = useCallback(
    (votingId: number) => {
      if (isAlumni(user)) return r.alumni.voting.show(votingId)
      if (isFederation(user)) return r.federation.voting.show(votingId)
      if (adminAccess) return r.admin.voting.show(votingId)
      return r.member.voting.show(votingId)
    },
    [user, adminAccess]
  )

  return (
    <Portlet boxed className="portlet-polls">
      <Header portlet>Open votes</Header>
      <Body>
        {polls.length ? (
          <table id="active-polls" className="table">
            <tbody>
              {polls.map(v => (
                <tr key={v.id}>
                  {canVote && (
                    <td style={{ width: '10%' }}>
                      <Link to={linkToShow(v.id)}>
                        {v.data.voted_option_ids ? (
                          <span className="label label-default">
                            <FaIcon check /> &nbsp; Voted
                          </span>
                        ) : (
                          <span className="label label-danger">
                            <FaIcon comment /> &nbsp; Vote now
                          </span>
                        )}
                      </Link>
                    </td>
                  )}
                  <td>
                    <Link to={`${linksPrefix || ''}/voting/${v.id}`}>{v.title}</Link>
                  </td>
                  <td style={{ width: '25%' }}>
                    <Units count={v.data.vote_count || 0} noun="vote" />
                  </td>
                  <td style={{ width: '25%' }}>
                    {v.ended_at && (
                      <span>
                        <span className="text-muted fs-90">voting closes on</span> <br />
                        <Date long date={v.ended_at} />
                      </span>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          emptyState
        )}
      </Body>
    </Portlet>
  )
}

export default ActivePollsPortlet
