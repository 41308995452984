export const messageCategoryQuery = `
  message_category {
    id
    name
    description
    recipient_descriptions
    created_at
    owner_type
    owner_id
    message_threads {
      id
      name
      created_at
      updated_at
      data {
        replies
        new
      }
      creator {
        id
        first_name
        last_name
      }
    }
    message_category_folders {
      id
      name
      data {
        thread_count
      }
    }
  }
`
