import { isGranted } from 'helpers'
import { FC, useContext } from 'react'

import LegacyContext from 'contexts/legacy'
import { Feature, FederationFeature } from 'types/feature'

import Item, { Props as ItemProps } from './item'

export type Props = ItemProps & {
  feature: Feature | FederationFeature
}

const FeatureItem: FC<Props> = ({ feature, ...props }) => {
  const { user } = useContext(LegacyContext)

  if (user?.role === 'federation') {
    if (!user || !isGranted<FederationFeature>(user, feature as FederationFeature)) return null
  } else {
    if (!user || !isGranted(user, feature as Feature)) return null
  }

  return <Item {...props} />
}

export default FeatureItem
