import { FC } from 'react'

import Preview from 'components/documents/preview'
import { Date, Money } from 'components/utilities'
import { Document } from 'types/document'

type Props = {
  event: {
    id: number
    cost_type: 'free' | 'fixed' | 'variable'
    cost: number
    purpose: 'event' | 'form'
  }
  eventRegistrations: {
    id: number
    tickets: number
    amount: number
    created_at: string
    event_optional_addons: {
      name: string
      quantity: number
      cost: number
    }[]
    event_answers: {
      question: string
      answer: string
      document?: Document
    }[]
  }[]
}

const RegistrationDetails: FC<Props> = ({ event, eventRegistrations }) => (
  <div>
    {eventRegistrations &&
      eventRegistrations.map((eventRegistration, i) => (
        <div key={i} data-id={eventRegistration.id}>
          <dl className="dl-horizontal dl-thick list-spaced">
            <dt>{event.purpose === 'event' ? 'Registration' : 'Submission'} date</dt>
            <dd>
              <Date date={eventRegistration.created_at} />
            </dd>

            {(eventRegistration.tickets > 1 || eventRegistration.amount > 0) && (
              <div>
                <dt>Tickets</dt>
                <dd>
                  {eventRegistration.tickets || 1}{' '}
                  {event.cost_type === 'fixed' && (
                    <>
                      x <Money amount={event.cost} />
                    </>
                  )}
                </dd>
              </div>
            )}

            {eventRegistration.event_optional_addons &&
              eventRegistration.event_optional_addons.map((item, i) => (
                <div key={i}>
                  <dt>{item.name}</dt>
                  <dd>
                    {item.quantity > 0 ? (
                      <>
                        {item.quantity}x <Money amount={item.cost} />
                      </>
                    ) : (
                      <span className="text-muted">none ordered</span>
                    )}
                  </dd>
                </div>
              ))}

            {eventRegistration.amount > 0 && (
              <div>
                <dt>{event.cost_type === 'variable' ? 'Contributed' : 'Total cost'}</dt>
                <dd>
                  <Money amount={eventRegistration.amount} />
                </dd>
              </div>
            )}

            {eventRegistration.event_answers &&
              eventRegistration.event_answers.map((ans, i) => (
                <div key={i} className="event-registration-answer">
                  <dt title={ans.question}>{ans.question}</dt>
                  <dd>
                    {_.isArray(ans.answer) ? ans.answer.join(', ') : ans.answer}
                    {ans.document && (
                      <div className="p-t-1">
                        <Preview document={ans.document} />
                      </div>
                    )}
                  </dd>
                </div>
              ))}
          </dl>

          {i + 1 < eventRegistrations.length && <hr />}
        </div>
      ))}
  </div>
)

export default RegistrationDetails
