import { FC } from 'react'
import Name from 'components/utilities/name'
import formatPhone from 'utils/format-phone'
import { Organization } from 'types/user'

import Header from './header'
import Item from './item'

type Props = {
  organization?: Organization
}

const PrimaryAdmin: FC<Props> = ({ organization }) => {
  if (!organization || !organization.primary_admin) {
    return null
  }

  const { user } = organization.primary_admin

  const phoneNumber = user.phone_number && (
    <Item url={`tel:${user.phone_number}`} icon="phone">
      {formatPhone(user.phone_number)}
    </Item>
  )

  return (
    <ul className="sidebar-menu">
      <Header>Have questions? Need help?</Header>
      <Item url={`tel:${user.phone_number}`} icon="user">
        <Name user={user} natural />
      </Item>
      {phoneNumber}
      <Item url={`mail:${user.email}`} icon="envelope">
        {user.email}
      </Item>
    </ul>
  )
}

export default PrimaryAdmin
