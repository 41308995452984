import { Quill } from 'react-quill'

import { File } from 'components/uploader'

const BlockEmbed = Quill.import('blots/block/embed')

class S3ImageBlot extends BlockEmbed {
  static create(data: File) {
    const node = super.create(data)
    node.setAttribute('src', data.preview)
    node.setAttribute('data-s3-path', data.s3_path)
    return node
  }

  static value(domNode: HTMLElement) {
    return {
      preview: domNode.getAttribute('src'),
      s3_path: domNode.getAttribute('data-s3-path'),
    }
  }
}

S3ImageBlot.blotName = 's3Image'
S3ImageBlot.tagName = 'img'

Quill.register({ 'formats/s3Image': S3ImageBlot })
