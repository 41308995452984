import asyncThunk from 'utils/asyncThunk'

export type Request = {
  query: string
  transaction: {
    amounts: Record<number, number>
    description: string
    member_ids: number[]
    organization_id: number
  }
}

export const createCredits = asyncThunk<Request, any>({
  name: 'transactions/create-credits',
  uri: '/transactions/credits',
  method: 'POST',
})
