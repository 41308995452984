type Input = {
  first_name: string
  last_name: string
}

export const formats = {
  short: ({ first_name: first, last_name: last }: Input) => `${last}, ${first[0]}`,
  full: ({ first_name: first, last_name: last }: Input) => `${last}, ${first}`,
  natural: ({ first_name: first, last_name: last }: Input) => `${first} ${last}`,
  firstInitial: ({ first_name: first, last_name: last }: Input) => `${first[0]} ${last}`,
} as const

type Format = keyof typeof formats

export default function formatName(user: Input, format: Format = 'full') {
  return formats[format](user)
}
