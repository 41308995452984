import React from 'react'
import connect from 'helpers/connect'
import { RequiredActionsState } from 'slices/requiredActions'
import { AppState } from 'store'
import { FaIcon } from 'components/utilities/font-awesome'
import Header from './header'
import Item from './item'
import PaymentPlansTooltip from './alerts/payment-plans-proposed-tooltip'
import SettingsTooltip from './alerts/settings-tooltip'
import HourTrackerTooltip from './alerts/hour-tracker-required-tooltip'
import ProposedReimbursementsTooltip from './alerts/proposed-reimbursements-tooltip'

type Props = {
  requiredActions: RequiredActionsState
  alwaysExpanded: boolean
}

type State = {
  myAccountVisible: boolean
}

class MemberAccount extends React.Component<Props, State> {
  static defaultProps = {
    alwaysExpanded: false,
  }

  constructor(props: Props) {
    super(props)

    const myAccountVisible = sessionStorage.getItem('myAccountVisible') === 'true' || props.alwaysExpanded

    this.state = { myAccountVisible }
  }

  toggleMyAccount = () => {
    const myAccountVisible = !this.state.myAccountVisible
    sessionStorage.setItem('myAccountVisible', String(myAccountVisible))
    this.setState({ myAccountVisible })
  }

  render() {
    const { alwaysExpanded, requiredActions } = this.props
    const { profile, hour_tracker: hourTracker, payment_plans: paymentPlans } = requiredActions
    const { myAccountVisible } = this.state
    const expanded = myAccountVisible ? 'expanded' : ''

    let key = 0

    return (
      <ul className="sidebar-menu">
        {alwaysExpanded ? (
          <Header>My Account</Header>
        ) : (
          <Header key="sidebar-header">
            <div className="c-pointer" onClick={this.toggleMyAccount}>
              <FaIcon chevron-right className={expanded} />
              My Account
            </div>
          </Header>
        )}
        {myAccountVisible && [
          <Item key={key++} url="/member/balance" icon="bank">
            Balance
          </Item>,
          <Item key={key++} url="/member/payment-plan" id="Payment plan" icon="calculator">
            Payment plan
            <PaymentPlansTooltip paymentPlans={paymentPlans} />
          </Item>,
          <Item key={key++} url="/member/reimbursements" id="reimbursements#member" icon="exchange">
            Reimbursements
            <ProposedReimbursementsTooltip reimbursements={requiredActions.reimbursements} />
          </Item>,
          <Item key={key++} url="/member/hour-tracker" icon="clock-o" id="hour-tracker#member">
            Hour tracker
            <HourTrackerTooltip hourTracker={hourTracker} />
          </Item>,
          <Item key={key++} url="/member/event-registrations" icon="calendar" id="eventRegistration">
            Events
          </Item>,
          <Item key={key} url="/member/settings" id="settings#user" icon="cog">
            Settings
            <SettingsTooltip profile={profile} />
          </Item>,
        ]}
      </ul>
    )
  }
}

const mapStateToProps = (state: AppState) => ({
  requiredActions: state.requiredActions,
})

export default connect(MemberAccount, mapStateToProps)
