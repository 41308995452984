import { FC, useCallback, useMemo, useState } from 'react'
import { Button } from 'react-bootstrap'

import * as Sentry from '@sentry/react'
import { Elements, useElements, useStripe } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

import { FaButton, FaIcon } from 'components/utilities'
import { useAPI } from 'hooks/useAPI'
import useLegacyContext from 'hooks/useLegacyContext'
import { createBankAccount } from 'thunks/payment-methods/create-bank-account'
import { createBankAccountIntent } from 'thunks/stripe/create-bank-account-intent'
import notify, { notifyError } from 'utils/notify'

import { PaymentMethod } from './type'
// @ts-ignore
import { paymentMethodQuery } from './type?query'

type Props = {
  bsSize?: 'xs' | 'sm' | 'lg'
  bsStyle?: 'primary' | 'default'
  noIcon?: true
  onCreate: (paymentMethod: PaymentMethod) => void
}

const LocalAddBankAccountButton: FC<Props> = ({ bsSize, bsStyle = 'default', noIcon = false, onCreate }) => {
  const { user } = useLegacyContext()
  const stripe = useStripe()
  useElements()
  const [isLoading, setIsLoading] = useState(false)

  const [createIntent] = useAPI(createBankAccountIntent)
  const [create] = useAPI(createBankAccount)
  const handleStart = useCallback(async () => {
    if (!stripe) return
    try {
      setIsLoading(true)

      const [intent] = await createIntent({})

      const { setupIntent, error } = await stripe.collectBankAccountForSetup({
        clientSecret: intent.client_secret,
        params: {
          payment_method_type: 'us_bank_account',
          payment_method_data: {
            billing_details: {
              name: `${user.first_name} ${user.last_name}`,
              email: user.email,
            },
          },
        },
        expand: ['payment_method'],
      })

      console.log('[INTENT] ', intent, error)

      if (error) {
        throw error
      }

      const [paymentMethod] = await create({
        query: paymentMethodQuery,
        payment_method: {
          stripe_intent: setupIntent.id,
        },
      })

      onCreate(paymentMethod)

      notify('Your bank account has been added.')
    } catch (err) {
      notifyError('Unable to add bank account.')
      console.log('[STRIPE ERROR ADD BANK ACCOUNT]', err)
      Sentry.captureException(err)
    } finally {
      setIsLoading(false)
    }
  }, [user, createIntent, create, stripe, onCreate])

  if (isLoading) {
    return (
      <Button bsSize={bsSize} disabled className="relative">
        <span className="invisible">
          {noIcon || <FaIcon icon="university" />}
          &nbsp; Add bank account
        </span>
        <span className="absolute left-6">
          {noIcon || <FaIcon icon="spinner" spin className="mr-4" />}
          Loading...
        </span>
      </Button>
    )
  }

  if (noIcon) {
    return (
      <Button bsSize={bsSize} bsStyle={bsStyle} onClick={handleStart}>
        Add bank account
      </Button>
    )
  }

  return (
    <FaButton bsSize={bsSize} bsStyle={bsStyle} onClick={handleStart} icon="university">
      Add bank account
    </FaButton>
  )
}

const AddBankAccountButton = (props: Props) => {
  const stripePromise = useMemo(() => loadStripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY), [])

  return (
    <Elements stripe={stripePromise}>
      <LocalAddBankAccountButton {...props} />
    </Elements>
  )
}

export default AddBankAccountButton
