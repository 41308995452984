import apiRequest, { Request as Req } from 'utils/apiRequest'

export const generatePayload = ({ data, query }: Partial<Pick<Request, 'data' | 'query'>>) => {
  const payload = _.clone(data) || {}
  if (query) {
    payload.query = query.replace(/\s+/g, ' ')
  }
  return payload
}

export type Request = Omit<Req, 'uri' | 'method'> & { query?: string }
export type RequestWithID = Request & { id: number | string }

export const list = (uri: string, { data, query, onSuccess, onFailure }: Request) => {
  return apiRequest({
    method: 'GET',
    uri: `/api${uri}`,
    data: generatePayload({ data, query }),
    on: {
      200: onSuccess,
    },
    onFailure,
  })
}

export const create = (uri: string, { data, query, onSuccess, onFailure }: Request) => {
  return apiRequest({
    method: 'POST',
    uri: `/api${uri}`,
    data: generatePayload({ data, query }),
    on: {
      201: onSuccess,
      204: onSuccess,
    },
    onFailure,
  })
}

export const retrieve = (uri: string, { id, data, query, onSuccess, onFailure }: RequestWithID) => {
  return apiRequest({
    method: 'GET',
    uri: `/api${uri}/${id}`,
    data: generatePayload({ data, query }),
    on: {
      200: onSuccess,
    },
    onFailure,
  })
}

export const update = (uri: string, { id, data, query, onSuccess, onFailure }: RequestWithID) => {
  return apiRequest({
    method: 'PUT',
    uri: `/api${uri}/${id}`,
    data: generatePayload({ data, query }),
    on: {
      200: onSuccess,
    },
    onFailure,
  })
}

export const archive = (uri: string, { id, data, query, onSuccess, onFailure }: RequestWithID) => {
  return apiRequest({
    method: 'DELETE',
    uri: `/api${uri}/${id}`,
    data: generatePayload({ data, query }),
    on: {
      204: onSuccess,
    },
    onFailure,
  })
}

export const unarchive = (uri: string, { id, query, onSuccess, onFailure }: RequestWithID) => {
  return apiRequest({
    method: 'PUT',
    uri: `/api${uri}/${id}/unarchive`,
    data: generatePayload({ query }),
    on: {
      200: onSuccess,
    },
    onFailure,
  })
}

const helpers = (resource: string) =>
  class ApiWrapper {
    static list = (opts: Request) => list(resource, opts)
    static create = (opts: Request) => create(resource, opts)
    static retrieve = (opts: RequestWithID) => retrieve(resource, opts)
    static update = (opts: RequestWithID) => update(resource, opts)
    static archive = (opts: RequestWithID) => archive(resource, opts)
    static unarchive = (opts: RequestWithID) => unarchive(resource, opts)
  }

export default helpers

export function updateMethod(uri: string, method: string) {
  return ({ id, data, query, onSuccess, onFailure }: RequestWithID) =>
    apiRequest({
      method: 'PUT',
      uri: `/api${uri}/${id}/${method}`,
      data: generatePayload({ data, query }),
      on: {
        200: onSuccess,
      },
      onFailure,
    })
}

export function getMethod(uri: string) {
  return ({ data, query, onSuccess, onFailure }: Request) =>
    apiRequest({
      method: 'GET',
      uri: `/api${uri}`,
      data: generatePayload({ data, query }),
      on: {
        200: onSuccess,
      },
      onFailure,
    })
}
