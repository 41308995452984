import { Column } from 'components/table'

import MemberThumbnail from './_MemberThumbnail'
import { Member, Organization } from './type'

type Props = {
  organization: Organization
  columnProps: Record<any, any>
  admin: boolean
}

const rosterColumns = ({ organization, admin, columnProps }: Props) => {
  const allCustomFields = [...organization.custom_fields, ...organization.federation.custom_fields]

  const { fields, width } = (() => {
    const validKeys = [
      'email',
      'phone-number',
      'alternate-email',
      'campus-address',
      'parent-email',
      'parent-phone-number',
      'parent-address',
      ...allCustomFields.map(cf => cf.slug),
    ]

    const roster = organization[admin ? 'admin_roster' : 'member_roster'].filter(f => validKeys.includes(f))
    const defaultFields = admin ? ['name', 'balance'] : ['name']

    const fields = [...defaultFields, ...roster].sort((a, b) => validKeys.indexOf(a) - validKeys.indexOf(b))
    const width = (100 - (admin ? 36 : 26)) / roster.length

    return { fields, width }
  })()

  return fields.map(field => {
    switch (field) {
      case 'name':
        return (
          <Column
            key={field}
            width={22}
            value={({ user }: Member) => `${user.last_name}, ${user.first_name}`}
            sortBy={sortMembersBy(organization, admin)}
            formatter={(_, member: Member) => (
              <MemberThumbnail member={member} primaryAdminId={organization.primary_admin.id} isAdminView={admin} />
            )}
            {..._.get(columnProps, field, {})}
          >
            Member
          </Column>
        )

      case 'balance':
        return (
          <Column key={field} width={10} value={({ data }: Member) => _.toNumber(data.balance)} balance>
            Balance
          </Column>
        )

      case 'email':
        return (
          <Column key={field} width={width} sortBy={_.lowerCase} value={({ user }: Member) => user.email} email>
            Email
          </Column>
        )

      case 'phone-number':
        return (
          <Column key={field} width={15} value={({ user }: Member) => user.phone_number} phone>
            Phone number
          </Column>
        )

      case 'alternate-email':
        return (
          <Column key={field} width={width} sortBy={_.lowerCase} value="alternate_email" email>
            Alternate email
          </Column>
        )

      case 'campus-address':
        return (
          <Column key={field} width={width} value={({ user }: Member) => user.address} address>
            Campus address
          </Column>
        )

      case 'parent-email':
        return (
          <Column
            key={field}
            width={width}
            value={({ first_parent }: Member) => _.get(first_parent, 'user.email')}
            email
          >
            Parent email
          </Column>
        )

      case 'parent-phone-number':
        return (
          <Column
            key={field}
            width={width}
            value={({ first_parent }: Member) => _.get(first_parent, 'user.phone_number')}
            phone
          >
            Parent phone number
          </Column>
        )

      case 'parent-address':
        return (
          <Column
            key={field}
            width={width}
            value={({ first_parent }: Member) => _.get(first_parent, 'user.address')}
            address
          >
            Parent address
          </Column>
        )

      default:
        const customField = _.find(allCustomFields, cf => cf.slug === field)
        if (!customField) {
          return (
            <Column key={field} width={width} value={() => 'error'}>
              Error
            </Column>
          )
        }

        return (
          <Column key={field} width={width} none value={({ custom_fields }: Member) => custom_fields[customField.slug]}>
            {customField.name}
          </Column>
        )
    }
  })
}

export default rosterColumns

function sortMembersBy(organization: Props['organization'], isAdminView: boolean) {
  return (name: string, { id, is_admin: isAdmin }: Member, asc: boolean) => {
    if (!isAdminView) {
      return name.toLowerCase()
    }

    const isPrimary = organization.primary_admin.id === id
    const first = asc ? !isPrimary : isPrimary
    const second = asc ? !isAdmin : isAdmin
    return [first, second, _.lowerCase(name)]
  }
}
