import { forwardRef, useMemo } from 'react'
import { Button, ButtonGroup } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import r from 'routes'

import createTable, { TableRef } from 'components/table-v2'
import { FaButton, Money, Percentage, Units } from 'components/utilities'
import useLegacyContext from 'hooks/useLegacyContext'
import { openModal } from 'hooks/useModal'
import { isAlumni, isFederation, isMember } from 'types/user-functions'
import formatMoney from 'utils/format-money'

import useSummary from './_useSummary'
import { Budget } from './type'

type Props = {
  organizationId?: number
  budgets: Budget[]
}

const { Table, Header, Column, Actions, Body, Row, Cell, Footer } = createTable<Budget>()

const BudgetsTable = forwardRef<TableRef, Props>(({ organizationId, budgets }, ref) => {
  const { user } = useLegacyContext()
  if (!isMember(user) && !isFederation(user) && !isAlumni(user)) throw new Error('invalid role')

  const isReadonly = useMemo(() => ['member', 'alumni'].includes(user.role), [user.role])

  const { startingBalanceSum, totalBudget, totalSpent } = useSummary(budgets)

  return (
    <Table ref={ref} data={budgets}>
      <Header>
        <Column width={20}>Name</Column>
        <Column width={12}>Committee</Column>
        <Column width={12} align="right">
          Budget
        </Column>
        <Column width={12} align="right">
          Spent
        </Column>
        <Column width={12} align="right">
          Remaining
        </Column>
        <Column width={12} align="right">
          % of Total
        </Column>
        {!isReadonly && <Actions width={20} />}
      </Header>
      <Body>
        {budget => {
          const startingBudget = budget.starting_balance?.amount ?? 0
          const spent = budget.budget_expenses.reduce((sum, e) => sum + e.cost, 0)
          const remaining = startingBudget - spent
          const percentage = startingBudget / totalBudget

          return (
            <Row>
              <Cell>
                <Link
                  to={
                    isAlumni(user)
                      ? r.alumni.advising.show(organizationId).budgets.show(budget.id)
                      : r[user.role].budgets.show(budget.id)
                  }
                >
                  {budget.name}
                </Link>
              </Cell>
              <Cell value={budget.users.length}>
                <Units count={budget.users.length} noun="member" />
              </Cell>
              <Cell value={formatMoney(startingBudget)} sortValue={startingBudget}>
                <Money amount={startingBudget} />
              </Cell>
              <Cell value={formatMoney(spent)} sortValue={spent}>
                <Money amount={spent} praise plus />
              </Cell>
              <Cell value={formatMoney(remaining)} sortValue={remaining}>
                <Money amount={remaining} warning />
              </Cell>
              <Cell value={!isNaN(percentage) ? `${(percentage * 100).toFixed(1)}%` : 'n/a'} sortValue={percentage}>
                <Percentage value={percentage} decimalPlaces={1} />
              </Cell>
              <Cell>
                <ButtonGroup>
                  {isFederation(user) && (
                    <Button bsSize="xs" onClick={openModal('AddBudgetCredit', budget.id)}>
                      Add credit
                    </Button>
                  )}
                  <Button bsSize="xs" onClick={openModal('AddBudgetExpense', budget.id)}>
                    Add expense
                  </Button>
                </ButtonGroup>
              </Cell>
            </Row>
          )
        }}
      </Body>
      <Footer>
        <td colSpan={2} className="text-right">
          Total:
        </td>
        <td className="text-right">
          <Money amount={startingBalanceSum} />
        </td>
        <td className="text-right">
          <Money amount={totalSpent} praise plus />
        </td>
        <td className="text-right">
          <Money amount={startingBalanceSum - totalSpent} warning />
        </td>
        {isReadonly || <td colSpan={2} />}
      </Footer>
    </Table>
  )
})

export default BudgetsTable
