import { GCM_ENVIRONMENT } from 'helpers'
import { history } from 'store'

import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'

Sentry.init({
  environment: GCM_ENVIRONMENT,
  dsn: 'https://9f77fee542e348289d1ad109fb361d37@o1093462.ingest.sentry.io/6112739',
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
    }),
  ],
  enabled: GCM_ENVIRONMENT === 'production',
  tracesSampleRate: 1.0,
})
