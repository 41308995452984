import asyncThunk from 'utils/asyncThunk'

export type Request = {
  query: string
  purpose?: 'all' | 'form'
  user_id?: number
  event_id?: number
  owner_type?: 'Federation' | 'Organization'
  owner_id?: number
}

export const listEventRegistrations = asyncThunk<Request, any>({
  name: 'event-registrations/list',
  uri: '/event_registrations',
  method: 'GET',
})
