import asyncThunk from 'utils/asyncThunk'

export type Request = {
  query: string
  id: number
}

export const retrieveIssuedCard = asyncThunk<Request, any>({
  name: 'issued-cards/retrieve',
  uri: ({ id }) => `/issued_cards/${id}`,
})
