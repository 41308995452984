import { FC } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

import { FaIcon, Units } from 'components/utilities'

type Props = {
  hourTracker?: {
    pending: number
  }
}

const HourTrackerPendingTooltip: FC<Props> = ({ hourTracker }) => {
  if (!hourTracker || hourTracker.pending === 0) return <></>

  return (
    <OverlayTrigger
      placement="right"
      overlay={
        <Tooltip id="hour-records-tooltip">
          <Units count={hourTracker.pending} noun="submission" /> are pending
        </Tooltip>
      }
    >
      <FaIcon exclamation-circle warning className="pull-right" />
    </OverlayTrigger>
  )
}

export default HourTrackerPendingTooltip
