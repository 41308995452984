import { FC } from 'react'

import useLegacyContext from 'hooks/useLegacyContext'

type Props = {
  messageCategory: {
    owner_type: 'Organization' | 'Federation'
    recipient_descriptions: string
  }
}

const Label: FC<Props> = ({ messageCategory }) => {
  const { user } = useLegacyContext()

  if (user.role !== 'federation' && messageCategory.owner_type === 'Federation') {
    return <small className="hidden-xs">From the national office</small>
  }

  if (user.member && messageCategory.recipient_descriptions === user.member.organization.name) {
    return null
  }

  return <small className="hidden-xs">{messageCategory.recipient_descriptions}</small>
}

export default Label
