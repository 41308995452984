import asyncThunk from 'utils/asyncThunk'

export type Request = {
  query: string
  owner_type?: 'Organization' | 'Federation'
  owner_id?: number
  role?: 'admin' | 'member'
}

export const listUsers = asyncThunk<Request, any>({
  name: 'users/list',
  uri: '/users',
  method: 'GET',
})
