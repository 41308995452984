import { FC } from 'react'
import { Button, Label, Modal } from 'react-bootstrap'

import { Date, Money, Name } from 'components/utilities'
import modal from 'helpers/modal'
import { closeModal } from 'hooks/useModal'
import useObject from 'hooks/useObject'
import { Request, retrievePayment } from 'thunks/payments/retrieve'

import PaymentMethod from './payment-method'
import RefundButton from './refund-button'
import { Payment } from './type'
// @ts-ignore
import { paymentQuery } from './type?query'

type Props = {
  id: number
  onRefund: () => void
}

const PaymentDetailsModal: FC<Props> = ({ id, onRefund }) => {
  const [payment] = useObject<Payment, Request>(retrievePayment, {
    id,
    query: paymentQuery,
  })

  if (!payment) {
    return null
  }

  return (
    <div>
      <Modal show onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            <Name user={payment.member.user} full />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="panel panel-default">
            <div className="panel-heading fw-semibold">
              Payment on <Date long date={payment.created_at} />
              <span className="pull-right">
                <Money amount={payment.amount} />
              </span>
            </div>
            <div className="panel-body">
              {/* @ts-ignore */}
              <PaymentMethod payment={payment} />
            </div>
          </div>

          <div className="panel panel-default">
            <div className="panel-heading fw-semibold">Account credits</div>
            <div className="panel-body">
              <table className="table m-b-0">
                <thead>
                  <tr>
                    <th style={{ width: '40%' }}>Account</th>
                    <th style={{ width: '30%' }} className="text-right">
                      Member credit
                    </th>
                    <th style={{ width: '30%' }} className="text-right">
                      Transfer amount
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {payment.account_payments.map(ap => (
                    <tr key={ap.id}>
                      <td>
                        {ap.account ? ap.account.name : 'Processing fee'}
                        {ap.transfer_id ? (
                          <Label bsStyle="default" className="m-l-1">
                            transferred
                          </Label>
                        ) : null}
                      </td>
                      <td className="text-right">
                        {ap.account ? <Money amount={ap.balance_amount} /> : <span className="text-muted">n/a</span>}
                      </td>
                      <td className="text-right">
                        <Money amount={ap.transfer_amount} />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <RefundButton payment={payment} onRefund={onRefund} />

          <Button onClick={() => closeModal()} bsStyle="primary">
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default modal<Props>('PaymentDetails', PaymentDetailsModal)
