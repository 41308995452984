export const documentFolderQuery = `
  document_folder {
    id
    name
    is_private
    owner_type
    owner_id
    purpose
    organization {
      id
      name
      secondary
    }
    executive_board_position {
      id
      title
    }
  }
`
