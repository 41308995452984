export const paymentPlanQuery = `
  payment_plan {
    id
    status
    payment_plan_payments {
      id
      amount
      due_on
      payment_id
    }
  }
`
export const paymentMethodQuery = `
  payment_method {
    id
    company
    last_four
    method
    last_payment {
      id
      amount
      created_at
    }
  }
`
