import asyncThunk from 'utils/asyncThunk'

export type Request = {
  query: string
  purpose?: 'alumni'
  user_id: number
}

export const listDocumentFoldersByUser = asyncThunk<Request, any>({
  name: 'document-folders/list-by-user',
  uri: '/document_folders/list_by_user',
})
