import { FC, useState } from 'react'
import { ChromePicker, ColorResult } from 'react-color'

import connect from 'helpers/connect'

type Props = {
  color: string
  name?: string
  className?: string
  onChange?: (color: string) => void
}

const ColorInput: FC<Props> = ({ name, color: defaultColor, className, onChange }) => {
  const [displayColorPicker, setDisplayColorPicker] = useState(false)
  const [color, setColor] = useState(defaultColor || '#fff')

  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker)
  }

  const handleClose = () => {
    setDisplayColorPicker(false)
  }

  const handleChange = (color: ColorResult) => {
    if (onChange) {
      onChange(color.hex)
    }
    setColor(color.hex)
  }

  return (
    <div className={className}>
      <input type="hidden" name={name} value={color} />
      <div className="color-input-swatch m-l-1" onClick={handleClick}>
        <div className="color-input-color" style={{ background: color }} />
      </div>
      {displayColorPicker ? (
        <div className="color-input-popover">
          <div className="color-input-cover" onClick={handleClose} />
          <ChromePicker color={color} onChange={handleChange} />
        </div>
      ) : null}
    </div>
  )
}

export default connect<Props>(ColorInput)
