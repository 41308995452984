import moment from 'moment'
import { FC, useContext, useEffect, useMemo } from 'react'
import { Panel } from 'react-bootstrap'

import { Date, FaIcon } from 'components/utilities'

import useVoters from '../hooks/useVoters'
import { RealtimeContext } from '../shared/RealtimeVotingProvider'
import { Poll } from './type'

type Props = {
  poll: Poll
  canSeeAnswers: boolean
}

const VoteSidebar: FC<Props> = ({ poll, canSeeAnswers }) => {
  const votingEndsInDays = useMemo(() => {
    const endedAtMoment = moment(poll.ended_at)
    return Math.ceil(endedAtMoment.diff(moment(), 'days', true))
  }, [poll])

  const endsToDay = useMemo(() => {
    const endedAtMoment = moment(poll.ended_at)
    return endedAtMoment.isSame(moment(), 'day')
  }, [poll.ended_at])

  const [voters, , recordVote] = useVoters(poll.id)

  const { addListener } = useContext(RealtimeContext)
  useEffect(() => {
    addListener(event => {
      if (event.type !== 'PollVoteCreate') return
      recordVote(event.user_id)
    })
  }, [addListener, recordVote])

  return (
    <Panel>
      <Panel.Body>
        <div className="vote-sidebar">
          <h2 className="vote-sidebar-title">{poll.title}</h2>
          <pre>{poll.description}</pre>
        </div>
      </Panel.Body>

      <Panel.Footer>
        <div>
          {poll.ended_at && (
            <div className="vote-sidebar-stat">
              <FaIcon clock-o />
              <div className="vote-sidebar-stat-text">
                {endsToDay ? (
                  <>
                    <div>Voting ends at</div>
                    <strong>
                      <Date date={poll.ended_at} format="time12format" />
                    </strong>
                  </>
                ) : votingEndsInDays === 1 ? (
                  <>
                    <div>Voting ends</div>
                    <strong>
                      Tomorrow at <Date date={poll.ended_at} format="time12format" />
                    </strong>
                  </>
                ) : (
                  <>
                    <div>Voting ends on</div>
                    <strong>
                      <Date date={poll.ended_at} format="human" />
                    </strong>
                  </>
                )}
              </div>
            </div>
          )}

          <div className="vote-sidebar-stat">
            <FaIcon users />
            <div className="vote-sidebar-stat-text">
              <div>Members who voted</div>
              <strong>
                {voters?.filter(v => v.data.has_voted).length ?? '...'} of {voters?.length ?? '...'}
              </strong>
            </div>
          </div>

          {canSeeAnswers && (
            <div className="vote-sidebar-stat vote-list">
              <div className="vote-sidebar-stat-text">
                {voters?.map(user => (
                  <div key={user.id}>
                    <div className="d-inline-block">
                      {user.data.has_voted ? (
                        <FaIcon check style={{ float: 'none', fontSize: 16, color: '#888' }} />
                      ) : (
                        <FaIcon times style={{ float: 'none', fontSize: 16 }} />
                      )}
                    </div>
                    {user.last_name}, {user.first_name}
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </Panel.Footer>
    </Panel>
  )
}

export default VoteSidebar
