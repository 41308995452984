import { Actions } from 'actions'
import { JSXElementConstructor } from 'react'
import { Modal } from 'react-bootstrap'
import { ConnectedComponent, MapStateToPropsParam } from 'react-redux'
import { AppState } from 'store'

import { unserializeMetadata } from 'utils/parse-metadata'

import connect from './connect'

export type ModalProps<OwnProps> = OwnProps &
  Actions & {
    id?: number | string
    hashPrefix?: string
    show: boolean
  }

const withResetBeforeShow = (ModalComponent: JSXElementConstructor<any>) => (props: any) => {
  if (props.show === false && !props.showAlways) return null

  return <ModalComponent {...props} />
}

const modal = function <Props>(
  id: string,
  modal: JSXElementConstructor<any>,
  mapStateToPropsFunc?: MapStateToPropsParam<any, any>
) {
  const mapStateToProps = (state: AppState, ownProps: any) => {
    const { hash } = state.router.location
    const modalIds = hash.substring(1).split(';')
    const modals = modalIds.map(modalId => {
      const parts = modalId.split('/', 2)

      return {
        id: parts[0],
        metadata: parts[1],
      }
    })

    const currentModal = modals.find(m => {
      const idToMatch = ownProps.id ? `${id}:${ownProps.hashPrefix || ''}${ownProps.id}` : id

      return m.id === idToMatch
    })

    const metadata = unserializeMetadata(_.get(currentModal, 'metadata', ''))
    const { user } = state.auth

    const data = { show: currentModal !== undefined, metadata, user }

    if (mapStateToPropsFunc) {
      _.assign(data, mapStateToPropsFunc(state, ownProps))
    }

    return data
  }

  return connect(withResetBeforeShow(connect(modal)), mapStateToProps) as ConnectedComponent<
    typeof Modal,
    Props & {
      id?: number | string
    }
  >
}

export default modal
