import asyncThunk from 'utils/asyncThunk'

export type Request = {
  query: string
  event_registration: {
    variable_amount: number | undefined
    tickets: number | undefined

    event_answers: {
      question: string
      answer: string | string[]
    }[]

    event_optional_addons: {
      name: string
      cost: number
      quantity: number
    }[]

    payment_method_id: number | undefined
    event_id: number
  }
}

export const createEventRegistration = asyncThunk<Request, any>({
  name: 'event-registrations/create',
  uri: '/event_registrations',
  method: 'POST',
})
