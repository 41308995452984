export default function parseMetadata<T extends Record<string, string>>({ hash }: { hash: string }): T {
  if (!hash) {
    return {} as T
  }

  return unserializeMetadata<T>(hash.substring(1))
}

export function unserializeMetadata<T extends Record<string, string>>(string: string): T {
  const parts = string.split('/')
  return parts.reduce((memo, part) => {
    const pieces = part.split(':', 2)
    return _.assign(
      {
        [pieces[0]]: pieces[1],
      },
      memo
    )
  }, {} as T)
}
