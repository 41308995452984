import { useCallback } from 'react'
import { useAppDispatch } from 'store'

import { fetchRequiredActions } from 'actions/required-actions'

export default function useFetchRequiredActions() {
  const dispatch = useAppDispatch()

  return useCallback(() => {
    dispatch(fetchRequiredActions())
  }, [dispatch])
}
