export const issuedCardQuery = `
  issued_card {
    id
    name_on_card
    company
    last_four
    deactivated_at
    budget {
      id
      name
    }
  }
`
