import React, { FC, ReactElement } from 'react'
import { ButtonGroup } from 'react-bootstrap'

type Props = {
  column: {
    formatter?: (data: any) => React.ReactNode
    children: React.ReactNode
  }
  data: any
}

const ActionsCell: FC<Props> = ({ column, data }) => {
  let children

  if (column.formatter) {
    children = column.formatter(data)
  } else {
    children = React.Children.map(column.children, child => {
      const c = child as ReactElement
      if (!('data-map-on-click' in c.props)) {
        return child
      }

      const onClick = () => c.props.onClick(data)
      const disabled = _.isFunction(c.props.disabled) ? c.props.disabled(data) : false
      return React.cloneElement(c, { onClick, disabled })
    })
  }

  return (
    <td className="text-right hidden-print">
      <ButtonGroup bsSize="sm">{children}</ButtonGroup>
    </td>
  )
}

export default ActionsCell
