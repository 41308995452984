import { createAsyncThunk } from '@reduxjs/toolkit'

import { decorateTerms, Term } from 'slices/terms'

export type Request = {
  id: number
  name: string
  started_on: string
}[]

export type Response = Term[]

const setTerms = createAsyncThunk<Response, Request>('terms/set', async terms => decorateTerms(terms))

export default setTerms
