import { FC } from 'react'

import { Date, Label } from 'components/utilities'
import formatDate from 'utils/format_date'

import { HourRecord } from './type'

type Props = {
  record: HourRecord
}

const Status: FC<Props> = ({ record }) => {
  if (record.approved_at) {
    return (
      <Label success>
        approved on <Date extraShort date={record.approved_at} />
      </Label>
    )
  }

  if (record.denied_at) {
    return (
      <Label danger>
        denied on <Date extraShort date={record.denied_at} />
      </Label>
    )
  }

  return <Label warning>pending approval</Label>
}

export function getStatus(record: HourRecord): string {
  if (record.approved_at) {
    return `approved on ${formatDate(record.approved_at, 'extraShort')}`
  }

  if (record.denied_at) {
    return `denied on ${formatDate(record.denied_at, 'extraShort')}`
  }

  return 'pending approval'
}

export default Status
