import moment from 'moment'
import { useMemo } from 'react'
import { useDateRangeSelector } from 'store'

import useCollapsedTransactions from './_useCollapsedTransaction'
import { Transaction } from './type'

export default function useFilteredTransactions(allTransactions: Transaction[]) {
  const { dateRange } = useDateRangeSelector()
  const collapsedTransactions = useCollapsedTransactions(allTransactions)

  return useMemo(() => {
    if (!dateRange) {
      return {
        outstandingBalance: 0,
        transactions: [],
      }
    }

    let outstandingBalance = 0

    const transactions = _.filter(collapsedTransactions, txn => {
      const createdAt = moment(txn.created_at)
      const inDateRange = createdAt.isAfter(dateRange.start) && createdAt.isBefore(dateRange.end)

      if (!inDateRange && createdAt.isBefore(dateRange.start)) {
        outstandingBalance += txn.amount
      }

      return inDateRange
    })

    return { outstandingBalance, transactions }
  }, [collapsedTransactions, dateRange])
}
