import asyncThunk from 'utils/asyncThunk'

export type Request = {
  query: string

  not_in_collections?: boolean
  in_collections?: boolean
  role?: 'admin' | 'member'
  member_ids?: number[]
  organization_id?: number
  federation_id?: number
}

export const listMembers = asyncThunk<Request, any>({
  name: 'members/list',
  uri: '/members',
  method: 'GET',
})
