import { FC } from 'react'
import { Tooltip, OverlayTrigger } from 'react-bootstrap'
import { FaIcon } from 'components/utilities/font-awesome'

type Props = {
  paymentPlans?: {
    proposed: boolean
  }
}

const PaymentPlansProposedTooltip: FC<Props> = ({ paymentPlans }) => {
  return paymentPlans && paymentPlans.proposed ? (
    <OverlayTrigger
      placement="right"
      overlay={
        <Tooltip id="payment-plans-proposed-tooltip">
          You{"'"}ve been proposed a payment plan
        </Tooltip>
      }
    >
      <FaIcon exclamation-circle warning className="pull-right" />
    </OverlayTrigger>
  ) : null
}

export default PaymentPlansProposedTooltip
