import asyncThunk from 'utils/asyncThunk'

export type Request = {
  id: number
}

export const archiveDocumentFolder = asyncThunk<Request, void>({
  name: 'document-folders/archive',
  uri: ({ id }) => `/document_folders/${id}`,
  method: 'DELETE',
})
