import { APIResponse } from 'hooks/useAPI'
import asyncThunk from 'utils/asyncThunk'

export type Request = {}

export type Response = {
  authorization: string
}

export const braintreeAuthorization = asyncThunk<Request, Response>({
  name: 'braintree/authorization',
  uri: '/braintree/authorization',
  method: 'POST',
})
