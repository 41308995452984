import { FC, useCallback, useMemo } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

import { useAPI } from 'hooks/useAPI'
import { updateParentDelivery } from 'thunks/parents/update-delivery-settings'
import notify from 'utils/notify'

import { Parent } from '../type'

type Props = {
  parent: Parent
  greenDeliveryEnabled: boolean
  noParentsGetPhysicalBills: boolean
  onUpdate: (setting: DeliverySetting, enabled: boolean) => void
}

export type DeliverySetting = 'send_email_bills' | 'send_physical_bills' | 'send_sms_reminders'

const ParentBillDelivery: FC<Props> = ({ parent, greenDeliveryEnabled, noParentsGetPhysicalBills, onUpdate }) => {
  const [update] = useAPI(updateParentDelivery)
  const handleUpdate = useCallback(
    async (setting: DeliverySetting, enabled: boolean) => {
      const { send_email_bills, send_physical_bills, send_sms_reminders } = parent

      await update({
        id: parent.id,
        query: 'parent { id }',
        parent: {
          send_email_bills,
          send_physical_bills,
          send_sms_reminders,
          [setting]: enabled,
        },
      })
      notify('Delivery settings were saved')
      onUpdate(setting, enabled)
    },
    [parent, update, onUpdate]
  )

  const physicalBillDeliverySelectionEnabled = noParentsGetPhysicalBills || parent.send_physical_bills

  const checkboxDisabled = !parent.user.address || !physicalBillDeliverySelectionEnabled || greenDeliveryEnabled

  const physicalBillDeliveryCheckbox = useMemo(
    () => (
      <label htmlFor={`physicalBills-${parent.id}`}>
        <input
          name="parent.send_physical_bills"
          id={`physicalBills-${parent.id}`}
          type="checkbox"
          checked={!greenDeliveryEnabled && parent.send_physical_bills}
          disabled={checkboxDisabled}
          onChange={e => handleUpdate('send_physical_bills', e.target.checked)}
        />
        Send physical bills
      </label>
    ),
    [parent, checkboxDisabled, greenDeliveryEnabled, handleUpdate]
  )

  return (
    <div className="notification-settings">
      <div className="checkbox">
        <label htmlFor={`smsReminders-${parent.id}`}>
          <input
            name="parent.send_sms_reminders"
            id={`smsReminders-${parent.id}`}
            type="checkbox"
            disabled={!parent.user.phone_number}
            checked={parent.send_sms_reminders}
            onChange={e => handleUpdate('send_sms_reminders', e.target.checked)}
          />
          Send monthly SMS bill reminders
        </label>
      </div>

      <div className="checkbox">
        <label htmlFor={`emailBills-${parent.id}`}>
          <input
            name="parent.send_email_bills"
            id={`emailBills-${parent.id}`}
            type="checkbox"
            checked={parent.send_email_bills}
            disabled={!parent.user.email}
            onChange={e => handleUpdate('send_email_bills', e.target.checked)}
          />
          Send email bills
        </label>
      </div>

      <div className="checkbox">
        {!physicalBillDeliverySelectionEnabled && !parent.user?.address && !greenDeliveryEnabled ? (
          <OverlayTrigger
            overlay={<Tooltip id={`bill-${parent.id}`}>Physical bills can be sent to only one address</Tooltip>}
          >
            {physicalBillDeliveryCheckbox}
          </OverlayTrigger>
        ) : (
          physicalBillDeliveryCheckbox
        )}
        {greenDeliveryEnabled && (
          <span className="help-block">No bills will be snail mailed because green delivery is active</span>
        )}
      </div>
    </div>
  )
}

export default ParentBillDelivery
