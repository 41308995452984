import { IconDefinition } from '@fortawesome/fontawesome-common-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { CSSProperties, FC, ReactNode } from 'react'
import { Button } from 'react-bootstrap'

type FaIconProps = {
  className?: string
  icon?: string
  size?: 'md' | 'lg'

  success?: boolean
  warning?: boolean
  danger?: boolean

  spinner?: boolean
  spin?: boolean

  ban?: boolean
  bars?: boolean
  bolt?: boolean
  calendar?: boolean
  check?: boolean
  close?: boolean
  comment?: boolean
  download?: boolean
  exchange?: boolean
  eye?: boolean
  gavel?: boolean
  image?: boolean
  inbox?: boolean
  li?: boolean
  list?: boolean
  mobile?: boolean
  money?: boolean
  pencil?: boolean
  plus?: boolean
  search?: boolean
  star?: boolean
  times?: boolean
  trash?: boolean
  usd?: boolean
  users?: boolean

  style?: CSSProperties
  onClick?: () => void
}

const FaIcon: FC<FaIconProps> = props => {
  const passThruProps: Record<string, any> = {}
  const classes = _.map(props, (val, prop) => {
    if (prop === 'icon') {
      return `fa-${val}`
    } else if (prop === 'size') {
      return `fa-${val}`
    } else if (prop === 'className') {
      return val
    } else if (['success', 'warning', 'danger'].indexOf(prop) > -1) {
      return val ? `text-${prop}` : undefined
    } else if (prop.match(/^on/)) {
      passThruProps[prop] = val
    } else if (prop === 'style') {
      passThruProps.style = val
    }
    return `fa-${prop}`
  })
  return <i className={`fa ${classes.join(' ')}`} {...passThruProps} />
}

type FaButtonProps = {
  icon: string | IconDefinition
  bsStyle?: 'primary' | 'secondary' | 'success' | 'danger' | 'default'
  bsSize?: 'xs' | 'sm' | 'lg'
  iconVariant?: 'warning'
  disabled?: boolean
  type?: 'submit' | 'button'
  children?: ReactNode
  style?: CSSProperties
  className?: string
  href?: string
  target?: string
  onClick?: (params: any) => any
}

const FaButton: FC<FaButtonProps> = props => {
  const { children, icon, iconVariant } = props

  return (
    <Button {...props}>
      {typeof icon === 'string' ? (
        <FaIcon warning={iconVariant === 'warning'} icon={icon} />
      ) : (
        <FontAwesomeIcon icon={icon} />
      )}
      {!children || `${String.fromCharCode(160)} ${children}`}
    </Button>
  )
}

export { FaIcon, FaButton }
