import cx from 'classnames'
import { orgHelpers } from 'helpers'
import { FC, useCallback } from 'react'
import { useRequiredActionsSelector } from 'store'

import { FaIcon } from 'components/utilities/font-awesome'
import { useLegacyAdmin } from 'hooks/useLegacyContext'
import { useLocalStorage } from 'hooks/useLocalStorage'

import BillPayTooltip from './alerts/bill-pay-tooltip'
import HourTrackerTooltip from './alerts/hour-tracker-pending-tooltip'
import OrganizationPaymentPlanTooltip from './alerts/organization-payment-plan-tooltip'
import PaymentPlansTooltip from './alerts/payment-plans-awaiting-tooltip'
import ReimbursementsTooltip from './alerts/reimbursements-tooltip'
import Divider from './divider'
import Header from './header'
import Item from './item'
import VotesAside from './votes-aside'

const Admin: FC = () => {
  const user = useLegacyAdmin()
  const requiredActions = useRequiredActionsSelector()

  const [reportingVisible, setReportingVisible] = useLocalStorage<'true' | 'false'>('reportingVisible', 'true')

  const toggleReporting = useCallback(() => {
    const isReportingVisible = reportingVisible === 'true' ? 'false' : 'true'
    sessionStorage.setItem('reportingVisible', isReportingVisible)
    setReportingVisible(isReportingVisible)
  }, [reportingVisible, setReportingVisible])

  const nationalOrg = orgHelpers.isNationalOrganization(user.member.organization)

  return (
    <ul className="sidebar-menu">
      <Header>Chapter finances</Header>
      <Item url="/admin/members" icon="users">
        Roster
      </Item>
      <Item url="/admin/g-capital" id="gCapital" icon="usd" backgroundIcon="file">
        gCapital
        <BillPayTooltip billpay={requiredActions.billpay} />
      </Item>
      <Item url="/admin/payment-plans" id="payment-plans#admin" icon="calculator">
        Payment plans
        <PaymentPlansTooltip paymentPlans={requiredActions.payment_plans} />
      </Item>
      <Item url="/admin/reimbursements" id="reimbursements#admin" icon="exchange">
        Reimbursements
        <ReimbursementsTooltip reimbursements={requiredActions.reimbursements} />
      </Item>
      <Item url="/admin/budgets" icon="book">
        Budgets
      </Item>
      {nationalOrg && user.member.organization && (
        <Item id="finances" url="/admin/national-overview" icon="bar-chart">
          {user.member.organization.federation.name} Finances
          {requiredActions.organization_payment_plans.count > 0 && (
            <OrganizationPaymentPlanTooltip
              count={requiredActions.organization_payment_plans.count}
              className="absolute"
            />
          )}
        </Item>
      )}
      <Item url="/admin/groups" icon="users">
        Groups
      </Item>

      <Divider />
      <Header>Chapter operations</Header>
      <Item url="/admin/attendance" icon="clock-o">
        Attendance
      </Item>
      <Item url="/member/calendar" icon="calendar">
        Calendar
      </Item>
      <Item url="/admin/document-folders" icon="folder">
        Documents
      </Item>
      <Item url="/admin/hour-tracker" icon="clock-o" id="hour-tracker#admin">
        Hour tracker
        <HourTrackerTooltip hourTracker={requiredActions.admin_hour_tracker} />
      </Item>
      <Item url="/member/message-board" icon="comment">
        Message Board
      </Item>
      <Item url="/admin/messaging" icon="bullhorn">
        Messaging
      </Item>
      <Item url="/admin/events" icon="calendar">
        Events
      </Item>
      <Item url="/admin/executive-board" icon="address-book">
        Executive Board
      </Item>
      <Item url="/admin/committee-positions" icon="users">
        Chairs/Committees
      </Item>
      <Item url="/admin/rush" icon="users">
        Recruitment
      </Item>
      <Item url="/admin/voting" icon="gavel" aside={<VotesAside votes={requiredActions.votes} />} asideType="primary">
        Voting
      </Item>
      <Item url="/admin/required-documents" icon="file-text">
        Required documents
      </Item>
      <Item url="/admin/settings" id="settings#admin" icon="cog">
        Settings
      </Item>
      {/* <Item url="/games" icon="gamepad">Games</Item> */}

      <Divider />
      <Header key="sidebar-header">
        <div className="c-pointer" onClick={toggleReporting}>
          <FaIcon chevron-right className={cx({ expanded: reportingVisible === 'true' })} />
          Reporting
        </div>
      </Header>

      {reportingVisible === 'true' && [
        <Item key={0} url="/admin/reporting" icon="dashboard">
          Overview
        </Item>,
        <Item key={1} url="/admin/transfers" icon="exchange">
          Transfers
        </Item>,
        <Item key={2} url="/admin/transactions" icon="money">
          Transactions
        </Item>,
        <Item key={3} url="/admin/donations" icon="money">
          Donations
        </Item>,
        <Item key={4} url="/admin/reporting/transaction-summaries" icon="table">
          Transaction summaries
        </Item>,
        <Item key={5} url="/admin/reporting/member-balances" icon="table">
          Member balances
        </Item>,
        <Item key={6} url="/admin/reporting/aging-detail" icon="table">
          Aging detail
        </Item>,
        <Item key={7} url="/admin/collections" icon="gavel">
          Collections
        </Item>,
        <Item key={8} url="/admin/taxes" icon="university">
          Taxes
        </Item>,
      ]}
    </ul>
  )
}

export default Admin
