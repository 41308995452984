import asyncThunk from 'utils/asyncThunk'

export type Request = {
  query: string
  id: number
  parent: {
    user: {
      first_name: string | undefined
      last_name: string | undefined
      email: string | undefined
      phone_number: string | null

      address?: {
        address: string
        address_2: string | undefined
        city: string
        state: string
        postal_code: string
        country: string
      }
    }
  }
}

export const updateParent = asyncThunk<Request, any>({
  name: 'parents/update',
  uri: ({ id }) => `/parents/${id}`,
  method: 'PUT',
})
