import { HalfYearsState } from 'slices/halfYears'

import T from './types'

export function ensureHalfYears() {
  return {
    type: T.ENSURE_HALF_YEARS,
  }
}

export function setHalfYears(halfYears: HalfYearsState) {
  return {
    type: T.SET_HALF_YEARS,
    halfYears,
  }
}
