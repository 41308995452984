import asyncThunk from 'utils/asyncThunk'

export type Request = {
  organization_id?: number
}

export type S3UploadInfo = {
  path: string
  url: string
}

export const uploadDocumentUrl = asyncThunk<Request, S3UploadInfo>({
  name: 'documents/upload-url',
  uri: '/documents/upload_url',
  method: 'POST',
})
