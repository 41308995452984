import { FC, useCallback, useState } from 'react'
import { Col, Grid, Row } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import r from 'routes'

import AddDocumentFolderModal from 'components/document-folders/add-document-folder-modal'
import ManagedDocumentFolder from 'components/documents/folder/managed'
import AsyncTypeahead from 'components/typeahead/AsyncTypeahead'
import { Action, ActionGroup, Content, EmptyState, Header, Loading } from 'components/utilities'
import useActiveSidebarItem from 'hooks/useActiveSidebarItem'
import { useAPI } from 'hooks/useAPI'
import { useLegacyMemberOrAdmin } from 'hooks/useLegacyContext'
import useList from 'hooks/useList'
import { openModal } from 'hooks/useModal'
import { listDocumentFoldersByUser, Request } from 'thunks/document-folders/list-by-user'
import { listDocuments, Request as DocumentRequest } from 'thunks/documents/list'

import { Document, DocumentFolder } from './type'
// @ts-ignore
import { documentFolderQuery, documentQuery } from './type?query'
import { isGranted } from 'helpers'

const AdminMemberFoldersIndex: FC = () => {
  const user = useLegacyMemberOrAdmin()
  useActiveSidebarItem('Documents')

  const [folders, { update, remove, patch }] = useList<DocumentFolder, Request>(listDocumentFoldersByUser, {
    query: documentFolderQuery,
    user_id: user.id,
  })

  const [documents, setDocuments] = useState<Document[]>([])
  const [list] = useAPI<Document[], DocumentRequest>(listDocuments)
  const handleSearch = useCallback(
    async (search: string) => {
      const [documents] = await list({
        query: documentQuery,
        search,
      })
      setDocuments(documents)
    },
    [list]
  )

  const history = useHistory()
  const handleSelect = useCallback(
    (documents: Document[]) => {
      const document = documents[0]
      const uri = r.member.documentFolders.show(document.document_folder.id)
      const suffix = `#id:${document.id}`
      history.push(`${uri}${suffix}`)
    },
    [history]
  )

  if (!folders) return <Loading />

  return (
    <>
      <Content>
        <Grid>
          <Header
            actions={
              <ActionGroup>
                <AsyncTypeahead
                  placeholder="Search document..."
                  className="document-searcher w-[260px]"
                  options={documents}
                  onSearch={handleSearch}
                  onChange={handleSelect}
                  onInputChange={() => setDocuments([])}
                  filterBy={() => true}
                  labelKey="filename"
                  defaultActiveIndex={0}
                  useCache={false}
                />

                {isGranted(user, 'Documents') && (
                  <Action icon="plus" onClick={openModal('AddDocumentFolder')}>
                    New folder
                  </Action>
                )}
              </ActionGroup>
            }
          >
            Documents
          </Header>
          <Row>
            <Col md={12}>
              {folders.length ? (
                <>
                  {folders.map(f => (
                    <ManagedDocumentFolder
                      key={f.id}
                      folder={f}
                      onUpdate={update}
                      onArchive={() => remove(f.id)}
                      onSubfolderCreated={folder =>
                        patch({
                          id: f.id,
                          document_folders: [...f.document_folders, folder],
                        })
                      }
                    />
                  ))}
                </>
              ) : (
                <EmptyState action="Add a folder" onClick={openModal('AddDocumentFolder')}>
                  No document folders have been added yet.
                </EmptyState>
              )}
            </Col>
          </Row>
        </Grid>
      </Content>

      <AddDocumentFolderModal
        ownerId={user.member.organization.id}
        ownerType="Organization"
        onCreate={folder => history.push(r.admin.documentFolders.show(folder.id))}
      />
    </>
  )
}

export default AdminMemberFoldersIndex
