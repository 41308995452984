import moment, { Moment } from 'moment'
import React from 'react'
import { Button, Modal } from 'react-bootstrap'

import DateRange from 'components/date-range/DateRange'
import modal from 'helpers/modal'
import { closeModal } from 'hooks/useModal'

type DateRangeType = {
  startDate: Moment
  endDate: Moment
}

type Props = {
  start: Moment
  end: Moment
  noRanges?: boolean
  maxDate?: Moment
  minDate?: Moment
  onRangeChange: (range: DateRangeType) => void
  onClose?: () => void
}

type State = {
  startDate: Moment
  endDate: Moment
}

class DateRangeModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      startDate: props.start,
      endDate: props.end,
    }
  }

  onRangeChange = (data: { startDate: Moment; endDate: Moment }) => {
    this.setState({
      startDate: data.startDate,
      endDate: data.endDate,
    })
  }

  doCloseModal = () => {
    const { onRangeChange, onClose } = this.props
    const { startDate, endDate } = this.state

    onRangeChange({ startDate, endDate })

    if (onClose) {
      onClose()
    }

    closeModal()
  }

  ranges = (): Record<string, Record<keyof DateRangeType, (now: Moment) => Moment>> => ({
    'This month': {
      startDate: (now: Moment) => now.startOf('month'),
      endDate: (now: Moment) => now,
    },
    'Last month': {
      startDate: (now: Moment) => now.subtract(1, 'month').startOf('month'),
      endDate: (now: Moment) => now.subtract(1, 'month').endOf('month'),
    },
    'Last 30 days': {
      startDate: (now: Moment) => now.subtract(30, 'days').startOf('day'),
      endDate: (now: Moment) => now,
    },
    'Last 90 days': {
      startDate: (now: Moment) => now.subtract(90, 'days').startOf('day'),
      endDate: (now: Moment) => now,
    },
  })

  render() {
    const { minDate, maxDate, start, end } = this.props

    const resultStartDate = start || moment().subtract(30, 'days')
    const resultEndDate = end || moment()

    return (
      <Modal show onHide={this.doCloseModal} className="has-rdr">
        <Modal.Header closeButton>
          <Modal.Title>Select date range</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <hr className="spacer-xs" />

          <DateRange
            calendars={1}
            startDate={resultStartDate}
            endDate={resultEndDate}
            maxDate={maxDate}
            minDate={minDate}
            onInit={this.onRangeChange}
            onChange={this.onRangeChange}
            ranges={this.props.noRanges ? undefined : this.ranges()}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button bsStyle="primary" onClick={this.doCloseModal}>
            Set date range
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default modal<Props>('DateRange', DateRangeModal)
