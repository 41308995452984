import { createSlice } from '@reduxjs/toolkit'

import { restoreDateRange, storeDateRange } from 'helpers/date-range-persistance'
import setHalfYearDateRange from 'thunks/half-year-date-range/set'

import { DateRange } from './dateRange'
import { getCurrentHalfYear } from './halfYears'

export type HalfYearDateRangeState = {
  dateRange: DateRange
}

const initialState: HalfYearDateRangeState = {
  dateRange: restoreDateRange() ?? getCurrentHalfYear(),
}

const slice = createSlice({
  name: 'half-year-date-range',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(setHalfYearDateRange.fulfilled, (_, { meta }) => {
      storeDateRange(meta.arg)
      return { dateRange: meta.arg }
    })
  },
})

export default slice
export const actions = slice.actions
