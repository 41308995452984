import { FC, useCallback } from 'react'

import { UploadAvatarModal } from 'components/documents'
import { File } from 'components/uploader'
import { useAPI } from 'hooks/useAPI'
import { closeModal } from 'hooks/useModal'
import { updateUserAvatar } from 'thunks/users/avatar'
import notify from 'utils/notify'

import { User } from './type'
// @ts-ignore
import { userQuery } from './type?query'

type Props = {
  userId: number
  onSuccess: (user: User) => void
}

const UploadUserAvatarModal: FC<Props> = ({ userId, onSuccess }) => {
  const [update] = useAPI(updateUserAvatar)
  const handleUpdate = useCallback(
    async (file: File) => {
      const [updated] = await update({
        query: userQuery,
        id: userId,
        user: {
          s3_path: file.s3_path,
        },
      })

      onSuccess(updated)
      notify('The photo has been updated!')
      closeModal()
    },
    [update, onSuccess, userId]
  )

  return <UploadAvatarModal onSuccess={handleUpdate} />
}

export default UploadUserAvatarModal
