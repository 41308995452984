import { FC, useRef } from 'react'
import { Alert, Col, Grid, Row } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import r from 'routes'
import { useDateRangeSelector } from 'store'

import { TableRef } from 'components/table-v2'
import TermSelector from 'components/terms/selector'
import {
  BreadcrumbHeader,
  BreadcrumbItem,
  ButtonBar,
  Content,
  Loading,
  PrintAction,
  TableAction,
} from 'components/utilities'
import useActiveSidebarItem from 'hooks/useActiveSidebarItem'
import useList from 'hooks/useList'
import useObject from 'hooks/useObject'
import { Headers, issuedCardLedger, LedgerItem, Request as LedgerRequest } from 'thunks/issued-cards/ledger'
import { Request, retrieveIssuedCard } from 'thunks/issued-cards/retrieve'

import IssuedCardLedger from './_IssuedCardLedger'
import { IssuedCard } from './type'
// @ts-ignore
import { issuedCardQuery } from './type?query'

type RouteParams = {
  id: string
}

const MemberCardsShow: FC = () => {
  useActiveSidebarItem('Budgets')
  const params = useParams<RouteParams>()
  const ref = useRef<TableRef>(null)
  const { dateRange } = useDateRangeSelector()

  const [card] = useObject<IssuedCard, Request>(retrieveIssuedCard, {
    id: +params.id,
    query: issuedCardQuery,
  })

  const [ledger, { headers }] = useList<LedgerItem, LedgerRequest, Headers>(issuedCardLedger, {
    id: +params.id,
    start_date: dateRange!.start.format('YYYY-MM-DD'),
    end_date: dateRange!.end.format('YYYY-MM-DD'),
  })

  if (!ledger || !card) {
    return <Loading />
  }

  return (
    <Content>
      <Grid>
        {card.deactivated_at && (
          <Alert bsStyle="warning" className="clearfix">
            <div className="fs-125" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <strong>This card is frozen. All transactions will be declined.</strong>
            </div>
          </Alert>
        )}

        <BreadcrumbHeader>
          <BreadcrumbItem link={r.member.budgets.show(card.budget.id)}>{card.budget.name}</BreadcrumbItem>
          <BreadcrumbItem>{`${card.name_on_card} (${card.company} *${card.last_four})`}</BreadcrumbItem>
        </BreadcrumbHeader>

        <IssuedCardLedger
          ref={ref}
          ledger={ledger}
          startDate={headers!['x-starting-date']}
          startingBalance={parseFloat(headers!['x-starting-balance'])}
        />

        <Row className="m-b-2">
          <Col sm={9} lg={9}>
            <TermSelector className="pull-left m-r-2" />
          </Col>
          <Col sm={3} lg={3}>
            <ButtonBar className="footer-toolbar">
              <PrintAction />
              <TableAction
                icon="download"
                onClick={() => ref.current?.downloadCSV(`${card.name_on_card.replaceAll(' ', '-')}-${card.last_four}`)}
              />
            </ButtonBar>
          </Col>
        </Row>
      </Grid>
    </Content>
  )
}

export default MemberCardsShow
