export const parentQuery = `
  parent {
    id
    send_email_bills
    send_physical_bills
    send_sms_reminders
    user {
      id
      first_name
      last_name
      phone_number
      email
      address {
        address
        address_2
        city
        state
        postal_code
        country
      }
    }
  }
`
