import { useCallback, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'

import { File } from 'components/uploader'
import { useAPI } from 'hooks/useAPI'
import useYupValidationResolver from 'hooks/useYupValidationResolver'
import { createEventRegistration, Request } from 'thunks/event-registrations/create'
import { isAPIError, isGenericAPIError, processValidationErrors } from 'utils/asyncThunk'
import { notifyError } from 'utils/notify'

import { Event, EventRegistration } from './type'
// @ts-ignore
import { eventRegistrationQuery } from './type?query'

export type FormData = {
  _: string
  variable_amount: number | undefined
  tickets: number
  event_answers: (string | string[])[]
  documents: File[]
  event_optional_addons: number[]
  payment_method_id: number | undefined
}

const MIN_VARIABLE_AMOUNT = 0

export default function useRegistrationForm(
  event: Event | undefined,
  onRegister: (registration: EventRegistration) => void
) {
  const [docs, setDocs] = useState([])

  const schema = useMemo(() => {
    if (!event) return yup.object()

    const answers: yup.ISchema<unknown>[] = event.event_questions.map((q, i) => {
      const v = q.checkbox ? yup.array(yup.string()) : yup.string()

      // Checkbox (multiple select)
      if (q.mandatory && q.select && q.checkbox) {
        return yup
          .array(yup.string().min(1, 'A response is required').required('A response is required'))
          .min(1, 'A response is required')
      }

      // Select
      if (q.mandatory && q.select && !q.checkbox) {
        return yup.string().min(1, 'A response is required').required('A response is required')
      }

      // File
      if (q.mandatory && q.with_image && !q.checkbox && !q.select) {
        return yup.string().test('required', 'You must upload a document', () => Boolean(docs[i]))
      }

      // Input
      if (q.mandatory && !q.select && !q.checkbox && !q.with_image) {
        return yup.string().trim().min(1, 'A response is required').required('A response is required')
      }

      return v
    })

    const variableAmount =
      event.cost_type === 'variable'
        ? yup
            .number()
            .min(MIN_VARIABLE_AMOUNT, `Amount must be greater than or equal to ${MIN_VARIABLE_AMOUNT}`)
            .required(`Amount must be greater than or equal to ${MIN_VARIABLE_AMOUNT}`)
        : yup.number()

    return yup.object({
      event_answers: yup.tuple(answers as any),
      variable_amount: variableAmount,
    })
  }, [docs, event])

  const resolver = useYupValidationResolver<yup.InferType<typeof schema>>(schema)
  const form = useForm<FormData>({
    resolver,
    defaultValues: {
      tickets: 1,
      event_optional_addons: [],
      event_answers: [],
      documents: [],
      variable_amount: MIN_VARIABLE_AMOUNT,
    },
  })

  // @ts-ignore
  const formDataDocuments = form.watch('documents')
  useEffect(() => {
    // @ts-ignore
    setDocs(formDataDocuments)
  }, [formDataDocuments])

  useEffect(() => {
    if (!event) return

    form.setValue(
      'event_optional_addons',
      event.optional_addons.map(() => 0)
    )
  }, [event, form])

  const [register, { timer }] = useAPI<EventRegistration, Request>(createEventRegistration)
  const handleSubmit = useCallback(
    async (data: FormData) => {
      if (!event) return
      try {
        form.clearErrors()

        const [registration] = await register({
          query: eventRegistrationQuery,
          event_registration: {
            ...data,
            event_answers: event.event_questions.map((q, i) => ({
              ...q,
              answer: data.event_answers[i],
              document: data.documents[i],
            })),
            event_optional_addons: event.optional_addons.map((a, i) => ({
              ...a,
              quantity: data.event_optional_addons[i],
            })),
            tickets: event.maximum_tickets ? data.tickets : undefined,
            variable_amount: data.variable_amount || 0,
            event_id: event.id,
            payment_method_id: data.payment_method_id ?? undefined,
          },
        })

        form.reset()

        onRegister(registration)
      } catch (err) {
        if (isAPIError<'event_registration'>(err)) {
          processValidationErrors('event_registration', err, form.setError)
        } else if (isGenericAPIError(err)) {
          notifyError(err.error ?? 'unable to register')
        } else {
          notifyError(`error: ${(err as any).message}`)
        }
      }
    },
    [event, form, onRegister, register]
  )

  return [handleSubmit, { form, timer }] as const
}
