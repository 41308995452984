export const documentQuery = `
  document {
    id
    filename
    mimetype
    url
    size
    created_at
    document_labels {
      id
      label
    }
  }
`
