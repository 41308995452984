import cx from 'classnames'
import moment from 'moment'
import { FC, useCallback, useMemo } from 'react'
import { Col, Grid, Row } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import r from 'routes'

import Table, { Column, downloadCSV } from 'components/table'
import {
  Body,
  ButtonBar,
  Content,
  EmptyState,
  Header,
  Loading,
  Portlet,
  PrintAction,
  TableAction,
} from 'components/utilities'
import { FaButton } from 'components/utilities/font-awesome'
import useActiveSidebarItem from 'hooks/useActiveSidebarItem'
import { useLegacyMember } from 'hooks/useLegacyContext'
import useList from 'hooks/useList'
import { listEventRegistrations, Request as EventRegistrationRequest } from 'thunks/event-registrations/list'
import { listEventsByUser, Request as EventRequest } from 'thunks/events/list-by-user'

import { Event, EventRegistration } from './type'
// @ts-ignore
import { eventQuery, eventRegistrationQuery } from './type?query'

const AdminEventRegistrations: FC = () => {
  useActiveSidebarItem('formSubmissions')

  const history = useHistory()

  const user = useLegacyMember()

  const [rawEvents, { timer: eventsTimer }] = useList<Event, EventRequest>(listEventsByUser, {
    query: eventQuery,
    user_id: user.id,
    purpose: 'form',
  })

  const [eventRegistrations] = useList<EventRegistration, EventRegistrationRequest>(listEventRegistrations, {
    query: eventRegistrationQuery,
    user_id: user.id,
    purpose: 'form',
  })

  const isRegistrationClosed = useCallback(
    (registrationClosesOn?: string) =>
      registrationClosesOn && moment(registrationClosesOn).endOf('day').isBefore(moment()),
    []
  )

  const events = useMemo(() => {
    if (!eventRegistrations) return []

    return rawEvents?.sort((a, b) => {
      const wasSubmittedA = eventRegistrations.find(({ event }) => event.id === a.id)
      const wasSubmittedB = eventRegistrations.find(({ event }) => event.id === b.id)

      const canSubmitA = !wasSubmittedA && !isRegistrationClosed(a.registration_closes_on)
      const canSubmitB = !wasSubmittedB && !isRegistrationClosed(b.registration_closes_on)
      if (canSubmitA && !canSubmitB) return -1
      if (!canSubmitA && canSubmitB) return 1

      if (wasSubmittedA && !wasSubmittedB) return -1
      if (!wasSubmittedA && wasSubmittedB) return 1
      return a.name.localeCompare(b.name)
    })
  }, [rawEvents, isRegistrationClosed, eventRegistrations])

  if (!events || !eventRegistrations) return <Loading />

  return (
    <Content>
      <Grid>
        <div>
          <Header>Forms</Header>

          <Portlet boxed>
            <Body table>
              <Table
                csvName="Forms"
                data={events}
                defaultSort={false}
                noResults={<EmptyState message="No forms added yet" onClick={() => history.push(r.admin.events.new)} />}
              >
                <Column width={30} value="name" link={({ id }: Event) => `/member/event-registrations/${id}/new`}>
                  Name
                </Column>

                <Column
                  width={20}
                  value={({ registration_closes_on }: Event) =>
                    registration_closes_on ? moment(registration_closes_on).format('MMM Do, YYYY') : ''
                  }
                  formatter={(v?: string) => v || <span className="text-muted">n/a</span>}
                  align="right"
                >
                  Submission closes
                </Column>

                <Column
                  width={15}
                  align="right"
                  formatter={(_, { id }: Event) => {
                    const eventReg = eventRegistrations.find(({ event }) => event.id === id)
                    return eventReg ? moment(eventReg.created_at).format('MMM Do, YYYY') : 'no'
                  }}
                  value={({ id }: Event) => {
                    const createdAt = eventRegistrations.find(({ event }) => event.id === id)?.created_at
                    return createdAt ? moment(createdAt).format('MMM Do, YYYY') : ''
                  }}
                >
                  Submitted
                </Column>

                <Column
                  width={15}
                  noSort
                  dontDownload
                  align="right"
                  formatter={(_, event: Event) => {
                    const registered = eventRegistrations.find(({ event: e }) => e.id === event.id)
                    const closed = isRegistrationClosed(event.registration_closes_on)

                    const icon = (() => {
                      if (registered) {
                        return 'check'
                      }
                      if (closed) {
                        return 'times'
                      }
                      return 'user-plus'
                    })()

                    const action = (() => {
                      if (registered) {
                        return 'Submitted'
                      }
                      if (closed) {
                        return 'Closed'
                      }
                      return 'Submit'
                    })()

                    return (
                      <FaButton
                        icon={icon}
                        bsSize="sm"
                        onClick={() => history.push(r.member.eventRegistrations.new(event.id))}
                        className={cx({ 'o-75': closed })}
                      >
                        {action}
                      </FaButton>
                    )
                  }}
                />
              </Table>
            </Body>
          </Portlet>

          <Row className="m-b-2">
            <Col sm={8} lg={8} />
            <Col sm={4} lg={4}>
              <ButtonBar className="footer-toolbar">
                <PrintAction disabled={eventsTimer.isLoading} />
                <TableAction icon="download" disabled={eventsTimer.isLoading} onClick={downloadCSV('Forms')} />
              </ButtonBar>
            </Col>
          </Row>
        </div>
      </Grid>
    </Content>
  )
}

export default AdminEventRegistrations
