import Humanize from 'humanize-plus'
import moment from 'moment'

import { Options, ProposedPayment } from './common'

export function generateQuickOptions({ balance, options }: { balance: number; options: (keyof Options)[] }) {
  return options.reduce((memo, installments, i) => {
    const amounts = generateAmounts(balance, 4 - i)
    const label = `${installments} payments of $${Humanize.formatNumber(amounts[0], 2)}`
    const payments = quickSchedulingPayments(amounts)

    return _.assign(
      {
        [installments]: { label, payments },
      },
      memo
    )
  }, {} as Options)
}

function generateAmounts(balance: number, installments: number): number[] {
  const amounts: number[] = []
  let remaining = balance
  const paymentAmount = Math.floor((balance / installments) * 100) / 100.0
  _.times(installments, i => {
    const amount = i === installments - 1 ? remaining : paymentAmount
    amounts.push(amount)
    remaining -= amount
  })
  return amounts
}

function quickSchedulingPayments(amounts: number[]) {
  return amounts.map((amount, i) => ({
    amount,
    due_on: moment()
      .add(i + 1, 'month')
      .startOf('month'),
  }))
}

export function paymentPlansAreEqual(one: ProposedPayment[], two: ProposedPayment[]) {
  if (one.length !== two.length) {
    return false
  }
  const changed = _.find(one, (a, index) => {
    const b = two[index]
    return a.amount !== b.amount || a.due_on.valueOf() !== b.due_on.valueOf()
  })
  return _.isUndefined(changed)
}
