export const pollQuery = `
  poll {
    id
    title
    description
    ended_at
    winners {
      id
      title
    }
    data {
      vote_count
      voted_option_ids
      voters_count
    }
  }
`
