import { createSlice } from '@reduxjs/toolkit'

import { bootstrap } from 'thunks/auth/bootstrap'
import setActiveParent from 'thunks/parents/set-active-parent'
import setDeliverySetting from 'thunks/parents/set-delivery-setting'

export type Parent = {
  id: number
  send_email_bills: boolean
  send_physical_bills: boolean
  send_sms_reminders: boolean
  member: {
    id: 1
    unique_id: string
    no_physical_bills: boolean
    v2_archive_url?: string
    user: {
      id: number
      first_name: string
      last_name: string
    }
    organization: {
      id: number
      cc_upcharge: boolean
    }
  }
}

export type ParentState = {
  activeParent?: Parent
  parents?: Parent[]
}

const initialState: ParentState = {}

const slice = createSlice({
  name: 'parent',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(bootstrap.fulfilled, (state, { payload }) => {
      if (payload.data.role !== 'parent') return {}
      return {
        activeParent: payload.data.parents[0],
        parents: payload.data.parents,
      }
    })
    builder.addCase(setActiveParent.fulfilled, (state, { meta }) => {
      const parent = state.parents?.find(p => p.id === meta.arg.id)
      return {
        activeParent: parent,
        parents: state.parents,
      }
    })
    builder.addCase(setDeliverySetting.fulfilled, (state, { payload }) => {
      if (!state.parents || !state.activeParent) return
      state.activeParent[payload.setting] = payload.enabled

      const idx = state.parents?.findIndex(p => p.id === state.activeParent?.id)
      if (idx === undefined) return
      state.parents[idx] = state.activeParent
    })
  },
})

export default slice
export const actions = slice.actions
