import ActionCable from 'actioncable'
import { createContext, FC, ReactNode, useCallback, useEffect, useMemo, useState } from 'react'

import useLegacyContext from 'hooks/useLegacyContext'

type RealtimeContextType = {
  addListener: (listener: Listener) => void
}

export const RealtimeContext = createContext<RealtimeContextType>({} as RealtimeContextType)

type PollVoteCreateEvent = {
  type: 'PollVoteCreate'
  user_id: number
}

type RealtimeEvent = PollVoteCreateEvent

type Listener = (event: RealtimeEvent) => void

type Props = {
  children: ReactNode
}

const RealtimeVotingProvider: FC<Props> = ({ children }) => {
  const { user } = useLegacyContext()
  const [listeners, setListeners] = useState<Listener[]>([])

  const config = useMemo(
    () => [
      {
        channel: 'Poll::Channel::EndVoting',
        user_id: user!.id,
      },
      {
        channel: 'Poll::Channel::Create',
        user_id: user!.id,
      },
      {
        channel: 'PollVote::Channel::Create',
        user_id: user!.id,
      },
    ],
    [user]
  )

  useEffect(() => {
    const token = localStorage.getItem('token')
    const consumer = ActionCable.createConsumer(
      `https://${import.meta.env.VITE_API_ORIGIN}/cable/socket?token=${token}`
    )
    const subscriptions = config.map(channel =>
      consumer.subscriptions.create(channel, {
        received: data => {
          console.log('received from websocket', data)
          listeners.forEach(listener => listener(data))
        },
      })
    )
    return () => {
      subscriptions.forEach(s => s.unsubscribe())
      consumer.disconnect()
    }
  }, [listeners, config])

  const addListener = useCallback((listener: Listener) => {
    setListeners(listeners => [...listeners, listener])
  }, [])

  return <RealtimeContext.Provider value={{ addListener }}>{children}</RealtimeContext.Provider>
}

export default RealtimeVotingProvider
