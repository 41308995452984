import classnames from 'classnames'
import { FC, useMemo } from 'react'
import { OverlayTrigger, Radio, Tooltip } from 'react-bootstrap'

import ConnectedField from 'components/form/connected-field'
import Field from 'components/form/field'
import { FaIcon } from 'components/utilities/font-awesome'

type Props = {
  accounts: {
    id: number
    name: string
    weight: number
  }[]
}

const Payment: FC<Props> = ({ accounts: origAccounts }) => {
  const accounts = useMemo(() => origAccounts.sort((a, b) => a.weight - b.weight), [origAccounts])

  const disabledAccountOverlay = useMemo(
    () => (
      <Tooltip id="tooltip">
        <strong>Manually added payments can only go into {accounts[accounts.length - 1].name}.</strong>
        <br />
        Contact GCM to record a payment on another balance.
      </Tooltip>
    ),
    [accounts]
  )

  return (
    <div>
      <ConnectedField name="description" label="Description" autoFocus />

      <ConnectedField name="amount" label="Amount" inputSize={5} money />

      {accounts.length > 1 && (
        <Field name="transaction.account_id" label="Apply to">
          {accounts.map(({ id, name }, i) => {
            const isDefaultAccount = i === accounts.length - 1

            return (
              <Radio
                key={id}
                id={`${id}`}
                name="transaction.account_id"
                value={id}
                checked={isDefaultAccount}
                readOnly
                disabled={!isDefaultAccount}
                className={classnames({ 'text-muted': !isDefaultAccount })}
              >
                {isDefaultAccount ? (
                  name
                ) : (
                  <OverlayTrigger placement="right" overlay={disabledAccountOverlay}>
                    <span>
                      {name}
                      <FaIcon question-circle className="m-l-05" />
                    </span>
                  </OverlayTrigger>
                )}
              </Radio>
            )
          })}
        </Field>
      )}
    </div>
  )
}

export default Payment
