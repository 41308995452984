import classNames from 'classnames'
import { useCallback, useMemo } from 'react'

import { Typeahead } from 'components/typeahead'
import { FaIcon } from 'components/utilities'
import { APIThunk } from 'hooks/useAPI'
import useList from 'hooks/useList'

import s from './index.module.scss'

type Props<Obj extends { id: number }, Request extends { query: string }> = {
  label: (obj: Obj) => string
  selectedId: number | undefined
  thunk: APIThunk<Request, Obj[]>
  query: string
  request: Omit<Request, 'query'>
  align?: 'left' | 'right'
  placeholder: string
  className?: string
  onChange: (obj?: Obj) => void
}

function RemoteSelect<Obj extends { id: number }, Request extends { query: string }>({
  label,
  selectedId,
  thunk,
  query,
  request,
  placeholder,
  align,
  className,
  onChange,
}: Props<Obj, Request>) {
  const [objects] = useList<Obj, Request>(thunk, { ...request, query } as Request)

  const options = useMemo(() => {
    if (!objects) return undefined
    return objects
      .map(el => ({
        key: el.id,
        label: label(el),
      }))
      .sort((a, b) => a.label.localeCompare(b.label))
  }, [objects, label])

  const handleChange = useCallback(
    (selected: { key: number }[]) => {
      if (selected.length === 0) return onChange(undefined)
      const obj = objects?.find(el => el.id === selected[0].key)
      onChange(obj)
    },
    [objects, onChange]
  )

  return (
    <div className={classNames(s.RemoteSelect, className)}>
      <Typeahead
        key={!!selectedId}
        options={options ?? []}
        placeholder={placeholder}
        paginate={false}
        align={align}
        onChange={handleChange}
        selected={options?.filter(option => option.key === selectedId)}
      />
      {selectedId && <FaIcon times onClick={() => onChange(undefined)} />}
    </div>
  )
}

export default RemoteSelect
