import { connect, isGranted as isGrantedHelper } from 'helpers'
import { FC, useContext } from 'react'
import { AppState } from 'store'

import LegacyContext from 'contexts/legacy'
import { useLegacyMember } from 'hooks/useLegacyContext'
import {
  ATTENDANCE_FEATURE,
  DOCUMENTS_FEATURE,
  EVENTS_FEATURE,
  HOUR_TRACKER_FEATURE,
  MESSAGING_FEATURE,
  RUSH_FEATURE,
  VOTING_FEATURE,
  WEBSITE_FEATURE,
} from 'resources/feature'
import { RequiredActionsState } from 'slices/requiredActions'
import { Feature, FederationFeature } from 'types/feature'

import HourTrackerTooltip from './alerts/hour-tracker-pending-tooltip'
import Contacts from './contacts'
import Header from './header'
import Item from './item'
import MemberAccount from './member-account'
import MemberMenuGMoney from './member-gmoney'
import National from './national'
import PrimaryAdmin from './primary-admin'
import VotesAside from './votes-aside'

type Props = {
  requiredActions: RequiredActionsState
}

const MemberMenu: FC<Props> = ({ requiredActions }) => {
  const user = useLegacyMember()
  const { organization } = useContext(LegacyContext)

  const isGranted = (feature: Feature | FederationFeature) => {
    if (user === undefined) {
      return false
    }
    return isGrantedHelper(user, feature)
  }

  const renderMemberFeatures = () => (
    <ul className="sidebar-menu">
      <Header>Admin</Header>
      {isGranted(ATTENDANCE_FEATURE) && (
        <Item url="/admin/attendance" icon="clock-o">
          Attendance
        </Item>
      )}
      {isGranted(DOCUMENTS_FEATURE) && (
        <Item url="/admin/document-folders" icon="folder">
          Documents
        </Item>
      )}
      {isGranted(HOUR_TRACKER_FEATURE) && (
        <Item url="/admin/hour-tracker" icon="clock-o" id="hour-tracker#admin">
          Hour tracker
          <HourTrackerTooltip hourTracker={requiredActions.hour_tracker} />
        </Item>
      )}
      {isGranted(MESSAGING_FEATURE) && (
        <Item url="/admin/messaging" icon="bullhorn">
          Messaging
        </Item>
      )}
      {isGranted(RUSH_FEATURE) && (
        <Item url="/admin/rush" icon="users">
          Recruitment
        </Item>
      )}
      {isGranted(EVENTS_FEATURE) && (
        <Item url="/admin/events" icon="calendar">
          Events
        </Item>
      )}
      {isGranted(VOTING_FEATURE) && (
        <Item url="/admin/voting" icon="gavel" id="voting#admin">
          Voting
        </Item>
      )}
      {isGranted(WEBSITE_FEATURE) && (
        <Item url="/admin/settings" icon="cog" id="settings#admin">
          Settings
        </Item>
      )}
    </ul>
  )

  const renderMember = () => (
    <ul className="sidebar-menu">
      <Header>Chapter</Header>
      <Item url="/member/budgets" icon="book">
        Budgets
      </Item>
      <Item url="/member/calendar" icon="calendar">
        Calendar
      </Item>
      {isGranted(DOCUMENTS_FEATURE) || (
        <Item url="/member/document-folders" icon="folder">
          Documents
        </Item>
      )}
      <Item url="/member/executive-board" icon="address-book">
        Executive Board
      </Item>
      <Item url="/member/committee-positions" icon="users">
        Chairs/Committees
      </Item>
      <Item url="/member/message-board" icon="comment">
        Message Board
      </Item>
      <Item url="/member/members" icon="users">
        Roster
      </Item>
      <Item url="/member/voting" icon="gavel" aside={<VotesAside votes={requiredActions.votes} />} asideType="primary">
        Voting
      </Item>
    </ul>
  )

  return (
    <div>
      <MemberAccount alwaysExpanded />
      {renderMember()}
      <MemberMenuGMoney />
      {user?.member && user.member.data.all_features.length > 0 && renderMemberFeatures()}
      <National />
      <PrimaryAdmin organization={organization} />
      <Contacts />
    </div>
  )
}

const mapStateToProps = (state: AppState) => ({
  requiredActions: state.requiredActions,
})

export default connect(MemberMenu, mapStateToProps)
