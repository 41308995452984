import moment, { Moment } from 'moment'
import TimePicker from 'rc-time-picker'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { Col, FormControl, InputGroup, Row } from 'react-bootstrap'

import DatePickerModal from 'components/modals/date-picker'
import { FaIcon } from 'components/utilities/font-awesome'
import connect from 'helpers/connect'
import { openModal } from 'hooks/useModal'

type Props = {
  dateTime?: Moment
  minDate?: Moment
  maxDate?: Moment
  modalId: string
  onChange: (time: Moment | undefined) => void
}

const CompactDateTimeInput: FC<Props> = ({ modalId, minDate, maxDate, dateTime: dateTimeProp, onChange }) => {
  const [dateTime, setDateTime] = useState<Moment | null>(dateTimeProp ? moment(dateTimeProp) : null)
  const [isOpenTimePicker, setIsOpenTimePicker] = useState<boolean>(false)

  useEffect(() => {
    setDateTime(dateTimeProp ? moment(dateTimeProp) : null)
  }, [dateTimeProp])

  const onDateChange = useCallback(
    (newDate: Moment | undefined) => {
      if (!newDate) {
        onChange(undefined)
        return
      }

      if (newDate && dateTime) {
        const newDateTime = dateTime.clone()

        newDateTime.set({
          date: newDate.date(),
          month: newDate.month(),
          year: newDate.year(),
        })

        onChange(newDateTime)
      } else {
        if (newDate) newDate.set({ h: 12 })
        onChange(newDate)
      }
    },
    [dateTime, onChange]
  )

  const onTimeChange = useCallback(
    (newTime: Moment) => {
      if (!newTime) {
        onChange(undefined)
        return
      }

      if (newTime) {
        const newDateTime = dateTime ? dateTime.clone() : moment()
        const minutes = newTime.minute() % 5 === 0 ? newTime.minute() : 0
        // s prevents the time from being set to the current second
        newDateTime.set({ h: newTime.hour(), m: minutes, s: 0 })
        onChange(newDateTime)
      }
    },
    [dateTime, onChange]
  )

  const dateTimeValue = useMemo(() => (dateTime ? dateTime.clone() : null), [dateTime])

  return (
    <Row>
      <Col md={7}>
        <InputGroup onClick={openModal('DatePicker', modalId)} className="c-pointer">
          <FormControl
            value={dateTime ? dateTime.format('MMMM D, YYYY') : ''}
            type="text"
            readOnly
            className="c-pointer bg-white"
          />
          <InputGroup.Addon className="bg-white">
            <FaIcon calendar />
          </InputGroup.Addon>
        </InputGroup>
      </Col>
      <Col md={5}>
        <InputGroup onClick={() => setIsOpenTimePicker(true)} className="c-pointer">
          <TimePicker
            className="c-pointer"
            allowEmpty
            open={isOpenTimePicker}
            value={dateTime ? dateTime : undefined}
            showSecond={false}
            use12Hours
            minuteStep={5}
            format="h:mma"
            onClose={() => setIsOpenTimePicker(false)}
            onChange={onTimeChange}
          />
          <InputGroup.Addon className="bg-white">
            <FaIcon clock-o />
          </InputGroup.Addon>
        </InputGroup>
      </Col>

      <DatePickerModal
        id={modalId}
        date={dateTimeValue || moment().add(1, 'day')}
        minDate={minDate ? moment(minDate) : moment()}
        maxDate={maxDate ? moment(maxDate) : undefined}
        onDateSelected={onDateChange}
      />
    </Row>
  )
}

export default connect<Props>(CompactDateTimeInput)
