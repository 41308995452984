export const documentQuery = `
  document {
    id
    filename
    url
    mimetype
    size
    created_at
    document_labels {
      id
      label
    }
    document_folder {
      id
      name
    }
  }
`
