import asyncThunk from 'utils/asyncThunk'

export type Request = {
  id: number
  query: string
  parent: {
    send_email_bills: boolean
    send_physical_bills: boolean
    send_sms_reminders: boolean
  }
}

export const updateParentDelivery = asyncThunk<Request, any>({
  name: 'parents/delivery',
  uri: ({ id }) => `/parents/${id}/delivery`,
  method: 'PUT',
})
