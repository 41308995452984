import classNames from 'classnames'
import { FC, ReactNode } from 'react'
import { Button as B } from 'react-bootstrap'

import { ActionTimer } from 'hooks/useActionTimer'

type Props = {
  timer: ActionTimer
  disabled?: boolean
  tabIndex?: number
  className?: string
  children: ReactNode
  onSubmit?: () => void
}

const Button: FC<Props> = ({ timer, disabled, tabIndex, className, children, onSubmit }) => {
  return (
    <B
      type={onSubmit ? 'button' : 'submit'}
      bsStyle="primary"
      disabled={timer.isLoading || disabled}
      tabIndex={tabIndex}
      className={classNames({ 'btn-loading': timer.isLoading }, className)}
      onClick={onSubmit}
    >
      {children}
    </B>
  )
}

export default Button
