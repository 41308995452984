import { FC } from 'react'
import { useAuthSelector } from 'store'

import Avatar from 'components/utilities/avatar'
import Name from 'components/utilities/name'

const NavbarName: FC = () => {
  const { user } = useAuthSelector()

  if (!user) return null

  if (user.role === 'member' || user.role === 'admin' || user.role === 'alumni') {
    return <Avatar avatar={user.avatar} className="navbar-profile-avatar m-r-1" />
  }

  if (user?.role === 'parent') {
    const { member } = user.parents[0]
    return (
      <span className="m-r-1 pt-3 no-avatar">
        Parent of {member.user.first_name} {member.user.last_name}
      </span>
    )
  }

  return (
    <span className="m-r-1 m-l-1 no-avatar">
      <Name user={user} firstInitial />
    </span>
  )
}

export default NavbarName
