import { apiRequest } from 'actions/use'

import apiWrapper, { generatePayload, getMethod, Request, RequestWithID } from './helpers'

export default class Group extends apiWrapper('/groups') {
  static sync({ id, data, query, onSuccess, onFailure }: RequestWithID) {
    apiRequest({
      method: 'PUT',
      uri: `/api/groups/${id}/sync`,
      data: generatePayload({ data, query }),
      on: {
        200: onSuccess,
      },
      onFailure,
    })
  }

  static alumniGroups({ data, query, onSuccess, onFailure }: Request) {
    apiRequest({
      method: 'GET',
      uri: '/api/groups/alumni_groups',
      data: generatePayload({ data, query }),
      on: {
        200: onSuccess,
      },
      onFailure,
    })
  }

  static alumniSummary = getMethod('/groups/alumni_summary')
  static alumniGroupsByFederation = getMethod('/groups/alumni_groups_by_federation')

  static addUsers({ id, data, query, onSuccess, onFailure }: RequestWithID) {
    apiRequest({
      method: 'PUT',
      uri: `/api/groups/${id}/add_users`,
      data: generatePayload({ data, query }),
      on: {
        200: onSuccess,
        201: onSuccess,
      },
      onFailure,
    })
  }
}
