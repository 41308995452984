import { FC } from 'react'

import { useLegacyAlumni } from 'hooks/useLegacyContext'

import Header from './header'
import Item from './item'

const OrganizationAdvisors: FC = () => {
  const user = useLegacyAlumni()

  const orgs = user.alumni.advised_organizations
  if (orgs.length === 0) {
    return <></>
  }
  const orgId = user.alumni.advised_organizations[0].id

  return (
    <ul className="sidebar-menu">
      <Header>Chapter Advising</Header>
      <Item url={`/alumni/advising/${orgId}/members`} icon="users">
        Chapter rosters
      </Item>
      <Item url={`/alumni/advising/${orgId}/executive-board`} icon="address-book">
        Executive Boards
      </Item>
      <Item url={`/alumni/advising/${orgId}/committee-positions`} icon="users">
        Chairs/Committees
      </Item>
      <Item url={`/alumni/advising/${orgId}/volunteers-and-staff`} icon="users">
        Volunteers & staff
      </Item>
      <Item url={`/alumni/advising/${orgId}/visitation-reports`} icon="book">
        Visitation reports
      </Item>
      <Item url={`/alumni/advising/${orgId}/taxes`} icon="university">
        Taxes
      </Item>
      <Item url={`/alumni/advising/${orgId}/financial-overview`} icon="usd">
        Financial overview
      </Item>
      <Item url={`/alumni/advising/${orgId}/budgets`} icon="book">
        Budgets
      </Item>
      <Item url={`/alumni/advising/${orgId}/bill-pay`} icon="usd" backgroundIcon="file">
        BillPay
      </Item>
      <Item url={`/alumni/advising/${orgId}/national-balance`} icon="table">
        National balances
      </Item>
      {/* <Item url={`/alumni/advising/${orgId}/forms`} icon="file-text-o">
        Form submissions
      </Item> */}
    </ul>
  )
}

export default OrganizationAdvisors
