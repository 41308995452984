import asyncThunk from 'utils/asyncThunk'

export type Request = {
  query: string
  id: number
  reimbursement: {
    reason: string
  }
}

export const rejectReimbursement = asyncThunk<Request, any>({
  name: 'reimbursements/reject',
  uri: ({ id }) => `/reimbursements/${id}/reject`,
  method: 'PUT',
})
