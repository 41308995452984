import { FC, useEffect, useMemo, useState } from 'react'
import { Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import { Body, EmptyState, Portlet } from 'components/utilities'
import useManageDocumentFolder from 'hooks/useManageDocumentFolder'
import useLegacyContext from 'hooks/useLegacyContext'
import { openModal } from 'hooks/useModal'
import { isAdmin, isAlumni, isFederation, isSuper } from 'types/user-functions'

import DocumentList from '../document-list'
import DownloadButton from '../document-list/_DownloadButton'
import Dropdown from '../document-list/_Dropdown'
import FolderList from '../folder-list'
import Actions from './_Actions'
import Header from './_Header'
import useManageDocuments from './_useManageDocuments'
import { DocumentFolder } from './type'

type Props = {
  preview?: boolean
  folder: DocumentFolder
  onUpdate: (folder: DocumentFolder) => void
  onArchive: () => void
  onSubfolderCreated: (folder: DocumentFolder) => void
}

const ManagedDocumentFolder: FC<Props> = ({ preview, folder, onUpdate, onArchive }) => {
  const { user } = useLegacyContext()

  const [documents, setDocuments] = useState<DocumentFolder['documents']>([])
  useEffect(() => {
    if (preview) {
      setDocuments(folder.recent_documents)
    } else {
      setDocuments(folder.documents.sort((a, b) => a.filename.localeCompare(b.filename)))
    }
  }, [folder, preview])

  const { handleAdd, handleUpdate, handleArchive } = useManageDocuments(documents, setDocuments)

  const folderURI = useMemo(() => {
    let url
    if (isFederation(user) && folder.executive_board_position) {
      url = `/federation/executive-boards/document-folders/${folder.executive_board_position.id}`
    } else if (isFederation(user) && folder.organization) {
      url = `/federation/organizations/${folder.organization.id}/documents`
    } else if (isFederation(user)) {
      url = `/federation/document-folders/${folder.id}`
    } else if (isAlumni(user)) {
      url = `/alumni/document-folders/${folder.id}`
    } else {
      url = `/admin/document-folders/${folder.id}`
    }
    return url
  }, [user, folder])

  const canManage = useManageDocumentFolder(folder)

  return (
    <Portlet className="portlet-posts portlet-with-table portlet-with-table">
      <div className="portlet-header clearfix">
        <h4 className="portlet-title">
          <Header folder={folder} folderUri={folderURI} />
        </h4>

        {canManage && <Actions folder={folder} onUpload={handleAdd} onUpdate={onUpdate} onArchive={onArchive} />}
      </div>
      <Body>
        {documents.length || folder.document_folders.length ? (
          <>
            {!!folder.document_folders && <FolderList folders={folder.document_folders} />}

            <DocumentList
              documents={documents}
              actions={document => (
                <span className="space-x-4">
                  <DownloadButton document={document} />

                  {canManage && (
                    <Dropdown document={document} onUpdate={handleUpdate} onArchive={() => handleArchive(document)} />
                  )}
                </span>
              )}
            />
          </>
        ) : (
          <EmptyState action={canManage ? 'Upload one now' : undefined} onClick={openModal('AddDocument', folder.id)}>
            No documents have been uploaded yet
          </EmptyState>
        )}

        {preview && !!documents.length && (
          <Link to={folderURI}>
            <Button bsStyle="default" bsSize="sm" className="hidden-print">
              View all documents
            </Button>
          </Link>
        )}
      </Body>
    </Portlet>
  )
}

export default ManagedDocumentFolder
