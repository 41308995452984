import asyncThunk from 'utils/asyncThunk'

export type Request = {
  query: string
  amount: string
  source: string
  payment_method_id: number
  member_id: number
}

export const createPaymentMethodPayment = asyncThunk<Request, any>({
  name: 'payments/payment-method',
  uri: '/payments/payment_method',
  method: 'POST',
})
