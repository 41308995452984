import { APIResponse } from 'hooks/useAPI'
import asyncThunk from 'utils/asyncThunk'

export type Request = {
  auth: {
    identifier: string
    password: string
  }
}

export type Response = {
  jwt: string
}

export const login = asyncThunk<Request, Response>({
  name: 'auth/login',
  uri: '/user_token',
  method: 'POST',
})
