import { FC } from 'react'
import { Tooltip, OverlayTrigger } from 'react-bootstrap'
import { FaIcon, Units } from 'components/utilities'

type Props = {
  pending: number
}

const ReportsTooltip: FC<Props> = ({ pending }) => {
  return pending > 0 ? (
    <OverlayTrigger
      placement="right"
      overlay={
        <Tooltip id="reimbursement-tooltip">
          <Units count={pending} noun="report is" plural="reports are" /> awaiting approval
        </Tooltip>
      }
    >
      <FaIcon exclamation-circle warning className="pull-right" />
    </OverlayTrigger>
  ) : null
}

export default ReportsTooltip
