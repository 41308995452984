type Event = {
  maximum_tickets: number | undefined
  data: {
    tickets_sold: number
  }
}

export default function areTicketsAvailable(event: Event) {
  return !event.maximum_tickets || event.maximum_tickets - event.data.tickets_sold > 0
}
