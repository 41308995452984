import { FC } from 'react'

import Alert from './_Alert'

import { Reimbursement } from './type'

type Props = {
  reimbursements: Reimbursement[]
}

const Alerts: FC<Props> = ({ reimbursements }) => (
  <div className="alert-wrapper">
    {reimbursements.map((reimbursement, i) => (
      <Alert key={i} reimbursement={reimbursement} />
    ))}
  </div>
)

export default Alerts
