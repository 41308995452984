import { FC, useCallback, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import Money from 'components/utilities/money'
import modal from 'helpers/modal'
import { rejectReimbursement, Request } from 'thunks/reimbursements/reject'
import { closeModal } from 'hooks/useModal'
import { useAPI } from 'hooks/useAPI'
import notify from 'utils/notify'

import { Reimbursement } from 'components/reimbursements/type'
// @ts-ignore
import { reimbursementQuery } from 'components/reimbursements/type?query'

type Props = {
  id?: number
  reimbursement: Reimbursement
  onReject: (reimbursement: Reimbursement) => void
}

const RejectReimbursementModal: FC<Props> = ({ reimbursement, onReject }) => {
  const [reason, setReason] = useState('')

  const [reject, { timer }] = useAPI<Reimbursement, Request>(rejectReimbursement)
  const handleReject = useCallback(async () => {
    const [updated] = await reject({
      id: reimbursement.id,
      query: reimbursementQuery,
      reimbursement: {
        reason,
      },
    })
    notify({
      type: 'warning',
      message: `${reimbursement.user.first_name}'s reimbursement has been rejected.`,
    })
    onReject(updated)
    closeModal({ closeAll: true })
  }, [reason, reject, reimbursement, onReject])

  const { created_by: creator, amount } = reimbursement

  return (
    <Modal show onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>Reject reimbursement request</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="text-center fw-semibold m-t-1 m-b-2">
          You are rejecting {creator.first_name} {creator.last_name}'s reimbursement of <Money amount={amount} />
        </p>

        <textarea
          name="reimbursement.reason"
          placeholder="reason for rejecting (optional)"
          rows={4}
          className="form-control"
          onChange={e => setReason(e.target.value)}
          value={reason}
          maxLength={250}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => closeModal()}>Cancel</Button>
        <Button disabled={timer.isLoading} onClick={handleReject} bsStyle="primary">
          Reject reimbursement
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default modal<Props>('RejectReimbursement', RejectReimbursementModal)
