import Container from 'container'
import connect from 'helpers/connect'
import React, { Fragment } from 'react'
import Item from './item'

class KappaSigmaMenu extends Container {
  isKappaSigma() {
    const id = _.get(this.context, 'user.member.organization.federation.id')
    return id === 2 || id === 1
  }

  render() {
    if (!this.isKappaSigma()) {
      return <></>
    }

    return (
      <Fragment>
        <Item url="/admin/kappa-sigma/add-pledges" icon="user-plus">
          Add pledges
        </Item>
        <Item url="/admin/kappa-sigma/initiate" icon="user">
          Initiate
        </Item>
        <Item url="/admin/kappa-sigma/alumni" icon="user">
          Alumni
        </Item>
      </Fragment>
    )
  }
}

export default connect(KappaSigmaMenu)
