import asyncThunk from 'utils/asyncThunk'

export type Request = {
  id: number
  user: {
    password: string
  }
}

export const changeUserPassword = asyncThunk<Request, any>({
  name: 'users/change-password',
  uri: ({ id }) => `/users/${id}/change_password`,
  method: 'PUT',
})
