import { useEffect, useState } from 'react'
import { Member } from 'resources'

export default function useProfileMember<T>(
  memberId: number | string,
  query: string
): readonly [T | undefined, (member: T) => void] {
  const [member, setMember] = useState<T>()

  useEffect(() => {
    Member.retrieve({
      id: +memberId,
      query,
    }).then(({ data }) => setMember(data))
  }, [memberId, query])

  return [member, setMember] as const
}
