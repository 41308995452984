import { createAsyncThunk } from '@reduxjs/toolkit'

type Request = {
  setting: 'send_email_bills' | 'send_physical_bills' | 'send_sms_reminders'
  enabled: boolean
}

const setDeliverySetting = createAsyncThunk<Request, Request>('parents/set-delivery-setting', setting => setting)

export default setDeliverySetting
