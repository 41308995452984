import { createSlice } from '@reduxjs/toolkit'

import setActiveSidebarItem from 'thunks/ui/set-active-sidebar-item'

export type UIState = {
  activeSidebarItem: string
}

const initialState: UIState = {
  activeSidebarItem: '',
}

const slice = createSlice({
  name: 'ui',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(setActiveSidebarItem.fulfilled, (state, { meta }) => {
      state.activeSidebarItem = meta.arg
    })
  },
})

export default slice
export const actions = slice.actions
