import { Redirect, Switch } from 'react-router-dom'
import r from 'routes'

import AppRoute from 'components/utilities/AppRoute'
import RequiredDocument from 'pages/Common/RequiredDocuments'
import Balance from 'pages/Member/Balance'
import Budgets from 'pages/Member/Budgets'
import BudgetsShow from 'pages/Member/Budgets/show'
import Calendar from 'pages/Member/Calendar'
import CardsShow from 'pages/Member/Cards/show'
import CommitteePositions from 'pages/Member/CommitteePositions'
import ContributionTrackersShow from 'pages/Member/ContributionTrackers/show'
import DocumentFolders from 'pages/Member/DocumentFolders'
import MemberDocumentsByLabel from 'pages/Member/DocumentFolders/search'
import DocumentFoldersShow from 'pages/Member/DocumentFolders/show'
import EventRegistrations from 'pages/Member/EventRegistrations'
import EventRegistrationsNew from 'pages/Member/EventRegistrations/new'
import ExecutiveBoard from 'pages/Member/ExecutiveBoard'
import FormSubmissions from 'pages/Member/FormSubmissions'
import HourTracker from 'pages/Member/HourTracker'
import HourTrackerShow from 'pages/Member/HourTracker/show'
import ImportantContacts from 'pages/Member/ImportantContacts'
import Members from 'pages/Member/Members'
import MessageCategories from 'pages/Member/MessageCategories'
import MessageCategoriesFolder from 'pages/Member/MessageCategories/folder'
import MessageCategoriesShow from 'pages/Member/MessageCategories/show'
import MessageThreadsShow from 'pages/Member/MessageThreads/show'
import PaymentPlans from 'pages/Member/PaymentPlans'
import Reimbursements from 'pages/Member/Reimbursements'
import Settings from 'pages/Member/Settings'
import Voting from 'pages/Member/Voting'
import VotingEnded from 'pages/Member/Voting/ended'
import VotingFinished from 'pages/Member/Voting/finished'
import VotingShow from 'pages/Member/Voting/show'

export default function MemberRoutes() {
  return (
    <Switch>
      <Redirect path="/member" exact to="/member/balance" />

      <AppRoute roles={['member', 'admin']} path="/member/balance" exact component={Balance} />
      <AppRoute roles={['member', 'admin']} path="/member/balance/:account_id" component={Balance} />
      <AppRoute roles={['member', 'admin']} path="/member/budgets" exact component={Budgets} />
      <AppRoute roles={['member', 'admin']} path="/member/budgets/:id" component={BudgetsShow} />
      <AppRoute roles={['member', 'admin']} path="/member/calendar" component={Calendar} />
      <AppRoute roles={['member', 'admin']} path={r.member.cards.show()} component={CardsShow} />
      <AppRoute roles={['member', 'admin']} path="/member/committee-positions" component={CommitteePositions} />
      <AppRoute roles={['member', 'admin']} path={r.member.contributionTrackers.show()} component={ContributionTrackersShow} />
      <AppRoute roles={['member', 'admin']} path="/member/document-folders" exact component={DocumentFolders} />
      <AppRoute roles={['member', 'admin']} path="/member/document-folders/:id" component={DocumentFoldersShow} />
      <AppRoute roles={['member', 'admin']} path="/member/documents/search/:label" component={MemberDocumentsByLabel} />
      <AppRoute roles={['member', 'admin']} path="/member/event-registrations" exact component={EventRegistrations} />
      <AppRoute roles={['member', 'admin']} path="/member/event-registrations/:federation?" exact component={EventRegistrations} />
      <AppRoute roles={['member', 'admin']} path="/member/event-registrations/:id/new" component={EventRegistrationsNew} />
      <AppRoute roles={['member', 'admin']} path="/member/executive-board" component={ExecutiveBoard} />
      <AppRoute roles={['member', 'admin']} path="/member/form-submissions" component={FormSubmissions} />
      <AppRoute roles={['member', 'admin']} path="/member/hour-tracker" exact component={HourTracker} />
      <AppRoute roles={['member', 'admin']} path="/member/hour-tracker/:id" component={HourTrackerShow} />
      <AppRoute roles={['member', 'admin']} path="/member/important-contacts" component={ImportantContacts} />
      <AppRoute roles={['member', 'admin']} path="/member/members" component={Members} />
      <AppRoute roles={['member', 'admin']} path={r.member.messageCategories.root} exact component={MessageCategories} />
      <AppRoute roles={['member', 'admin']} path={r.member.messageCategories.show()} exact component={MessageCategoriesShow} />
      <AppRoute roles={['member', 'admin']} path={r.member.messageCategories.folder()} component={MessageCategoriesFolder} />
      <AppRoute roles={['member', 'admin']} path={r.member.messageCategories.thread()} component={MessageThreadsShow} />
      <AppRoute roles={['member', 'admin']} path="/member/payment-plan" component={PaymentPlans} />
      <AppRoute roles={['member', 'admin']} path="/member/reimbursements" component={Reimbursements} />
      <AppRoute roles={['member', 'admin']} path="/member/settings" component={Settings} />
      <AppRoute roles={['member', 'admin']} path="/member/voting" exact component={Voting} />
      <AppRoute roles={['member', 'admin']} path="/member/voting/ended" exact component={VotingEnded} />
      <AppRoute roles={['member', 'admin']} path="/member/voting/:id" exact component={VotingShow} />
      <AppRoute roles={['member', 'admin']} path="/member/voting/:id/finished" exact component={VotingFinished} />
      <AppRoute roles={['member', 'admin']} path="/member/required-documents" component={RequiredDocument} />
    </Switch>
  )
}
