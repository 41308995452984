import { useEffect } from 'react'
import { useAppDispatch } from 'store'

import setActiveSidebarItem from 'thunks/ui/set-active-sidebar-item'

export default function useActiveSidebarItem(title: string) {
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(setActiveSidebarItem(title))
  }, [title, dispatch])
}
