import { isGranted } from 'helpers'
import moment from 'moment'
import { FC, ReactNode, useMemo } from 'react'
import { Grid } from 'react-bootstrap'

import { Content, Header, Loading } from 'components/utilities'
import ActivePollsPortlet from 'components/voting/shared/ActivePollsPortlet'
import isFinished from 'components/voting/util/is-finished'
import useActiveSidebarItem from 'hooks/useActiveSidebarItem'
import useLegacyContext from 'hooks/useLegacyContext'
import { isAlumni, isFederation } from 'types/user-functions'

import EndedPollsPortlet from './_RecentVotes'
import { Poll } from './type'

type Props = {
  headerActions?: ReactNode
  emptyState: ReactNode
  polls?: Poll[]
}

const VotingContainer: FC<Props> = ({ polls, headerActions, emptyState }) => {
  useActiveSidebarItem('Voting')

  const { user } = useLegacyContext()

  const adminAccess = useMemo(() => isGranted(user, 'Voting'), [user])

  const { activePolls, endedPolls } = useMemo(() => {
    if (!polls) return { activePolls: [], endedPolls: [] }

    const activePolls = polls.filter(poll => !isFinished(poll))
    const endedPolls = polls.filter(isFinished).sort((a, b) => (moment(b.ended_at).isBefore(a.ended_at) ? -1 : 1))

    return { activePolls, endedPolls }
  }, [polls])

  const linkPrefix = useMemo(() => {
    if (isAlumni(user)) return '/alumni'
    if (isFederation(user)) return '/federation'
    if (adminAccess) return '/admin'
    return '/member'
  }, [adminAccess, user])

  if (!polls) return <Loading />

  return (
    <Content>
      <Grid>
        <Header actions={headerActions}>Voting</Header>

        <ActivePollsPortlet canVote polls={activePolls} linksPrefix={linkPrefix} emptyState={emptyState} />

        {!!endedPolls.length && <EndedPollsPortlet polls={endedPolls} />}
      </Grid>
    </Content>
  )
}

export default VotingContainer
