// @ts-nocheck
import actionProps from 'actions'
import React, { FormEvent, FormEventHandler, JSXElementConstructor, ReactElement, ReactNode } from 'react'
import { connect } from 'react-redux'

import { Request, RequestWithID } from 'resources/helpers'
import apiRequest from 'utils/apiRequest'

import { FormContext, FormContextType } from './context'

type Fn = () => void

type Props = {
  id?: number | string
  action: ((opts: Request) => void) | ((opts: RequestWithID) => void) | string
  autoComplete?: 'off'
  method?: string
  data?: Record<string, any>
  query?: string
  doSubmit?: (fn: Fn) => void
  formRef?: Fn
  validBefore?: Fn
  fieldsWrapper?: JSXElementConstructor<any>
  resetOnSuccess?: boolean
  className?: string
  children: ReactNode
  onInput?: FormEventHandler
  onSubmitStart?: Fn
  onSubmitEnd?: Fn
  onSuccess: ({ data }: { data: any }) => void
  onValidationErrors?: (errors: Record<string, string>, status: number) => void
}

type State = {
  data: Record<string, any>
  errors: Record<string, string>
}

class Form extends React.Component<Props, State> {
  identifier = ''
  submits: ReactElement[] = []

  constructor(props: Props) {
    super(props)

    this.identifier = _.uniqueId()
    this.submits = []

    this.state = {
      data: {},
      errors: {},
    }

    if (this.props.doSubmit) {
      this.props.doSubmit(this.doSubmit)
    }
  }

  childContext(): FormContextType {
    return {
      form: {
        addSubmit: submitComponent => {
          this.submits.push(submitComponent)
        },
        removeSubmit: submitComponent => {
          const index = this.submits.indexOf(submitComponent)

          if (index !== -1) {
            this.submits.splice(index, 1)
          }
        },
      },
      errors: this.state.errors,
    }
  }

  onInput = (e: FormEvent) => {
    if (!_.isEmpty(this.state.errors)) {
      this.setState({
        errors: {},
      })
      if (_.isFunction(this.props.onValidationErrors)) {
        this.props.onValidationErrors({})
      }
    }
    if (this.props.onInput) {
      this.props.onInput(e)
    }
  }

  onSubmit(e: FormEvent) {
    e.preventDefault()

    if ((this.props.validBefore && !this.props.validBefore()) || e.target !== this.form) {
      return
    }

    const data = _.reduce(
      e.target.elements,
      (result, element) => {
        if (element.name === '' || element.disabled) return result
        if (element.type === 'radio' && !element.checked) {
          return result
        }
        const value = this.formatValue(element)
        let elementName = element.name
        const elementNameMatches = elementName.match(/(.+)\[\]$/)
        if (elementNameMatches) {
          elementName = elementNameMatches[1]
        }
        _.setWith(result, elementName, value, Object)
        return result
      },
      this.props.data || {}
    )

    if ('query' in this.props) {
      data.query = this.props.query
    }

    const { action, onSubmitStart } = this.props
    let { onSuccess } = this.props
    const onFailure = this.onSubmitEnd.bind(this, 'failure', this.onFailure.bind(this))

    if (typeof onSuccess !== 'function' && typeof onSuccess !== 'string') {
      onSuccess = () => {}
    }

    if (typeof onSuccess === 'function') {
      onSuccess = this.onSubmitEnd.bind(this, 'success', onSuccess)
    }

    this.submits.forEach(submit => {
      submit.disable()
    })

    if (typeof onSubmitStart === 'function') {
      onSubmitStart()
    }

    if (_.isFunction(action)) {
      const { id } = this.props
      action({ id, data, onSuccess, onFailure })
    } else {
      apiRequest({
        identifier: this.identifier,
        method: this.props.method,
        uri: action,
        data,
        onFailure,
        onSuccess,
      })
    }
  }

  onSubmitEnd(state, next, ...args) {
    this.submits.forEach(submit => {
      submit.enable()
    })

    if (state === 'success' && 'resetOnSuccess' in this.props) {
      this.form.reset()
    }

    const { onSubmitEnd } = this.props

    if (typeof onSubmitEnd === 'function') {
      onSubmitEnd()
    }

    return next(...args)
  }

  onFailure({ status, data }) {
    this.setState({ errors: data })
    if (_.isFunction(this.props.onValidationErrors)) {
      this.props.onValidationErrors(data, status)
    }
  }

  doSubmit = () => {
    this.form.dispatchEvent(new Event('submit'))
  }

  formatValue(element) {
    let formatted = element.value || null

    const booleans = ['true', 'false']
    if (booleans.indexOf(formatted) !== -1) {
      formatted = formatted === 'true'
    }

    if (element.tagName === 'SELECT') {
      const selected = [...element.options].filter(o => o.selected).map(o => parseInt(o.value, 10) || o.value)
      if (element.type === 'select-multiple') {
        formatted = selected
      } else if (selected[0]) {
        formatted = selected[0]
      }
    }

    if (element.type === 'checkbox') {
      formatted = element.checked
      if (element.getAttribute('data-inverse')) {
        formatted = !formatted
      }
    }

    if (element.name.match(/_id$/) && !element.name.match(/salesforce_id/)) {
      formatted = parseInt(formatted, 10)
    }

    if (element.hasAttribute('data-numeric')) {
      formatted = parseFloat((formatted || '').replace(/[^0-9.]+/g, ''))
    }

    return formatted
  }

  createFormRef = form => {
    const { formRef } = this.props

    this.form = form

    if (formRef) {
      formRef(form)
    }
  }

  render() {
    const { fieldsWrapper: FieldsWrapper } = this.props
    const propsSubset = _.pick(this.props, ['action', 'method', 'accept'])
    if (typeof propsSubset.action === 'function') delete propsSubset.action

    let { children } = this.props

    if (FieldsWrapper) children = <FieldsWrapper children={children} />

    const className = this.props.className ? this.props.className : 'form-horizontal'

    return (
      <FormContext.Provider value={this.childContext()}>
        <form
          {...propsSubset}
          children={children}
          ref={this.createFormRef}
          onInput={this.onInput.bind(this)}
          onSubmit={this.onSubmit.bind(this)}
          className={className}
          autoComplete="off"
        />
      </FormContext.Provider>
    )
  }
}

export default connect(null, actionProps)(Form)
