import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import scriptLoader from 'react-async-script-loader'
import modal from 'helpers/modal'
import { Organization, PaymentCategory, StatusUpdateReason, CustomField, LifetimeDonationTier } from 'resources'
import { Row, Col, Modal, FormGroup, FormControl, Checkbox, Button } from 'react-bootstrap'
import Container from 'container'

import { GRADES } from 'types/grades'
import { RemoteSelect, StateSelect } from 'components/form'
import { NearLocationControl } from 'components/addresses'
import DatePickerPopoverInput from 'components/popovers/date-picker-popover-input'
import { Typeahead } from 'components/typeahead'

const { number, func, shape, object, string } = PropTypes

class MemberFiltersModal extends Container {
  static propTypes = {
    federationId: number.isRequired,
    initialSearch: shape({
      organization_id: number,
      deactivation_reason_id: number,
      reactivation_reason_id: number,
      graduation_year_start: string,
      designation_expire_in: string,
      graduation_year_end: string,
      grade_from: string,
      grade_to: string,
      current_postal_code: string,
      current_state: string,
      parent_postal_code: string,
      parent_state: string,
      custom_fields: object,
    }),
    onChange: func.isRequired,
    loadStateToParent: func.isRequired,
    errors: object,
  }

  constructor(props) {
    super(props, {
      customFields: props.customFields || [],
      deactivationReasons: props.deactivationReasons || [],
      reactivationReasons: props.reactivationReasons || [],
      paymentCategories: props.paymentCategories || [],
      search: props.initialSearch,
      errors: props.errors,
    })
  }

  componentDidUpdate(_, nextState) {
    super.componentDidUpdate()
    const { search } = this.state

    if (search.type !== nextState.search.type) this.clearExcessCustomFields()
  }

  componentDidMount() {
    super.componentDidMount()
    const { federationId } = this.props
    const { paymentCategories, deactivationReasons, reactivationReasons, customFields } = this.props

    if (!paymentCategories) {
      PaymentCategory.list({
        query: 'payment_category { id title }',
        data: {
          federation_id: federationId,
        },
        onSuccess: this.updateState('paymentCategories'),
      })
    }

    if (!deactivationReasons && !reactivationReasons) {
      StatusUpdateReason.list({
        query: 'status_update_reason { id reason status }',
        data: {
          federation_id: federationId,
        },
        onSuccess: this.onStatusUpdateReasonSuccess,
      })
    }

    if (!customFields) {
      CustomField.list({
        data: {
          owner_type: 'Federation',
          owner_id: federationId,
          include_organizations: false,
          role: ['member', 'alumni'],
          query: `
          custom_field {
            name
            slug
            role
          }
        `,
        },
        onSuccess: this.onCustomFieldSuccess,
      })
    }
  }

  componentWillUnmount() {
    const { loadStateToParent } = this.props
    loadStateToParent(this.state)
    super.componentWillUnmount()
  }

  onStatusUpdateReasonSuccess = ({ data }) => {
    this.setState({
      deactivationReasons: _.filter(data, { status: 'deactivated' }),
      reactivationReasons: _.filter(data, { status: 'reactivated' }),
    })
  }

  onCustomFieldSuccess = ({ data }) => {
    const customFields = _.chain(data).uniqBy('slug').sortBy('name').value()
    this.setState({ customFields })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { errors, initialSearch } = this.props

    if (nextProps.initialSearch !== initialSearch) {
      this.setState({ search: nextProps.initialSearch })
    }
    if (nextProps.errors !== errors) {
      this.setState({ errors: nextProps.errors })
    }
  }

  onOrganizationSelect = organizations => {
    const organizationId = _.get(organizations, '0.id')
    if (organizationId) {
      this.updateSearch({
        organization_id: organizationId,
      })
    } else {
      this.removeFromSearch('organization_id')
    }
  }

  onInputChange = name => e => {
    const { value, checked } = e.target

    if (value === '' || (e.target.type === 'checkbox' && !checked)) {
      this.removeFromSearch(name)
    } else {
      this.updateSearch(_.set({}, name, value))
    }
  }

  onStatusUpdateReasonsChange =
    name =>
      ([value]) => {
        if (value) {
          this.updateSearch({ [name]: value.id })
        } else {
          this.removeFromSearch(name)
        }
      }

  onGradeChange =
    name =>
      ([rawValue]) => {
        if (rawValue) {
          const value = Object.keys(rawValue)[0]
          this.updateSearch({ [name]: value })
        } else {
          this.removeFromSearch(name)
        }
      }

  onDatePickerInputChange = name => value => {
    if (value) {
      this.updateSearch({ [name]: value })
    } else {
      this.removeFromSearch(name)
    }
  }

  onNearLocationChange = location => {
    if (location) {
      this.updateSearch({ location })
    } else {
      this.removeFromSearch('location')
    }
  }

  onSubmit = () => {
    this.triggerChange()
  }

  clearExcessCustomFields = () => {
    const { customFields } = this.state
    let {
      search: { ...search },
    } = this.state

    if (!search.type) return
      ;['member', 'alumni']
        .filter(role => role !== search.type)
        .forEach(role => {
          _.filter(customFields, { role }).forEach(customField => {
            search = _.omit(search, `custom_fields.${customField.slug}`)
          })
        })

    this.setState({ search })
  }

  updateSearch = data => {
    this.setState({
      search: _.merge({}, this.state.search, data),
    })
  }

  removeFromSearch = key => {
    this.setState({
      search: _.omit(this.state.search, key),
    })
  }

  triggerChange = () => {
    this.setState({ errors: null })
    this.props.onChange(_.pickBy(this.state.search, p => p !== ''))
  }

  onChangeLifeTimeDonation = ([{ id }]) => {
    this.updateSearch({ lifetime_donation_tier_id: id })
  }

  renderCustomField = ({ name, slug, role }) => {
    const { search } = this.state
    const fieldPath = `custom_fields.${slug}`

    return (
      <FormGroup key={slug}>
        <label htmlFor={fieldPath} className="control-label">
          {name}
        </label>
        <FormControl
          type="text"
          id={fieldPath}
          disabled={search.type && role !== search.type}
          value={_.get(search.custom_fields, slug, '')}
          onChange={this.onInputChange(fieldPath)}
        />
      </FormGroup>
    )
  }

  render() {
    const { federationId, isScriptLoadSucceed } = this.props
    const { search, paymentCategories, deactivationReasons, reactivationReasons, errors, customFields } = this.state
    const lifetimeDonationErrors = _.get(errors, 'member.lifetime_donation_start', []).concat(
      _.get(errors, 'member.lifetime_donation_end', [])
    )

    const formattedGrades = Object.entries(GRADES).map(e => ({ [e[0]]: e[1] }))

    return (
      <Modal show bsSize="lg" onHide={this.props.actions.closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Filter members</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <hr className="spacer-xs" />

          <Row>
            <Col md={4}>
              <h5>Member details</h5>
              <hr />

              <FormGroup>
                <label htmlFor="type" className="control-label">
                  Member status
                </label>
                <select
                  id="type"
                  className="form-control"
                  onChange={this.onInputChange('type')}
                  defaultValue={search.type || ''}
                >
                  <option value="">Active & Alumni</option>
                  <option value="member">Active members only</option>
                  <option value="alumni">Alumni only</option>
                </select>
              </FormGroup>
              <FormGroup>
                <label htmlFor="search_name" className="control-label">
                  Name, email, or phone number
                </label>
                <FormControl
                  type="text"
                  id="search_name"
                  value={search.name || ''}
                  onChange={this.onInputChange('name')}
                />
              </FormGroup>
              <FormGroup>
                <label htmlFor="organization_id" className="control-label">
                  School
                </label>
                <RemoteSelect
                  name="organization_id"
                  display="secondary"
                  resource={Organization}
                  scope={{ federation_id: federationId }}
                  selectFirst={false}
                  selectedIds={[search.organization_id]}
                  query={`
                    organization {
                      secondary
                    }
                  `}
                  onChange={this.onOrganizationSelect}
                  placeholder="&ndash; all schools &ndash;"
                />
              </FormGroup>

              <FormGroup>
                <label htmlFor="deactivation_reasons" className="control-label">
                  Deactivation reason
                </label>
                <Typeahead
                  labelKey="reason"
                  placeholder="Select deactivation reason..."
                  options={deactivationReasons}
                  selected={_.filter(deactivationReasons, { id: search.deactivation_reason_id })}
                  onChange={this.onStatusUpdateReasonsChange('deactivation_reason_id')}
                />
              </FormGroup>

              <FormGroup>
                <label htmlFor="reactivation_reasons" className="control-label">
                  Reactivation reason
                </label>
                <Typeahead
                  labelKey="reason"
                  placeholder="Select reactivation reason..."
                  options={reactivationReasons}
                  selected={_.filter(reactivationReasons, { id: search.reactivation_reason_id })}
                  onChange={this.onStatusUpdateReasonsChange('reactivation_reason_id')}
                />
              </FormGroup>

              <FormGroup>
                <label htmlFor="graduation_year_start" className="control-label">
                  Graduation year
                </label>
                <Row>
                  <Col md={6}>
                    <label htmlFor="graduation_year_start" className="control-label text-muted">
                      From
                    </label>
                    <FormControl
                      id="graduation_year_start"
                      type="text"
                      value={search.graduation_year_start || ''}
                      onChange={this.onInputChange('graduation_year_start')}
                    />
                  </Col>
                  <Col md={6}>
                    <label htmlFor="graduation_year_end" className="control-label text-muted">
                      To
                    </label>
                    <FormControl
                      id="graduation_year_end"
                      type="text"
                      value={search.graduation_year_end || ''}
                      onChange={this.onInputChange('graduation_year_end')}
                    />
                  </Col>
                </Row>
              </FormGroup>

              <FormGroup>
                <label htmlFor="grade_from" className="control-label">
                  Grade range
                </label>
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <label htmlFor="grade_from" className="control-label text-muted">
                        From
                      </label>
                      <Typeahead
                        labelKey={row => Object.values(row)[0]}
                        placeholder="Select grade..."
                        options={formattedGrades}
                        selected={_.filter(formattedGrades, item => Object.keys(item)[0] === search.grade_from)}
                        onChange={this.onGradeChange('grade_from')}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <label htmlFor="grade_to" className="control-label text-muted">
                        To
                      </label>
                      <Typeahead
                        labelKey={row => Object.values(row)[0]}
                        placeholder="Select grade..."
                        options={formattedGrades}
                        selected={_.filter(formattedGrades, item => Object.keys(item)[0] === search.grade_to)}
                        onChange={this.onGradeChange('grade_to')}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </FormGroup>

              {federationId === 29 && (
                <FormGroup style={{ marginTop: -20 }}>
                  <label htmlFor="grade_from" className="control-label">
                    Promissory note status
                  </label>
                  <select
                    id="promissoryNoteStatus"
                    className="form-control"
                    onChange={this.onInputChange('promissoryNoteStatus')}
                  >
                    <option>Active</option>
                  </select>
                </FormGroup>
              )}

              {customFields.length > 0 && (
                <Fragment>
                  <h5>Custom Info</h5>
                  <hr />
                </Fragment>
              )}

              {customFields.map(this.renderCustomField)}
            </Col>

            <Col md={4}>
              <h5>Donation details</h5>
              <hr />

              <FormGroup>
                <label htmlFor="designation" className="control-label">
                  Donation level
                </label>
                <select id="designation" className="form-control" onChange={this.onInputChange('designation')}>
                  <option></option>
                  {paymentCategories.map(paymentCategory => (
                    <option key={paymentCategory.id} value={paymentCategory.title}>
                      {paymentCategory.title}
                    </option>
                  ))}
                </select>
              </FormGroup>

              <FormGroup validationState={lifetimeDonationErrors.length ? 'error' : null}>
                <label htmlFor="lifetime_donation_start" className="control-label">
                  Lifetime donation
                </label>
                <Row>
                  <Col md={6}>
                    <label htmlFor="lifetime_donation_start" className="control-label text-muted">
                      From
                    </label>
                    <FormControl
                      id="lifetime_donation_start"
                      type="text"
                      value={search.lifetime_donation_start || ''}
                      onChange={this.onInputChange('lifetime_donation_start')}
                    />
                  </Col>
                  <Col md={6}>
                    <label htmlFor="lifetime_donation_end" className="control-label text-muted">
                      To
                    </label>
                    <FormControl
                      id="lifetime_donation_end"
                      type="text"
                      value={search.lifetime_donation_end || ''}
                      onChange={this.onInputChange('lifetime_donation_end')}
                    />
                  </Col>
                </Row>
                {lifetimeDonationErrors.map(error => (
                  <span key={error} className="help-block">
                    {error}
                  </span>
                ))}
              </FormGroup>

              <FormGroup>
                <label htmlFor="lifetime_donation_level" className="control-label">
                  Lifetime donation level
                </label>
                <RemoteSelect
                  query={'lifetime_donation_tier { id level threshold }'}
                  resource={LifetimeDonationTier}
                  scope={{ federation_id: federationId }}
                  name="lifetime_donation_level"
                  display="level"
                  selectFirst={false}
                  placeholder="&mdash; no tier level &mdash;"
                  onChange={this.onChangeLifeTimeDonation}
                />
              </FormGroup>

              <FormGroup>
                <label htmlFor="expiring_in_30d" className="control-label">
                  Donation designation
                </label>
                <Checkbox
                  id="expiring_in_30d"
                  defaultValue="30"
                  defaultChecked={search.designation_expire_in}
                  onChange={this.onInputChange('designation_expire_in')}
                >
                  Expiring in 30 days
                </Checkbox>
              </FormGroup>

              <DatePickerPopoverInput
                id="donation_timeframe_start"
                label="From donation timeframe"
                value={search.donation_timeframe_start || ''}
                onChange={this.onDatePickerInputChange('donation_timeframe_start')}
              />

              <DatePickerPopoverInput
                id="donation_timeframe_end"
                label="To donation timeframe"
                value={search.donation_timeframe_end || ''}
                onChange={this.onDatePickerInputChange('donation_timeframe_end')}
              />
            </Col>

            <Col md={4}>
              <h5>Location filters</h5>
              <hr />

              <FormGroup>
                <label htmlFor="current_postal_code" className="control-label">
                  Current zip code
                </label>
                <FormControl
                  type="text"
                  id="current_postal_code"
                  value={search.current_postal_code || ''}
                  onChange={this.onInputChange('current_postal_code')}
                />
              </FormGroup>

              <FormGroup>
                <label htmlFor="current_state" className="control-label">
                  Current state
                </label>
                <StateSelect
                  id="current_state"
                  value={search.current_state || ''}
                  onChange={this.onInputChange('current_state')}
                />
              </FormGroup>

              <FormGroup>
                <label htmlFor="parent_postal_code" className="control-label">
                  Parent zip code
                </label>
                <FormControl
                  type="text"
                  id="parent_postal_code"
                  value={search.parent_postal_code || ''}
                  onChange={this.onInputChange('parent_postal_code')}
                />
              </FormGroup>

              <FormGroup>
                <label htmlFor="parent_state" className="control-label">
                  Parent state
                </label>
                <StateSelect
                  id="parent_state"
                  value={search.parent_state || ''}
                  onChange={this.onInputChange('parent_state')}
                />
              </FormGroup>

              {isScriptLoadSucceed && (
                <NearLocationControl errors={errors} location={search.location} onChange={this.onNearLocationChange} />
              )}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button bsStyle="primary" onClick={this.onSubmit} offset={5}>
            Search
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default scriptLoader(
  `https://maps.googleapis.com/maps/api/js?key=${import.meta.env.VITE_GOOGLE_PLACES_API_KEY}&libraries=places`
)(modal('MemberFilters', MemberFiltersModal))
