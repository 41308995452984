import asyncThunk from 'utils/asyncThunk'

export type Request = {
  id: number
  query: string
}

export const retrievePoll = asyncThunk<Request, any>({
  name: 'polls/retrieve',
  uri: ({ id }) => `/polls/${id}`,
  method: 'GET',
})
