import { isGranted } from 'helpers'
import { FC, useCallback, useMemo } from 'react'

import { MessageThread } from 'components/message-board/type'
import { useLegacyMember } from 'hooks/useLegacyContext'
import useList from 'hooks/useList'
import SharedMessageCategoryIndex from 'pages/Shared/MessageCategories'
import { MessageCategory } from 'pages/Shared/MessageCategories/type'
// @ts-ignore
import { messageCategoryQuery } from 'pages/Shared/MessageCategories/type?query'
import { listMessageCategoriesByUser, Request } from 'thunks/message-categories/list-by-user'

const MemberMessageCategoryIndex: FC = () => {
  const user = useLegacyMember()

  const [categories, { prepend, patch }] = useList<MessageCategory, Request>(listMessageCategoriesByUser, {
    query: messageCategoryQuery,
    user_id: user.id,
  })

  const handleNewCategory = useCallback(
    (category: Omit<MessageCategory, 'recently_active_message_threads'>) =>
      prepend({
        ...category,
        recently_active_message_threads: [],
      }),
    [prepend]
  )

  const handleNewThread = useCallback(
    (categoryId: number, thread: MessageThread) => {
      const category = categories?.find(c => c.id === categoryId)
      if (!category) return

      patch({
        id: category.id,
        recently_active_message_threads: [
          {
            ...thread,
            data: {
              replies: 0,
              new: 0,
            },
          },
          ...(category.recently_active_message_threads || []),
        ],
      })
    },
    [categories, patch]
  )

  const canManage = useMemo(() => isGranted(user, 'Message Board'), [user])

  return (
    <SharedMessageCategoryIndex
      role="member"
      categories={categories}
      onCreateCategory={canManage ? handleNewCategory : undefined}
      onCreateThread={handleNewThread}
    />
  )
}

export default MemberMessageCategoryIndex
