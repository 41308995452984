import { FC } from 'react'
import { Tooltip, OverlayTrigger } from 'react-bootstrap'
import { FaIcon, Units } from 'components/utilities'

type Props = {
  className?: string
  count: number
}

const OrganizationPaymentPlanTooltip: FC<Props> = ({ count, className }) => {
  if (!count) {
    return null
  }

  return (
    <OverlayTrigger
      placement="right"
      overlay={
        <Tooltip id="payment-plan-tooltip">
          <Units count={count} noun="payment plan is" plural="payment plans are" /> pending
        </Tooltip>
      }
    >
      <FaIcon exclamation-circle warning className={className || 'pull-right'} />
    </OverlayTrigger>
  )
}

export default OrganizationPaymentPlanTooltip
