import { FC } from 'react'

import { Event } from 'components/event/registration-form/type'

type Props = {
  question: Event['event_questions'][number]
  value: string
  onChange: (val: string) => void
}

const Select: FC<Props> = ({ question, value, onChange }) => {
  return (
    <select value={value} className="form-control" onChange={e => onChange(e.target.value)}>
      <option value="">&mdash; select answer &mdash;</option>
      {question?.options?.map((value, i) => (
        <option key={i} value={value}>
          {value}
        </option>
      ))}
    </select>
  )
}

export default Select
