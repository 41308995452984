import moment from 'moment'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { Button, Col, Row } from 'react-bootstrap'

import { FaIcon, Money } from 'components/utilities'
import { openModal } from 'hooks/useModal'

import AddPaymentModal from './add-payment-modal'
import { Options, PaymentPlanPayment, ProposedPayment } from './common'
import { generateQuickOptions, paymentPlansAreEqual } from './schedule-helpers'

type Props = {
  balance: number
  onScheduleChange: (payments: ProposedPayment[]) => void
}

const PaymentPlanScheduleOptions: FC<Props> = ({ balance, onScheduleChange }) => {
  const generatedOptions = useMemo(
    () =>
      generateQuickOptions({
        balance: balance,
        options: ['Four', 'Three', 'Two'],
      }),
    [balance]
  )

  const [payments, setPayments] = useState<ProposedPayment[]>(generatedOptions.Four.payments)
  const [options] = useState(generatedOptions)

  useEffect(() => {
    onScheduleChange(payments)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const setPaymentPlan = useCallback(
    (payments: ProposedPayment[]) => {
      setPayments(payments)
      onScheduleChange(payments)
    },
    [onScheduleChange]
  )

  const setToCustomSchedule = useCallback(() => {
    setPaymentPlan([])
    openModal('AddPayment')()
  }, [setPaymentPlan])

  const doSelectQuickSchedule = useCallback(
    (installments: 'Two' | 'Three' | 'Four') => {
      setPaymentPlan(options[installments].payments)
    },
    [options, setPaymentPlan]
  )

  const doAddPayment = useCallback(
    (data: ProposedPayment) => {
      setPaymentPlan([...payments, data])
    },
    [payments, setPaymentPlan]
  )

  const doRemovePayment = useCallback(
    ({ amount, due_on }: ProposedPayment) => {
      const filteredPayments = payments.filter(
        item => amount !== item.amount || due_on.valueOf() !== item.due_on.valueOf()
      )
      setPaymentPlan(filteredPayments)
    },
    [payments, setPaymentPlan]
  )

  const totalMatchesBalance = useCallback(
    (accountedFor: number) => {
      return Number(balance).toFixed(2) === accountedFor.toFixed(2)
    },
    [balance]
  )

  const renderRemaining = useCallback(
    ({ accountedFor }: { accountedFor: number }) => {
      if (totalMatchesBalance(accountedFor)) {
        return (
          <em className="text-success pull-right m-t-1">
            <FaIcon check /> <Money amount={accountedFor} />
          </em>
        )
      }

      if (balance < accountedFor) {
        return (
          <em className="text-danger pull-right m-t-1">
            <FaIcon times /> exceeds balance by <Money amount={accountedFor - balance} />
          </em>
        )
      }

      return (
        <em className="text-danger pull-right m-t-1">
          remaining: <Money amount={balance - accountedFor} />
        </em>
      )
    },
    [balance, totalMatchesBalance]
  )

  const sortedPayments = useMemo(() => _.sortBy(payments, ({ due_on }) => due_on.valueOf()), [payments])
  const isCustom = useMemo(
    () => !_.some(options, o => paymentPlansAreEqual(o.payments, sortedPayments)),
    [options, sortedPayments]
  )
  const accountedFor = useMemo(() => payments.map(p => p.amount).reduce((memo, amount) => memo + amount, 0), [payments])

  return (
    <Row>
      <Col sm={4}>
        <table className="table">
          <thead>
            <tr>
              <th colSpan={1} className="text-center p-t-0">
                Scheduling options
              </th>
            </tr>
          </thead>
        </table>

        <ul className="list-quickee m-t-neg-05">
          {_.map(
            options,
            ({ label, payments: list }: { label: string; payments: PaymentPlanPayment[] }, key: keyof Options) => (
              <li key={key} className={paymentPlansAreEqual(list, sortedPayments) ? 'active' : undefined}>
                <Button bsStyle="link" className="no-style" onClick={() => doSelectQuickSchedule(key)}>
                  {label}
                </Button>
              </li>
            )
          )}
          <li className={isCustom ? 'active' : undefined}>
            <Button bsStyle="link" onClick={setToCustomSchedule}>
              Custom plan
            </Button>
          </li>
        </ul>
      </Col>
      <Col sm={8}>
        <table id="payment-schedule" className="table">
          <thead>
            <tr>
              <th colSpan={3} className="text-center p-t-0">
                Proposed payment schedule
              </th>
            </tr>
          </thead>
          {isCustom ? (
            <tfoot>
              <tr>
                <td colSpan={3}>
                  <Button className="m-t-05" onClick={openModal('AddPayment')}>
                    Add payment
                  </Button>
                  {renderRemaining({ accountedFor })}
                </td>
              </tr>
            </tfoot>
          ) : null}
          <tbody>
            {sortedPayments.map((payment, i) => (
              <tr key={i}>
                <td style={{ width: '40%' }}>{moment(payment.due_on).format('MMM Do, YYYY')}</td>
                <td style={{ width: '45%' }}>
                  <Money amount={payment.amount} />
                </td>
                <td className="text-right" style={{ width: '15%' }}>
                  {isCustom ? (
                    <Button
                      bsStyle="link"
                      onClick={() => doRemovePayment(payment)}
                      className="no-style text-very-muted"
                    >
                      &times;
                    </Button>
                  ) : null}
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {isCustom ? (
          <AddPaymentModal
            defaultValue={Math.max(balance - accountedFor, 0)}
            defaultDate={
              sortedPayments.length > 0
                ? sortedPayments[sortedPayments.length - 1].due_on
                : moment().format('YYYY-MM-DD')
            }
            onSuccess={doAddPayment}
          />
        ) : null}
      </Col>
    </Row>
  )
}

export default PaymentPlanScheduleOptions
