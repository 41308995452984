export const eventQuery = `
  event {
    id
    name
    cost
    registration_closes_on
    maximum_tickets
    owner_type
    is_approval_required
    data {
      tickets_sold
    }
    event_registrations {
      id
      approved_at
    }
  }
`
