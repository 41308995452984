import { createAsyncThunk } from '@reduxjs/toolkit'

export type Request = {
  id: number
}

export type Response = void

const setActiveParent = createAsyncThunk<Response, Request>('parents/set-active-parent', async () => { })

export default setActiveParent
