import { FC } from 'react'

import { useLegacyAdmin } from 'hooks/useLegacyContext'

import Admin from './admin'
import Contacts from './contacts'
import MemberAccount from './member-account'
import National from './national'
import PrimaryAdmin from './primary-admin'

const AdminMenu: FC = () => {
  const user = useLegacyAdmin()

  return (
    <div>
      <MemberAccount />
      <Admin />
      <National />
      <PrimaryAdmin organization={user.member.organization} />
      <Contacts />
    </div>
  )
}

export default AdminMenu
