import { createContext } from 'react'

import { Parent } from '../type'

export type ParentsContextType = {
  parents: Parent[]
  updateParent: (parent: Parent) => void
  archiveParent: (id: number) => void
}

const ParentsContext = createContext({} as ParentsContextType)

export default ParentsContext
