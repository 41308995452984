export const hourRequirementQuery = `
  hour_requirement {
    id
    title
    description
    hours_required
    completion_date
    created_at
    data {
      member_count
    }
    organization {
      id
    }
  }
`
export const hourRecordQuery = `
  hour_record {
    id
    hours
    description
    approved_at
    denied_at
    created_at
    document {
      id
      mimetype
      url
    }
  }
`
