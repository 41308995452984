import asyncThunk from 'utils/asyncThunk'

export type Request = {
  query: string
  term: {
    name: string
    started_on: string
    organization_ids: number[]
  }
}

export const bulkCreateTerms = asyncThunk<Request, any>({
  name: 'terms/bulk-create',
  uri: '/terms/bulk_create',
  method: 'POST',
})
