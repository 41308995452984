import mixpanel from 'mixpanel-browser'
import QueryString from 'qs'
import { UseFormSetError } from 'react-hook-form'
import { AppState } from 'store'

import { createAsyncThunk } from '@reduxjs/toolkit'

import { APIResponse, APIThunk } from 'hooks/useAPI'

import objectKeys from './object-keys'

type RejectValueGeneric = {
  statusCode: number
  error?: string
}

export type RejectValue<T extends string = string> = Record<T, Record<string, any>>

export function isGenericAPIError(err: any): err is RejectValueGeneric {
  return 'statusCode' in err
}

export function isAPIError<T extends string>(err: any): err is RejectValue<T> & { statusCode: number } {
  return 'statusCode' in err
}

export function processValidationErrors<T extends string>(
  prefix: T,
  errors: Record<T, Record<string, any>>,
  setError: UseFormSetError<any>,
  includePrefix = false
) {
  const errs: Record<string, any> = errors[prefix]
  objectKeys(errs).forEach(field => {
    if (Array.isArray(errors[prefix][field])) {
      const key = includePrefix ? `${prefix}.${field}` : field
      setError(key, { type: 'server', message: errors[prefix][field][0] })
    } else {
      processValidationErrors(field, errors[prefix], setError, true)
    }
  })
}

type Input<Request> = {
  name: string
  uri: ((req: Request) => string) | string
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE'
  successStatus?: number
  data?: Record<string, any>
  track?: (req: Request) => Record<string, string | number>
}

export default function asyncThunk<Request, Reply, Headers extends Record<string, string> | undefined = undefined>({
  name,
  method = 'GET',
  uri,
  successStatus,
  data,
  track,
}: Input<Request>): APIThunk<Request, Reply, Headers> {
  return createAsyncThunk<APIResponse<Reply, Headers>, Request, { rejectValue: RejectValue | RejectValueGeneric }>(
    name,
    async (req: Request, api) => {
      const body = req ? { ...req, ...(data || {}) } : data

      const headers = new Headers()
      if (method !== 'GET') {
        headers.append('Content-Type', 'application/json')
      }

      const { auth } = api.getState() as AppState
      if (auth) {
        headers.append('Authorization', `Bearer ${auth.token}`)
      }

      const opts: RequestInit = {
        method,
        headers,
      }

      let appUri = typeof uri === 'function' ? uri(req) : uri
      if (body) {
        if (method === 'GET') {
          const query = QueryString.stringify(body, { arrayFormat: 'brackets' })
          appUri = `${appUri}?${query}`
        } else {
          opts.body = JSON.stringify(body)
        }
      }

      const response = await fetch(`https://${import.meta.env.VITE_API_ORIGIN}${appUri}`, opts)

      if (method !== 'GET') {
        const key = `${method} ${appUri}`
        if (!['POST /documents/upload_url'].includes(key)) {
          mixpanel.track(`${method} ${appUri}`, {
            status: response.status,
            ...(track ? track(req) : {}),
          })
        }
      }

      if (response.status >= 400 && response.status < 500) {
        let resp
        try {
          resp = await response.json()
        } catch (err) {
          console.log('[THUNK ERROR] unable to process JSON', err)
        }
        return api.rejectWithValue({
          ...resp,
          statusCode: response.status,
        })
      }

      const expectedStatus = ((): number => {
        if (successStatus) {
          return successStatus
        }

        switch (method) {
          case 'GET':
            return 200
          case 'PUT':
            return 200
          case 'POST':
            return 201
          case 'DELETE':
            return 204
        }
      })()

      if (response.status !== expectedStatus) {
        return api.rejectWithValue({
          statusCode: response.status,
          error: `unexpected response status: ${response.status}`,
        })
      }

      let resp: Reply
      try {
        if (response.status !== 204) {
          resp = await response.json()
        } else {
          resp = null as Reply
        }
      } catch {
        return api.rejectWithValue({
          error: 'unable to process response',
          statusCode: response.status,
        })
      }

      // @ts-ignore
      const responseHeaders: Headers = {}
      for (let [key, value] of response.headers) {
        // @ts-ignore
        responseHeaders[key] = value
      }

      return {
        headers: responseHeaders,
        data: resp,
      }
    }
  )
}
