import asyncThunk from 'utils/asyncThunk'

export type Request = {
  query: string
  member_id?: number
}

export const listParents = asyncThunk<Request, any>({
  name: 'parents/list',
  uri: '/parents',
  method: 'GET',
})
