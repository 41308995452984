import modal from 'helpers/modal'
import { FC } from 'react'
import { Row, Col, Modal, Button } from 'react-bootstrap'
import { Date, Units, Label } from 'components/utilities'
import Preview from 'components/documents/preview'
import { closeModal } from 'hooks/useModal'

type HourRecord = {
  id: number
  hours: number
  description?: string
  document?: any
  created_at: string
  approved_at?: string
  denied_at?: string
}

type Props = {
  hourRecord: HourRecord
  hourRequirement: {
    title: string
  }
}

const HourRecordModal: FC<Props> = ({ hourRecord, hourRequirement }) => {
  const renderState = (hourRecord: HourRecord) => {
    if (hourRecord.hours < 0) {
      return <Label info>adjustment</Label>
    }
    if (hourRecord.approved_at) {
      return (
        <Label success>
          approved on <Date extraShort date={hourRecord.approved_at} />
        </Label>
      )
    }
    if (hourRecord.denied_at) {
      return (
        <Label danger>
          denied on <Date extraShort date={hourRecord.denied_at} />
        </Label>
      )
    }

    return <Label secondary>pending</Label>
  }

  return (
    <Modal show onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>{hourRequirement.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col xs={6}>
            <Preview document={hourRecord.document} empty="no documentation uploaded" />
          </Col>
          <Col xs={6}>
            <p className="fs-125">{renderState(hourRecord)}</p>

            <p className="fs-125 m-b-0">
              <Units count={hourRecord.hours} noun="hour" />
              {' recorded'}
            </p>

            <hr className="m-t-05" />

            {hourRecord.description || <em className="text-muted">no description</em>}
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => closeModal()}>close</Button>
      </Modal.Footer>
    </Modal>
  )
}

export default modal<Props>('HourRecord', HourRecordModal)
