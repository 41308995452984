import { AdminUser, AlumniUser, AnyUser, FederationUser, MemberUser, ParentUser, RootUser } from './user'

export function isMember(user: AnyUser): user is MemberUser {
  return user.role === 'member' || user.role === 'admin'
}

export function isAdmin(user: AnyUser): user is AdminUser {
  return user.role === 'admin'
}

export function isSuper(user: AnyUser): user is RootUser {
  return user.role === 'root'
}

export function isParent(user: AnyUser): user is ParentUser {
  return user.role === 'parent'
}

export function isFederation(user: AnyUser): user is FederationUser {
  return user.role === 'federation'
}

export function isAlumni(user: AnyUser): user is AlumniUser {
  return user.role === 'alumni'
}

export function hasAvatar(user?: AnyUser): user is MemberUser | AdminUser | AlumniUser {
  if (!user) return false
  return isMember(user) || isAdmin(user) || isAlumni(user)
}
