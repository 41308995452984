import { APIResponse } from 'hooks/useAPI'
import asyncThunk from 'utils/asyncThunk'

export type Request = {
  query: string
  payment: {
    amount: number
    braintree_nonce: string
    source: string
    member_id: number
  }
}

export const chargeCreditCard = asyncThunk<Request, any>({
  name: 'payments/credit-card',
  uri: '/payments/credit_card',
  method: 'POST',
})
