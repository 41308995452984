export const accountQuery = `
  account {
    id
    name
    scope
    weight
  }
`
export const transactionQuery = `
  transaction {
    id
    txn_type
    description
    amount
  }
`
