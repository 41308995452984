import moment from 'moment'
import { FC, useCallback } from 'react'
import { Button, Modal } from 'react-bootstrap'

import { FaButton } from 'components/utilities/font-awesome'
import modal from 'helpers/modal'
import { closeModal } from 'hooks/useModal'
import { UnpersistedEvent } from 'pages/Member/Calendar'
import { CalendarEvent } from 'thunks/calendar-events/list'
import formatLinks from 'utils/format-links'

type Props = {
  event: CalendarEvent | UnpersistedEvent
}

const DetailsModal: FC<Props> = ({ event }) => {
  const formatTimeStamp = useCallback(
    (datetime: string) =>
      datetime.match(/^\d{4}-\d{2}-\d{2}$/)
        ? moment(datetime).format('dddd, MMM Do YYYY')
        : moment(datetime).format('dddd, MMM Do YYYY [at] h:mm a'),
    []
  )

  return (
    <Modal show onHide={closeModal} className="calendar-event-modal">
      <Modal.Header closeButton>
        <Modal.Title>Event details {event.purpose ? '(Federation event)' : ''}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <dl className="dl-horizontal m-t-1 list-spaced">
          <dt>Event title</dt>
          <dd>{event.title}</dd>

          {event.description && (
            <div>
              <dt>Description</dt>
              <dd dangerouslySetInnerHTML={{ __html: formatLinks(event.description) }} />
            </div>
          )}

          <dt>Start</dt>
          <dd>{formatTimeStamp(event.start)}</dd>

          <dt>End</dt>
          <dd>{formatTimeStamp(event.end)}</dd>
        </dl>
      </Modal.Body>
      <Modal.Footer>
        {event.link && (
          <FaButton icon="google" onClick={() => window.open(event.link, '_blank')} className="pull-left" />
        )}

        <Button className="pull-right" onClick={() => closeModal()}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default modal<Props>('Details', DetailsModal)
