import { FC } from 'react'
import { Col, Grid, Row } from 'react-bootstrap'

import Table, { Column, downloadCSV } from 'components/table'
import {
  Body, ButtonBar, Content, EmptyState, FaIcon, Header, Loading, Portlet, PrintAction, TableAction,
  Units,
} from 'components/utilities'
import useActiveSidebarItem from 'hooks/useActiveSidebarItem'
import { useLegacyMember } from 'hooks/useLegacyContext'
import useList from 'hooks/useList'
import useObject from 'hooks/useObject'
import { memberMapHourRecords } from 'thunks/hour-records/member-map'
import {
  listHourRequirements, Request as HourRequirementRequest,
} from 'thunks/hour-requirements/list'

import { HourRequirement } from './type'
// @ts-ignore
import { hourRequirementQuery } from './type?query'

const AdminHourTracker: FC = () => {
  useActiveSidebarItem('hour-tracker#member')

  const user = useLegacyMember()

  const [hourRequirements, { timer: hourRequirementsTimer }] = useList<HourRequirement, HourRequirementRequest>(
    listHourRequirements,
    {
      query: hourRequirementQuery,
      member_id: user.member.id,
      organization_id: user.member.organization.id,
    }
  )

  const [hourRecordMap] = useObject(memberMapHourRecords, {
    is_approved: true,
    member_id: user?.member?.id,
  })

  if (!hourRequirements || !hourRecordMap) return <Loading />

  return (
    <Content>
      <Grid>
        <Header>Hour tracker</Header>

        <Portlet boxed>
          <Body table>
            <Table
              csvName="HourTracker"
              data={hourRequirements}
              noResults={<EmptyState message="No requirements have been added yet" />}
            >
              <Column width={36} value="title" link={({ id }: HourRequirement) => `/member/hour-tracker/${id}`}>
                Description
              </Column>

              <Column
                width={17}
                value={({ id, hours_required }: HourRequirement) => `${hourRecordMap[id] || 0} of ${hours_required}`}
                formatter={(_, { id, hours_required }: HourRequirement) => {
                  const hours = hourRecordMap[id] || 0

                  if (hours >= hours_required) {
                    return (
                      <span className="text-success">
                        <FaIcon check /> <Units count={hours} noun="hour" /> completed
                      </span>
                    )
                  }

                  return `${hours} of ${hours_required} hours`
                }}
                align="right"
              >
                Hours completed
              </Column>

              <Column width={15} value="created_at" date="MMM D, YYYY" align="right">
                Created on
              </Column>

              <Column width={15} value="completion_date" date="MMM D, YYYY" align="right">
                Completed by
              </Column>
            </Table>
          </Body>
        </Portlet>

        <Row className="m-b-2">
          <Col sm={8} lg={8} />
          <Col sm={4} lg={4}>
            <ButtonBar className="footer-toolbar">
              <PrintAction disabled={hourRequirementsTimer.isLoading} />
              <TableAction
                icon="download"
                disabled={hourRequirementsTimer.isLoading}
                onClick={downloadCSV('HourTracker')}
              />
            </ButtonBar>
          </Col>
        </Row>
      </Grid>
    </Content>
  )
}

export default AdminHourTracker
