import { oxford } from 'humanize-plus'
import moment from 'moment'
import { FC, useCallback, useMemo } from 'react'
import { Grid } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import r from 'routes'

import createTable from 'components/table-v2'
import { Body, BreadcrumbHeader, BreadcrumbItem, Content, Date, Loading, Portlet } from 'components/utilities'
import { Poll } from 'components/voting/index-container/type'
import isFinished from 'components/voting/util/is-finished'
import useActiveSidebarItem from 'hooks/useActiveSidebarItem'
import useLegacyContext from 'hooks/useLegacyContext'

const { Table, Header: THeader, Column, Body: TBody, Row, Cell } = createTable<Poll>()

type Props = {
  polls?: Poll[]
}

const EndedVotingContainer: FC<Props> = ({ polls: allPolls }) => {
  useActiveSidebarItem('Voting')

  const { user } = useLegacyContext()

  const polls = useMemo(
    () => allPolls?.filter(isFinished).sort((a, b) => (moment(b.ended_at).isBefore(a.ended_at) ? -1 : 1)),
    [allPolls]
  )

  const winners = useMemo(
    () =>
      polls?.reduce((memo, poll) => {
        return {
          ...memo,
          [poll.id]: poll.winners.map(w => w.title),
        }
      }, {} as Record<number, string[]>),
    [polls]
  )

  const linkToRootVoting = useMemo(() => {
    switch (user.role) {
      case 'alumni':
        return r.alumni.voting.root
      case 'federation':
        return r.federation.voting.root
      case 'admin':
        return r.admin.voting.root
      default:
        return r.member.voting.root
    }
  }, [user])

  const linkToFinished = useCallback(
    (pollId: number) => {
      switch (user.role) {
        case 'alumni':
          return r.alumni.voting.finished(pollId)
        case 'federation':
          return r.federation.voting.finished(pollId)
        case 'admin':
          return r.admin.voting.finished(pollId)
        default:
          return r.member.voting.finished(pollId)
      }
    },
    [user]
  )

  if (!polls || !winners) return <Loading />

  return (
    <Content>
      <Grid>
        <BreadcrumbHeader>
          <BreadcrumbItem link={linkToRootVoting}> Voting</BreadcrumbItem>
          <BreadcrumbItem>Ended votes</BreadcrumbItem>
        </BreadcrumbHeader>

        <Portlet boxed>
          <Body>
            <Table data={polls} sortIndex={2}>
              <THeader>
                <Column width={45}>Title</Column>
                <Column width={15}>Participation</Column>
                <Column width={20}>Winner</Column>
                <Column width={20} align="right" sortDir="desc">
                  Voting ended on
                </Column>
              </THeader>
              <TBody>
                {poll => (
                  <Row key={poll.id} id={poll.id}>
                    <Cell>
                      <Link to={linkToFinished(poll.id)}>{poll.title}</Link>
                    </Cell>
                    <Cell>
                      {poll.data.vote_count} of {poll.data.voters_count} votes
                    </Cell>
                    <Cell>
                      {winners[poll.id] ? oxford(winners[poll.id]) : <span className="text-muted">none</span>}
                    </Cell>
                    <Cell value={poll.ended_at}>
                      <Date long date={poll.ended_at} />
                    </Cell>
                  </Row>
                )}
              </TBody>
            </Table>
          </Body>
        </Portlet>
      </Grid>
    </Content>
  )
}

export default EndedVotingContainer
