import classNames from 'classnames'
import { modal } from 'helpers'
import { FC, useMemo } from 'react'
import { Button, Col, FormGroup, Modal } from 'react-bootstrap'
import { FormProvider } from 'react-hook-form'

import ConnectedAddress from 'components/form/connected-address'
import ConnectedField from 'components/form/connected-field'
import { useAPIFormHandler } from 'hooks/useAPIFormHandler'
import { closeModal } from 'hooks/useModal'
import { Request, updateParent } from 'thunks/parents/update'
import { Address } from 'types/address'
import notify from 'utils/notify'

import { Parent } from '../type'
// @ts-ignore
import { parentQuery } from '../type?query'

type Props = {
  id: number
  parent: Parent
  onUpdate: (parent: Parent) => void
}

type FormData = {
  user: {
    id: number
    first_name: string
    last_name: string
    email: string
    phone_number: string

    address: Address
  }
}

const EditParentModal: FC<Props> = ({ parent, onUpdate }) => {
  const defaultValues = useMemo(() => {
    const val = { ...parent }
    if (!val.user.phone_number) val.user.phone_number = ''
    return val
  }, [parent])

  const [onSubmit, { form, timer }] = useAPIFormHandler<FormData, Parent, Request>({
    model: 'parent',
    thunk: updateParent,
    defaultValues,
    request: data => {
      return {
        id: parent.id,
        query: parentQuery,
        parent: {
          ...data,
          user: {
            first_name: data.user.first_name || '',
            last_name: data.user.last_name || '',
            email: data.user.email || '',
            phone_number: data.user.phone_number || null,
            address: data.user.address,
          },
        },
      }
    },
    onSuccess: data => {
      closeModal()
      notify(`${data.user.first_name} was updated!`)
      onUpdate({ ...parent, ...data })
    },
  })

  return (
    <Modal show onHide={closeModal}>
      <FormProvider {...form}>
        <form className="form-horizontal" onSubmit={form.handleSubmit(onSubmit)}>
          <Modal.Header closeButton>
            <Modal.Title>Update parent profile</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <hr className="spacer-xs" />

            <FormGroup>
              <label htmlFor="user.first_name" className="col-md-3 control-label">
                Name
              </label>
              <Col md={4}>
                <input
                  {...form.register('user.first_name')}
                  id="user.first_name"
                  className="form-control"
                  placeholder="First"
                  autoFocus
                />
              </Col>
              <Col md={5}>
                <input {...form.register('user.last_name')} className="form-control" placeholder="Last" />
              </Col>
            </FormGroup>

            <ConnectedField label="Email" name="user.email" inputSize={9} />

            <ConnectedField label="Phone number" name="user.phone_number" mask="(999) 999 - 9999" inputSize={9} />

            <ConnectedAddress label="Address" name="user.address" inputSize={9} />
          </Modal.Body>
          <Modal.Footer>
            <Col md={7} mdPush={5}>
              <Button
                type="submit"
                bsStyle="primary"
                className={classNames({
                  'btn-loading': timer.isLoading,
                })}
              >
                Update parent
              </Button>
            </Col>
          </Modal.Footer>
        </form>
      </FormProvider>
    </Modal>
  )
}

export default modal<Props>('EditParent', EditParentModal)
