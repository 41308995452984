import { useMemo } from 'react'

import { AnyUser } from 'types/user'
import { isFederation, isMember } from 'types/user-functions'

type Poll = {
  owner_type: 'Organization' | 'Federation'
  owner_id: number
}

export default function useCanManage(poll: Poll | undefined, user: AnyUser) {
  return useMemo(() => {
    if (!poll) return false
    if (poll.owner_type === 'Organization') {
      return isMember(user) && poll.owner_id === user.member.organization.id
    }
    if (poll.owner_type === 'Federation') {
      return isFederation(user) && poll.owner_id === user.federation.id
    }
    return false
  }, [poll, user])
}
