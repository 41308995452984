import React, { FC, ReactNode } from 'react'
import { Button, ButtonProps, ButtonToolbar, Grid } from 'react-bootstrap'

import { FaButton } from './font-awesome'
import Search from './search'
import { IconDefinition } from '@fortawesome/fontawesome-common-types'

type ActionProps = ButtonProps & {
  icon?: string | IconDefinition
}

export function Action(props: ActionProps) {
  if (props.icon) {
    // @ts-ignore
    return <FaButton {...props} />
  }
  return <Button bsStyle="secondary" {...props} />
}

type ActionGroupProps = {
  className?: string
  children: ReactNode
}

export function ActionGroup({ children, className }: ActionGroupProps) {
  return <ButtonToolbar className={className}>{children}</ButtonToolbar>
}

type PageHeaderProps = {
  actions?: ReactNode
  disabled?: boolean
  search?: boolean
  preActions?: () => ReactNode
  children: ReactNode
}

const PageHeader: FC<PageHeaderProps> = ({ disabled, preActions, search, actions, children }) => {
  const hasActions = search || actions
  return (
    <Grid className="page-header">
      <h3 className="page-title">{children}</h3>
      {hasActions ? (
        <div className="page-actions hidden-print">
          <div>
            {preActions && preActions()}
            {!search || <Search disabled={disabled} />}
            {actions}
          </div>
        </div>
      ) : null}
    </Grid>
  )
}

type PortletHeaderProps = {
  actions?: ReactNode
  disabled?: boolean
  search?: boolean
  children: ReactNode
}

const PortletHeader: FC<PortletHeaderProps> = ({ disabled, search, actions, children }) => {
  return (
    <div className="portlet-header clearfix">
      <h4 className="portlet-title">{children}</h4>
      <div className="pull-right hidden-print">
        {!search || <Search className={!actions || 'm-r-1'} disabled={disabled} />}
        {actions}
      </div>
    </div>
  )
}

type Props = {
  actions?: ReactNode
  disabled?: boolean
  search?: boolean
  portlet?: boolean
  preActions?: () => ReactNode
  children: ReactNode
}

const Header: FC<Props> = props => {
  if (props.portlet) {
    return <PortletHeader {...props} />
  }
  return <PageHeader {...props} />
}

export default Header
