const parentRoutes = {
  root: '/parent/balance',
  settings: {
    root: '/parent/settings',
    notifications: '/parent/settings/notifications',
    password: '/parent/settings/password',
    paymentMethods: '/parent/settings/payment-methods',
    profile: '/parent/settings/profile',
  },
  requiredDocuments: {
    root: '/parent/required-documents',
    show: (id: number | ':id' = ':id') => `/parent/required-documents/${id}`,
  },
}

export default parentRoutes
