// @ts-nocheck

import Humanize from 'humanize-plus'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { ReactNode } from 'react'

import * as Sentry from '@sentry/react'

import { Loading } from 'components/utilities'
import formatMoney from 'utils/format-money'

import ActionsCell from './actions-cell'
import Cell from './cell'
import Header from './header'
import Tbody from './tbody'

const tables = Object.create(null)

const { any, arrayOf, bool, element, func, object, string, number, oneOfType, shape } = PropTypes

export type TableSortChange = {
  column: string
  direction: 'asc' | 'desc'
}

type Props<T> = {
  data: T[] | undefined

  bodyClassName?: string
  cellsFormatter?: (data: any) => ReactNode[] | false
  csvAdditionalColumns?: {
    header: string
    value: string | ((data: T) => string)
    position?: number
  }[]
  csvAdditionalData?: string[]
  csvName?: string
  defaultSort?: number | false
  defaultSortDir?: boolean
  dontSortByDefault?: boolean
  firstRow?: ReactNode
  footer?: ReactNode
  isSearching?: boolean
  noResults?: any
  noSearchResults?: string
  noSort?: boolean
  rowClassName?: (data: T) => string | undefined
  rowKey?: string
  search?: string
  sortByColumnIndices?: number[]
  striped?: boolean
  tableHead?: ReactNode
  className?: string
  children: ReactNode
  onChangeOrder?: (order: number[]) => void
  onCheckDrop?: (dragId: string, dropId: string) => void
  onSort?: (details: { column: string; direction: 'asc' | 'desc' }) => void
}

export default class Table<T extends Record<string, any> = Record<string, any>> extends React.Component<Props<T>> {
  /* eslint-disable react/no-unused-prop-types */
  static propTypes = {
    className: string,
    bodyClassName: string,
    csvName: string,
    data: arrayOf(object),
    firstRow: element,
    tableHead: element,
    onChangeOrder: func,
    onCheckDrop: func,
    onSort: func,
    noSort: bool,
    noSearchResults: string,
    noResults: any,
    search: string,
    sortByColumnIndices: arrayOf(number),
    dontSortByDefault: bool,
    cellsFormatter: func,
    rowClassName: func,
    csvAdditionalColumns: arrayOf(
      shape({
        header: string.isRequired,
        value: oneOfType([string, func]).isRequired,
        position: number,
      })
    ),
    csvAdditionalData: arrayOf(string),
  }
  /* eslint-enable react/no-unused-prop-types */

  static defaultProps = {
    csvAdditionalColumns: [],
  }

  constructor(props) {
    super(props)

    this.columns = React.Children.map(props.children, c => c && c.props).filter(c => !!c)

    const { dontSortByDefault, sortByColumnIndices, defaultSort, defaultSortDir } = props
    const sort = {}
    if (!dontSortByDefault) {
      sort.column = _.defaultTo(defaultSort, _.first(sortByColumnIndices) || 0)
      sort.dir = _.defaultTo(defaultSortDir, true)
    }

    const data = _.isEmpty(props.data) ? null : this.prepareData(props.data, sort)

    this.state = { sort, data }
  }

  componentDidMount() {
    const { csvName } = this.props

    if (csvName) {
      if (tables[csvName]) {
        throw new Error(`<Table> with name "${csvName}" is already registered`)
      }

      tables[csvName] = this
    }
  }

  UNSAFE_componentWillReceiveProps(props) {
    const data = this.prepareData(props.data)

    // need update logic when the react state changing self children
    this.columns = React.Children.map(props.children, c => c && c.props).filter(c => !!c)

    this.setState({ data })
  }

  componentWillUnmount() {
    const { csvName } = this.props

    if (csvName) {
      tables[csvName] = null
    }
  }

  onChangeSort(index) {
    const { onSort } = this.props

    const sort = {
      column: index,
      dir: this.state.sort.column === index ? !this.state.sort.dir : true,
    }

    if (_.isFunction(onSort)) {
      this.setState({ sort }, () => {
        const column = this.columns[sort.column]
        const sortRemotelyByValue = column.sortRemotelyByValue || column.sortColumn || column.value

        onSort({
          column: sortRemotelyByValue,
          direction: sort.dir ? 'asc' : 'desc',
        })
      })
    } else {
      const data = this.prepareData(null, sort)
      this.setState({ sort, data })
    }
  }

  onChangeOrder = ids => {
    const data = _.chain(this.state.data)
      .sortBy(r => ids.indexOf(`${r.id}`))
      .map('id')
      .value()

    this.props.onChangeOrder(data)
  }

  onCheckDrop = (dragTarget, dropTarget) => {
    if (!this.props.onCheckDrop) {
      return true
    }

    if (!dragTarget || !dropTarget) {
      this.blockRow(null)
      return true
    }

    const dropId = dropTarget.getAttribute('data-id')
    const result = this.props.onCheckDrop(dragTarget.getAttribute('data-id'), dropId)

    if (result) {
      this.blockRow(null)
    } else {
      this.blockRow(+dropId)
    }

    return result
  }

  blockRow = _.debounce(blockedRow => this.setState({ blockedRow }))

  processData(data) {
    return _.map(data, row => {
      const values = _.map(this.columns, column => {
        if ('actions' in column) {
          return null
        }
        return _.isFunction(column.value) ? column.value(row) : _.get(row, column.value)
      })
      return { ...row, values }
    })
  }

  sortValue(columnIndex, row, dir) {
    const value = row.values[columnIndex]
    const column = this.columns[columnIndex]
    return 'sortBy' in column ? column.sortBy(value, row, dir) : value
  }

  prepareData(_data, _sort) {
    const { onSort, sortByColumnIndices } = this.props
    const data = _data || this.state.data
    const sort = _sort || this.state.sort

    const processed = this.processData(data)
    if (_.isEmpty(sort) || sort.column === false || _.isFunction(onSort)) {
      return processed
    }

    const direction = (sort.dir && 'asc') || 'desc'

    if (_.includes(sortByColumnIndices, sort.column)) {
      const sortIndex = sortByColumnIndices.indexOf(sort.column)
      const sortColumns = sortByColumnIndices.slice(sortIndex)
      return _.orderBy(
        processed,
        sortColumns.map(sortColumn => r => this.sortValue(sortColumn, r, sort.dir)),
        sortColumns.map((sortColumn, index) => (index ? 'asc' : direction))
      )
    }

    const sorted = _.sortBy(processed, [
      r => this.sortValue(sort.column, r, sort.dir),
      r => this.sortValue(this.props.defaultSort || 0, r, sort.dir),
    ])
    return sort.dir ? sorted : _.reverse(sorted)
  }

  downloadCSV() {
    const csvRows = []

    const ignoredColumns = ['actions', 'dontDownload', 'isCheckbox']

    const headers = _.chain(this.columns)
      .reject(c => _.some(ignoredColumns, prop => prop in c))
      .map('children')
      .map(h => (_.isObject(h) ? _.get(h, 'props.children') : _.toString(h)))
      .value()

    const { csvAdditionalColumns } = this.props
    _.each(csvAdditionalColumns, col => headers.splice(col.position, 0, col.header))

    csvRows.push(headers)

    const excludedColumns = _.chain(this.columns)
      .map((c, i) => (_.some(ignoredColumns, prop => prop in c) ? i : null))
      .filter(_.isNumber)
      .value()

    const { firstRow } = this.props

    if (!_.isNil(firstRow)) {
      const rows = _.filter(
        firstRow.props.children,
        el => _.isObject(el) && el.$$typeof === Symbol.for('react.element')
      )
      const firstRowData = _.map(rows, td => (_.isNil(td.props.value) ? td.props.children : td.props.value))
      csvRows.push(firstRowData)
    }

    const data = _.map(this.state.data, row => {
      const info = _.reduce(
        this.columns,
        (acc, c, index) => {
          if (_.includes(excludedColumns, index)) return acc

          let value = row.values[index]

          if ('downloadFormatter' in c) {
            value = c.downloadFormatter(c, row)
          } else if (_.isUndefined(value)) {
            value = ''
          }

          return [...acc, this.formatValueForCSV(value, c)]
        },
        []
      )

      _.each(csvAdditionalColumns, col => {
        const value = typeof col.value === 'string' ? _.get(row, col.value, '') : col.value(row)
        info.splice(col.position, 0, this.formatValueForCSV(value))
      })

      return info
    })

    csvRows.push(...data)

    const csv = _.map(csvRows, row => row.join(',')).join(`\n`) // eslint-disable-line quotes

    const date = moment().format('YYYY-MM-DD')
    const filename = `${this.props.csvName}.${date}.csv`

    const a = document.createElement('a')

    if (typeof a.download === 'undefined') {
      this.downloadCSVRemotely(filename, csv)
      return
    }

    a.textContent = 'download'
    a.download = filename
    a.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`

    const clickEvent = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: false,
    })
    a.dispatchEvent(clickEvent)
  }

  downloadCSVRemotely = (filename, csv) => {
    const form = document.createElement('form')
    form.method = 'POST'
    form.action = '/api/download/csv'
    form.target = '_blank'

    const el1 = document.createElement('input')
    el1.value = filename
    el1.name = 'filename'
    el1.type = 'hidden'
    form.appendChild(el1)

    const el2 = document.createElement('input')
    el2.value = JSON.stringify(csv)
    el2.name = 'csv'
    el2.type = 'hidden'
    form.appendChild(el2)

    document.body.appendChild(form)

    form.submit()
    form.parentNode.removeChild(form)
  }

  formatValueForCSV(cell, column) {
    let value = cell

    // For csvAdditionalData
    if (!column) return `"${value}"`

    if (_.isNumber(cell)) {
      if ('balance' in column) {
        const modifier = cell < 0 ? '-' : ''
        value = `"${modifier}${formatMoney(cell)}"`
      } else if ('money' in column) {
        const formatted = Humanize.toFixed(Math.abs(cell), 2)
        if (cell < 0) {
          value = `($${formatted})`
        } else {
          value = `$${formatted}`
        }
      } else if ('percentage' in column) {
        value = parseFloat(cell)
        if (isNaN(value)) {
          value = 'n/a'
        } else {
          const decimalPlaces = _.defaultTo(column.percentage, 1)
          value = `${(value * 100).toFixed(decimalPlaces)}%`
        }
      }
    } else if (_.isString(cell)) {
      if ('date' in column) {
        const date = moment(value, moment.ISO_8601)
        if (date && date.isValid()) {
          value = date.format('MMM D, YYYY')
        }
      }
      value = `"${value.replace(/"/g, '\\"')}"`
    } else if (_.isObject(cell)) {
      if ('name' in column) {
        const user = cell.user || cell
        if (user.role === 'root') {
          value = user.first_name
        } else {
          value = `"${user.last_name}, ${user.first_name}"`
        }
      } else if ('address' in column) {
        value = `${cell.address} ${cell.city}, ${cell.state} ${cell.postal_code}`
        if (!_.isEmpty(cell.address_2)) {
          value = `${cell.address}, ${cell.address}, ${cell.city} ${cell.state} ${cell.postal_code}`
        }
        if (value.replace(/(null|,| )/g, '').length === 0) {
          value = ''
        }
        value = `"${value.replace(/"/g, '\\"')}"`
      } else if ('payment_id' in cell && 'due_on' in cell) {
        const modifier = cell < 0 ? '+' : ''
        value = `"${modifier}${formatMoney(cell.amount)} on ${moment(cell.due_on).format('MMM D')}"`
      } else {
        Sentry.captureMessage(`Table cell value is unknown: ${this.props.csvName}`, {
          extra: {
            location: window.location.href,
          },
        })
        value = ''
      }
    }

    return value
  }

  renderCells = (row, rowIndex) => {
    // Use cellsFormatter if it presented and returned not `false` value
    if (this.props.cellsFormatter) {
      const cells = this.props.cellsFormatter(row)

      if (cells !== false) {
        return cells
      }
    }

    return _.map(this.columns, (column, j) => {
      if ('actions' in column) {
        return <ActionsCell key={j} data={row} column={column} />
      }

      return <Cell<T> key={j} value={row.values[j]} data={row} column={column} />
    })
  }

  renderTableHead = () => {
    const { noSort } = this.props
    return (
      <tr>
        {_.map(this.columns, (column, index) =>
          'actions' in column ? (
            <th
              key={index}
              className="hidden-print no-sort text-right"
              style={{ width: `${_.defaultTo(column.width, 5)}%` }}
            >
              {column.cell}
            </th>
          ) : (
            <Header
              key={index}
              width={column.width}
              align={column.align}
              verticalAlign={column.verticalAlign}
              noSort={noSort || column.noSort}
              sorted={this.state.sort.column === index ? this.state.sort.dir : null}
              onChangeSort={this.onChangeSort.bind(this, index)}
            >
              {column.children}
            </Header>
          )
        )}
      </tr>
    )
  }

  render() {
    const { csvName, onChangeOrder, search, firstRow, footer, className, tableHead, bodyClassName } = this.props
    const { data } = this.state

    const isSearching = !_.isEmpty(search)
    let noResults = _.get(this.props, 'noResults', 'No results found')
    if (isSearching) {
      noResults = _.get(this.props, 'noSearchResults', noResults)
    }

    let filtered = isSearching ? null : data
    if (_.isNull(filtered)) {
      const regex = new RegExp(search, 'i')
      filtered = _.reject(data, row => _.findIndex(row.values, v => regex.test(v)) === -1)
    }

    return (
      <table id={_.kebabCase(csvName)} className={`table table-striped ${className}`}>
        <thead>{tableHead || this.renderTableHead()}</thead>
        {footer}
        <Tbody
          onChangeOrder={onChangeOrder}
          onDrop={this.onChangeOrder}
          onCheckDrop={this.onCheckDrop}
          className={bodyClassName}
        >
          {firstRow}
          {_.map(filtered, (row, i) => {
            let cName = 'data-row'
            if (i === filtered.length - 1) {
              cName += ' last-row'
            }
            if (this.state.blockedRow === row.id) {
              cName += ' no-drop'
            }
            if (this.props.rowClassName) {
              cName += ` ${this.props.rowClassName(row)}`
            }

            return (
              <tr key={`${i}-${row.key}`} data-id={row.id} className={cName}>
                {this.renderCells(row, i)}
              </tr>
            )
          })}

          {_.size(filtered) === 0 ? (
            <tr className="table-no-results">
              <td colSpan={_.size(this.columns)}>
                {typeof noResults === 'string' ? <p className="no-results-str">{noResults}</p> : noResults}
              </td>
            </tr>
          ) : null}

          <tr className="table-loading">
            <td colSpan={_.size(this.columns)}>
              <Loading />
            </td>
          </tr>
        </Tbody>
      </table>
    )
  }
}

export function downloadCSV(tableName) {
  return () => {
    const table = tables[tableName]

    if (table && table.downloadCSV) {
      table.downloadCSV()
    }
  }
}
