import { useCallback, useMemo } from 'react'

import useList from 'hooks/useList'
import { Headers, listPollVotes, Request, Voter } from 'thunks/poll-votes/list'
import objectKeys from 'utils/object-keys'

export default function useVoters(pollId: number) {
  const [voters, { headers, patch }] = useList<Voter, Request, Headers>(listPollVotes, {
    poll_id: pollId,
  })

  const sortedVoters = useMemo(
    () =>
      voters?.sort((a, b) => {
        if (a.data.has_voted && !b.data.has_voted) return -1
        if (!a.data.has_voted && b.data.has_voted) return 1
        return a.last_name.localeCompare(b.last_name)
      }),
    [voters]
  )

  const recordVote = useCallback(
    (userId: number) => {
      patch({
        id: userId,
        data: {
          has_voted: true,
        },
      })
    },
    [patch]
  )

  const voteCounts = useMemo(() => {
    if (!headers) return

    const counts = JSON.parse(headers['x-votes']) as Record<string, number>
    return objectKeys(counts).reduce((acc, optionId) => {
      acc[parseInt(optionId)] = counts[optionId]
      return acc
    }, {} as Record<number, number>)
  }, [headers])

  return [sortedVoters, voteCounts, recordVote] as const
}
