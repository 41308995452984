import asyncThunk from 'utils/asyncThunk'

export type Request = {
  query: string
  search?: string
  purpose?: string
  document_folder_id?: number
}

export const listDocuments = asyncThunk<Request, any>({
  name: 'document/list',
  uri: '/documents',
})
