import cx from 'classnames'
import moment from 'moment'
import { FC } from 'react'

import Money from 'components/utilities/money'

import PaymentStatus from './payment-status'

type Props = {
  paymentPlanPayments: {
    id: number
    amount: number
    due_on: string
    payment_id?: number
  }[]
}

const PaymentPlanPaymentList: FC<Props> = ({ paymentPlanPayments }) => (
  <ul className="list-unstyled">
    {paymentPlanPayments.map(payment => (
      <li key={payment?.id} className={cx({ 'list-item-faded': moment(payment.due_on).isBefore() })}>
        <h6>
          {moment(payment.due_on).format('MMMM Do, YYYY')}
          <small className="pull-right">
            <PaymentStatus {...payment} />
            &nbsp;
            <Money amount={payment.amount} className="m-l-05" />
          </small>
        </h6>
        <hr />
      </li>
    ))}
  </ul>
)

export default PaymentPlanPaymentList
