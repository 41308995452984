import { FC, useCallback } from 'react'
import { Button, ButtonToolbar, OverlayTrigger, Tooltip } from 'react-bootstrap'

import AddBankAccountButton from 'components/add-bank-account-button'
import BraintreeBadge from 'components/braintree/badge'
import { AddCreditCardModal, ConfirmModal, NoticeModal } from 'components/modals'
import {
  Date,
  EmptyState,
  FaButton,
  Loading,
  Money,
  PaymentMethod as PaymentMethodComponent,
} from 'components/utilities'
import { useAPI } from 'hooks/useAPI'
import useList from 'hooks/useList'
import { openModal } from 'hooks/useModal'
import { archivePaymentMethod } from 'thunks/payment-methods/archive'
import { listPaymentMethods, Request } from 'thunks/payment-methods/list'
import notify from 'utils/notify'

import { PaymentMethod } from './type'
// @ts-ignore
import { paymentMethodQuery } from './type?query'

type Props = {
  userId: number
}

const SharedSettingsPaymentMethods: FC<Props> = ({ userId }) => {
  const [paymentMethods, { add, remove }] = useList<PaymentMethod, Request>(listPaymentMethods, {
    query: paymentMethodQuery,
    user_id: userId,
  })

  const [archive, { timer }] = useAPI(archivePaymentMethod)
  const doPaymentMethodArchive = useCallback(
    async (id: number) => {
      try {
        await archive({ id })

        remove(id)
        notify({ message: 'The credit card has been deleted', type: 'warning' })
      } catch (err) {
        console.log('[ERROR doPaymentMethodArchive]', err)
        openModal('Notice')()
      }
    },
    [archive, remove]
  )

  const renderDeleteButton = useCallback(
    (paymentMethod: PaymentMethod) => {
      // mouse events don't fire on disabled elements, thus this hack
      if (paymentMethod.payment_plans.length !== 0) {
        return (
          <OverlayTrigger
            placement="left"
            overlay={<Tooltip id="delete-button-tooltip">Can't be deleted when attached to a payment plan</Tooltip>}
          >
            <div style={{ display: 'inline-block', cursor: 'not-allowed' }}>
              <FaButton style={{ pointerEvents: 'none' }} disabled icon="trash" />
            </div>
          </OverlayTrigger>
        )
      }
      return <FaButton icon="trash" disabled={timer.isLoading} onClick={openModal('Confirm', paymentMethod.id)} />
    },
    [timer]
  )

  if (!paymentMethods) return <Loading />

  if (paymentMethods.length === 0) {
    return (
      <div>
        <EmptyState size={10}>
          <h3 className="mb-12">No payment methods added yet</h3>
          <div className="flex space-x-4 justify-center">
            <Button bsSize="lg" bsStyle="primary" onClick={openModal('AddCreditCard')}>
              Add credit card
            </Button>
            <AddBankAccountButton
              bsSize="lg"
              bsStyle="primary"
              noIcon
              onCreate={pm =>
                add({
                  ...pm,
                  payment_plans: [],
                })
              }
            />
          </div>
        </EmptyState>

        <AddCreditCardModal onCreditCardCreate={add} />
      </div>
    )
  }

  return (
    <div className="tab-pane active">
      <div className="heading-block clearfix">
        <h3 className="pull-left">Payment methods</h3>
        <ButtonToolbar className="hidden-print pull-right p-b-1">
          <FaButton onClick={openModal('AddCreditCard')} icon="credit-card">
            Add credit card
          </FaButton>
          <AddBankAccountButton
            onCreate={pm =>
              add({
                ...pm,
                payment_plans: [],
              })
            }
          />
        </ButtonToolbar>
      </div>

      <table className="table table-striped">
        <thead>
          <tr>
            <th>Payment method</th>
            <th>Last payment</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {paymentMethods.map(paymentMethod => (
            <tr key={paymentMethod.id} data-id={paymentMethod.id}>
              <td>
                <PaymentMethodComponent paymentMethod={paymentMethod} />
              </td>
              <td>
                {paymentMethod.last_payment ? (
                  <span>
                    <Date short date={paymentMethod.last_payment.created_at} />
                    &nbsp;for&nbsp;
                    <Money amount={paymentMethod.last_payment.amount} />
                  </span>
                ) : (
                  <span className="text-muted">never charged</span>
                )}
              </td>
              <td className="hidden-print text-right">{renderDeleteButton(paymentMethod)}</td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="hidden-print">
        <hr />
        <BraintreeBadge height={34} />
      </div>

      <AddCreditCardModal onCreditCardCreate={add} />

      {paymentMethods.map(({ id, company, last_four: lastFour }) => (
        <ConfirmModal
          key={id}
          id={id}
          prompt={`Are you sure you want to delete the ${company} ending in ${lastFour}?`}
          yes="delete payment method"
          onConfirm={() => doPaymentMethodArchive(id)}
        />
      ))}

      <NoticeModal>Can't be deleted when attached to a payment plan</NoticeModal>
    </div>
  )
}

export default SharedSettingsPaymentMethods
