import asyncThunk from 'utils/asyncThunk'

export type Request = {
  query: string
  id: number
  document_folder: {
    name: string
    is_private?: 'true' | 'false'
  }
}

export const updateDocumentFolder = asyncThunk<Request, any>({
  name: 'document-folders/update',
  uri: ({ id }) => `/document_folders/${id}`,
  method: 'PUT',
})
