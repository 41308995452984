import { connect } from 'helpers'
import { FC } from 'react'
import { useAppDispatch } from 'store'

import morph from 'thunks/auth/morph'

import Header from './header'
import Item from './item'

type Props = {
  actions: { morph: (id: number) => void }
}

const DevMenu: FC<Props> = () => {
  const dispatch = useAppDispatch()

  const m = (id: number) => dispatch(morph({ id }))

  /*
   * This is to hide the quick morphs for demos
   */
  const hide = false // user.id === 5;

  return (
    <ul className="sidebar-menu c-pointer">
      <Header>Quick morph</Header>
      <Item onClick={() => m(25)} icon="user-secret">
        Member
      </Item>
      <Item onClick={() => m(47)} icon="user-secret">
        Admin
      </Item>
      <Item onClick={() => m(36148)} icon="user-secret">
        National
      </Item>
      <Item onClick={() => m(76884)} icon="user-secret">
        Alumni
      </Item>
      {hide || (
        <Item onClick={() => m(77651)} icon="user-secret">
          Chi Psi
        </Item>
      )}
      {hide || (
        <Item onClick={() => m(74718)} icon="user-secret">
          KBG
        </Item>
      )}
      {hide || (
        <Item onClick={() => m(127828)} icon="user-secret">
          KΣ
        </Item>
      )}
      {hide || (
        <Item onClick={() => m(77653)} icon="user-secret">
          SPD
        </Item>
      )}
      {hide || (
        <Item onClick={() => m(97622)} icon="user-secret">
          GAO
        </Item>
      )}
      {hide || (
        <Item onClick={() => m(159004)} icon="user-secret">
          ΔΕΨ
        </Item>
      )}
      {hide || (
        <Item onClick={() => m(218439)} icon="user-secret">
          ΑΩΕ
        </Item>
      )}
      {hide || (
        <Item onClick={() => m(217092)} icon="user-secret">
          MDA
        </Item>
      )}
      {hide || (
        <Item onClick={() => m(87859)} icon="user-secret">
          ΣA
        </Item>
      )}
    </ul>
  )
}

export default connect(DevMenu)
