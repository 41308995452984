export const paymentQuery = `
  payment {
    id
    amount
    description
    descriptor
    method
    member {
      id
    }
  }
`
