/* global braintree */

import React from 'react'
import PropTypes from 'prop-types'
import scriptLoader from 'react-async-script-loader'
import * as Sentry from '@sentry/react'

const { func, string } = PropTypes

class BraintreeForm extends React.Component {
  static propTypes = {
    className: string,
    onError: func.isRequired,
    onBraintreeReady: func.isRequired,
    onProcessPayment: func.isRequired,
    onSubmit: func,
    onCardTypeChange: func.isRequired,
    onValidityErrorChange: func.isRequired,
  }

  constructor() {
    super()

    this.state = {
      data: {},
    }
  }

  UNSAFE_componentWillReceiveProps = ({ authorization, isScriptLoadSucceed }) => {
    if (!isScriptLoadSucceed) {
      return
    }
    if (!authorization) {
      return
    }
    if (this.state.initialized) {
      return
    }
    braintree.client.create({ authorization }, this.onBraintreeClientCreate)
    this.setState({ initialized: true })
  }

  onBraintreeClientCreate = (err, client) => {
    if (err) {
      return Sentry.captureException(err)
    }

    const opts = {
      client,
      styles: {
        input: {
          'font-size': '13px',
          'font-family': "'Open Sans', 'Trebuchet MS', arial, sans-serif",
        },
      },
      fields: {
        number: {
          selector: '#number',
          placeholder: 'Card number',
        },
        expirationDate: {
          selector: '#expirationDate',
          placeholder: 'MM / YY',
        },
        cvv: {
          selector: '#cvv',
          placeholder: 'CVV',
        },
        postalCode: {
          selector: '#postalCode',
          placeholder: 'Billing zip code',
        },
      },
    }

    return braintree.hostedFields.create(opts, this.onBraintreeHostedFieldsCreate)
  }

  onBraintreeHostedFieldsCreate = (err, hostedFields) => {
    if (err) {
      return Sentry.captureException(err)
    }
    this.setState({ hostedFields })
    hostedFields.on('cardTypeChange', this.onCardTypeChange)
    hostedFields.on('focus', () => this.props.onError(null))
    hostedFields.on('validityChange', this.onValidityChange)
    return this.props.onBraintreeReady()
  }

  onCardTypeChange = ({ cards }) => {
    const type = cards.length === 1 ? cards[0].type : null
    this.props.onCardTypeChange(type)
  }

  onValidityChange = event => {
    const field = event.fields[event.emittedBy]
    const fieldKey = field.container.getAttribute('id')
    this.props.onValidityErrorChange(fieldKey, field.isPotentiallyValid)
  }

  onSubmit = e => {
    e.preventDefault()

    const { onSubmit } = this.props
    const { hostedFields } = this.state

    if (_.isFunction(onSubmit)) {
      onSubmit()
    }

    hostedFields.tokenize((err, payload) => {
      if (err) {
        return this.props.onError(err)
      }
      const { nonce } = payload
      return this.props.onProcessPayment({ nonce })
    })
  }

  render() {
    const { className, children } = this.props

    return (
      <form action="#" onSubmit={this.onSubmit} autoComplete="off" className={className}>
        {children}
      </form>
    )
  }
}

export default scriptLoader(
  'https://js.braintreegateway.com/web/3.6.2/js/client.min.js',
  'https://js.braintreegateway.com/web/3.6.2/js/hosted-fields.min.js'
)(BraintreeForm)
