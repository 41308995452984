import asyncThunk from 'utils/asyncThunk'

export type Request = {
  id: number
  query: string
  term: {
    name: string
    started_on: string
  }
}

export const updateTerm = asyncThunk<Request, any>({
  name: 'terms/update',
  uri: ({ id }) => `/terms/${id}`,
  method: 'PUT',
})
