import { FC } from 'react'
import { Grid } from 'react-bootstrap'
import { Body, Content, Header, Loading, Name, Portlet } from 'components/utilities'
import { listCommitteePositions, Request } from 'thunks/committee-positions/list'
import useActiveSidebarItem from 'hooks/useActiveSidebarItem'
import { useLegacyMember } from 'hooks/useLegacyContext'
import useList from 'hooks/useList'

import s from './index.module.scss'

import { CommitteePosition } from './type'
// @ts-ignore
import { committeePositionQuery } from './type?query'

const MemberCommitteePositions: FC = () => {
  useActiveSidebarItem('Chairs/Committees')

  const { member } = useLegacyMember()

  const [positions] = useList<CommitteePosition, Request>(listCommitteePositions, {
    query: committeePositionQuery,
    organization_id: member.organization.id,
    federation_id: member.organization.federation.id,
  })

  if (!positions) return <Loading />

  return (
    <Content>
      <Grid>
        <Header>Chairs/Committees</Header>
        <Portlet boxed>
          <Body>
            <table className={s.Committees}>
              <tbody>
                {positions.map(position => (
                  <tr key={position.id}>
                    <td>{position.title}</td>
                    <td>
                      {position.member ? (
                        <Name user={position.member.user} natural />
                      ) : (
                        <em className="text-muted">no one</em>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Body>
        </Portlet>
      </Grid>
    </Content>
  )
}

export default MemberCommitteePositions
