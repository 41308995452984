import { FC } from 'react'

import { Date, FaIcon, Money } from 'components/utilities'
import Label from 'components/utilities/label'
import useLegacyContext from 'hooks/useLegacyContext'
import { openModal } from 'hooks/useModal'
import { isSuper } from 'types/user-functions'

import { Transaction } from './type'

const HighlightMark: FC = () => (
  <Label danger className="m-r-05">
    <FaIcon star />
  </Label>
)

const DescriptionText: FC<{ description: string; onClick: () => void }> = ({ description, onClick }) => {
  const { user } = useLegacyContext()

  return isSuper(user!) ? (
    <span className="c-pointer" onClick={onClick}>
      {description}
    </span>
  ) : (
    <>{description}</>
  )
}

const DueDate: FC<{ dueOn: string; lateFee?: number }> = ({ dueOn, lateFee }) => (
  <Label info className="m-l-1">
    Due <Date extraShort date={dueOn} />
    {lateFee ? (
      <span>
        &nbsp;or a <Money amount={lateFee} /> late fee
      </span>
    ) : null}
  </Label>
)

type Props = {
  transaction: Transaction
  isHighlighted: boolean
  onClick: () => void
}

const Description: FC<Props> = ({ transaction, isHighlighted, onClick }) => {
  const { user } = useLegacyContext()

  if (transaction.txn_type === 'payment') {
    if (isSuper(user!) && transaction.payment) {
      return (
        <span className="c-pointer" onClick={openModal('PaymentDetails', transaction.payment.id)}>
          {isHighlighted && (
            <Label danger className="m-r-05">
              <FaIcon star />
            </Label>
          )}
          <Label success>
            <FaIcon search />
            &nbsp; Payment
          </Label>
          &nbsp; {transaction.description}
        </span>
      )
    }

    return (
      <span>
        {isHighlighted && <HighlightMark />}
        <Label success>Payment</Label>
        &nbsp; {transaction.description}
      </span>
    )
  }

  if (transaction.due_on) {
    return (
      <span>
        {isHighlighted && <HighlightMark />}
        <DescriptionText description={transaction.description} onClick={onClick} />
        <DueDate dueOn={transaction.due_on} lateFee={transaction.late_fee} />
      </span>
    )
  }

  return (
    <span>
      {isHighlighted && <HighlightMark />}
      <DescriptionText description={transaction.description} onClick={onClick} />
    </span>
  )
}

export default Description
