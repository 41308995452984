import { FC, ReactNode } from 'react'

type Props = {
  actions?: boolean
  address?: boolean
  align?: 'left' | 'center' | 'right'
  balance?: boolean
  cell?: ReactNode
  cellClassName?: string
  cellsFormatter?: Function
  children?: ReactNode
  date?: string
  dontDownload?: boolean
  email?: boolean
  fallback?: string
  formatter?: (value: any | never, data: any) => ReactNode | ((value: any) => ReactNode)
  downloadFormatter?: (value: any | never, data: any) => ReactNode | ((value: any) => ReactNode)
  footer?: ReactNode
  isCheckbox?: boolean
  link?: (data: any) => string | undefined
  linkId?: string
  money?: boolean | string
  name?: boolean | string
  none?: ReactNode | Function
  noSort?: boolean
  percentage?: number | boolean
  phone?: boolean
  plus?: boolean
  rowClassName?: string
  sortBy?: string | Function
  sortRemotelyByValue?: string
  unit?: string
  value?: string | Function
  width?: number
  className?: string
  url?: string
  onSort?: () => void
}

const Column: FC<Props> = props => {
  const { children } = props

  return <tr>{children}</tr>
}

export default Column
