import { FC, useState } from 'react'
import moment, { Moment } from 'moment'
import modal from 'helpers/modal'
import { HourRequirement } from 'resources'
import { Modal } from 'react-bootstrap'
import { Form, Field, SubmitButton } from 'components/form'
import Calendar from 'components/date-range/Calendar'
import { closeModal } from 'hooks/useModal'

type HourRequirementType = {
  title: string
  description: string
  hours_required: number
  completion_date: string
  member_ids: number[]
  data: {
    approved_hours: number
    pending_hours: number
    pending_count: number
  }
}

type Props = {
  organizationId: number
  onHourRequirementCreate: (hourRequirement: HourRequirementType) => void
}

const AddHourRequirementModal: FC<Props> = ({ organizationId, onHourRequirementCreate }) => {
  const [date, setDate] = useState(moment())

  const data = {
    hour_requirement: {
      organization_id: organizationId,
      completion_date: date && date.format('YYYY-MM-DD'),
    },
  }

  return (
    <Modal show onHide={closeModal}>
      <Form
        action={HourRequirement.create}
        data={data}
        query={`
            hour_requirement {
              title
              description
              hours_required
              completion_date
              member_ids

              data {
                approved_hours
                pending_hours
                pending_count
              }
            }
          `}
        // @ts-ignore
        onSuccess={onHourRequirementCreate}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add an hours requirement</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <hr className="spacer-xs" />

          <Field label="Title" name="hour_requirement.title" inputSize={8} autoFocus />

          <Field label="Description" name="hour_requirement.description" textarea={6} inputSize={8} />

          <Field label="Hours required" name="hour_requirement.hours_required" inputSize={3} />

          <Field label="Completed by" name="hour_requirement.completion_date" noFeedbackControl>
            <Calendar date={date} onChange={(d: Moment) => setDate(d)} />
          </Field>
        </Modal.Body>
        <Modal.Footer>
          <SubmitButton offset={5}>Add hour requirement</SubmitButton>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default modal<Props>('AddHourRequirement', AddHourRequirementModal)
