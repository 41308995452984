import { FC } from 'react'
import Units from 'components/utilities/units'

type Props = {
  votes?: {
    needed: number
  }
}

const VotesAside: FC<Props> = ({ votes }) => {
  return votes && votes.needed > 0 ? (
    <Units count={votes.needed} noun="vote needed" plural="votes needed" />
  ) : null
}

export default VotesAside
