import asyncThunk from 'utils/asyncThunk'

export type Request = {
  query: string
  federation_id?: number
  organization_id?: number
}

export const listExecutiveBoardPositions = asyncThunk<Request, any>({
  name: 'executive-board-positions/list',
  uri: '/executive_board_positions',
  method: 'GET',
})
