import asyncThunk from 'utils/asyncThunk'

export type ExpenseMethod = 'Cash' | 'Check' | 'Credit card' | 'Reimbursement'

export type Request = {
  query: string
  budget_expense: {
    description: string
    method: ExpenseMethod
    cost: number
    budget_id: number
    s3_path?: string
    is_credit?: boolean
  }
}

export const createBudgetExpense = asyncThunk<Request, any>({
  name: 'budget-expenses/create',
  uri: '/budget_expenses',
  method: 'POST',
  track: req => ({
    budget_id: req.budget_expense.budget_id,
    description: req.budget_expense.description,
    method: req.budget_expense.method,
    cost: req.budget_expense.cost,
  }),
})
