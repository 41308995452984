import asyncThunk from 'utils/asyncThunk'

export type Request = {
  query: string
  message_thread: {
    name: string
    body: string
    message_category_folder_id?: number
    message_category_id: number
  }
}

export const createMessageThread = asyncThunk<Request, any>({
  name: 'message-threads/create',
  uri: '/message_threads',
  method: 'POST',
})
