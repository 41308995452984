import moment from 'moment'
import { useMemo } from 'react'

type Event = {
  maximum_tickets: number | undefined
  registration_closes_on: string | undefined | null
  data: {
    tickets_sold: number
  }
}

export default function useEventState(event: Event | undefined) {
  const areTicketsAvailable = useMemo(
    (): boolean => !event || event.maximum_tickets === undefined || event.maximum_tickets - event.data.tickets_sold > 0,
    [event]
  )

  const isClosed: boolean = useMemo(
    () =>
      Boolean(event?.registration_closes_on) && moment(event?.registration_closes_on).endOf('day').isBefore(moment()),
    [event]
  )

  return useMemo(() => ({ areTicketsAvailable, isClosed }), [areTicketsAvailable, isClosed])
}
