export const executiveBoardPositionQuery = `
  executive_board_position {
    id
    title
    member {
      id
      user {
        id
        first_name
        last_name
      }
    }
  }
`
