import { connectParent } from 'helpers'
import { FC } from 'react'
import { useAppDispatch, useParentSelector } from 'store'

import setActiveParent from 'thunks/parents/set-active-parent'

import Contacts from './contacts'
import Item from './item'

const ParentMenu: FC = () => {
  const { parents, activeParent } = useParentSelector()
  const dispatch = useAppDispatch()

  return (
    <>
      {parents && parents.length > 1 && (
        <ul className="sidebar-menu">
          {parents.map(parent => (
            <Item
              key={parent.id}
              onClick={() => dispatch(setActiveParent(parent))}
              isActive={parent.id === activeParent?.id}
            >
              {parent.member.user.first_name} {parent.member.user.last_name}
            </Item>
          ))}
        </ul>
      )}

      <ul className="sidebar-menu">
        <Item url="/parent/balance" icon="bank">
          Balance
        </Item>
        <Item url="/parent/payment-plan" icon="calendar">
          Payment plan
        </Item>
        <Item url="/parent/required-documents" icon="file-text-o">
          Required signatures
        </Item>
        <Item url="/parent/settings" icon="cog">
          Settings
        </Item>
      </ul>

      <Contacts />
    </>
  )
}

export default connectParent(ParentMenu)
