import { FC } from 'react'
import { Alert, Grid } from 'react-bootstrap'

import PaymentPlanDetails from 'components/payment-plans/details'
import { Body, Content, Header, PaymentMethod, Portlet } from 'components/utilities'

import { PaymentPlan } from './type'

type Props = {
  paymentPlan: PaymentPlan
}

const MemberPaymentPlanPending: FC<Props> = ({ paymentPlan }) => (
  <Content>
    <Grid>
      <Header>Payment plan</Header>
      <Portlet boxed>
        <Body>
          <Alert bsStyle="warning" className="fw-semibold">
            This payment schedule has been submitted for approval.
          </Alert>

          {paymentPlan.payment_method ? (
            <p className="m-t-2">
              If approved, your&nbsp;
              <PaymentMethod paymentMethod={paymentPlan.payment_method} className="fw-semibold" />
              &nbsp;will be charged:
            </p>
          ) : (
            <p className="m-t-2 m-l-1 fw-semibold text-danger">The payment method has been deleted</p>
          )}

          <PaymentPlanDetails paymentPlan={paymentPlan} noStatus className="max-w-250" />
        </Body>
      </Portlet>
    </Grid>
  </Content>
)

export default MemberPaymentPlanPending
