import { FC } from 'react'

import { PollOption } from '../show-container/type'
import VoteOption from './_VoteOption'
import s from './_VoteOption.module.scss'

type Props = {
  options: PollOption[]
  winnerIds: number[]
  onSelectImage: (id: number) => void
}

const Options: FC<Props> = ({ options, winnerIds, onSelectImage }) => {
  return (
    <div className={s.VoteOptions}>
      {options.map(option => (
        <VoteOption
          key={option.id}
          option={option}
          isWinner={winnerIds.includes(option.id)}
          onImageClick={() => onSelectImage(option.id)}
        />
      ))}
    </div>
  )
}

export default Options
