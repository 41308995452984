import { MemberStatus } from 'types/member'
import asyncThunk from 'utils/asyncThunk'

export type Request = {
  id: number
  query: string
  member: {
    grade?: string
    graduation_year?: number | null
    status?: MemberStatus
  }
}

export const updateMember = asyncThunk<Request, any>({
  name: 'members/update',
  uri: ({ id }) => `/members/${id}`,
  method: 'PUT',
})
