import moment from 'moment'

type Event = {
  registration_closes_on?: string
}

const isRegistrationClosed = ({ registration_closes_on: rco }: Event): boolean =>
  rco ? moment(rco).isBefore(moment()) : false

export default isRegistrationClosed
