const superRoutes = {
  root: '/super/organizations',
  alumni: {
    root: '/super/alumni',
    show: (id: number | string = ':id') => ({
      root: `/super/alumni/${id}`,
    }),
  },
  terms: '/super/terms',
  transactions: {
    root: '/super/transactions',
  },
  transfers: {
    root: '/super/transfers',
  },
  accounts: {
    root: '/super/accounts',
    v2: '/super/accounts/v2',
  },
  federationBalances: {
    root: '/super/federation-balances',
    show: (id: number | string = ':id') => `/super/federation-balances/${id}`,
  },
  billPay: {
    root: (page?: number) => (page ? `/super/bill-pay/${page}` : '/super/bill-pay'),
    pending: '/super/bill-pay/pending',
  },
  gMoney: {
    root: '/super/g-money',
  },
  collections: {
    root: '/super/collections',
  },
  taxes: {
    root: '/super/taxes',
  },
  endOfSemester: {
    root: '/super/end-of-semester',
  },
  gcmFees: {
    root: '/super/gcm-fees',
  },
  messaging: {
    root: '/super/messaging',
  },
  paymentPlans: {
    root: '/super/payment-plans',
    page: (page: number | string = ':page') => `/super/payment-plans/${page}`,
  },
  requiredDocuments: {
    root: '/super/required-documents',
    new: '/super/required-documents/new',
    archived: '/super/required-documents/archived',
    show: (id: number | string = ':id') => `/super/required-documents/${id}`,
    edit: (id: number | string = ':id') => `/super/required-documents/${id}/edit`,
    paymentSchedule: (id: number | string = ':id') => `/super/required-documents/${id}/payment-schedule`,
  },
  members: {
    show: (id: number | string = ':id') => ({
      root: `/super/members/${id}`,
      transactions: `/super/members/${id}/transactions`,
      profile: `/super/members/${id}/profile`,
      access: `/super/members/${id}/access`,
      notes: `/super/members/${id}/notes`,
      password: `/super/members/${id}/password`,
      resetPassword: `/super/members/${id}/reset-password`,
      collections: `/super/members/${id}/collections`,
      contactEvents: `/super/members/${id}/contact-events`,
      paymentPlan: `/super/members/${id}/payment-plan`,
      history: `/super/members/${id}/history`,
      historyV2: `/super/members/${id}/history/v2`,
      archive: `/super/members/${id}/archive`,
    }),
  },
  organizations: {
    root: '/super/organizations',
    archived: '/super/organizations/archived',
    show: (id: number | string = ':id') => ({
      root: `/super/organizations/${id}`,
      members: `/super/organizations/${id}/members`,
      accounts: `/super/organizations/${id}/accounts`,
      transfers: `/super/organizations/${id}/transfers`,
      bills: `/super/organizations/${id}/bills`,
      billPay: {
        root: `/super/organizations/${id}/bill-pay`,
        show: (accountId: number) => `/super/organizations/${id}/bill-pay/${accountId}`,
      },
      bankAccount: `/super/organizations/${id}/bank-account`,
      taxes: `/super/organizations/${id}/taxes`,
      gMoney: `/super/organizations/${id}/g-money`,
    }),
  },
  federations: {
    root: '/super/federations',
    show: (id: number | string = ':id') => ({
      root: `/super/federations/${id}`,
      transfers: `/super/federations/${id}/transfers`,
      accounts: `/super/federations/${id}/accounts`,
      reports: {
        root: `/super/federations/${id}/reports`,
        new: `/super/federations/${id}/reports/new`,
        show: (reportId: number | string = ':reportId') => `/super/federations/${id}/reports/${reportId}`,
      },
    }),
  },
  salesPermissions: {
    root: '/super/sales-permissions',
  },
}

export default superRoutes
