import { useCallback, useState } from 'react'

import usePaypalPayment from 'components/braintree/usePaypalPayment'
import getChargeAmount from 'components/modals/quick-pay/get-charge-amount'
import { useAPI } from 'hooks/useAPI'
import useLegacyContext from 'hooks/useLegacyContext'
import { closeModal } from 'hooks/useModal'
import { createPaymentMethodPayment, Request } from 'thunks/payments/payment-method'
import { PaymentMethod } from 'types/payment-method'
import formatMoney from 'utils/format-money'
import notify from 'utils/notify'

type PayPalMethod = { id: 0; method: 'paypal' }
export type PaymentMethodType = PaymentMethod | PayPalMethod

export type Payment = {
  id: number
}

type Input = {
  member: {
    id: number
    organization: {
      cc_upcharge: boolean
    }
  }
  paymentMethod: PaymentMethod | PayPalMethod | undefined
  onSuccess: (payment: Payment) => void
}

export default function useSubmitPayment({ member, paymentMethod, onSuccess }: Input) {
  const { user } = useLegacyContext()
  const [error, setError] = useState<string>()

  const [create, { timer }] = useAPI<Payment, Request>(createPaymentMethodPayment)
  const submitPaypal = usePaypalPayment(member.id, timer)

  const submit = useCallback(
    async (amount: number) => {
      if (!paymentMethod) throw new Error('No payment method selected')

      if (paymentMethod.method === 'paypal') {
        const payment = await submitPaypal(amount)
        notify(`Your ${formatMoney(amount, { strip: true })} PayPal payment was successful!`)
        onSuccess(payment)
        closeModal()
        return
      }

      try {
        const [payment] = await create({
          query: 'payment { id }',
          amount: getChargeAmount(amount, paymentMethod.method, member.organization.cc_upcharge),
          source: `web/${user?.role}`,
          payment_method_id: paymentMethod.id,
          member_id: member.id,
        })

        onSuccess(payment)
        notify(`Your ${formatMoney(amount, { strip: true })} payment was successful!`)

        closeModal()
      } catch (err) {
        if ((err as any).payment?._) {
          setError((err as any).payment._[0])
        } else {
          setError('Unable to process the payment')
        }
      }
    },
    [member, paymentMethod, submitPaypal, onSuccess, create, user?.role]
  )

  return [submit, { error, timer }] as const
}
