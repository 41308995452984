import { AdminUser, AlumniUser, AnyUser, FederationUser, MemberUser, ParentUser, Role, RootUser } from 'types/user'
import asyncThunk from 'utils/asyncThunk'

export type Request = {
  query: string
  morphed: boolean
}

export type BootstrappedUser<T extends Role = Role> = T extends 'member'
  ? MemberUser
  : T extends 'admin'
  ? AdminUser
  : T extends 'parent'
  ? ParentUser
  : T extends 'federation'
  ? FederationUser
  : T extends 'root'
  ? RootUser
  : T extends 'alumni'
  ? AlumniUser
  : AnyUser

export const bootstrap = asyncThunk<Request, BootstrappedUser>({
  name: 'auth/bootstrap',
  uri: '/users/me',
  method: 'GET',
})
