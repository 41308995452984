import { useMemo } from 'react'
import { useAuthSelector } from 'store'

import { LegacyContextType } from 'contexts/legacy'
import { AdminUser, AlumniUser, FederationUser, MemberUser, ParentUser, RootUser } from 'types/user'
import { isAdmin, isAlumni, isFederation, isMember, isParent, isSuper } from 'types/user-functions'

export default function useLegacyContext(): LegacyContextType {
  const { user } = useAuthSelector()

  return useMemo(() => {
    const context: LegacyContextType = {
      user: user!,
    }

    if (user?.role === 'member' || user?.role === 'admin') {
      context.organization = user.member.organization
      context.member = user.member
      context.owner = {
        owner_type: 'Organization',
        owner_id: user.member.organization.id,
      }
    }

    if (user?.role === 'alumni') {
      context.owner = {
        owner_type: 'Organization',
        owner_id: user.alumni.organization.id,
      }
    }

    if (user?.role === 'federation') {
      context.owner = {
        owner_type: 'Federation',
        owner_id: user.federation.id,
      }
    }

    return context
  }, [user])
}

export function useLegacyMember(): MemberUser {
  const { user } = useAuthSelector()
  if (!user || !isMember(user)) {
    throw new Error('user is not a member')
  }
  return user
}

export function useLegacyAdmin(): AdminUser {
  const { user } = useAuthSelector()
  if (!user || !isAdmin(user)) {
    throw new Error('user is not an admin')
  }
  return user
}

export function useLegacyMemberOrAdmin(): MemberUser | AdminUser {
  const { user } = useAuthSelector()
  if (!user || (!isMember(user) && !isAdmin(user))) {
    throw new Error('user is not a member or  admin')
  }
  return user
}

export function useLegacySuper(): RootUser {
  const { user } = useAuthSelector()
  if (!user || !isSuper(user)) {
    throw new Error('user is not a super')
  }
  return user
}

export function useLegacyParent(): ParentUser {
  const { user } = useAuthSelector()
  if (!user || !isParent(user)) {
    throw new Error('user is not a parent')
  }
  return user
}

export function useLegacyFederation(): FederationUser {
  const { user } = useAuthSelector()
  if (!user || !isFederation(user)) {
    throw new Error('user is not a federation')
  }
  return user
}

export function useLegacyAlumni(): AlumniUser {
  const { user } = useAuthSelector()
  if (!user || !isAlumni(user)) {
    throw new Error('user is not an alum')
  }
  return user
}
