import { push, replace } from 'connected-react-router'
import { Moment } from 'moment'
import { Dispatch } from 'react'

import { closeModal, openModal } from 'hooks/useModal'
import { HalfYearsState } from 'slices/halfYears'
import { bootstrap } from 'thunks/auth/bootstrap'
import bootstrapQuery from 'thunks/auth/bootstrap-query'
import setDateRange from 'thunks/date-range/set'
import downloadPDFBill from 'thunks/downloads/pdf-bill'
import setHalfYearDateRange from 'thunks/half-year-date-range/set'
import setActiveParent from 'thunks/parents/set-active-parent'
import setActiveSidebarItem from 'thunks/ui/set-active-sidebar-item'
import { Role } from 'types/user'
import { Request } from 'utils/apiRequest'
import notify, { Notification } from 'utils/notify'

import * as API from './api'
import * as CalendarActions from './calendar'
import * as DownloadActions from './download'
import * as HalfYearsActions from './half-years'
import * as OrganizationActions from './organization'
import * as RequiredActions from './required-actions'

export type Actions = ReturnType<typeof actions>

function actions(dispatch: Dispatch<any>) {
  return {
    actions: {
      fetchOrganization: (organizationId: number) => dispatch(OrganizationActions.fetchOrganization(organizationId)),
      setOrganization: (organization: Record<string, never>) =>
        dispatch(OrganizationActions.setOrganization(organization)),
      clearOrganization: () => dispatch(OrganizationActions.clearOrganization()),

      setActiveSidebarItem: (title: string) => dispatch(setActiveSidebarItem(title)),

      refreshUser: () =>
        dispatch(
          bootstrap({
            query: bootstrapQuery,
            morphed: !!localStorage.getItem('rootToken'),
          })
        ),

      openModal: (modal: string, id?: string) => openModal(modal, id),
      closeModal: (opts: any) => closeModal(opts),

      setCalendarEvent: (event: Record<string, never>) => dispatch(CalendarActions.setCalendarEvent(event)),
      clearCalendarEvent: () => dispatch(CalendarActions.clearCalendarEvent()),

      apiRequest: (payload: Request) => dispatch(API.apiRequest(payload)),

      notify,
      notifyAndRedirect: (payload: Notification & { location: string }) => {
        notify(payload)
        dispatch(push(payload.location))
      },

      // @ts-ignore
      routePush: (...args: string[]) => dispatch(push(...args)),
      // @ts-ignore
      routeReplace: (...args: never) => dispatch(replace(...args)),

      downloadFederationMembershipReport: (reportSubmissionId: number) =>
        dispatch(DownloadActions.downloadFederationMembershipReport(reportSubmissionId)),
      requiredDocumentCSV: (requiredDocumentId: number) =>
        dispatch(DownloadActions.requiredDocumentCSV(requiredDocumentId)),
      downloadAllTransfers: (federationId: number) => dispatch(DownloadActions.downloadAllTransfers(federationId)),
      downloadPDFBill: (memberId: number) => dispatch(downloadPDFBill({ memberId })),
      downloadRequiredDocumentAcceptance: (id: number) =>
        dispatch(DownloadActions.downloadRequiredDocumentAcceptance(id)),
      downloadRegistrationCSV: (organizationId: number, id: number) =>
        dispatch(DownloadActions.registrationCSV({ organizationId, id })),
      downloadTransactionsCSV: (queryString: { txn_type: string[]; created_by_role: Role }) =>
        dispatch(DownloadActions.transactionsCSV(queryString)),
      downloadMembersCSV: (queryString: Record<string, any>) => dispatch(DownloadActions.membersCSV(queryString)),
      downloadDonationsCSV: () => dispatch(DownloadActions.donationsCSV()),
      openNewBrowserTab: (url: string) => dispatch(DownloadActions.openNewBrowserTab(url)),

      fetchRequiredActions: () => dispatch(RequiredActions.fetchRequiredActions()),

      ensureHalfYears: () => dispatch(HalfYearsActions.ensureHalfYears()),
      setHalfYears: (halfYears: HalfYearsState) => dispatch(HalfYearsActions.setHalfYears(halfYears)),

      setHalfYearDateRange: ({ start, end }: { start: Moment; end: Moment }) =>
        dispatch(setHalfYearDateRange({ start, end })),
      setDateRange: ({ start, end }: { start: Moment; end: Moment }) => dispatch(setDateRange({ start, end })),

      setActiveParent: (id: number) => dispatch(setActiveParent({ id })),
    },
  }
}

export default actions
