import moment, { Moment } from 'moment'
import { FC, FormEvent, KeyboardEvent, useCallback, useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'

import Calendar from 'components/date-range/Calendar'
import { Field } from 'components/form'
import modal from 'helpers/modal'
import { closeModal } from 'hooks/useModal'

import { ProposedPayment } from './common'

type Props = {
  defaultValue: number
  defaultDate: string | undefined
  onSuccess: (payment: ProposedPayment) => void
}

const AddPaymentModal: FC<Props> = ({ defaultValue, defaultDate, onSuccess }) => {
  const [amount, setAmount] = useState(defaultValue)
  const [dueOn, setDueOn] = useState(() => moment(defaultDate) ?? moment().add(1, 'day'))

  useEffect(() => {
    setAmount(defaultValue)
  }, [defaultValue])

  const onSubmit = useCallback(
    (e: FormEvent) => {
      e.preventDefault()
      onSuccess({ amount, due_on: dueOn.format('YYYY-MM-DD') })
      closeModal()
    },
    [amount, dueOn, onSuccess]
  )

  const onAmountChange = useCallback((formatted: string) => {
    const amount = formatted.replace(/[^0-9.]+/, '')
    setAmount(+amount)
  }, [])

  const preventEnter = useCallback((e: KeyboardEvent) => {
    if (e.keyCode === 13) {
      e.stopPropagation()
      e.preventDefault()
    }
  }, [])

  return (
    <Modal show onHide={closeModal}>
      <form className="form-horizontal" autoComplete="off" onSubmit={onSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>Add a payment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Field
            name="amount"
            label="Amount"
            money
            onChange={onAmountChange}
            onKeyDown={preventEnter}
            defaultValue={amount}
            inputSize={4}
            autoFocus
          />

          <div className="clearfix">
            <Field name="due_on" label="Due on">
              <Calendar
                firstDayOfWeek={0}
                date={dueOn}
                onChange={(date: Moment) => setDueOn(date)}
                minDate={moment().utcOffset(-4).add(1, 'day')}
              />
            </Field>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button type="submit" bsStyle="primary" disabled={amount <= 0}>
            Add payment
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}

export default modal<Props>('AddPayment', AddPaymentModal)
