import asyncThunk from 'utils/asyncThunk'

export type Request = {
  query: string
  id: number
}

export const retrieveMessageCategoryFolder = asyncThunk<Request, any>({
  name: 'message-category-folders/retrieve',
  uri: ({ id }) => `/message_category_folders/${id}`,
})
