import asyncThunk from 'utils/asyncThunk'

export type Request = {
  query: string
  is_approved?: boolean
  hour_requirement_id: number
  member_id: number
}

export const listHourRecords = asyncThunk<Request, any>({
  name: 'hour-records/list',
  uri: '/hour_records',
})
