import cx from 'classnames'
import { FC, Fragment, useCallback, useEffect } from 'react'
import { Button, ButtonToolbar } from 'react-bootstrap'

import AddBankAccountButton from 'components/add-bank-account-button'
import { AddCreditCardModal } from 'components/modals'
import {
  Body,
  Loading,
  Money,
  PaymentMethod as PaymentMethodComponent,
  Percentage,
  Portlet,
} from 'components/utilities'
import * as payments from 'helpers/payments'
import useList from 'hooks/useList'
import { openModal } from 'hooks/useModal'
import { listPaymentMethods, Request as PaymentMethodRequest } from 'thunks/payment-methods/list'

import { Acceptance, Errors } from './index'

type PaymentMethod = {
  id: number
  method: 'card' | 'bank'
  company: string
  last_four: string
}

type Props = {
  acceptance: {
    payment_plan_option: number
    payment_method_id?: number
  }
  hasUpcharge: boolean
  errors: Errors
  userId: number
  onUpdateAcceptance: (key: 'payment_method_id', value: Acceptance[keyof Acceptance]) => void
}

const pMeQuery = `
  payment_method {
    method
    company
    last_four
  }
`

const PaymentMethodSelector: FC<Props> = ({ acceptance, hasUpcharge, userId, onUpdateAcceptance, errors }) => {
  const [paymentMethods, { add: addPaymentMethod }] = useList<PaymentMethod, PaymentMethodRequest>(listPaymentMethods, {
    query: pMeQuery,
    user_id: userId,
  })

  useEffect(() => {
    if (!paymentMethods) return
    if (paymentMethods.length === 0) return
    if (acceptance.payment_method_id) return
    if (paymentMethods[0].id === acceptance.payment_method_id) return

    onUpdateAcceptance('payment_method_id', paymentMethods[0].id)
  }, [acceptance.payment_method_id, onUpdateAcceptance, paymentMethods])

  const onPaymentMethodCreate = useCallback(
    (paymentMethod: PaymentMethod) => {
      addPaymentMethod(paymentMethod)

      onUpdateAcceptance('payment_method_id', paymentMethod.id)
    },
    [addPaymentMethod, onUpdateAcceptance]
  )

  if (!paymentMethods) return <Loading />

  return (
    <Fragment>
      <Portlet boxed>
        <Body>
          <h5>Please select a payment method:</h5>

          {paymentMethods.map(paymentMethod => (
            <h6
              key={paymentMethod.id}
              className="c-pointer payment-method"
              onClick={() => onUpdateAcceptance('payment_method_id', paymentMethod.id)}
            >
              <input
                type="radio"
                name="payment_plan.payment_method_id"
                className="m-r-1"
                value={paymentMethod.id}
                checked={paymentMethod.id === acceptance.payment_method_id}
                onChange={() => { }}
              />
              <PaymentMethodComponent paymentMethod={paymentMethod} />
            </h6>
          ))}

          <ButtonToolbar className={cx('m-b-05', { 'm-t-2': !_.isEmpty(paymentMethods) })}>
            <Button onClick={openModal('AddCreditCard')} bsSize="xs">
              Add credit card
            </Button>
            <AddBankAccountButton bsSize="xs" noIcon onCreate={onPaymentMethodCreate} />
          </ButtonToolbar>

          {hasUpcharge ? (
            <div className="p-t-05 lh-18">
              There is a <Percentage value={payments.CC_UPCHARGE / 100.0} decimalPlaces={0} /> charge for credit cards
              and a <Money amount={payments.ACH_UPCHARGE} /> charge for e-checks (per installment).
              <br />
              Paying by cash or check will settle your balance without additional cost.
            </div>
          ) : (
            <div className="p-t-05 lh-18">
              There is a <Money amount={payments.ACH_UPCHARGE} /> charge for e-checks (per installment).
              <br />
              Paying by credit card, cash, or check will settle your balance without additional cost.
            </div>
          )}

          {errors.payment_method_id && (
            <p>
              <strong className="text-danger">Please select a payment method</strong>
            </p>
          )}
        </Body>
      </Portlet>

      <AddCreditCardModal onCreditCardCreate={onPaymentMethodCreate} />
    </Fragment>
  )
}

export default PaymentMethodSelector
