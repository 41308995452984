import classnames from 'classnames'
import React, { FC } from 'react'

import { FaIcon } from 'components/utilities/font-awesome'

const renderSort = (sorted: boolean | null | undefined) => {
  let icon = 'sort'
  if (_.isBoolean(sorted)) {
    icon = sorted ? 'sort-asc' : 'sort-desc'
  }

  return (
    <FaIcon
      icon={icon}
      className={classnames('m-r-05', {
        'o-25': _.isNull(sorted),
      })}
    />
  )
}

type Props = {
  noSort?: boolean
  sorted?: boolean | null
  width: number
  children: React.ReactNode
  align?: 'left' | 'center' | 'right'
  verticalAlign?: 'top' | 'middle' | 'bottom'
  onChangeSort?: () => void
}

const TableHeader: FC<Props> = props => {
  const { onChangeSort } = props
  const { noSort, sorted, width, children, align, verticalAlign } = props

  if (noSort) {
    return (
      <th className="no-sort" style={{ width: `${width}%`, textAlign: align }}>
        {children}
      </th>
    )
  }

  return (
    <th
      className={sorted === true ? 'dropup' : ''}
      onClick={onChangeSort}
      style={{
        width: `${width}%`,
        textAlign: align,
        verticalAlign,
      }}
    >
      {renderSort(sorted)}
      {children}
    </th>
  )
}

export default TableHeader
