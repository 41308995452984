import { AppState } from 'store'

import { createAsyncThunk } from '@reduxjs/toolkit'

import { RequiredActionsState } from 'slices/requiredActions'
import apiRequest from 'utils/apiRequest'

export type Request = Record<string, never>

export type Response = {
  data: RequiredActionsState
}

const fetchRequiredActions = createAsyncThunk<Response, Request>('required-actions/fetch', async (req, api) => {
  const {
    auth: { user },
  } = api.getState() as AppState
  if (!user) throw new Error('no user for required actions')

  let uri
  switch (user.role) {
    case 'member':
    case 'admin':
      uri = `members/${user.member.id}`
      break
    case 'alumni':
      uri = `alumnis/${user.alumni.id}`
      break
    default:
      uri = `users/${user.id}`
  }

  return apiRequest({ method: 'GET', uri: `/api/${uri}/required_actions` })
})

export default fetchRequiredActions
