import { FC, Fragment, useEffect, useState } from 'react'
import { Radio } from 'react-bootstrap'

import { Visibility } from 'types/visibility'

import ExecutiveBoardPositions from './_ExecutiveBoardPositions'
import Organizations from './_Organizations'

export type RecipientType =
  | 'members_and_alumni'
  | 'federation_members'
  | 'federation_alumni'
  | 'organization_members'
  | 'organization_admins'
  | 'executive_board_positions'

const allOptions = [
  {
    label: 'All members (active + alumni)',
    value: 'members_and_alumni',
  },
  {
    label: 'All active undergraduate members',
    value: 'federation_members',
  },
  {
    label: 'All alumni',
    value: 'federation_alumni',
  },
  {
    label: 'Chapter members',
    value: 'organization_members',
  },
  {
    label: 'Chapter admins',
    value: 'organization_admins',
  },
  {
    label: 'One or more executive board positions',
    value: 'executive_board_positions',
  },
] as const

type Props = {
  value: Visibility<RecipientType>
  options: RecipientType[]
  onChange: (visibility: Visibility<RecipientType>) => void
}

const VisibilitySelector: FC<Props> = ({ value, options, onChange }) => {
  const [visibility, setVisibility] = useState<RecipientType>(value.visibility)
  const [organizationIds, setOrganizationIds] = useState<number[]>(value.permitted_organization_ids || [])
  const [positionIds, setPositionIds] = useState<number[]>(value.permitted_position_ids || [])

  useEffect(() => {
    onChange({
      visibility,
      permitted_organization_ids: ['organization_members', 'organization_admins'].includes(visibility)
        ? organizationIds
        : undefined,
      permitted_position_ids: visibility === 'executive_board_positions' ? positionIds : undefined,
    })
  }, [visibility, organizationIds, positionIds, onChange])

  return (
    <div>
      {allOptions.map(
        ({ label, value: option }) =>
          options.includes(option) && (
            <Fragment key={option}>
              <Radio checked={value.visibility === option} name="visibility" onChange={() => setVisibility(option)}>
                {label}
              </Radio>

              {value.visibility === option && value.visibility === 'organization_members' && (
                <Organizations value={organizationIds} onChange={setOrganizationIds} />
              )}

              {value.visibility === option && value.visibility === 'organization_admins' && (
                <Organizations value={organizationIds} onChange={setOrganizationIds} />
              )}

              {value.visibility === option && value.visibility === 'executive_board_positions' && (
                <ExecutiveBoardPositions value={positionIds} onChange={setPositionIds} />
              )}
            </Fragment>
          )
      )}
    </div>
  )
}

export default VisibilitySelector
