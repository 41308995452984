import { connect, orgHelpers } from 'helpers'
import { FC, useMemo } from 'react'
import { Grid } from 'react-bootstrap'

import { Body, Content, Header, Loading, Name, Portlet } from 'components/utilities'
import Phone from 'components/utilities/phone'
import useActiveSidebarItem from 'hooks/useActiveSidebarItem'
import { useLegacyMember } from 'hooks/useLegacyContext'
import useList from 'hooks/useList'
import { listStaffMembers, Request } from 'thunks/staff-members/list'
import { listUsers, Request as UserRequest } from 'thunks/users/list'

import { StaffMember, User } from './type'
// @ts-ignore
import { staffMemberQuery, userQuery } from './type?query'

const ImportantContactsIndex: FC = () => {
  const { member } = useLegacyMember()
  useActiveSidebarItem('Important Contacts')

  const [allStaffMembers] = useList<StaffMember, Request>(listStaffMembers, {
    query: staffMemberQuery,
    organization_id: member.organization.id,
    federation_id: member.organization.federation.id,
  })

  const [allUsers] = useList<User, UserRequest>(listUsers, {
    query: userQuery,
    owner_id: member.organization.federation.id,
    owner_type: 'Federation',
  })

  const staffMembers: StaffMember[] | undefined = useMemo(() => {
    if (!allStaffMembers || !allUsers) return

    return allUsers
      .map(u => ({
        ...u,
        staff_position: {
          id: 0,
          title: 'National Officer',
        },
      }))
      .concat(...allStaffMembers)
      .sort((a, b) => {
        const left = `${a.last_name} ${a.first_name}}`
        const right = `${b.last_name} ${b.first_name}}`
        return left.localeCompare(right)
      })
  }, [allStaffMembers, allUsers])

  if (!staffMembers) return <Loading />

  return (
    <Content>
      <Grid>
        <Header>Important contacts</Header>
        <Portlet boxed>
          <Body>
            <dl className="dl-horizontal list-spaced">
              {staffMembers.map(user => (
                <div key={user.id} className="staff-position">
                  <dt>{user.staff_position ? user.staff_position.title : 'National Officer'}</dt>
                  <dd className="lh-18 m-b-0">
                    {user.staff_position ? orgHelpers.formatStaffPositionMemberName(user) : <Name user={user} />}
                    <br />
                    {user.email}
                    {user.phone_number && (
                      <>
                        <br />
                        <Phone>{user.phone_number}</Phone>
                      </>
                    )}
                  </dd>
                </div>
              ))}
            </dl>
          </Body>
        </Portlet>
      </Grid>
    </Content>
  )
}

export default connect(ImportantContactsIndex)
