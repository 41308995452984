import asyncThunk from 'utils/asyncThunk'

export type Request = {
  query: string
  message: {
    message_thread_id: number
    body: string
  }
}

export const createMessage = asyncThunk<Request, any>({
  name: 'messages/create',
  uri: '/messages',
  method: 'POST',
})
