import { FC, ReactNode } from 'react'
import { Grid } from 'react-bootstrap'

import { Content, Loading } from 'components/utilities'

type PortletProps = {
  processing?: boolean
  boxed?: boolean
  className?: string
  children: ReactNode
}

const Portlet: FC<PortletProps> = props => {
  if (props.processing) {
    return (
      <Content>
        <Grid>
          <Loading />
        </Grid>
      </Content>
    )
  }

  const classes = ['portlet']
  if (props.className) {
    classes.push(props.className)
  }
  if ('boxed' in props) {
    classes.push('portlet-boxed')
  }

  return <div className={classes.join(' ')}>{props.children}</div>
}

type BodyProps = {
  table?: boolean
  pagination?: boolean
  className?: string
  children: ReactNode
}

const Body: FC<BodyProps> = props => {
  const classes = ['portlet-body']
  if (props.className) {
    classes.push(props.className)
  }
  if ('table' in props) {
    classes.push('table-responsive')
  }
  if ('pagination' in props) {
    classes.push('with-actions')
  }

  return <div className={classes.join(' ')}>{props.children}</div>
}

export { Body }

export default Portlet
