import { FC } from 'react'
import { useAppDispatch } from 'store'

import { Field } from 'components/form'
import { FaButton } from 'components/utilities'
import downloadPDFBill from 'thunks/downloads/pdf-bill'
import { Member } from 'types/user'

type Props = {
  member: Pick<Member, 'id' | 'unique_id'>
}

const CheckInstructions: FC<Props> = ({ member }) => {
  const dispatch = useAppDispatch()
  function handleDownloadPDF() {
    dispatch(downloadPDFBill({ memberId: member.id }))
  }

  return (
    <Field className="m-b-0 m-t-2" name="payment.destination" label="Send checks to:" inputSize={9}>
      Greek Capital Management
      <br />
      PO Box 41027
      <br />
      Bethesda, MD 20824
      <div className="fs-125 m-t-05 m-b-05">
        <strong>Memo line:</strong> {member.unique_id}
      </div>
      <FaButton icon="download" bsSize="sm" onClick={handleDownloadPDF}>
        Download bill PDF
      </FaButton>
    </Field>
  )
}

export default CheckInstructions
