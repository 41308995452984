import { apiRequest } from 'actions/use'

import apiWrapper, { generatePayload, Request } from './helpers'

export const ATTENDANCE_FEATURE = 'Attendance'
export const CALENDAR_FEATURE = 'Calendar'
export const DOCUMENTS_FEATURE = 'Documents'
export const EVENTS_FEATURE = 'Events'
export const HOUR_TRACKER_FEATURE = 'Hour tracker'
export const MESSAGING_FEATURE = 'Messaging'
export const RUSH_FEATURE = 'Rush'
export const MESSAGE_BOARD_FEATURE = 'Message Board'
export const ALUMNI_GROUPS_FEATURE = 'Alumni Groups'
export const VOTING_FEATURE = 'Voting'
export const FINANCES_FEATURE = 'Finances'
export const BUDGET_APPROVAL = 'Budget approval'
export const WEBSITE_FEATURE = 'Website'

export const FEATURES = [
  ATTENDANCE_FEATURE,
  CALENDAR_FEATURE,
  DOCUMENTS_FEATURE,
  EVENTS_FEATURE,
  HOUR_TRACKER_FEATURE,
  MESSAGING_FEATURE,
  RUSH_FEATURE,
  WEBSITE_FEATURE,
  VOTING_FEATURE,
]

export const FEDERATION_TRANSFER_EMAILS = 'Transfer emails'
export const FEDERATION_BILLPAY = 'BillPay'
export const FEDERATION_WEEKLY_DONATION_EMAILS = 'Weekly donation emails'
export const GRANT_PERMISSION = 'Grant permission'

export const SUPER_FEDERATION_FEATURES = [
  FEDERATION_TRANSFER_EMAILS,
  FEDERATION_BILLPAY,
  FEDERATION_WEEKLY_DONATION_EMAILS,
  GRANT_PERMISSION,
]

export const FEDERATION_FEATURES = [
  FINANCES_FEATURE,
  BUDGET_APPROVAL,
  CALENDAR_FEATURE,
  DOCUMENTS_FEATURE,
  MESSAGE_BOARD_FEATURE,
  MESSAGING_FEATURE,
  ALUMNI_GROUPS_FEATURE,
  VOTING_FEATURE,
  EVENTS_FEATURE,
  FEDERATION_TRANSFER_EMAILS,
  FEDERATION_BILLPAY,
  FEDERATION_WEEKLY_DONATION_EMAILS,
]

export default class Feature extends apiWrapper('/features') {
  static remove({ data, query, onSuccess, onFailure }: Request) {
    apiRequest({
      method: 'DELETE',
      uri: '/api/features',
      data: generatePayload({ data, query }),
      on: {
        200: onSuccess,
      },
      onFailure,
    })
  }
}
