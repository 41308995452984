import React from 'react'
import PropTypes from 'prop-types'
import Cropper from 'react-easy-crop'
import ReactSlider from 'react-slider'
import { Button, Row, Col } from 'react-bootstrap'
import * as Sentry from '@sentry/react'

import { urltoFile, getCroppedImg } from '../../utils/image'

const { func, object } = PropTypes

export default class ImageCropper extends React.Component {
  static propTypes = {
    file: object.isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    onSuccess: func.isRequired,
  }

  state = {
    crop: { x: 0, y: 0 },
    zoom: 1,
    aspect: 1,
    cropShape: 'round',
  }

  onCropChange = crop => {
    this.setState({ crop })
  }

  onCropComplete = (croppedArea, croppedAreaPixels) => {
    this.setState({ croppedAreaPixels }, this.buildImage)
  }

  onZoomChange = zoom => {
    this.setState({ zoom })
  }

  onCropEnd = async () => {
    const { previewImage } = this.state
    const { onSuccess } = this.props

    try {
      onSuccess(previewImage)
    } catch (e) {
      Sentry.captureException(e)
    }
  }

  buildImage = async () => {
    const { croppedAreaPixels } = this.state
    const {
      file: { preview, type, name },
    } = this.props

    try {
      const croppedImage = await getCroppedImg(preview, croppedAreaPixels, type)
      const croppedFile = await urltoFile(croppedImage, name, type)
      croppedFile.preview = croppedImage

      this.setState({ previewImage: croppedFile })
    } catch (e) {
      Sentry.captureException(e)
    }
  }

  render() {
    const { crop, aspect, cropShape, zoom, previewImage } = this.state
    const {
      file: { preview },
    } = this.props

    return (
      <div className="image-cropper-container">
        <div className="crop-container">
          <Cropper
            image={preview}
            crop={crop}
            zoom={zoom}
            aspect={aspect}
            cropShape={cropShape}
            onCropChange={this.onCropChange}
            onCropComplete={this.onCropComplete}
            onZoomChange={this.onZoomChange}
          />
        </div>

        <Row className="m-t-1">
          <Col md={6} mdPush={3}>
            <ReactSlider
              ariaLabel="Zoom"
              className="horizontal-slider m-t-1"
              onChange={this.onZoomChange}
              value={zoom}
              min={1}
              max={3}
              step={0.1}
            />
          </Col>
        </Row>

        <div className="m-t-1 text-center">
          {previewImage && <img alt="preview" className="crop-preview" src={previewImage.preview} />}
          <Button onClick={this.onCropEnd} bsStyle="secondary" className="m-l-1">
            Finish upload
          </Button>
        </div>
      </div>
    )
  }
}
