import apiRequest from 'utils/apiRequest'

type Response = {
  download_token: string
}

export async function download(uri: string, params: Record<string, string | number> = {}) {
  const { data } = await apiRequest<void, Response>({
    method: 'GET',
    uri: '/api/user_download_token',
  })
  const token = data.download_token

  const p = new URLSearchParams()
  p.append('token', token)
  for (const key in params) {
    if (params[key] !== undefined) {
      p.append(key, `${params[key]}`)
    }
  }
  const query = p.toString()

  const url = `https://${import.meta.env.VITE_API_ORIGIN}/download${uri}?${query}`
  try {
    window.openRef(url, '_blank')
  } catch (e) {
    window.location.href = url
  }
}
