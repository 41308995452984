import { FC } from 'react'
import { Event } from 'components/event/registration-form/type'

import EventQuestion from './_Question'

type Props = {
  questions: Event['event_questions']
}

const CustomEventQuestions: FC<Props> = ({ questions }) => {
  if (!questions || questions.length === 0) return <></>

  return (
    <>
      <hr />

      <ul className="list-unstyled">
        {questions.map((question, i) => (
          <li key={i}>
            <EventQuestion idx={i} question={question} name={`event_answers.${i}`} />
          </li>
        ))}
      </ul>
    </>
  )
}

export default CustomEventQuestions
