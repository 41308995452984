import { createSlice } from '@reduxjs/toolkit'

import logout from 'thunks/auth/logout'
import fetchRequiredActions from 'thunks/required-actions/fetch'

export type Role = 'member' | 'admin' | 'super' | 'alumni' | 'federation' | 'parent'

export type RequiredActionsState = {
  payment_plans: {
    proposed: boolean
    waiting: number
  }
  reimbursements: {
    pending: number
    proposed: number
  }
  billpay: {
    waiting: number
  }
  profile: {
    complete: boolean
  }
  votes: {
    needed: number
  }
  hour_tracker: {
    required: number
    pending: number
  }
  admin_hour_tracker: {
    required: number
    pending: number
  }
  organization_payment_plans: {
    count: number
  }
  reports: {
    count: number
  }
}

const initialState: RequiredActionsState = {
  payment_plans: {
    proposed: false,
    waiting: 0,
  },
  reimbursements: {
    pending: 0,
    proposed: 0,
  },
  billpay: {
    waiting: 0,
  },
  profile: {
    complete: true,
  },
  votes: {
    needed: 0,
  },
  hour_tracker: {
    required: 0,
    pending: 0,
  },
  admin_hour_tracker: {
    required: 0,
    pending: 0,
  },
  organization_payment_plans: {
    count: 0,
  },
  reports: {
    count: 0,
  },
}

const slice = createSlice({
  name: 'required-actions',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchRequiredActions.fulfilled, (state, { payload }) => {
      return payload.data
    })
    builder.addCase(logout.fulfilled, () => {
      return { ...initialState }
    })
  },
})

export default slice
export const actions = slice.actions
