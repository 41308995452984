import mixpanel from 'mixpanel-browser'

import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as Sentry from '@sentry/react'

import { bootstrap } from 'thunks/auth/bootstrap'
import { login } from 'thunks/auth/login'
import logout from 'thunks/auth/logout'
import { usePasswordReset } from 'thunks/password-reset/use'
import { AnyUser } from 'types/user'

export type AuthState = {
  token?: string
  user?: AnyUser
}

const initialState: AuthState = {
  token: localStorage.getItem('token') || undefined,
}

const setJWT: CaseReducer<AuthState, PayloadAction<string>> = (state, action) => {
  localStorage.setItem('token', action.payload)
  return {
    token: action.payload,
  }
}

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: { setJWT },
  extraReducers: builder => {
    builder.addCase(login.fulfilled, (state, { payload }) => {
      localStorage.setItem('token', payload.data.jwt)
      return {
        token: payload.data.jwt,
      }
    })
    builder.addCase(usePasswordReset.fulfilled, (state, { payload }) => {
      localStorage.setItem('token', payload.data.jwt)
      return {
        token: payload.data.jwt,
      }
    })
    builder.addCase(bootstrap.fulfilled, (state, { payload }) => {
      console.log('===', payload.data.id, payload.data.first_name, payload.data.last_name, '===')

      Sentry.setUser({
        id: '' + payload.data.id,
        name: `${payload.data.first_name} ${payload.data.last_name}`,
        email: payload.data.email,
        segment: payload.data.role,
      })

      mixpanel.identify(`${payload.data.id}`)
      mixpanel.people.set({
        $first_name: payload.data.first_name,
        $last_name: payload.data.last_name,
        $email: payload.data.email,
        Role: payload.data.role,
        OrganizationId: payload.data.member?.organization?.id,
        FederationId: payload.data.federation?.id,
      })
      if (payload.data.role === 'admin') {
        mixpanel.add_group('Admins', 'admins')
      }

      return {
        ...state,
        user: payload.data,
      }
    })
    builder.addCase(logout.pending, () => {
      localStorage.removeItem('token')
      localStorage.removeItem('rootToken')
      return {}
    })
    builder.addCase(logout.fulfilled, () => {
      Sentry.setUser(null)
      return {}
    })
  },
})

export default slice
export const actions = slice.actions
