import React, { FC, Suspense } from 'react'
import { Toaster } from 'react-hot-toast'
import { Route, Switch } from 'react-router-dom'

import * as Sentry from '@sentry/react'

import AppWrapper from 'components/layout/app-wrapper'
import { Loading } from 'components/utilities'
import LegacyContext from 'contexts/legacy'
import useAuth from 'hooks/useAuth'
import useGoogleAnalytics from 'hooks/useGoogleAnalytics'
import useLegacyContext from 'hooks/useLegacyContext'
import Error from 'pages/Shared/Error'
import MemberRoutes from 'routes/member'

import './App.scss'

const AdminRoutes = React.lazy(() => import('routes/admin'))
const ParentRoutes = React.lazy(() => import('routes/parent'))
const SuperRoutes = React.lazy(() => import('routes/super'))
const UnauthenticatedRoutes = React.lazy(() => import('routes/unauthenticated'))
const FederationRoutes = React.lazy(() => import('routes/federation'))
const AlumniRoutes = React.lazy(() => import('routes/alumni'))

const App: FC = () => {
  useAuth()
  useGoogleAnalytics()
  const context = useLegacyContext()

  return (
    <LegacyContext.Provider value={context}>
      <Sentry.ErrorBoundary fallback={props => <Error {...props} />}>
        <Suspense fallback={<Loading className="mt-2" />}>
          <AppWrapper>
            <Switch>
              <Route path="/admin" component={AdminRoutes} />
              <Route path="/alumni" component={AlumniRoutes} />
              <Route path="/federation" component={FederationRoutes} />
              <Route path="/member" component={MemberRoutes} />
              <Route path="/parent" component={ParentRoutes} />
              <Route path="/super" component={SuperRoutes} />
              <Route component={UnauthenticatedRoutes} />
            </Switch>
          </AppWrapper>
        </Suspense>
      </Sentry.ErrorBoundary>
      <Toaster containerClassName="toaster !z-[99999]" />
    </LegacyContext.Provider>
  )
}

export default App
