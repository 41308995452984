import Braintree, { BraintreeError } from 'braintree-web'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { PaymentMethod } from 'resources'

import * as Sentry from '@sentry/react'

import { ActionTimer } from 'hooks/useActionTimer'
import { useAPI } from 'hooks/useAPI'
import useLegacyContext from 'hooks/useLegacyContext'
import useScript from 'hooks/useScript'
import { createPayPalPayment } from 'thunks/payments/paypal'

const usePaypalPayment = (memberId: number, timer: ActionTimer) => {
  const scriptOne = useScript('https://www.paypalobjects.com/api/checkout.js')
  const scriptTwo = useScript('https://js.braintreegateway.com/web/3.6.2/js/client.min.js')
  const scriptThree = useScript('https://js.braintreegateway.com/web/3.6.2/js/paypal.min.js')

  const isReady = useMemo(
    () => scriptOne === 'ready' && scriptTwo === 'ready' && scriptThree === 'ready',
    [scriptOne, scriptTwo, scriptThree]
  )

  const { user } = useLegacyContext()
  const [instance, setInstance] = useState<Braintree.PayPal>()

  useEffect(() => {
    if (!isReady) return

    PaymentMethod.authorization({
      onSuccess: ({ data: { authorization } }: { data: { authorization: string } }) => {
        Braintree.client.create({ authorization }, (clientErr, clientInstance) => {
          if (clientErr) {
            Sentry.captureException(clientErr)
            return
          }

          Braintree.paypal.create({ client: clientInstance }, (paypalClientErr, paypalCheckoutInstance) => {
            if (paypalClientErr) {
              Sentry.captureException(paypalClientErr)
              return
            }

            setInstance(paypalCheckoutInstance)
          })
        })
      },
    })
  }, [isReady])

  const [create] = useAPI(createPayPalPayment)
  return useCallback(
    (amount: number) => {
      return new Promise<{ id: number }>((resolve, reject) => {
        if (!instance || !isReady) return reject('not ready to take a payment')

        timer.start()

        instance.tokenize(
          {
            flow: 'checkout',
            amount,
            currency: 'USD',
            intent: 'sale',
            useraction: 'commit',
          },
          async (err: BraintreeError | undefined, payload: { nonce: string } | undefined) => {
            if (err) {
              Sentry.captureException(err)
              reject(err)
              return
            }

            if (!payload) return

            const [payment] = await create({
              query: 'payment { id }',
              payment: {
                amount,
                braintree_nonce: payload.nonce,
                source: `web/${user?.role}`,
                member_id: memberId,
              },
            })

            resolve(payment)

            timer.succeeded()
          }
        )
      })
    },
    [instance, isReady, memberId, timer, create, user]
  )
}

export default usePaypalPayment
