import { FC, ReactElement, ReactNode, useMemo, useState } from 'react'
import { Col, Collapse, Row } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'

import Portlet, { Body } from 'components/utilities/portlet'
import { Feature, FederationFeature } from 'types/feature'
import { isMobile } from 'utils/isMobile'

import FeatureItem from './feature-item'
import Item, { Props as ItemProps } from './item'

type LinkProps = Omit<ItemProps, 'isActive'>

type Props = {
  items: ReactElement<{
    children: ReactElement<LinkProps>[]
  }>
  header?: ReactNode
  footer?: ReactNode
  level?: number
  children: ReactNode
}

const Settings: FC<Props> = ({ items, header, footer, level = 4, children }) => {
  const [isOpen, setIsOpen] = useState(!isMobile())

  const { pathname } = useLocation()
  const activePath = useMemo(() => {
    const parts = pathname.split('/')
    return parts[level]
  }, [pathname, level])

  const prefix = useMemo(() => {
    const parts = pathname.split('/')
    return parts.slice(0, level).join('/')
  }, [pathname, level])

  return (
    <div>
      <Portlet boxed>
        <Body className="p-b-0">
          <Row className="layout layout-main-right layout-stack-sm">
            <div
              className="sidebar-trigger sidebar-toggle visible-xs-block hidden-print"
              onClick={() => setIsOpen(!isOpen)}
            >
              <i className="fa fa-bars"></i>
            </div>

            <Collapse in={isOpen}>
              <Col xs={12} sm={4} md={3} className="layout-sidebar hidden-print !visible">
                {header}
                <ul className="nav nav-layout-sidebar nav-stacked">
                  {items.props.children
                    .flat()
                    .filter(c => !!c)
                    .map(({ props: { path, ...props } }) => (
                      <Item key={path} isActive={path === activePath} path={`${prefix}/${path}`} {...props} />
                    ))}
                </ul>
                {footer}
              </Col>
            </Collapse>

            <Col xs={12} sm={8} md={9} className="layout-main">
              <div className="tab-content stacked-content">{children}</div>
            </Col>
          </Row>
        </Body>
      </Portlet>
    </div>
  )
}

Object.assign(Settings, { Item, FeatureItem })

export default Settings as typeof Settings & {
  Item: FC<LinkProps>
  FeatureItem: FC<
    LinkProps & {
      feature: Feature | FederationFeature
    }
  >
}
