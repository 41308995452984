import asyncThunk from 'utils/asyncThunk'

type Answer = {
  answer: string
}

export type Request = {
  query: string
  required_document_acceptance: {
    required_document_id: number
    s3_path?: string
    answers?: Answer[]
  }
}

export const createRequiredDocumentAcceptance = asyncThunk<Request, any>({
  name: 'required-document-acceptances/create',
  uri: '/required_document_acceptances',
  method: 'POST',
})
