import { FC } from 'react'
import { Button } from 'react-bootstrap'

type Props = {
  isActive: boolean
  children: string
  onClick: () => void
}

const ToggleButton: FC<Props> = ({ isActive, children, onClick }) => {
  return (
    <Button onClick={onClick} active={isActive} bsStyle={isActive ? 'secondary' : 'default'}>
      {children}
    </Button>
  )
}

export default ToggleButton
