import { isGranted } from 'helpers'
import useLegacyContext from 'hooks/useLegacyContext'
import { DocumentFolderOwnerType, DocumentFolderPurpose } from 'types/document-folder'
import { isFederation } from 'types/user-functions'

type DocumentFolder = {
  id: number
  is_private: boolean
  owner_type: DocumentFolderOwnerType
  owner_id: number
  purpose: DocumentFolderPurpose
}

const useManageDocumentFolder = (folder: DocumentFolder) => {
  const { user } = useLegacyContext()

  // Shouldn't be handled by this hook
  if (folder.purpose === 'event') return false
  if (folder.purpose === 'taxes') return false

  if (folder.owner_type === 'Federation') {
    // Internal federation folders
    if (['shared', 'internal'].includes(folder.purpose)) {
      return isFederation(user)
    }
  }

  // Executive board folders, managed by federation users
  if (folder.owner_type === 'ExecutiveBoardPosition' && folder.purpose === 'federation') {
    return isFederation(user)
  }

  // Alumni group folders, managed by federation users
  if (folder.owner_type === 'Group' && folder.purpose === 'alumni') {
    return isFederation(user)
  }

  // Chapter folder, managed by federation users
  if (folder.owner_type === 'Organization' && folder.purpose === 'federation') {
    return isFederation(user)
  }

  if (folder.owner_type === 'Organization' && folder.purpose === null) {
    return isGranted(user, 'Documents')
  }

  return false
}

export default useManageDocumentFolder
