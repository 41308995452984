import asyncThunk from 'utils/asyncThunk'

export type Request = {
  query: string
  id: number
  organization_id?: number
}

export const retrieveEvent = asyncThunk<Request, any>({
  name: 'events/retrieve',
  method: 'GET',
  uri: ({ id }) => `/events/${id}`,
})
