import { FC, ReactNode, useMemo } from 'react'
import { Button, Col, Row, Well } from 'react-bootstrap'

import frownyFace from 'images/etc/frowny-face.svg'

type RenderActionsProps = { action: string; onClick: () => void }[]

const renderActions: FC<RenderActionsProps> = actions => {
  if (_.isEmpty(actions)) {
    return null
  }

  return (
    <p>
      {_.map(actions, ({ action, onClick }, i) => (
        <Button key={i} bsStyle="primary" bsSize="large" onClick={onClick} className="m-l-05 m-r-05">
          {action}
        </Button>
      ))}
    </p>
  )
}

export type EmptyStateProps = {
  message?: string
  action?: string
  modal?: string
  icon?: boolean
  size?: number
  actions?: { action: string; onClick: () => void }[]
  children?: ReactNode
  onClick?: () => void
}

const EmptyState: FC<EmptyStateProps> = ({ message, action, icon, size: origSize, actions, children, onClick }) => {
  const allActions = useMemo(() => {
    const list = [...(actions || [])]
    if (action && onClick) list.push({ action, onClick })
    return list
  }, [actions, action, onClick])

  const size = origSize ?? 8
  const padding = (12 - size) / 2

  return (
    <Row>
      <Col md={size} mdPush={padding}>
        <hr className="spacer-sm" />

        <Well bsSize="large" className="text-center empty-state pt-24">
          <h3 className="m-b-1">{message ?? children}</h3>
          {icon && (
            <span className="text-muted">
              <img src={frownyFace} height={80} alt="" />
            </span>
          )}
          <hr className="spacer-sm" />
          {renderActions(allActions)}
        </Well>
      </Col>
    </Row>
  )
}

export default EmptyState
