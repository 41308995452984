import asyncThunk from 'utils/asyncThunk'

export type Request = {
  query: string
  organization_id?: number
  federation_id: number
}

export const listCommitteePositions = asyncThunk<Request, any>({
  name: 'committee-positions/list',
  uri: '/committee_positions',
  method: 'GET',
})
