export const memberQuery = `
  member {
    id
    unique_id
    is_admin
    alternate_email
    graduation_year
    status
    custom_fields
    no_physical_bills
    created_at
    data {
      term_when_joined
    }
    organization {
      id
      name
      secondary
      cc_upcharge
      federation {
        id
        member_types
      }
    }
    alumni {
      id
      created_at
    }
    user {
      id
      email
      phone_number
      first_name
      last_name
      address {
        address
        address_2
        city
        state
        postal_code
        country
      }
      avatar {
        id
        public_url
      }
    }
  }
`
