import { FC } from 'react'

type PaymentMethodType = {
  id: number
  method: 'card' | 'bank'
  company: string
  last_four: string
}

type Props = {
  paymentMethod: PaymentMethodType
  className?: string
}

const PaymentMethod: FC<Props> = ({ paymentMethod, className }) => (
  <span className={className}>
    {paymentMethod.method === 'bank' && `${paymentMethod.company} *${paymentMethod.last_four}`}
    {paymentMethod.method === 'card' && `${paymentMethod.company} ending in ${paymentMethod.last_four}`}
  </span>
)

export default PaymentMethod
