import Humanize from 'humanize-plus'
import { FC } from 'react'

type Props = {
  count: number
  noun: string
  plural?: string
  decimals?: number
}

const Units: FC<Props> = ({ count, noun, plural, decimals }) => {
  if (!decimals && `${count}`.match(/\./)) {
    // eslint-disable-next-line no-param-reassign
    decimals = `${count}`.split('.')[1].length
  }
  const rounded = Humanize.formatNumber(count, decimals || 0)
  const pluralized = Humanize.pluralize(count || -1, noun, plural)
  return <span>{`${rounded} ${pluralized}`}</span>
}

export default Units
