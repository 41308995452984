export const messageCategoryQuery = `
  message_category {
    id
    name
    description
    recipient_descriptions
    created_at
    owner_type
    owner_id
    message_category_folders {
      id
      name
      data {
        thread_count
      }
    }
  }
`
export const messageThreadQuery = `
  message_thread {
    id
    name
    updated_at
    created_at
    data {
      replies
    }
    creator {
      id
      first_name
      last_name
      avatar {
        id
        url
      }
    }
  }
`
export const messageQuery = `
  message {
    id
    body
    created_at
    creator {
      id
      first_name
      last_name
      avatar {
        id
        url
      }
    }
  }
`
