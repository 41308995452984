import { connectWithDateRange } from 'helpers'
import { FC, Fragment, useRef } from 'react'
import { Col, Grid, Row } from 'react-bootstrap'

import AddBudgetExpenseModal from 'components/budgets/add-budget-expense-modal'
import BudgetsTable from 'components/budgets/budgets-table'
import { Budget } from 'components/budgets/budgets-table/type'
// @ts-ignore
import { budgetQuery } from 'components/budgets/budgets-table/type?query'
import { downloadCSV } from 'components/table'
import { TableRef } from 'components/table-v2'
import TermSelector from 'components/terms/selector'
import {
  Body,
  ButtonBar,
  Content,
  EmptyState,
  Header,
  Loading,
  Portlet,
  PrintAction,
  TableAction,
} from 'components/utilities'
import { ConnectWithDateRangeProps } from 'helpers/date-range'
import useActiveSidebarItem from 'hooks/useActiveSidebarItem'
import { useLegacyMember } from 'hooks/useLegacyContext'
import useList from 'hooks/useList'
import { listBudgets, Request } from 'thunks/budgets/list'

type Props = ConnectWithDateRangeProps

const MemberBudgets: FC<Props> = ({ dateRange }) => {
  useActiveSidebarItem('Budgets')
  const { member } = useLegacyMember()
  const ref = useRef<TableRef>(null)

  const [budgets, { refetch, timer }] = useList<Budget, Request>(listBudgets, {
    query: budgetQuery,
    owner_type: 'Organization' as const,
    owner_id: member.organization.id,
    start_date: dateRange.start.format('YYYY-MM-DD'),
    end_date: dateRange.end.format('YYYY-MM-DD'),
  })

  if (!budgets) return <Loading />

  return (
    <div>
      <Content>
        <Grid>
          <div>
            <Header>Budgets</Header>

            <Portlet boxed>
              <Body table>
                {budgets.length > 0 ? (
                  <BudgetsTable ref={ref} budgets={budgets} />
                ) : (
                  <EmptyState message="You haven't been added to any budgets yet" />
                )}
              </Body>
            </Portlet>

            <Row>
              <Col sm={9} lg={9}>
                <TermSelector className="pull-left m-r-2" />
              </Col>
              <Col sm={3} lg={3}>
                <ButtonBar className="footer-toolbar">
                  <PrintAction disabled={timer.isLoading} />
                  <TableAction
                    icon="download"
                    disabled={timer.isLoading}
                    onClick={() => ref.current?.downloadCSV('Budgets')}
                  />
                </ButtonBar>
              </Col>
            </Row>
          </div>
        </Grid>
      </Content>

      {budgets.map(({ id }: Budget, i) => (
        <Fragment key={i}>
          <AddBudgetExpenseModal
            key={id}
            id={id}
            budgetId={id}
            organizationId={member.organization.id}
            onCreate={refetch}
          />
        </Fragment>
      ))}
    </div>
  )
}

export default connectWithDateRange(MemberBudgets)
