// @ts-nocheck
import moment from 'moment'
import { bool, number, object, oneOfType, string } from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'

import { Address, Email, Fallback, Money, Name, Percentage, Phone, Units } from 'components/utilities'

const none = ({ column, data }) => (
  <em className="text-muted">{_.isFunction(column.none) ? column.none(data) : column.none || 'none'}</em>
)

const types = [
  ['Loading', args => args.loading],
  ['Formatter', args => 'formatter' in args.column],
  ['Display', args => 'display' in args.column],
  ['Money', args => 'money' in args.column],
  ['Unit', args => 'unit' in args.column],
  ['Balance', args => 'balance' in args.column],
  ['Email', args => 'email' in args.column],
  ['Percentage', args => 'percentage' in args.column],
  ['Phone', args => 'phone' in args.column],
  ['None', args => 'none' in args.column && _.isEmpty(args.value)],
  ['Address', args => 'address' in args.column],
  ['Fallback', args => 'fallback' in args.column],
  ['Date', args => 'date' in args.column],
  ['Name', args => 'name' in args.column && args.column.name],
]

export default class Cell<
  T extends Record<string, any> = Record<string, any>,
  U extends keyof T
> extends React.Component {
  static propTypes = {
    value: oneOfType([object, string, number]),
    data: object,
    column: object,
    loading: bool,
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return (
      !_.isEqual(this.props, nextProps) || !_.isEqual(this.state, nextState) || !_.isEqual(this.context, nextContext)
    )
  }

  renderLoading = ({ data }) => <span className="blokk">{data}</span>

  renderFormatter = ({ value, data, column }: { value: T[U]; data: T; column: any }) =>
    column.formatter(value, data, column.children)

  renderDisplay = ({ data, column }) => _.get(data, column.display)

  renderMoney = ({ value, data, column }) => {
    if ('none' in column && !_.isNumber(value)) {
      return none({ column, data })
    }

    const moneyAttr = _.isString(column.money) ? column.money : ''

    return (
      <Money
        amount={parseFloat(value)}
        warning={moneyAttr === 'warning'}
        praise={moneyAttr.match(/praise/)}
        strip={moneyAttr === 'strip'}
        plus={moneyAttr.match(/plus/)}
        className={column.className}
      />
    )
  }

  renderUnit = ({ value, data, column }) => {
    if ('none' in column && (_.isNil(value) || value === 0)) {
      return none({ column, data })
    }

    return <Units count={value} noun={column.unit} />
  }

  renderBalance = ({ value }) => <Money amount={value} praise plus />

  renderEmail = ({ value, data, column }) =>
    value ? <Email className="no-style">{value}</Email> : none({ column, data })

  renderPercentage = ({ value, column }) => {
    const float = parseFloat(value)
    if (isNaN(float)) {
      return 'n/a'
    }

    const decimalPlaces = _.defaultTo(column.percentage, 1)
    return <Percentage value={float} decimalPlaces={decimalPlaces} />
  }

  renderPhone = ({ value, data, column }) => (value ? <Phone>{value}</Phone> : none({ column, data }))

  renderNone = ({ data, column }) => none({ column, data })

  renderAddress = ({ value, data, column }) =>
    value && value.address ? <Address address={value} className="no-style" /> : none({ column, data })

  renderFallback = ({ value, column }) => <Fallback fallback={column.fallback}>{value}</Fallback>

  renderDate = ({ value, column }) => {
    const f = moment(value).format(column.date)
    return _.last(f) === '_' ? f.slice(0, -2) : f
  }

  renderName = ({ value, column }) => <Name user={value} format={_.isString(column.name) && column.name} />

  render() {
    const { value, data, column, loading, onClick } = this.props
    let display = value
    let align = column.align
    const verticalAlign = column.verticalAlign

    const type = _.find(types, t => t[1](this.props))
    if (!_.isEmpty(type)) {
      const func = `render${type[0]}`
      display = this[func](this.props)
    }

    if (loading && 'actions' in column) {
      align = 'right'
    }

    let uri
    if (!loading && !_.isEmpty(value)) {
      if ('linkId' in column && _.isString(column.linkId)) {
        uri = `${column.linkId}${data.id}`
      } else if ('link' in column && column['link']) {
        uri = column.link(data)
      }
    }

    let url
    if (!loading && !_.isEmpty(value)) {
      if ('url' in column) {
        url = this.props.data[column.url]
      }
    }

    const onTdClick =
      onClick ||
      (column.isCheckbox
        ? e => {
            if (e.target.tagName === 'TD') {
              e.target.children[0].click()
            }
          }
        : null)

    return (
      <td onClick={onTdClick} style={{ textAlign: align, verticalAlign }} className={column.cellClassName}>
        {(() => {
          if (uri) {
            return <Link to={uri}>{display}</Link>
          }
          if (url) {
            return (
              <a href={url} target="_blank" rel="noreferrer">
                {display}
              </a>
            )
          }
          return display
        })()}
      </td>
    )
  }
}
