import React from 'react'
import PropTypes from 'prop-types'

import { Row, Col, InputGroup, FormControl, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { FaIcon } from 'components/utilities/font-awesome'
import { Field } from 'components/form'
import { Calendar } from 'components/date-range'

const { bool, func, number, object, string } = PropTypes

class DateTimeSelector extends React.Component {
  static propTypes = {
    type: string.isRequired,
    datetime: object.isRequired,
    allDay: bool.isRequired,
    showCalendarTicker: number.isRequired,
    onChange: func.isRequired,
  }

  state = {
    showDatePicker: false,
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.showCalendarTicker < nextProps.showCalendarTicker) {
      this.setState({ showDatePicker: false })
    }
  }

  onDateChange = chosenDate => {
    const { datetime, onChange } = this.props
    const newDatetime = datetime.clone()
    newDatetime.month(chosenDate.month())
    newDatetime.date(chosenDate.date())
    newDatetime.year(chosenDate.year())
    onChange(newDatetime.format())
    this.setState({ showDatePicker: false })
  }

  onTimeChange = e => {
    try {
      const { datetime, onChange } = this.props
      const { hours, minutes } = this.parseTime(e.target.value)

      const newDatetime = datetime.clone()
      newDatetime.hours(hours)
      newDatetime.minutes(minutes)
      onChange(newDatetime.format())
    } catch (_) { } // eslint-disable-line
  }

  parseTime = str => {
    const parts = str.match(/(\d+):?(\d{0,2}) ?([ap])m?/)
    if (_.isNull(parts)) return null

    const hours = parseInt(parts[1], 10) + (parts[1] !== '12' && parts[3] === 'p' ? 12 : 0)
    const minutes = parseInt(parts[2], 10) || 0

    return { hours, minutes }
  }

  verifyTimeFormat = e => {
    const info = this.parseTime(e.target.value)
    const timeError = _.isNull(info) ? 'Must be in a format like 8pm, 8:15p, or 10a' : null
    this.setState({ timeError })
  }

  render() {
    const { type, allDay, datetime } = this.props
    const { showDatePicker, timeError } = this.state

    return (
      <Field label={_.capitalize(type)} noFeedbackControl inputSize={9}>
        <Row>
          <Col md={7} className="date-picker">
            <InputGroup>
              <FormControl
                name={type}
                id={type}
                type="text"
                value={datetime.format('dddd, MMM Do YYYY')}
                readOnly
                className="c-pointer bg-white"
                onClick={() => this.setState({ showDatePicker: !showDatePicker })}
              />
              <InputGroup.Addon
                className="c-pointer"
                onClick={() => this.setState({ showDatePicker: !showDatePicker })}
              >
                <FaIcon calendar />
              </InputGroup.Addon>
            </InputGroup>

            {showDatePicker && (
              <div className="calendar-container p-t-1">
                <Calendar date={datetime.clone()} onChange={this.onDateChange} />
              </div>
            )}
          </Col>
          {!allDay && (
            <Col md={5}>
              <InputGroup>
                <FormControl
                  type="text"
                  defaultValue={datetime.format('h:mm a')}
                  onChange={this.onTimeChange}
                  onBlur={this.verifyTimeFormat}
                />
                <InputGroup.Addon className="c-pointer">
                  <FaIcon clock-o />
                </InputGroup.Addon>
              </InputGroup>
              {timeError && <div className="help-block text-danger">{timeError}</div>}
            </Col>
          )}
        </Row>
      </Field>
    )
  }
}

export default DateTimeSelector
