import classNames from 'classnames'
import { FC, KeyboardEvent, useCallback, useContext, useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import r from 'routes'
import { useAppDispatch } from 'store'

import { unwrapResult } from '@reduxjs/toolkit'

import { Label, Units } from 'components/utilities'
import useLegacyContext from 'hooks/useLegacyContext'
import morph from 'thunks/auth/morph'
import globalSearch, { SearchResult } from 'thunks/global-search/search'
import { retrieveMember } from 'thunks/members/retrieve'
import { Role } from 'types/user'
import { SearchResponseHit } from 'typesense/lib/Typesense/Documents'
import formatPhoneNumber from 'utils/format-phone'

import { GlobalSearchContext } from '../context'
import s from './index.module.scss'

function getUri(myRole: Role, result: SearchResult): string {
  if (myRole === 'root') {
    switch (result.role) {
      case 'admin':
      case 'member':
        return r.super.members.show(result.memberId).root
      case 'parent':
        return r.super.members.show(result.memberId).access
      case 'alumni':
        return r.super.alumni.show(result.alumniId).root
      default:
        throw new Error(`unknown role ${result.role}`)
    }
  }

  if (myRole === 'admin') {
    switch (result.role) {
      case 'admin':
      case 'member':
        return `/admin/members/${result.memberId}`
      case 'parent':
        return `/admin/members/${result.memberId}/access`
      default:
        throw new Error(`unknown role ${result.role}`)
    }
  }

  throw new Error(`unknown search role ${myRole}`)
}

const GlobalSearchModal: FC = () => {
  const { user } = useLegacyContext()
  const { isOpen, close } = useContext(GlobalSearchContext)
  const history = useHistory()

  const [query, setQuery] = useState('')
  useEffect(() => {
    if (isOpen) return
    setQuery('')
  }, [isOpen])

  const [results, setResults] = useState<SearchResponseHit<SearchResult>[]>([])
  const dispatch = useAppDispatch()
  useEffect(() => {
    if (query.length < 3) return setResults([])
    dispatch(globalSearch({ query })).then(unwrapResult).then(setResults)
  }, [dispatch, query])

  const [selectedIndex, setSelectedIndex] = useState(0)
  useEffect(() => {
    setSelectedIndex(0)
  }, [results])

  const handleKeyUp = useCallback(
    async (e: KeyboardEvent) => {
      if (!isOpen || !user) return

      if (e.key === 'Enter' && results[selectedIndex]) {
        close()
        if (selectedIndex === 5) return history.push(`/super/search/${query}`)

        history.push(getUri(user.role, results[selectedIndex].document))
      }

      if (e.key === 'ArrowUp') {
        e.preventDefault()
        setSelectedIndex(Math.max(0, selectedIndex - 1))
      }

      if (e.key === 'ArrowDown') {
        e.preventDefault()
        setSelectedIndex(Math.min(5, selectedIndex + 1))
      }
    },
    [isOpen, user, results, selectedIndex, query, close, history]
  )

  const handleKeyDown = useCallback(
    async (e: KeyboardEvent) => {
      if (!e.metaKey || e.key !== 'Enter') return

      const { data } = await dispatch(
        retrieveMember<{ user: { id: number } }>({
          query: 'member { user { id } }',
          id: results[selectedIndex].document.memberId,
        })
      ).then(unwrapResult)
      dispatch(morph({ id: data.user.id }))
    },
    [dispatch, results, selectedIndex]
  )

  if (!user) return <></>
  if (!isOpen) return <></>

  return (
    <div className={s.GlobalSearchModal}>
      <div className={s.GlobalSearchModalBG} onClick={close}></div>
      <div>
        <input
          autoFocus
          value={query}
          placeholder="search..."
          onChange={e => setQuery(e.target.value)}
          onKeyDown={handleKeyDown}
          onKeyUp={handleKeyUp}
          className="placeholder:text-gray-400"
        />
        {query.length >= 3 && (
          <ul>
            {results.slice(0, 5).map((result, idx) => (
              <li
                key={result.document.id}
                onClick={() => {
                  close()
                  history.push(getUri(user.role, result.document))
                }}
                className={selectedIndex === idx ? s.Highlighted : undefined}
              >
                <header>
                  <strong>
                    {result.document.firstName || result.document.lastName ? (
                      <>
                        {result.document.firstName} {result.document.lastName}
                      </>
                    ) : (
                      <>[no name]</>
                    )}
                  </strong>
                  {result.document.role === 'admin' && <Label danger>admin</Label>}
                  {result.document.role === 'parent' && <Label info>parent</Label>}
                </header>
                <main>
                  <p className="text-gray-600">
                    {result.document.fedName} at {result.document.orgSecondary}
                  </p>
                  <ul>
                    {result.document.alternateEmail === query ? (
                      <li>{result.document.alternateEmail}</li>
                    ) : result.document.email ? (
                      <li>{result.document.email}</li>
                    ) : (
                      <></>
                    )}
                    {result.document.phoneNumber && <li>{formatPhoneNumber(result.document.phoneNumber)}</li>}
                  </ul>
                </main>
              </li>
            ))}
            {results.length > 5 && (
              <li className={classNames(s.MoreResults, 5 === selectedIndex && s.Highlighted)}>
                <Link to={`/super/search/${query}`}>
                  View all <Units count={results.length} noun="result" />
                </Link>
              </li>
            )}
            {results.length === 0 && <li className={s.NoResults}>no results found</li>}
          </ul>
        )}
      </div>
    </div>
  )
}

export default GlobalSearchModal
