export const paymentQuery = `
  payment {
    id
    amount
    description
    external_ref
    created_at
    payment_method {
      id
      method
      company
      last_four
    }
    account_payments {
      id
      balance_amount
      transfer_amount
      transfer_id
      account {
        id
        name
      }
    }
    member {
      id
      user {
        id
        first_name
        last_name
      }
    }
  }
`
