import T from './types'

export function fetchOrganization(organizationId: number, location?: string) {
  return {
    type: T.FETCH_ORGANIZATION,
    organizationId,
    location,
  }
}

export function setOrganization(organization: Record<string, never>) {
  return {
    type: T.SET_ORGANIZATION,
    organization,
  }
}

export function clearOrganization() {
  return {
    type: T.CLEAR_ORGANIZATION,
  }
}
