import { FC } from 'react'
import { Modal } from 'react-bootstrap'
import { Document } from 'resources'
import { store } from 'store'

import { unwrapResult } from '@reduxjs/toolkit'

import Form from 'components/form/form'
import Uploader, { File } from 'components/uploader'
import { MimeType } from 'helpers/ext-helpers'
import modal from 'helpers/modal'
import { closeModal } from 'hooks/useModal'
import { uploadDocumentUrl } from 'thunks/documents/upload-url'

type Props = {
  id?: number | string
  noun?: string
  organizationId?: number
  mimetypes?: MimeType[]
  onSuccess: (file: File) => void
}

const UploadFileModal: FC<Props> = ({ noun, organizationId, mimetypes, onSuccess }) => {
  return (
    <Modal show onHide={closeModal}>
      <Form action={Document.create} onSuccess={({ data }) => onSuccess(data)}>
        <Modal.Header closeButton>
          <Modal.Title>Upload a {noun || 'file'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="upload-wrapper">
            <Uploader
              noun={noun || 'file'}
              mimeTypes={mimetypes ?? ['image/jpeg', 'image/png']}
              getS3Info={() =>
                store.dispatch(uploadDocumentUrl({ organization_id: organizationId! })).then(unwrapResult)
              }
              onSuccess={onSuccess}
            />
          </div>
        </Modal.Body>
      </Form>
    </Modal>
  )
}

export default modal<Props>('UploadFile', UploadFileModal)
