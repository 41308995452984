import { FC, useContext, useEffect } from 'react'

import { GlobalSearchContext } from './context'
import s from './index.module.scss'

const GlobalSearch: FC = () => {
  const globalSearch = useContext(GlobalSearchContext)

  useEffect(() => {
    const handleKeyPress = (e: KeyboardEvent) => {
      if (e.key === 'k' && e.metaKey) globalSearch.toggle()
      if (e.key === 'Escape') globalSearch.close()
    }

    document.addEventListener('keydown', handleKeyPress)
    return () => document.removeEventListener('keydown', handleKeyPress)
  }, [globalSearch])

  return (
    <div className={s.GlobalSearch}>
      {/* <input type="text" placeholder="Search..." onFocus={globalSearch.toggle} /> */}
      <aside className="cursor-pointer" onClick={() => globalSearch.toggle()}>
        <span>&#8984;K</span>
      </aside>
    </div>
  )
}

export default GlobalSearch
