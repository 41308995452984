import { apiRequest } from 'actions/use'

import apiWrapper, { generatePayload, Request } from './helpers'

export default class ExecutiveBoardPosition extends apiWrapper('/executive_board_positions') {
  static updateOrdering = ({ data, onSuccess, onFailure }: Request) => {
    apiRequest({
      method: 'PUT',
      uri: '/api/executive_board_positions/update_ordering',
      data: generatePayload({ data }),
      on: {
        200: onSuccess,
      },
      onFailure,
    })
  }
}
