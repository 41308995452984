import { FC, useCallback, useMemo } from 'react'
import { Modal } from 'react-bootstrap'
import { HourRecord } from 'resources'

import { Field, Form, SubmitButton } from 'components/form'
import { MimeType } from 'helpers/ext-helpers'
import modal from 'helpers/modal'
import { closeModal } from 'hooks/useModal'
import notify from 'utils/notify'

type HourRecordType = {
  id: number
  description: string
  hours: number
  approved_at: string
  denied_at: string
  hour_requirement_id: number
  member_id: number
  document: {
    mimetype: MimeType
    url: string
  }
}

type Props = {
  hourRequirementId: number
  memberId: number
  onHourRecordCreate: (hourRecord: HourRecordType) => void
}

const AddHourAdjustmentModal: FC<Props> = ({ hourRequirementId, memberId, onHourRecordCreate }) => {
  const handleOnHourRecordCreate = useCallback(
    ({ data }: { data: HourRecordType }) => {
      notify('Hour adjustment added!')
      closeModal()
      onHourRecordCreate(data)
    },
    [onHourRecordCreate]
  )

  const data = useMemo(
    () => ({
      hour_record: {
        hour_requirement_id: hourRequirementId,
        member_id: memberId,
      },
    }),
    [memberId, hourRequirementId]
  )

  return (
    <Modal show onHide={closeModal}>
      <Form
        action={HourRecord.create}
        data={data}
        query={`
            hour_record {
              description
              hours
              approved_at
              denied_at
              hour_requirement_id
              member_id

              document {
                mimetype
                url
              }
            }
          `}
        onSuccess={handleOnHourRecordCreate}
      >
        <Modal.Header closeButton>
          <Modal.Title>Make an hour adjustment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <hr className="spacer-xs" />

          <Field label="Reason" name="hour_record.description" inputSize={8} autoFocus />

          <Field
            label="Hours"
            name="hour_record.hours"
            inputSize={8}
            hint="A positive number credits the member the hours. A negative number means more hours are required."
          />
        </Modal.Body>
        <Modal.Footer>
          <SubmitButton offset={5}>Add hour adjustment</SubmitButton>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default modal<Props>('AddHourAdjustment', AddHourAdjustmentModal)
