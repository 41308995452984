import { FC } from 'react'

import { PaymentMethod as PM } from 'components/utilities'
import { PaymentMethod as PaymentMethodType } from 'types/payment-method'

type Props = {
  payment: {
    id: number
    description: string
    external_ref: string

    payment_method: PaymentMethodType
  }
}

const PaymentMethod: FC<Props> = ({ payment }) => {
  let link = ''
  if (payment.external_ref) {
    if (payment.external_ref.match(/^bt:/)) {
      link = `https://${import.meta.env.VITE_BRAINTREE_GATEWAY}/merchants/${import.meta.env.VITE_BRAINTREE_MERCHANT_ID
        }/transactions/${payment.external_ref.split(/:/)[1]}`
    }
    if (payment.external_ref.match(/^st:/)) {
      link = `https://dashboard.stripe.com/payments/${payment.external_ref.split(/:/)[1]}`
    }
  }

  return (
    <div>
      {link && (
        <a href={link} target="_blank" className="pull-right" rel="noreferrer">
          view on {payment.external_ref.match(/^bt:/) ? 'Braintree' : 'Stripe'}
        </a>
      )}

      {payment.payment_method ? <PM paymentMethod={payment.payment_method} /> : payment.description}

      {payment.external_ref && payment.external_ref.match(/^bt:/) ? null : (
        <div className="m-t-1 fs-90">
          {payment.external_ref ? (
            `reference: ${payment.external_ref}`
          ) : (
            <span className="text-muted">No reference</span>
          )}
        </div>
      )}
    </div>
  )
}

export default PaymentMethod
