import { FC, useCallback, useState } from 'react'
import { Modal } from 'react-bootstrap'

import Button from 'components/form/button'
import modal from 'helpers/modal'
import { useAPI } from 'hooks/useAPI'
import { closeModal } from 'hooks/useModal'
import Select from 'react-select/creatable'
import { Request, updateDocumentLabels } from 'thunks/documents/labels'
import notify from 'utils/notify'

import { Document, Label } from './type'
// @ts-ignore
import { documentQuery } from './type?query'

type LabelOption = {
  value: number | string
  label: string
}

type Props = {
  documentId: number
  labels: Label[]
  onUpdate: (labels: Label[]) => void
}

const LabelsModal: FC<Props> = ({ documentId, labels: defaultValue, onUpdate }) => {
  const [labels, setLabels] = useState<LabelOption[]>(defaultValue.map(l => ({ value: l.id, label: l.label })))

  const [update, { timer }] = useAPI<Document, Request>(updateDocumentLabels)
  const handleSubmit = useCallback(async () => {
    const [updated] = await update({
      query: documentQuery,
      id: documentId,
      document: {
        labels: labels.map(({ label }) => label),
      },
    })

    onUpdate(updated.document_labels)
    closeModal()
    notify('The labels have been updated.')
  }, [documentId, labels, update, onUpdate])

  return (
    <Modal show onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>Change labels</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="flex space-x-8">
          <label className="w-1/5 text-bold flex items-center justify-end">Document labels</label>
          <div className="flex-1">
            <Select<LabelOption, true>
              isMulti
              autoFocus
              placeholder="Enter labels..."
              options={labels}
              value={labels}
              onChange={labels => setLabels([...labels])}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button timer={timer} onSubmit={handleSubmit}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default modal<Props>('Labels', LabelsModal)
