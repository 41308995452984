import { FC } from 'react'
import { Tooltip, OverlayTrigger } from 'react-bootstrap'
import { FaIcon, Units } from 'components/utilities'

type Props = {
  hourTracker?: {
    required: number
  }
}

const HourTrackerRequiredTooltip: FC<Props> = ({ hourTracker }) => {
  return hourTracker && hourTracker.required > 0 ? (
    <OverlayTrigger
      placement="right"
      overlay={
        <Tooltip id="hour-tracker-required-tooltip">
          <Units count={hourTracker.required} noun="hour" /> required
        </Tooltip>
      }
    >
      <FaIcon exclamation-circle warning className="pull-right" />
    </OverlayTrigger>
  ) : null
}

export default HourTrackerRequiredTooltip
