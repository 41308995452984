export const requiredDocumentQuery = `
  required_document {
    id
    title
    body
    custom_questions
    acceptance_type
    was_already_paid
    payment_schedule {
      options
    }
  }
`
