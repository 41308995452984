import asyncThunk from 'utils/asyncThunk'

export type Request = {
  query: string
  user_id: number
}

export const listPollsByUser = asyncThunk<Request, any>({
  name: 'polls/list-by-user',
  uri: '/polls/list_by_user',
  method: 'GET',
})
