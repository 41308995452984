import asyncThunk from 'utils/asyncThunk'

export type Request = {
  query?: string
  parent_id?: number
  account_id?: number
  organization_id?: number
  federation_id?: number
  archived?: boolean
  start_date?: string
  end_date?: string
}

export const listRequiredDocuments = asyncThunk<Request, any>({
  name: 'required-documents/list',
  uri: '/required_documents',
  method: 'GET',
})
