import { FC, useMemo } from 'react'
import { Button, ButtonToolbar } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import r from 'routes'

import { Body, FaButton, Portlet } from 'components/utilities'
import { openModal } from 'hooks/useModal'
import { MessageCategoryFolder } from 'pages/Shared/MessageCategories/folder/type'

import FolderList from '../folder-list'
import NewThreadModal from '../new-thread-modal'
import { MessageCategory, MessageThread } from '../type'
import Label from './_Label'
import Thread from './_Thread'

type Props = {
  role: 'member' | 'federation' | 'alumni'
  limit?: number
  folder?: MessageCategoryFolder
  messageCategory: MessageCategory
  messageThreads: MessageThread[]
  onCreateThread: (thread: MessageThread) => void
}

const MessageBoardCategory: FC<Props> = ({ role, limit, folder, messageCategory, messageThreads, onCreateThread }) => {
  const showThreads = useMemo(() => (limit ? messageThreads.slice(0, limit) : messageThreads), [limit, messageThreads])

  return (
    <>
      <Portlet key={messageCategory.id} className="portlet-posts">
        <div className="portlet-header clearfix">
          <h4 className="portlet-title">
            <Link to={r[role].messageCategories.show(messageCategory.id)} className="no-style">
              {folder?.name ?? messageCategory.name}
            </Link>

            <Label messageCategory={messageCategory} />
          </h4>
          <ButtonToolbar className="hidden-print pull-right">
            <FaButton onClick={openModal('NewThread', messageCategory.id)} icon="pencil-square-o" bsStyle="primary">
              New thread
            </FaButton>
          </ButtonToolbar>
        </div>

        <Body table>
          <FolderList
            role={role}
            messageCategoryId={messageCategory.id}
            folders={messageCategory.message_category_folders}
          />

          <table className="table table-posts">
            <tbody>
              {showThreads.map(thread => (
                <Thread key={thread.id} role={role} messageCategory={messageCategory} messageThread={thread} />
              ))}
            </tbody>
          </table>

          {limit && limit < messageThreads.length && (
            <Link to={r[role].messageCategories.show(messageCategory.id)} className="hidden-print">
              <Button bsSize="sm">View all {messageCategory.name} threads</Button>
            </Link>
          )}

          {messageThreads.length === 0 && (
            <div className="text-center">
              <p className="fs-125 m-t-1 m-b-1">No threads have been added</p>
              <Button bsStyle="primary" onClick={openModal('NewThread', messageCategory.id)}>
                Add a new thread
              </Button>
            </div>
          )}
        </Body>
      </Portlet>

      <NewThreadModal
        id={messageCategory.id}
        role={role}
        messageCategoryId={messageCategory.id}
        onCreate={onCreateThread}
      />
    </>
  )
}

export default MessageBoardCategory
