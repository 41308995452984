import { iconForMimeType } from 'helpers'
import { ChangeEvent, FC, useCallback, useState } from 'react'
import { Checkbox } from 'react-bootstrap'

import { Field, Input } from 'components/form'
import { UploadFileModal } from 'components/modals'
import { FaButton } from 'components/utilities/font-awesome'
import { MimeType } from 'helpers/ext-helpers'
import { closeModal, openModal } from 'hooks/useModal'

import { EventQuestion } from 'components/event/wizard/context'
import { Document } from 'types/document'

import { Errors } from './index'

function PreviewImage({ mimetype, name }: { mimetype: MimeType; name: string }) {
  const src = iconForMimeType(mimetype, name)
  return <img className="sm-btn-image p-l-1" src={src} alt="Preview" />
}

type Props = {
  eventQuestions: EventQuestion[]
  labelClassName?: string
  inputSize?: number
  errors: Errors
  onChangeEventAnswer: (value: string | Document, index: number, field?: 'document') => void
  onChangeEventCheckboxAnswer: (target: HTMLInputElement, index: number) => void
}

const CustomEventQuestions: FC<Props> = ({
  eventQuestions,
  errors,
  onChangeEventAnswer,
  labelClassName,
  inputSize,
  onChangeEventCheckboxAnswer,
}) => {
  const [documents, setDocuments] = useState<Record<number, Document>>({})

  const handleUploadSuccess = useCallback(
    (index: number) => (document: Document) => {
      documents[index] = document

      setDocuments(documents)

      onChangeEventAnswer(document, index, 'document')
      closeModal()
    },
    [documents, onChangeEventAnswer]
  )

  return (
    <div>
      {eventQuestions.map((obj, index) => {
        // @ts-ignore
        const error = errors ? errors[`answers[${index}].answer`]?.message : undefined

        return (
          <Field
            key={index}
            hint={error}
            validationState={error && 'error'}
            id={obj.question}
            labelFor={obj.question}
            label={
              <span>
                {obj.mandatory && <span className="text-danger">* </span>}
                {obj.question}
              </span>
            }
            {...{
              labelClassName,
              inputSize,
            }}
          >
            {obj.options && !obj.checkbox && (
              <select
                id={obj.question}
                className="form-control"
                onChange={e => onChangeEventAnswer(e.target.value, index)}
              >
                <option value="">&mdash; select answer &mdash;</option>
                {obj.options.map((value, i) => (
                  <option key={i} value={value}>
                    {value}
                  </option>
                ))}
              </select>
            )}

            {obj.options &&
              obj.checkbox &&
              obj.options.map((value, i) => (
                <Checkbox
                  key={i}
                  onChange={e => onChangeEventCheckboxAnswer(e.target as HTMLInputElement, index)}
                  value={value}
                >
                  {value}
                </Checkbox>
              ))}

            {!obj.select && !obj.checkbox && (
              <Input
                name={obj.question}
                onChange={(e: ChangeEvent<HTMLInputElement>) => onChangeEventAnswer(e.target.value, index)}
              />
            )}

            {obj.with_image && (
              <div>
                <FaButton icon="cloud-upload" bsSize="sm" onClick={openModal('UploadFile', index)} className="m-t-1">
                  Upload document
                </FaButton>

                {documents[index] && <PreviewImage {...documents[index]} />}

                <UploadFileModal id={index} noun="document" onSuccess={handleUploadSuccess(index)} />
              </div>
            )}
          </Field>
        )
      })}
    </div>
  )
}

export default CustomEventQuestions
