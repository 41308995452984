import { apiRequest } from 'actions/api'

import apiWrapper, { generatePayload, getMethod, Request, RequestWithID, updateMethod } from './helpers'

// @ts-ignore
export default class User extends apiWrapper('/users') {
  static avatar({ id, data, query, onSuccess }: RequestWithID) {
    apiRequest({
      method: 'PUT',
      uri: `/api/users/${id}/avatar`,
      data: generatePayload({ data, query }),
      on: {
        200: onSuccess,
      },
    })
  }

  static changePassword({ id, data, query, onSuccess, onFailure }: RequestWithID) {
    apiRequest({
      method: 'PUT',
      uri: `/api/users/${id}/change_password`,
      data: generatePayload({ data, query }),
      onSuccess,
      onFailure,
    })
  }

  static changePasswordPlain({ id, data, query, onSuccess, onFailure }: RequestWithID) {
    apiRequest({
      method: 'PUT',
      uri: `/api/users/${id}/plain_password`,
      data: generatePayload({ data, query }),
      onSuccess,
      onFailure,
    })
  }

  static passwordReset({ email, onSuccess, onFailure }: Request & { email: string }) {
    apiRequest({
      method: 'POST',
      uri: '/api/password_resets',
      data: {
        password_reset: { email },
      },
      on: {
        201: onSuccess,
      },
      onFailure,
    })
  }

  static groups({ id, data, query, onSuccess, onFailure }: RequestWithID) {
    apiRequest({
      method: 'PUT',
      uri: `/api/users/${id}/groups`,
      data: generatePayload({ data, query }),
      on: {
        200: onSuccess,
      },
      onFailure,
    })
  }

  static groupUsers({ data, query, onSuccess, onFailure }: Request) {
    apiRequest({
      method: 'GET',
      uri: '/api/users/group_users',
      data: generatePayload({ data, query }),
      on: {
        200: onSuccess,
      },
      onFailure,
    })
  }

  static budgets({ id, data, query = '', onSuccess, onFailure }: RequestWithID) {
    apiRequest({
      method: 'PUT',
      uri: `/api/users/${id}/budgets`,
      data: generatePayload({ data, query }),
      on: {
        200: onSuccess,
      },
      onFailure,
    })
  }

  static unsubscribe({ data, onSuccess, onFailure }: Request) {
    apiRequest({
      method: 'PUT',
      uri: '/api/users/unsubscribe',
      data,
      on: {
        200: onSuccess,
      },
      onFailure,
    })
  }

  static unsubscribeById = updateMethod('/users', 'unsubscribe_by_id')

  static subscribeById = updateMethod('/users', 'subscribe_by_id')

  static listByEmail = getMethod('/users/list_by_email')

  static unarchive({ id, onSuccess, onFailure }: RequestWithID) {
    apiRequest({
      method: 'PUT',
      uri: `/api/users/${id}/unarchive`,
      data: {
        query: 'user { archived_at }',
      },
      onSuccess,
      onFailure,
    })
  }
}
