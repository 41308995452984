import classNames from 'classnames'
import {
  FC,
  forwardRef,
  HTMLProps,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react'
import reactToText from 'react-to-text'

import { FaIcon } from 'components/utilities'
import { EmptyStateProps } from 'components/utilities/empty-state'

import downloadCSV from './downloadCSV'
import s from './index.module.scss'

export type TableProps<Obj> = {
  data: Obj[]
  empty?: string | ReactElement<EmptyStateProps>
  noSort?: boolean
  sortIndex?: number
  sortDir?: 'asc' | 'desc'
  children: (
    | ReactElement<HeaderProps>
    | ReactElement<FirstRowProps>
    | ReactElement<BodyProps<Obj>>
    | ReactElement<FooterProps>
    | false
    | undefined
  )[]
  className?: string
  onSort?: (idx: number, dir: 'asc' | 'desc') => void
}

export type HeaderProps = {
  children: (ReactElement<ColumnProps> | ReactElement<ColumnProps>[] | false)[]
}

export type ColumnProps = {
  width: number
  align?: 'right'
  noSort?: true
  sortDir?: 'asc' | 'desc'
  children: ReactNode
}

type ActionsProps = {
  width: number
  children?: ReactNode
}

export type BodyProps<Obj> = {
  children: (object: Obj) => ReactElement<RowProps>
}

type RowProps = {
  id?: number | string
  children: (ReactElement<CellProps> | ReactElement<CellProps>[] | false)[]
}

export type CellProps = {
  value?: number | string
  sortValue?: number | string
  className?: string
  children: ReactNode
}

type FooterProps = {
  className?: string
  children: ReactNode
}

export type FirstRowProps = {
  children: ReactElement<Omit<HTMLProps<'tr'>, 'children'> & { children: ReactElement<HTMLProps<'id'>>[] }>
}

export type TableRef = {
  downloadCSV: (filename: string) => void
  sort: (idx: number, dir: 'asc' | 'desc') => void
}

export default function createTable<Obj extends Record<any, any>>() {
  const Table = forwardRef<TableRef, TableProps<Obj>>(
    ({ data: source, empty, noSort, sortIndex, children, className, onSort }, ref) => {
      const [data, setData] = useState<Obj[]>()

      const header = children.find(c => c && (c as any).type.name === 'Header') as ReactElement<HeaderProps>
      const firstRow = children.find(c => c && (c as any).type.name === 'FirstRow') as ReactElement<FirstRowProps>
      const body = children.find(c => c && (c as any).type.name === 'Body') as ReactElement<BodyProps<Obj>>
      const footer = children.find(c => c && (c as any).type.name === 'Footer') as ReactElement<FooterProps>

      const columns = useMemo(
        (): ReactElement<ColumnProps>[] =>
          header.props.children.filter(c => c !== false).flat() as ReactElement<ColumnProps>[],
        [header.props.children]
      )

      const [sortIdx, setSortIdx] = useState<number>(sortIndex || 0)
      const [sortDir, setSortDir] = useState<'asc' | 'desc'>(() => {
        const sortCol = columns[sortIdx]
        if (!sortCol) return 'asc'
        if (!sortCol.props.sortDir) return 'asc'
        return sortCol.props.sortDir
      })

      useImperativeHandle(ref, () => ({
        downloadCSV(filename: string) {
          if (!data) return
          downloadCSV(filename, [header, firstRow, body], data)
        },
        sort(idx: number, dir: 'asc' | 'desc') {
          setSortIdx(idx)
          setSortDir(dir)
        },
      }))

      const handleSort = useCallback(
        (idx: number) => {
          if (idx === sortIdx) {
            setSortDir(sortDir === 'asc' ? 'desc' : 'asc')
          } else {
            setSortIdx(idx)
            const sortCol = columns[idx]
            setSortDir(sortCol ? sortCol.props.sortDir ?? 'asc' : 'asc')
          }
        },
        [sortDir, sortIdx, columns]
      )

      useEffect(() => {
        onSort?.(sortIdx, sortDir)
      }, [sortIdx, sortDir, onSort])

      useEffect(() => {
        if (noSort || onSort) {
          setData(source)
          return
        }

        function sortValue(object: Obj) {
          const row = body.props.children(object)
          const cell = row.props.children.flat()[sortIdx]
          if (!cell) return ''

          if (cell.props.sortValue !== undefined) {
            return cell.props.sortValue
          }
          if (cell.props.value !== undefined) {
            return cell.props.value
          }
          return reactToText(cell.props.children)
        }

        const sorted = [...source].sort((a, b) => {
          const aValue = sortValue(a)
          const bValue = sortValue(b)
          return aValue < bValue ? -1 : 1
        })

        if (sortDir === 'desc') sorted.reverse()
        setData(sorted)
      }, [source, noSort, body.props, sortIdx, sortDir, onSort])

      const alignments = columns.map(({ props, type }) =>
        props.align ?? (type as any).name === 'Actions' ? 'right' : undefined
      )

      return (
        <table className={classNames('table table-striped mb-4', className)}>
          <thead>
            <tr>
              {columns.map(({ props, type }, idx) => (
                <th
                  key={idx}
                  className={classNames((noSort || props.noSort) && s.NoSort)}
                  style={{ width: `${props.width}%` }}
                  onClick={noSort || props.noSort ? undefined : () => handleSort(idx)}
                >
                  <div className={classNames('flex items-center', alignments[idx] === 'right' && 'justify-end')}>
                    {noSort || props.noSort || (type as any).name === 'Actions' || (
                      <FaIcon
                        icon={sortIdx === idx ? (sortDir === 'asc' ? 'sort-up' : 'sort-down') : 'sort'}
                        className={classNames('m-r-05', { 'o-25': sortIdx !== idx })}
                      />
                    )}
                    {props.children}
                  </div>
                </th>
              ))}
            </tr>
          </thead>

          {footer && (
            <tfoot className={footer.props.className}>
              <tr>{footer.props.children}</tr>
            </tfoot>
          )}

          <tbody>
            {firstRow}

            {data?.map((object, index) => (
              <tr key={index} data-id={body.props.children(object).props.id}>
                {body.props
                  .children(object)
                  .props.children.flat()
                  .filter(c => !!c)
                  .map(c => c as ReactElement<CellProps>)
                  .map(({ props }, idx) => (
                    <td
                      key={idx}
                      style={{ textAlign: alignments[idx] === 'right' ? 'right' : undefined }}
                      className={props.className}
                    >
                      {props.children}
                    </td>
                  ))}
              </tr>
            ))}
            {data?.length === 0 && empty && (
              <tr>
                <td colSpan={header.props.children.length} className={s.Empty}>
                  {empty}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      )
    }
  )

  const Header: FC<HeaderProps> = () => null
  const Column: FC<ColumnProps> = () => null
  const Actions: FC<ActionsProps> = () => null
  const Body: FC<BodyProps<Obj>> = () => null
  const Row: FC<RowProps> = ({ children }) => <>{children}</>
  const Cell: FC<CellProps> = () => null
  const Footer: FC<FooterProps> = () => null
  const FirstRow: FC<FirstRowProps> = ({ children }) => <>{children}</>

  return { Table, Header, Column, Actions, Body, Row, Cell, Footer, FirstRow }
}
