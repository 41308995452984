import React, { FC, ReactNode } from 'react'
import { DropdownButton, MenuItem } from 'react-bootstrap'

type ItemProps = {
  icon: string | ReactNode
  disabled?: boolean
  onClick: () => void
  children: ReactNode
}

export const Item: FC<ItemProps> = ({ icon, onClick, disabled, children }) => {
  return (
    <MenuItem onClick={onClick} disabled={disabled} eventKey={children}>
      {typeof icon === 'string' ? <i className={`fa fa-${icon}`} /> : icon}
      &nbsp;&nbsp; {children}
    </MenuItem>
  )
}

export function Divider() {
  return <MenuItem divider />
}

type Props = {
  disabled?: boolean
  className?: string
  children: ReactNode
}

const SettingsDropdown: FC<Props> = ({ disabled, className, children }) => (
  <DropdownButton
    id="settings"
    title={<i className="fa fa-cog" />}
    noCaret
    pullRight
    disabled={disabled}
    className={className}
  >
    {children}
  </DropdownButton>
)

export default SettingsDropdown
