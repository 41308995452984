import asyncThunk from 'utils/asyncThunk'

export type Request = {
  id: number
}

export const archiveDocument = asyncThunk<Request, void>({
  name: 'documents/archive',
  uri: ({ id }) => `/documents/${id}`,
  method: 'DELETE',
})
