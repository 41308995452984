import asyncThunk from 'utils/asyncThunk'

export type Request = {
  id: number
}

export const refundPayment = asyncThunk<Request, void>({
  name: 'payments/refund',
  uri: ({ id }) => `/payments/${id}/refund`,
  method: 'PUT',
})
