import classNames from 'classnames'
import { HTMLProps, ReactElement, ReactNode, useMemo } from 'react'
import { InputGroup } from 'react-bootstrap'
import { Controller, ControllerRenderProps, ErrorOption, FieldValues, Path, useFormContext } from 'react-hook-form'
import MaskedInput from 'react-input-mask'
import { NumericFormat } from 'react-number-format'

import s from './connected-field.module.scss'

export type Props<Fields extends FieldValues = FieldValues, Field extends Path<Fields> = Path<Fields>> = {
  label: string | ReactNode | false
  name: Field
  inputSize?: number
  money?: boolean
  mask?: string
  prefix?: string
  suffix?: string
  textarea?: number
  hint?: ReactNode
  defaultValue?: number | string
  error?: string
  errorObjectKey?: string
  children?: ReactNode | ((props: ControllerRenderProps<Fields, Field>) => ReactElement)
} & Omit<HTMLProps<HTMLInputElement>, 'label' | 'defaultValue' | 'children'>

function ConnectedField<Fields extends FieldValues, Field extends Path<Fields> = Path<Fields>>({
  label,
  name,
  money,
  mask,
  prefix,
  suffix,
  textarea,
  hint,
  inputSize,
  error: errorProp,
  errorObjectKey,
  className,
  children,
  ...rest
}: Props<Fields, Field>) {
  const { register, control, getFieldState } = useFormContext<Fields>()

  const err = getFieldState(name)?.error
  const error = useMemo(() => {
    if (errorProp) return errorProp
    if (!errorObjectKey || !err) return err?.message
    return (err as unknown as Record<string, ErrorOption>)[errorObjectKey]?.message
  }, [errorProp, errorObjectKey, err])

  return (
    <div className={classNames(s.ConnectedField, error && s.HasError, className)}>
      {label !== false && <label htmlFor={name}>{label}</label>}

      <div
        style={{
          width: inputSize ? `${(100 * inputSize) / 12}%` : undefined,
        }}
      >
        {money ? (
          <InputGroup>
            <InputGroup.Addon>$</InputGroup.Addon>
            <Controller
              control={control}
              name={name}
              render={({ field }) => (
                <NumericFormat
                  thousandSeparator={true}
                  decimalScale={2}
                  onValueChange={v => field.onChange(+v.value)}
                  // @ts-ignore
                  value={field.value}
                  {...rest}
                  type="text"
                  className="form-control"
                />
              )}
            />
          </InputGroup>
        ) : mask ? (
          // @ts-ignore
          <MaskedInput {...register(name)} id={name} {...rest} mask={mask} className="form-control" />
        ) : typeof children === 'function' ? (
          <Controller<Fields, Field> control={control} name={name} render={({ field }) => children(field)} />
        ) : children ? (
          <div>{children}</div>
        ) : textarea ? (
          // @ts-ignore
          <textarea {...register(name)} type="text" id={name} rows={textarea} {...rest} className="form-control" />
        ) : prefix || suffix ? (
          <InputGroup>
            {prefix && <InputGroup.Addon>{prefix}</InputGroup.Addon>}
            <input {...register(name)} type="text" id={name} {...rest} className="form-control" />
            {suffix && <InputGroup.Addon>{suffix}</InputGroup.Addon>}
          </InputGroup>
        ) : (
          <input {...register(name)} type="text" id={name} {...rest} className="form-control" />
        )}

        {error && <div className={s.Error}>{error}</div>}

        {hint && <div className={s.Hint}>{hint}</div>}
      </div>
    </div>
  )
}

export default ConnectedField
