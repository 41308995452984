import asyncThunk from 'utils/asyncThunk'

export type Request = {
  id: number
  query: string
  user: {
    s3_path: string
  }
}

export const updateUserAvatar = asyncThunk<Request, any>({
  name: 'users/update-avatar',
  uri: ({ id }) => `/users/${id}/avatar`,
  method: 'PUT',
})
