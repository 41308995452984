import asyncThunk from 'utils/asyncThunk'

export type Request = {
  query: string
  payment_plan: {
    note: string | null
    payment_method_id?: number
    member_id: number
    payment_plan_schedule_id?: number
    payment_plan_payments: {
      due_on?: string
      amount?: number
    }[]
  }
}

export const createPaymentPlan = asyncThunk<Request, any>({
  name: 'payment-plans/create',
  uri: '/payment_plans',
  method: 'POST',
})
