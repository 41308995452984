import { FC, useCallback } from 'react'
import { Modal } from 'react-bootstrap'
import { FormProvider, useForm } from 'react-hook-form'

import SubmitButton from 'components/form/button'
import ConnectedField from 'components/form/connected-field'
import RemoteSelect from 'components/form/remote-select-v2'
import modal from 'helpers/modal'
import { useAPI } from 'hooks/useAPI'
import { closeModal } from 'hooks/useModal'
import { createContribution } from 'thunks/contributions/create'
import { listMembers, Request } from 'thunks/members/list'
import notify from 'utils/notify'

import { Contribution, ContributionTracker, Member } from './type'
// @ts-ignore
import { contributionQuery, memberQuery } from './type?query'

type FormData = {
  member_id: number
  amount: number
  details: string
}

type Props = {
  contributionTracker: ContributionTracker
  organizationId: number
  onCreate: (contribution: Contribution) => void
}

const RecordContributionModal: FC<Props> = ({ contributionTracker, organizationId, onCreate }) => {
  const form = useForm<FormData>()

  const [create, { timer }] = useAPI(createContribution)
  const handleSubmit = useCallback(
    async (data: FormData) => {
      const [contribution] = await create({
        query: contributionQuery,
        contribution: {
          amount: data.amount,
          details: data.details,
          contribution_tracker_id: contributionTracker.id,
          owner_type: contributionTracker.specificity === 'member' ? 'Member' : 'Organization',
          owner_id: contributionTracker.specificity === 'member' ? data.member_id : organizationId,
        },
      })

      onCreate(contribution)
      closeModal()
      notify('The contribution has been recorded.')
    },
    [create, contributionTracker.id, contributionTracker.specificity, organizationId, onCreate]
  )

  return (
    <Modal show onHide={closeModal}>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(handleSubmit)}>
          <Modal.Header closeButton>
            <Modal.Title>Record a contribution</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <hr className="spacer-xs" />

            {contributionTracker.specificity === 'member' && (
              <ConnectedField name="member_id" label="Member">
                {({ value, onChange }) => (
                  <RemoteSelect<Member, Request>
                    label={member => `${member.user.first_name} ${member.user.last_name}`}
                    placeholder="&mdash; select a member &mdash;"
                    query={memberQuery}
                    request={{ organization_id: organizationId }}
                    selectedId={value}
                    thunk={listMembers}
                    onChange={member => onChange(member?.id)}
                  />
                )}
              </ConnectedField>
            )}

            <ConnectedField
              money={contributionTracker.purpose === 'money'}
              label={contributionTracker.purpose === 'money' ? 'Amount' : 'Hours'}
              name="amount"
              autoFocus
            />

            <ConnectedField label="Details" name="details" placeholder="(optional)" textarea={4} />
          </Modal.Body>
          <Modal.Footer>
            <SubmitButton timer={timer}>Save</SubmitButton>
          </Modal.Footer>
        </form>
      </FormProvider>
    </Modal>
  )
}

export default modal<Props>('RecordContribution', RecordContributionModal)
