import asyncThunk from 'utils/asyncThunk'

export type Request = {
  is_approved?: boolean
  member_id?: number
}

export const memberMapHourRecords = asyncThunk<Request, Record<number, number>>({
  name: 'hour-records/member-map',
  uri: '/hour_records/member_map',
})
