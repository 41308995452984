import classNames from 'classnames'
import { FC, ReactNode } from 'react'
import { Link } from 'react-router-dom'

import { IconDefinition } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { FaIcon } from 'components/utilities/font-awesome'

export type Props = {
  path: string
  icon: string | IconDefinition
  backgroundIcon?: string
  isActive: boolean
  isDisabled?: boolean
  children: ReactNode
}

const Item: FC<Props> = ({ path, icon, backgroundIcon, isActive, isDisabled, children }) => {
  return (
    <li className={classNames(isActive && 'active')}>
      <Link to={path} className={classNames(isDisabled && 'disabled-click')}>
        {typeof icon === 'string' ? (
          <FaIcon {...{ icon, backgroundIcon }} />
        ) : (
          <FontAwesomeIcon icon={icon} className="mr-2" />
        )}
        &nbsp; {children}
      </Link>
    </li>
  )
}

export default Item
