import { FC, useCallback, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { HourRecord } from 'resources'
import { store } from 'store'

import { unwrapResult } from '@reduxjs/toolkit'

import { Field, Form, SubmitButton } from 'components/form'
import Uploader from 'components/uploader'
import { allMimeTypes } from 'helpers/ext-helpers'
import modal from 'helpers/modal'
import useLegacyContext from 'hooks/useLegacyContext'
import { closeModal } from 'hooks/useModal'
import { uploadDocumentUrl } from 'thunks/documents/upload-url'
import notify from 'utils/notify'

import { HourRecord as HourRecordType } from './type'
// @ts-ignore
import { hourRecordQuery } from './type?query'

type Props = {
  hourRequirementId: number
  organizationId: number
  onCreate: (hourRecord: HourRecordType) => void
}

const AddHourRecordModal: FC<Props> = ({ hourRequirementId, organizationId, onCreate }) => {
  const { user } = useLegacyContext()
  const [s3Path, setS3Path] = useState<string>()

  const handleCreate = useCallback(
    ({ data }: { data: HourRecordType }) => {
      notify('Hours have been recorded!')
      onCreate(data)
      closeModal()
    },
    [onCreate]
  )

  return (
    <Modal show onHide={closeModal}>
      <Form
        action={HourRecord.create}
        data={{
          hour_record: {
            s3_path: s3Path,
            hour_requirement_id: hourRequirementId,
            member_id: user?.member?.id,
          },
        }}
        query={hourRecordQuery}
        onSuccess={handleCreate}
      >
        <Modal.Header closeButton>
          <Modal.Title>Record hours</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Field
            label="Description"
            name="hour_record.description"
            textarea={3}
            placeholder="Where did you go? What did you do?"
            inputSize={9}
          />

          <Field label="Hours completed" name="hour_record.hours" inputSize={4} />

          <Field label="Documentation (optional)" inputSize={9}>
            <div className="upload-wrapper upload-wrapper-full-width">
              <Uploader
                noun="document"
                getS3Info={() =>
                  store.dispatch(uploadDocumentUrl({ organization_id: organizationId! })).then(unwrapResult)
                }
                onSuccess={({ s3_path }) => setS3Path(s3_path)}
                mimeTypes={allMimeTypes}
              />
            </div>
          </Field>
        </Modal.Body>
        <Modal.Footer>
          <SubmitButton offset={5}>Record hours</SubmitButton>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default modal<Props>('AddHourRecord', AddHourRecordModal)
