import moment from 'moment'

import { createSlice } from '@reduxjs/toolkit'

import { archiveTerm } from 'thunks/terms/archive'
import { bulkCreateTerms } from 'thunks/terms/bulk_create'
import { listTerms } from 'thunks/terms/list'
import setTerms from 'thunks/terms/set'
import { updateTerm } from 'thunks/terms/update'

export type Term = {
  id: number
  name: string
  started_on: string
  ended_on: string
}

export type TermsState = {
  terms?: Term[]
  organizationId?: number
}

const initialState: TermsState = {}

const slice = createSlice({
  name: 'terms',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(listTerms.fulfilled, (_, { meta, payload }) => {
      const terms = payload.data

      return {
        terms: decorateTerms(terms),
        organizationId: meta.arg.organization_id,
      }
    })

    builder.addCase(bulkCreateTerms.fulfilled, (state, { payload }) => {
      const [term] = payload.data
      return {
        ...state,
        terms: [term, ...(state.terms || [])],
      }
    })

    builder.addCase(updateTerm.fulfilled, (state, { payload }) => {
      const term = payload.data
      const terms = (state.terms || []).map(t => (t.id === term.id ? term : t))
      return {
        ...state,
        terms: decorateTerms(terms),
      }
    })

    builder.addCase(archiveTerm.fulfilled, (state, { meta }) => {
      const terms = (state.terms || []).filter(t => t.id !== meta.arg.id)
      return { ...state, terms }
    })

    builder.addCase(setTerms.fulfilled, (state, { meta }) => {
      const terms = decorateTerms(meta.arg)
      return { ...state, terms }
    })
  },
})

export default slice
export const actions = slice.actions

export function decorateTerms(orig: Omit<Term, 'ended_on'>[]): Term[] {
  return orig
    .sort((a, b) => {
      const aDate = moment(a.started_on)
      const bDate = moment(b.started_on)
      return aDate.isBefore(bDate) ? 1 : -1
    })
    .map((term, i) => {
      const endedOn = i === 0 ? moment() : moment(orig[i - 1].started_on).subtract(1, 'day')

      return {
        ...term,
        ended_on: endedOn.format('YYYY-MM-DD'),
      }
    })
}
