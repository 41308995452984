import { AppState } from 'store'
import Typesense, { Client } from 'typesense'

import { createAsyncThunk } from '@reduxjs/toolkit'

import { Role } from 'types/user'
import { SearchResponseHit } from 'typesense/lib/Typesense/Documents'

var client: Client

type Request = {
  query: string
}

export type SearchResult = {
  id: string
  role: Role
  memberId: number
  parentId: number
  alumniId: number
  uniqueId: string
  firstName: string
  lastName: string
  email: string
  alternateEmail: string
  phoneNumber: string
  orgName: string
  orgSecondary: string
  orgId: number
  fedName: string
  fedId: number
}

const globalSearch = createAsyncThunk<SearchResponseHit<SearchResult>[], Request>(
  'global-search/root',
  async ({ query }, api) => {
    const state = api.getState() as AppState
    if (!state.auth.user) throw new Error('Global search is unavailable')

    var apiKey: string
    switch (state.auth.user.role) {
      case 'root':
        apiKey = state.auth.user.data.typesense_key
        break
      case 'admin':
        apiKey = state.auth.user.member.organization.typesense_key
        break
      default:
        throw new Error('Global search is unavailable')
    }

    if (!client) {
      const match = import.meta.env.VITE_TYPESENSE_ORIGIN.match(/(https?):\/\/(.+):(\d+)/)

      client = new Typesense.Client({
        nodes: [
          {
            protocol: match[1],
            host: match[2],
            port: +match[3],
          },
        ],
        apiKey,
      })
    }

    const queryBy = (() => {
      const q = query.replaceAll(/[^\d]/g, '')
      if (q.length === 10) {
        return ['phoneNumber']
      }

      if (query.indexOf('@') > -1) {
        return ['email', 'alternateEmail']
      }

      const fields = ['firstName', 'lastName']
      if (query.match(/^[A-Za-z0-9]{5}$/)) {
        fields.push('uniqueId')
      }
      return fields
    })()

    const results = await client
      .collections<SearchResult>('users')
      .documents()
      .search({
        q: query,
        query_by: queryBy.join(','),
        sort_by: 'priority:asc',
      })

    if (!results.hits) throw new Error('Unable to perform search')

    return results.hits
  }
)

export default globalSearch
