import { useEffect, useState } from 'react'

import { useAPI } from 'hooks/useAPI'
import { listParents, Request } from 'thunks/parents/list'

import { Parent } from '../type'
// @ts-ignore
import { parentQuery } from '../type?query'

function sortParents(l: Parent, r: Parent): number {
  return `${l.user.first_name} ${l.user.last_name} `.toLowerCase() >
    `${r.user.first_name} ${r.user.last_name} `.toLowerCase()
    ? 1
    : -1
}

export default function useListParents(memberId: number) {
  const [list, { timer }] = useAPI<Parent[], Request>(listParents)

  const [parents, setParents] = useState<Parent[]>()
  useEffect(() => {
    list({
      member_id: memberId,
      query: parentQuery,
    })
      .then(([payments]) => payments.sort(sortParents))
      .then(setParents)
  }, [memberId, list])

  function addParent(parent: Parent) {
    setParents(parents => {
      if (!parents) return parents
      return [...parents, parent]
    })
  }

  function updateParent(parent: Parent) {
    setParents(parents => {
      if (!parents) return parents
      return parents.map(p => (p.id === parent.id ? parent : p))
    })
  }

  function archiveParent(id: number) {
    setParents(parents => {
      if (!parents) return parents
      return parents.filter(p => p.id !== id)
    })
  }

  return [parents, { addParent, updateParent, archiveParent, timer }] as const
}
