import moment from 'moment'
import { FC } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

import { FaIcon } from 'components/utilities/font-awesome'

type Props = {
  id?: number
  due_on: string
  payment_id?: number
}

const PaymentStatus: FC<Props> = ({ id, due_on: dueOn, payment_id: paymentId }) => {
  if (paymentId) {
    const tooltip = <Tooltip id={`tooltip-${id ?? dueOn}`}>Payment made on time</Tooltip>
    return (
      <OverlayTrigger placement="bottom" overlay={tooltip}>
        <FaIcon check success />
      </OverlayTrigger>
    )
  }

  if (moment(dueOn).isBefore()) {
    const tooltip = <Tooltip id={`tooltip-${id ?? dueOn}`}>Payment was missed</Tooltip>
    return (
      <OverlayTrigger placement="bottom" overlay={tooltip}>
        <FaIcon exclamation-triangle danger />
      </OverlayTrigger>
    )
  }

  const tooltip = <Tooltip id={`tooltip-${id ?? dueOn}`}>Future payment</Tooltip>
  return (
    <OverlayTrigger placement="bottom" overlay={tooltip}>
      <FaIcon clock-o className="text-muted" />
    </OverlayTrigger>
  )
}

export default PaymentStatus
