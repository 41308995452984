import { FC, FormEventHandler, useCallback, useEffect, useState } from 'react'
import { Button, ButtonToolbar, FormGroup, Radio } from 'react-bootstrap'

import AddBankAccountButton from 'components/add-bank-account-button'
import { AddCreditCardModal } from 'components/modals'
import { Loading, PaymentMethod as PayMethod } from 'components/utilities'
import paymentMethod from 'components/utilities/payment-method'
import useLegacyContext from 'hooks/useLegacyContext'
import useList from 'hooks/useList'
import { openModal } from 'hooks/useModal'
import { listPaymentMethods, Request } from 'thunks/payment-methods/list'

import { PaymentMethod } from './type'
// @ts-ignore
import { paymentMethodQuery } from './type?query'

type Props = {
  onPaymentMethodSelection: (paymentMethod: PaymentMethod) => void
  goBack: () => void
}

const PaymentMethodSection: FC<Props> = ({ onPaymentMethodSelection, goBack }) => {
  const { user } = useLegacyContext()

  const [paymentMethods, { add }] = useList<PaymentMethod, Request>(listPaymentMethods, {
    query: paymentMethodQuery,
    user_id: user?.id,
  })

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<PaymentMethod>()

  useEffect(() => {
    if (selectedPaymentMethod) return
    if (!paymentMethods || paymentMethods.length === 0) return
    setSelectedPaymentMethod(paymentMethods[0])
  }, [paymentMethods, selectedPaymentMethod])

  const onPaymentMethodCreate = useCallback(
    (paymentMethod: PaymentMethod) => {
      add(paymentMethod)
      setSelectedPaymentMethod(paymentMethod)
    },
    [add]
  )

  const onPaymentMethodChange: FormEventHandler<Radio> = useCallback(
    e => {
      const id = +(e.target as HTMLInputElement).value
      const selectedPaymentMethod = paymentMethods?.find(pm => pm.id === id)
      setSelectedPaymentMethod(selectedPaymentMethod)
    },
    [paymentMethods]
  )

  if (!paymentMethods) return <Loading />
  if (paymentMethods.length > 0 && !selectedPaymentMethod) return <></>

  return (
    <div>
      <h5>Which payment method should be used?</h5>

      {paymentMethods.length > 0 && (
        <FormGroup className="m-l-1">
          {paymentMethods.map((method, i) => (
            <Radio
              key={i}
              name="payment-method"
              value={method.id}
              checked={method.id === selectedPaymentMethod?.id}
              onChange={onPaymentMethodChange}
            >
              <PayMethod paymentMethod={method} />
            </Radio>
          ))}
        </FormGroup>
      )}

      <ButtonToolbar className="m-l-1">
        <Button onClick={openModal('AddCreditCard')} bsSize="sm">
          Add credit card
        </Button>

        <AddBankAccountButton bsSize="sm" noIcon onCreate={onPaymentMethodCreate} />
      </ButtonToolbar>

      <hr />
      <div>
        <Button
          bsStyle="secondary"
          disabled={!selectedPaymentMethod}
          onClick={() => {
            if (!selectedPaymentMethod) return
            onPaymentMethodSelection(selectedPaymentMethod)
          }}
          className="pull-right"
        >
          Next &raquo;
        </Button>

        <Button onClick={goBack}>Previous</Button>
      </div>

      <AddCreditCardModal onCreditCardCreate={onPaymentMethodCreate} />
    </div>
  )
}

export default PaymentMethodSection
