import moment from 'moment'

import { createSlice } from '@reduxjs/toolkit'

import { DateRange } from './dateRange'

export type HalfYear = {
  name: string
  started_on: string
  ended_on: string
}

export type HalfYearsState = HalfYear[]

const initialState: HalfYear[] = generateHalfYears()

const slice = createSlice({
  name: 'halfYears',
  initialState,
  reducers: {},
  extraReducers: builder => { },
})

export default slice
export const actions = slice.actions

function getNextStart([_month, _year]: [number, number]): [number, number] {
  const month = _month === 1 ? 6 : 1
  const year = _month === 1 ? _year : _year + 1
  return [month, year]
}

export function generateHalfYears(): HalfYear[] {
  const currentDate = moment()

  const startDates: [number, number][] = [[6, 2017]]

  let last = startDates[startDates.length - 1]
  do {
    startDates.push(getNextStart(last))

    last = startDates[startDates.length - 1]
  } while (moment(`${last[1]}-0${last[0]}-01`).isBefore(currentDate))

  const halfYears: HalfYear[] = startDates.map(([month, year]) => {
    const startedOn = moment(`${year}-0${month}-01`)
    const endedOn = moment(`${year}-${month === 1 ? '05' : 12}-01`).endOf('month')

    return {
      name: `${month === 1 ? 'Spring' : 'Fall'} • ${year}`,
      started_on: startedOn.format('YYYY-MM-DD'),
      ended_on: endedOn.format('YYYY-MM-DD'),
    }
  })

  return _.reverse(halfYears)
}

export function getCurrentHalfYear(): DateRange {
  const halfYear = generateHalfYears()[1]

  const start = moment(halfYear.started_on)
  const end = moment(halfYear.ended_on)

  return { start, end }
}

export function getHalfYearTitle(date: string): string {
  const m = moment(date)
  return `${m.month() < 6 ? 'Spring' : 'Fall'} ${m.year()}`
}
