import classNames from 'classnames'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { Button, ButtonToolbar, Col, Modal, Radio, Row } from 'react-bootstrap'

import { Field } from 'components/form'
import UpCharge from 'components/modals/quick-pay/render-up-charge'
import { Loading, PaymentMethod as PaymentMethodComponent } from 'components/utilities'
import modal from 'helpers/modal'
import useLegacyContext from 'hooks/useLegacyContext'
import { closeModal, openModal } from 'hooks/useModal'
import { PaymentMethod } from 'types/payment-method'

import { AddCreditCardModal } from '../'
import AddBankAccountButton from '../../add-bank-account-button'
import usePaymentMethods from './_usePaymentMethods'
import CheckInstructions from './CheckInstructions'
import getChargeAmount, { getUpchargeAmount } from './get-charge-amount'
import useSubmitPayment, { Payment, PaymentMethodType } from './useSubmitPayment'

type Props = {
  member: {
    id: number
    unique_id: string
    organization: {
      id: number
      cc_upcharge: boolean
    }
  }
  balance: number
  onSuccess: (payment: Payment) => void
}

const QuickPayModal: FC<Props> = ({ member, balance, onSuccess }) => {
  const { user } = useLegacyContext()
  const [paymentMethods, { prepend }] = usePaymentMethods(user.id)
  const [showAdvanced, setShowAdvanced] = useState(false)
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethodType>()
  const [submit, { error, timer }] = useSubmitPayment({ member, paymentMethod, onSuccess })
  const [amount, setAmount] = useState<number>()

  useEffect(() => {
    if (!paymentMethods?.length) return
    if (paymentMethod) return

    setPaymentMethod(paymentMethods[0])
  }, [paymentMethod, paymentMethods])

  const handlePaymentMethodCreate = useCallback(
    (paymentMethod: PaymentMethod) => {
      prepend(paymentMethod)
      setPaymentMethod(paymentMethod)
    },
    [prepend]
  )

  const amounts = useMemo(() => {
    if (!paymentMethod) return
    const charge = +getChargeAmount(amount || 0, paymentMethod.method, member.organization.cc_upcharge)
    const upcharge = getUpchargeAmount(amount || 0, paymentMethod.method, member.organization.cc_upcharge)

    return { charge, upcharge }
  }, [amount, paymentMethod, member.organization.cc_upcharge])

  return (
    <>
      <Modal show onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Quick pay</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <hr className="spacer-xs" />

          <Field name="payment.payment_method_id" label="Payment method" inputSize={9} noFeedbackControl>
            {paymentMethods ? (
              paymentMethods.map((pm, i) => (
                <Radio
                  key={i}
                  name="payment.payment_method_id"
                  value={pm.id}
                  checked={pm.id === paymentMethod?.id}
                  onChange={() => setPaymentMethod(pm)}
                >
                  <PaymentMethodComponent paymentMethod={pm} />
                </Radio>
              ))
            ) : (
              <Loading className="!mx-0 h-20 relative -top-4 -left-4" />
            )}

            {(paymentMethods?.length === 0 || showAdvanced) && (
              <ButtonToolbar className="wrap-buttons">
                <Button onClick={openModal('AddCreditCard')} bsSize="sm">
                  Add credit card
                </Button>
                <AddBankAccountButton
                  bsSize="sm"
                  noIcon
                  onCreate={pm => {
                    prepend(pm)
                    setPaymentMethod(pm)
                  }}
                />
                <Button
                  bsStyle={paymentMethod?.method === 'paypal' ? 'secondary' : 'default'}
                  onClick={() => setPaymentMethod({ id: 0, method: 'paypal' })}
                  bsSize="sm"
                >
                  Pay with PayPal
                </Button>
              </ButtonToolbar>
            )}
          </Field>

          <hr className="spacer-xs" />

          <Field
            name="payment.input_amount"
            errorKey="payment.amount"
            label="Amount to pay"
            inputSize={4}
            defaultValue={amount ? Math.max(amount, 0) : undefined}
            money
            autoFocus
            onChange={(amt: string) => setAmount(+amt.replace(/[^0-9.]+/g, ''))}
          />

          {error && (
            <Row className="text-danger fw-semibold m-t-neg-1 m-b-1">
              <Col md={9} mdPush={3}>
                {error}
              </Col>
            </Row>
          )}

          {!!paymentMethod && amounts && amounts.upcharge > 0 && (
            <UpCharge balance={balance} chargeAmount={amounts.charge} method={paymentMethod.method} />
          )}

          {showAdvanced && <CheckInstructions member={member} />}

          <hr className="spacer-xs" />
        </Modal.Body>
        <Modal.Footer>
          <Row>
            <Col md={5}>
              <Button onClick={() => setShowAdvanced(!showAdvanced)} className="pull-left">
                {showAdvanced ? <>&laquo; Quick pay</> : 'More options'}
              </Button>
            </Col>
            <Col md={7}>
              {member.organization.id === 1 ? (
                <Button bsStyle="success" disabled onClick={() => { }} className="m-l-0 m-r-1">
                  Disabled in Demo
                </Button>
              ) : (
                <Button
                  disabled={timer.isLoading}
                  bsStyle="success"
                  className={classNames('m-l-0', {
                    'btn-loading': timer.isLoading,
                  })}
                  onClick={() => submit(amount || 0)}
                >
                  {paymentMethod?.method === 'paypal' ? 'Pay with PayPal' : 'Make payment'}
                </Button>
              )}
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>

      <AddCreditCardModal onCreditCardCreate={handlePaymentMethodCreate} />
    </>
  )
}

export default modal<Props>('QuickPay', QuickPayModal)
