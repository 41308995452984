export const messageThreadQuery = `
  message_thread {
    id
    name
    created_at
    creator {
      id
      first_name
      last_name
      member {
        id
      }
    }
    message_category {
      id
      name
      owner_type
      owner_id
    }
    messages {
      id
      body
      created_at
      creator {
        id
        first_name
        last_name
        member {
          id
        }
        avatar {
          id
          url
        }
      }
    }
  }
`
