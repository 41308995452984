import { FC } from 'react'

import formatDate, { DateFormat } from 'utils/format_date'

const DEFAULT_FORMAT = 'long'

type Props = {
  date: string | Date
  className?: string
  format?: DateFormat
  onClick?: () => void
} & Partial<Record<DateFormat, boolean>>

const Date: FC<Props> = props => {
  const dateFormat =
    props.format ??
    ((_.reject(Object.keys(props), propKey => ['className', 'date'].includes(propKey))[0] ||
      DEFAULT_FORMAT) as DateFormat)
  const formatted = formatDate(props.date, dateFormat)

  if (props.onClick) {
    return (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <a
        onClick={e => {
          e.preventDefault()
          props.onClick && props.onClick()
          e.currentTarget.blur()
        }}
        className={props.className}
      >
        {formatted}
      </a>
    )
  }

  return <span className={props.className}>{formatted}</span>
}

export default Date
