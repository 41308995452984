import aviIcon from 'images/file-types/avi.svg'
import csvIcon from 'images/file-types/csv.svg'
import docIcon from 'images/file-types/doc.svg'
import folderIcon from 'images/file-types/folder.svg'
import jpgIcon from 'images/file-types/jpg.svg'
import pdfIcon from 'images/file-types/pdf.svg'
import pngIcon from 'images/file-types/png.svg'
import pptIcon from 'images/file-types/ppt.svg'
import rtfIcon from 'images/file-types/rtf.svg'
import svgIcon from 'images/file-types/svg.svg'
import txtIcon from 'images/file-types/txt.svg'
import unknownIcon from 'images/file-types/unknown.svg'
import xlsIcon from 'images/file-types/xls.svg'
import zipIcon from 'images/file-types/zip.svg'

type Ext = keyof typeof icons
export type MimeType = typeof allMimeTypes[number]

const icons = {
  rtf: rtfIcon,
  txt: txtIcon,
  unknown: unknownIcon,
  doc: docIcon,
  zip: zipIcon,
  csv: csvIcon,
  svg: svgIcon,
  pdf: pdfIcon,
  ppt: pptIcon,
  jpg: jpgIcon,
  avi: aviIcon,
  xls: xlsIcon,
  xlsx: xlsIcon,
  folder: folderIcon,
  png: pngIcon,
  pages: docIcon,
  numbers: xlsIcon,
}

export const allMimeTypes = [
  'text/csv',
  'image/jpeg',
  'image/jpg',
  'image/png',
  'image/webp',
  'application/pdf',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/msword',
  'binary/octet-stream',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/x-iwork-pages-sffpages',
  'application/x-iwork-numbers-sffnumbers',
] as const

const mimeTypeMap: Record<MimeType, Ext> = {
  'text/csv': 'csv',
  'image/jpeg': 'jpg',
  'image/jpg': 'jpg',
  'image/png': 'png',
  'image/webp': 'jpg',
  'application/pdf': 'pdf',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'doc',
  'application/msword': 'doc',
  'binary/octet-stream': 'doc',
  'application/vnd.ms-excel': 'xls',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
  'application/x-iwork-pages-sffpages': 'pages',
  'application/x-iwork-numbers-sffnumbers': 'numbers',
}

const extensions: Record<string, string> = {
  numbers: 'xls',
  pages: 'doc',
}

export function extFromName(filename: string): Ext {
  return filename.split('.').pop() as Ext
}

export function extForMimeType(type: MimeType, filename?: string): Ext {
  if (/^\.\w+$/.test(type)) return type.substring(1) as Ext

  return mimeTypeMap[type] || (filename && extensions[extFromName(filename)]) || 'unknown'
}

export function iconForMimeType(type: MimeType, filename: string | undefined): string {
  const ext = extForMimeType(type, filename)
  return icons[ext]
}
