import cx from 'classnames'
import { FC } from 'react'

type Props = {
  bsSize?: 'large' | 'lg' | 'small' | 'sm'
}

const Loader: FC<Props> = ({ bsSize }) => (
  <div
    className={cx('bootstrap-typeahead-loader', {
      'loader-lg': bsSize === 'large' || bsSize === 'lg',
      'loader-sm': bsSize === 'small' || bsSize === 'sm',
    })}
  />
)

export default Loader
