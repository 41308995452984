export const userQuery = `
  user {
    id
    first_name
    last_name
    email
    phone_number
  }
`
export const staffMemberQuery = `
  staff_member {
    id
    first_name
    last_name
    email
    phone_number
    staff_position {
      id
      title
    }
  }
`
