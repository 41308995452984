export const pollQuery = `
  poll {
    id
    title
    description
    is_anonymous
    ended_at
    owner_type
    owner_id
    has_multiple_answers
    poll_options {
      id
      title
      cost
      document {
        id
        url
      }
    }
    data {
      voted_option_ids
    }
  }
`
export const pollVoteQuery = `
  poll_vote {
    id
    poll_option {
      id
      title
    }
  }
`
