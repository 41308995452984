import asyncThunk from 'utils/asyncThunk'

export type Request = {
  query: string
  id: number
}

export const retrieveMessageCategory = asyncThunk<Request, any>({
  name: 'message-categories/retrieve',
  uri: ({ id }) => `/message_categories/${id}`,
})
