export const hourRequirementQuery = `
  hour_requirement {
    id
    title
    description
    hours_required
    completion_date
    member_ids
    created_at
    data {
      pending_count
    }
  }
`
export const hourRecordQuery = `
  hour_record {
    id
    description
    hours
    approved_at
    denied_at
    hour_requirement_id
    member_id
    created_at
    created_by {
      id
      first_name
      last_name
    }
    document {
      id
      mimetype
      url
    }
  }
`
