import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import { Col, Button } from 'react-bootstrap'
import { FormContext } from './context'

const { bool, number, string } = PropTypes

export default class SubmitButton extends React.Component {
  static contextType = FormContext

  static propTypes = {
    disabled: bool,
    unclickable: bool,
    offset: number,
    className: string,
    bsStyle: string,
    tabIndex: number,
    justButton: bool,
  }

  constructor(...args) {
    super(...args)

    this.state = {
      disabled: !!this.props.disabled,
    }
  }

  componentDidMount() {
    this.context.form.addSubmit(this)
  }

  componentWillUnmount() {
    this.context.form.removeSubmit(this)
  }

  enable() {
    this.setState({
      disabled: false,
    })
  }

  disable() {
    this.setState({
      disabled: true,
    })
  }

  render() {
    const push = _.defaultTo(this.props.offset, 3)
    const className = this.props.className

    const button = (
      <Button
        type="submit"
        bsStyle={this.props.bsStyle || 'primary'}
        tabIndex={this.props.tabIndex}
        disabled={this.state.disabled || this.props.unclickable}
        className={cx({
          'btn-loading': this.state.disabled,
        })}
      >
        {this.props.children}
      </Button>
    )

    if (this.props.justButton) {
      return button
    }

    return (
      <Col md={7} mdPush={push} className={className}>
        {button}
      </Col>
    )
  }
}
