import { Actions } from 'actions'
import React, { FC } from 'react'
import { Button, Modal } from 'react-bootstrap'

import modal from 'helpers/modal'
import { closeModal } from 'hooks/useModal'

type OwnProps = {
  id?: number
  url: string
}

type Props = Actions & OwnProps

const PublicSiteModal: FC<Props> = ({ url, actions }) => (
  <Modal show onHide={closeModal}>
    <Modal.Header closeButton>
      <Modal.Title>Public URL</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <hr className="spacer-xs" />

      <input
        value={url}
        readOnly
        autoFocus
        className="form-control text-center"
        onFocus={e => e.target.select()}
        // @ts-ignore
        onClick={e => e.target.select()}
      />

      <hr className="spacer-xs" />
    </Modal.Body>
    <Modal.Footer>
      <Button bsStyle="primary" onClick={() => window.open(url, '_blank')}>
        Visit registration page
      </Button>
    </Modal.Footer>
  </Modal>
)

export default modal<OwnProps>('PublicSite', PublicSiteModal)
