import { FC } from 'react'

import ParentsList from 'components/parents/list'
import { FaButton } from 'components/utilities'
import { useLegacyMember } from 'hooks/useLegacyContext'
import { openModal } from 'hooks/useModal'

const MemberSettingsAccess: FC = () => {
  const { member } = useLegacyMember()

  return (
    <div className="tab-pane active">
      <div className="heading-block">
        <FaButton icon="plus" bsStyle="secondary" className="pull-right" onClick={openModal('AddParent')}>
          Add parent
        </FaButton>

        <h3>Parent access</h3>
      </div>

      <ParentsList member={member} />
    </div>
  )
}

export default MemberSettingsAccess
