import moment from 'moment'
import { oxford } from 'humanize-plus'
import { FC, useCallback, useMemo } from 'react'
import { Link, useHistory } from 'react-router-dom'
import r from 'routes'
import { Action, ActionGroup, Body, Date, Header, Portlet, Units } from 'components/utilities'
import useLegacyContext from 'hooks/useLegacyContext'

import { Poll } from './type'

type Props = {
  polls: Poll[]
}

const RecentVotes: FC<Props> = ({ polls: allPolls }) => {
  const history = useHistory()

  const { user } = useLegacyContext()

  const linkToFinished = useCallback(
    (pollId: number) => {
      switch (user.role) {
        case 'alumni':
          return r.alumni.voting.finished(pollId)
        case 'federation':
          return r.federation.voting.finished(pollId)
        case 'admin':
          return r.admin.voting.finished(pollId)
        default:
          return r.member.voting.finished(pollId)
      }
    },
    [user]
  )

  const linkToEnded = useMemo(() => {
    switch (user.role) {
      case 'alumni':
        return r.alumni.voting.ended
      case 'federation':
        return r.federation.voting.ended
      case 'admin':
        return r.admin.voting.ended
      default:
        return r.member.voting.ended
    }
  }, [user])

  const polls = useMemo(
    () => allPolls.filter(poll => poll.ended_at && moment(poll.ended_at).diff(moment(), 'days') > -15),
    [allPolls]
  )

  const winners = useMemo(
    () =>
      polls?.reduce((memo, poll) => {
        return {
          ...memo,
          [poll.id]: poll.winners.map(w => w.title),
        }
      }, {} as Record<number, string[]>),
    [polls]
  )

  return (
    <Portlet boxed className="portlet-polls">
      <Header
        actions={
          <ActionGroup>
            <Action bsStyle="default" onClick={() => history.push(linkToEnded)}>
              View all
            </Action>
          </ActionGroup>
        }
        portlet
      >
        Recently ended votes
      </Header>

      <Body>
        {polls.length > 0 ? (
          <table id="ended-polls" className="table">
            <tbody>
              {polls.map(poll => (
                <tr key={poll.id} data-id={poll.id}>
                  <td>
                    <Link to={linkToFinished(poll.id)}>{poll.title}</Link>
                    <br />
                    <span className="winner">
                      {winners[poll.id] ? oxford(winners[poll.id]) : <span className="text-muted">none</span>}
                    </span>
                  </td>
                  <td style={{ width: '25%' }}>
                    {poll.data.vote_count} of <Units count={poll.data.voters_count} noun="vote" />
                  </td>
                  <td style={{ width: '25%' }}>
                    {poll.ended_at && (
                      <span>
                        <span className="text-muted fs-90">voting ended on</span> <br />
                        <Date long date={poll.ended_at} />
                      </span>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div className="text-center m-t-1">
            <p className="text-muted">No votes ended recently</p>
          </div>
        )}
      </Body>
    </Portlet>
  )
}

export default RecentVotes
