import { FC } from 'react'
import { Link } from 'react-router-dom'
import r from 'routes'

import Units from 'components/utilities/units'
import folderIcon from 'images/file-types/folder.svg'

type Props = {
  role: 'member' | 'federation' | 'alumni'
  messageCategoryId: number
  folders: {
    id: number
    name: string

    data: {
      thread_count: number
    }
  }[]
}

const FolderList: FC<Props> = ({ role, messageCategoryId, folders }) => {
  return (
    <div>
      <table className="table table-documents">
        <tbody>
          {folders.map((folder, idx) => (
            <tr key={idx}>
              <td style={{ width: '70%' }}>
                <img src={folderIcon} width={36} height={36} alt="" />
                <div>
                  <Link to={r[role].messageCategories.folder(messageCategoryId, folder.id)}>{folder.name}</Link>
                </div>
              </td>
              <td className="text-right" style={{ width: '30%' }}>
                {folder.data.thread_count === 0 ? (
                  <span className="text-muted">no threads</span>
                ) : (
                  <Units noun="thread" count={folder.data.thread_count} />
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default FolderList
