import { isGranted } from 'helpers'
import moment from 'moment'
import { FC, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Col, Grid, Row } from 'react-bootstrap'
import { useHistory, useParams } from 'react-router-dom'
import r from 'routes'

import { Action, ActionGroup, BreadcrumbHeader, BreadcrumbItem, Content, Loading } from 'components/utilities'
import RealtimeVotingProvider from 'components/voting/shared/RealtimeVotingProvider'
import useActiveSidebarItem from 'hooks/useActiveSidebarItem'
import { useAPI } from 'hooks/useAPI'
import useLegacyContext from 'hooks/useLegacyContext'
import useObject from 'hooks/useObject'
import 'react-bnb-gallery/dist/style.css'
import { createPollVote, Request as VoteRequest } from 'thunks/poll-votes/create'
import { Request, retrievePoll } from 'thunks/polls/retrieve'
import arraysAreEqual from 'utils/arraysAreEqual'
import notify from 'utils/notify'

import Images, { ImagesRef } from './_Images'
import Options from './_Options'
import { Poll, PollVote } from './type'
// @ts-ignore
import { pollQuery, pollVoteQuery } from './type?query'
import VoteSidebar from './vote-sidebar'
import { isFederation, isAdmin } from 'types/user-functions'

type RouteParams = {
  id: string
}

export type Ref = {
  poll: Poll | undefined
}

type Props = {
  extraActions?: ReactNode
  onPoll?: (poll: Poll) => void
}

const ShowContainer: FC<Props> = ({ extraActions, onPoll }) => {
  const { user } = useLegacyContext()
  useActiveSidebarItem('Voting')
  const history = useHistory()
  const params = useParams<RouteParams>()
  const imageRef = useRef<ImagesRef>(null)

  const [poll] = useObject<Poll, Request>(retrievePoll, {
    id: +params.id,
    query: pollQuery,
  })

  const linkToFinished = useCallback(
    (pollId: number) => {
      switch (user.role) {
        case 'alumni':
          return r.alumni.voting.finished(pollId)
        case 'federation':
          return r.federation.voting.finished(pollId)
        case 'admin':
          return r.admin.voting.finished(pollId)
        default:
          return r.member.voting.finished(pollId)
      }
    },
    [user]
  )

  useEffect(() => {
    poll && onPoll && onPoll(poll)
  }, [poll, onPoll])

  useEffect(() => {
    if (!poll?.ended_at) return
    if (moment(poll.ended_at).isAfter()) return

    history.push(linkToFinished(poll.id))
  }, [poll, history, linkToFinished])

  const [selectedOptionIds, setSelectedOptionIds] = useState<number[]>([])
  useEffect(() => {
    if (!poll) return

    setSelectedOptionIds(poll.data.voted_option_ids)
    setCurrentVoteIds(poll.data.voted_option_ids)
  }, [poll])

  const [currentVoteIds, setCurrentVoteIds] = useState<number[]>([])
  const [vote] = useAPI<PollVote, VoteRequest>(createPollVote)
  const handleVote = useCallback(
    (type: 'initial' | 'change') => async () => {
      await vote({
        query: pollVoteQuery,
        poll_vote: {
          poll_option_ids: selectedOptionIds,
        },
      })

      setCurrentVoteIds(selectedOptionIds)
      notify(`Your vote has been ${type === 'initial' ? 'cast' : 'changed'}.`)
    },
    [selectedOptionIds, vote]
  )

  const handleToggleOption = useCallback(
    (id: number, checked: boolean) => {
      if (!poll) return

      if (checked) {
        if (poll.has_multiple_answers) {
          setSelectedOptionIds([...selectedOptionIds, id])
        } else {
          setSelectedOptionIds([id])
        }
      } else {
        setSelectedOptionIds(selectedOptionIds.filter(o => o !== id))
      }
    },
    [selectedOptionIds, poll]
  )

  const linkToRoot = useMemo(() => {
    switch (user.role) {
      case 'admin':
        return r.admin.voting.root
      case 'federation':
        return r.federation.voting.root
      case 'alumni':
        return r.alumni.voting.root
      default:
        return r.member.voting.root
    }
  }, [user])

  const canSeeAnswers = useMemo(() => {
    if (poll?.owner_type === 'Federation') return isFederation(user)
    return isAdmin(user) || isGranted(user, 'Voting')
  }, [poll, user])

  if (!poll) return <Loading />

  return (
    <RealtimeVotingProvider>
      <Content className="voting">
        <Grid>
          <BreadcrumbHeader
            actions={
              <ActionGroup>
                {extraActions}
                {currentVoteIds.length === 0 ? (
                  <Action
                    disabled={selectedOptionIds.length === 0}
                    icon="hand-paper-o"
                    bsStyle={selectedOptionIds ? 'success' : 'default'}
                    onClick={handleVote('initial')}
                  >
                    Vote
                  </Action>
                ) : arraysAreEqual(currentVoteIds, selectedOptionIds) ? (
                  <Action icon="check" disabled bsStyle="secondary">
                    Voted
                  </Action>
                ) : (
                  <Action
                    icon="exchange"
                    bsStyle="success"
                    disabled={selectedOptionIds.length === 0}
                    onClick={handleVote('change')}
                  >
                    Change vote
                  </Action>
                )}
              </ActionGroup>
            }
          >
            <BreadcrumbItem onClick={() => history.push(linkToRoot)}>Voting</BreadcrumbItem>
            <BreadcrumbItem>{poll.title}</BreadcrumbItem>
          </BreadcrumbHeader>

          <Row>
            <Col sm={7}>
              {poll.poll_options.some(o => !!o.cost) && (
                <Row>
                  <Col sm={12} className="text-bold lh-12 m-b-2">
                    When voting ends, if you've voted for the winning option and it has a cost, you will be
                    automatically charged.
                  </Col>
                </Row>
              )}

              <Row className="vote-options">
                <Options
                  options={poll.poll_options}
                  selectedOptionIds={selectedOptionIds}
                  isMultiple={poll.has_multiple_answers}
                  onSelectImage={id => imageRef.current?.selectImage(id)}
                  onToggle={handleToggleOption}
                />
              </Row>
            </Col>

            <Col sm={5}>
              <VoteSidebar poll={poll} canSeeAnswers={canSeeAnswers} />
            </Col>

            <Images ref={imageRef} options={poll.poll_options} />
          </Row>
        </Grid>
      </Content>
    </RealtimeVotingProvider>
  )
}

export default ShowContainer
