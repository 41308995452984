// @ts-nocheck
import Humanize from 'humanize-plus'
import PropTypes from 'prop-types'
import React from 'react'
import { FormControl, InputGroup } from 'react-bootstrap'

type Props = {
  name: string
  value?: string
  defaultValue?: number
  autoComplete?: 'off'
  autoFocus?: boolean
  disabled?: boolean
  placeholder?: string
  className?: string
  groupClassName?: string
  onChange?: (value: string, name: string) => void
}

export default class MoneyInput extends React.Component<Props> {
  static propTypes = {
    name: PropTypes.string.isRequired,
    defaultValue: PropTypes.number,
    onChange: PropTypes.func,
  }

  handleChange(e) {
    const target = e.target
    const { value, selectionStart } = target

    const notAllowedIndex = value.search(/[^\d.,]/)

    let newValue = value
    let newSelection = selectionStart

    if (notAllowedIndex >= 0) {
      newValue = value.replace(/[^\d.,]/g, '')
      newSelection = Math.min(notAllowedIndex, selectionStart)
    }

    ;({ newValue, newSelection } = this.format(newValue, newSelection))

    const { name, onChange } = this.props
    if (onChange) {
      onChange(newValue, name)
    }

    target.value = newValue
    target.setSelectionRange(newSelection, newSelection)
  }

  format(value, selectionIndex) {
    let newValue
    let newSelection

    let leftPart = value.slice(0, selectionIndex)
    let rightPart = value.slice(selectionIndex)

    leftPart = leftPart.replace(/,/g, '')
    rightPart = rightPart.replace(/,/g, '')

    const lastPoint = rightPart.lastIndexOf('.')

    if (leftPart[leftPart.length - 1] === '.') {
      leftPart = leftPart.replace(/\./g, '')

      if (lastPoint === -1) {
        leftPart += '.'

        if (rightPart.length > 2) {
          rightPart = rightPart.slice(0, 2)
        }
      } else {
        // Two ways here: either jump to existing decimal point or
        // covert right side to decimals. Right now it's jumping
        leftPart = leftPart.replace(/\./g, '') + rightPart.slice(0, lastPoint + 1)
        rightPart = rightPart.slice(lastPoint + 1, lastPoint + 1 + 2)
      }

      newValue = leftPart + rightPart
      newSelection = leftPart.length
    } else if (lastPoint !== -1) {
      leftPart = leftPart.replace(/\./g, '')
      rightPart = rightPart.slice(0, lastPoint).replace(/\./g, '') + rightPart.slice(lastPoint, lastPoint + 3)
      newValue = leftPart + rightPart
      newSelection = leftPart.length
    } else {
      const leftLastPoint = leftPart.lastIndexOf('.')

      if (leftLastPoint !== -1) {
        const rightAllowed = leftPart.length - 1 - leftLastPoint

        leftPart =
          leftPart.slice(0, leftLastPoint).replace(/\./g, '') + leftPart.slice(leftLastPoint, leftLastPoint + 3)
        rightPart = 2 - rightAllowed > 0 ? rightPart.slice(0, 2 - rightAllowed) : ''
      }

      newValue = leftPart + rightPart
      newSelection = leftPart.length
    }

    const parts = newValue.split('.')
    const part1 = parts[0]

    leftPart = []
    rightPart = parts.length > 1 ? `.${parts[1]}` : ''

    let i = part1.length
    let selectionOffset = 0

    for (; i > 0; i -= 3) {
      if (i < Math.min(newSelection, part1.length)) {
        selectionOffset++
      }

      leftPart.push(part1.slice(Math.max(0, i - 3), i))
    }

    newSelection += selectionOffset
    newValue = leftPart.reverse().join(',') + rightPart

    return { newValue, newSelection }
  }

  render() {
    const controlProps = _.omit(this.props, ['defaultValue', 'onChange', 'groupClassName', 'controlClassName'])
    controlProps.id = controlProps.name

    const defaultValue = _.isNumber(this.props.defaultValue) ? Humanize.formatNumber(this.props.defaultValue, 2) : null

    return (
      <InputGroup className={this.props.groupClassName}>
        <InputGroup.Addon>$</InputGroup.Addon>
        <FormControl
          type="text"
          placeholder="0.00"
          {...controlProps}
          {...(defaultValue ? { defaultValue } : {})}
          onInput={this.handleChange.bind(this)}
          data-numeric
          className={this.props.controlClassName}
        />
      </InputGroup>
    )
  }
}
