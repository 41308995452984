import { FC, useCallback } from 'react'

import { EventQuestion } from 'components/event/wizard/context'
import Portlet, { Body } from 'components/utilities/portlet'
import CustomEventQuestions from 'pages/Common/RequiredDocuments/_custom_event_questions'
import { Document } from 'types/document'

import { Acceptance, Errors } from './index'

export type Answer = {
  answer: string
}

type Props = {
  document: {
    custom_questions: EventQuestion[]
  }
  errors: Errors
  answers?: Answer[]
  onUpdateAcceptance: (key: string, value: Acceptance[keyof Acceptance]) => void
}

const Questions: FC<Props> = ({ document, answers = [], errors, onUpdateAcceptance }) => {
  const onChangeCustomAnswer = useCallback(
    (value: string | Document, index: number, field = 'answer') => {
      _.set(answers, `[${index}].${field}`, value)
      onUpdateAcceptance('answers', answers)
    },
    [answers, onUpdateAcceptance]
  )

  const onChangeCustomCheckboxAnswer = useCallback(
    (target: HTMLInputElement, index: number) => {
      const newValue = target.checked
        ? [..._.get(answers, `[${index}].answer`, []), target.value]
        : _.remove(_.get(answers, `[${index}].answer`, []), ans => ans !== target.value)
      _.set(answers, `[${index}].answer`, newValue)
      onUpdateAcceptance('answers', answers)
    },
    [answers, onUpdateAcceptance]
  )

  return (
    <Portlet boxed>
      <Body>
        <CustomEventQuestions
          eventQuestions={document.custom_questions}
          errors={errors}
          onChangeEventAnswer={onChangeCustomAnswer}
          onChangeEventCheckboxAnswer={onChangeCustomCheckboxAnswer}
        />

        {'answers' in errors && (
          <p>
            {/* @ts-ignore */}
            <strong className="text-danger">{errors.answers}</strong>
          </p>
        )}
      </Body>
    </Portlet>
  )
}

export default Questions
