import { ObjectSchema } from 'yup'
import { useCallback } from 'react'

type ValidationError = {
  path: string
  type?: string
  message: string
}

export type ValidationErrors<T> = {
  [key in keyof T]?: ValidationError
}

type InnerValidationErrors = {
  inner: ValidationError[]
}

const useYupValidationResolver = <Data extends {}>(validationSchema: ObjectSchema<Data>) =>
  useCallback(
    async (data: Data) => {
      try {
        const values = await validationSchema.validate(data, {
          abortEarly: false,
        })

        return {
          values,
          errors: {},
        }
      } catch (errors: unknown) {
        return {
          values: {},
          errors: (errors as InnerValidationErrors).inner.reduce(
            (allErrors, currentError) => ({
              ...allErrors,
              [currentError.path]: {
                type: currentError.type ?? 'validation',
                message: currentError.message,
              },
            }),
            {}
          ),
        }
      }
    },
    [validationSchema]
  )

export default useYupValidationResolver
