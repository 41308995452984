import { FC, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import r from 'routes'
import { useAuthSelector } from 'store'

import { AnyUser } from 'types/user'

export function rootPathFromRole(role: AnyUser['role']): string {
  switch (role) {
    case 'member':
      return r.member.root
    case 'admin':
      return r.admin.root
    case 'parent':
      return r.parent.root
    case 'alumni':
      return r.alumni.root
    case 'federation':
      return r.federation.root
    case 'root':
      return r.super.root
  }
}

const RootRedirect: FC = () => {
  const { token, user } = useAuthSelector()
  const history = useHistory()

  useEffect(() => {
    if (token && !user) return
    const path = user ? rootPathFromRole(user.role) : r.auth.login
    history.push(path)
  }, [token, user, history])

  return <></>
}

export default RootRedirect
