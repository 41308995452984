import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Container from 'container'

import { Typeahead } from 'components/typeahead'

const { array, arrayOf, bool, func, number, object, oneOfType, string } = PropTypes

class RemoteSelect extends Container {
  static defaultProps = {
    selectFirst: true,
    preselectAll: false,
    showClearAllButton: false,
    selectedIds: [],
  }

  // There are more...
  static propTypes = {
    resource: oneOfType([func, object]),
    name: string,
    className: string,
    disabled: bool,
    display: oneOfType([string, func]),
    placeholder: string,
    action: string,
    identifier: string,
    fromKeyboard: bool,
    filter: oneOfType([func, array]),
    filterListBy: oneOfType([func, string]),
    mapListBy: object,
    sortListBy: oneOfType([func, string]),
    scope: object,
    query: string,
    selectedIds: arrayOf(number),
    selectFirst: bool,
    multiple: bool,
    preselectAll: bool,
    showClearAllButton: bool,
    onChange: func,
    onResourceLoaded: func,
  }

  constructor(props) {
    super(props, {
      selected: [],
      inputText: '',
    })

    this.resources.list = props.resource
    this.listResourceAction = props.action || 'list'

    this.filterListBy = props.filter

    const funcs = ['filterListBy', 'mapListBy', 'sortListBy']
    _.forEach(funcs, fn => {
      if (fn in this.props) {
        this[fn] = this.props[fn]
      }
    })
  }

  get listScope() {
    const scope = _.defaultTo(this.props.scope, {})
    return scope
  }

  get listQuery() {
    return this.props.query
  }

  afterResourceList = list => {
    const { onResourceLoaded, preselectAll } = this.props

    this.initializeSelected()

    if (preselectAll) this.selectAll()

    if (onResourceLoaded) {
      onResourceLoaded(list, { selectAll: this.selectAll })
    }
  }

  initializeSelected = () => {
    const list = this.getList()
    const { selectedIds, selectFirst } = this.props
    const filterFunc = opt => _.includes(selectedIds, opt.id)

    if (list.length === 1 && selectFirst) {
      this.onChange(list)
    } else {
      const selected = _.filter(list, filterFunc)
      this.setState({ selected })
    }
  }

  componentDidUpdate(prevProps) {
    const { selectedIds } = this.props
    const list = this.getList()
    if (list && _.xor(selectedIds, prevProps.selectedIds).length > 0) {
      this.initializeSelected()
    }
  }

  onChange = selected => {
    const { onChange } = this.props

    this.setState({ selected })

    if (_.isFunction(onChange)) {
      onChange(selected)
    }
  }

  onInputChange = text => {
    this.setState({ inputText: text })
  }

  selectAll = () => {
    this.setState({ selected: this.getList() })
  }

  render() {
    const { display, name, disabled, selectFirst, identifier, fromKeyboard, showClearAllButton } = this.props
    const { selected, inputText } = this.state
    const list = this.getList()

    const propsSubset = _.pick(
      this.props,
      'emptyLabel',
      'actions',
      'className',
      'display',
      'placeholder',
      'multiple',
      'multiplePlaceholder'
    )
    const options = (() => {
      if (!list) {
        return []
      }
      if ('sortListBy' in this.props) {
        return list
      }
      return _.sortBy(list, t => _.toLower(t[display]))
    })()

    let selectedObjects = null
    if (_.isArray(selected)) {
      selectedObjects = selected
    } else if (selected) {
      selectedObjects = [selected]
    } else if (options.length === 1 && selectFirst) {
      selectedObjects = options
    } else {
      selectedObjects = []
    }

    const props = {
      selected: selectedObjects,
    }

    if (this.props.multiple && !this.props.multiplePlaceholder) {
      props.multiplePlaceholder = this.props.placeholder
    }

    const typeaheadProps = _.assign({}, propsSubset, props, {
      options,
      labelKey: display,
      disabled,
      onChange: this.onChange,
      onInputChange: this.onInputChange,
      inputId: name,
    })

    if (!name) {
      return <Typeahead {...typeaheadProps} />
    }

    let inputComponent

    if (this.props.multiple) {
      inputComponent = (
        <select
          multiple
          readOnly
          name={name}
          style={{ display: 'none' }}
          value={_.map(selectedObjects, identifier || 'id')}
        >
          {selectedObjects.map(s => {
            const optValue = _.get(s, identifier || 'id')
            return <option key={optValue} value={optValue} />
          })}
        </select>
      )
    } else {
      let hiddenValue = ''

      if (fromKeyboard) {
        hiddenValue = selectedObjects.length > 0 ? _.map(selectedObjects, identifier || 'id') : inputText
      } else {
        hiddenValue = _.map(selectedObjects, identifier || 'id')
      }
      inputComponent = <input ref={this.props.ref} type="hidden" name={name} value={hiddenValue} />
    }

    return (
      <div>
        {inputComponent}
        <Typeahead {...typeaheadProps} />
        {showClearAllButton && (
          <em className="text-muted c-pointer fs-90 pull-right p-t-05" onClick={() => this.setState({ selected: [] })}>
            clear all
          </em>
        )}
      </div>
    )
  }
}

export default connect(null)(RemoteSelect)
