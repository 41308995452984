export const termQuery = `
  term {
    id
    name
    started_on
  }
`
export const requestQuery = `
  request {
    query
    organization_id
  }
`
