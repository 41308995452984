import { FC, useEffect, useMemo, useState } from 'react'
import { Grid } from 'react-bootstrap'
import { useLocation } from 'react-router'

import { FallbackRender } from '@sentry/react'

import AppWrapper from 'components/layout/app-wrapper'
import { Body, Content, Header, Portlet } from 'components/utilities'

type Props = Parameters<FallbackRender>[0]

const Error: FC<Props> = ({ error, componentStack, resetError }) => {
  const location = useLocation()
  const [curr] = useState(location.pathname)
  useEffect(() => {
    if (curr === location.pathname) return

    resetError()
  }, [curr, location.pathname, resetError])

  const stacktrace = useMemo(() => {
    if (!componentStack) return

    return componentStack
      .split('\n')
      .filter(l => l.match(/\/src\//))
      .filter(l => !l.match(/(App.tsx|app-wrapper.tsx|AppRoute.tsx)/))
      .map(l => {
        const parts = l.match(/at .+\/src\/(.+)\?t=\d+:(\d+):/)
        return parts ? `  ${parts[1]}#${parts[2]}` : l
      })
      .map(l => l.replace(/https:\/\/[^/]+\/src\//, ''))
      .join('\n')
  }, [componentStack])

  return (
    <AppWrapper>
      <Content>
        <Grid>
          <Header>
            <span className="text-danger">An exception occurred</span>
          </Header>
          <Portlet boxed>
            <Body>
              <p className="text-2xl leading-10">
                We track these kinds of errors though, so you can expect this error to be resolved soon. If for some
                reason you continue to run into this issue, please email info@greekcapitalmanagement.com. The more
                details you include about what lead to the error, the faster we'll be able to diagnose and fix the
                problem.
              </p>
            </Body>
            <pre className="m-t-2 text-muted text-base">{`${error.message}\n\n${stacktrace}`}</pre>
          </Portlet>
        </Grid>
      </Content>
    </AppWrapper>
  )
}

export default Error
