export const eventQuery = `
  event {
    id
    name
    description
    purpose
    cost_type
    cost
    maximum_tickets
    registration_closes_on
    start_date
    end_date
    is_approval_required
    event_questions {
      question
      mandatory
      select
      with_image
      checkbox
      options
    }
    optional_addons {
      name
      cost
    }
    data {
      tickets_sold
    }
    document_folder {
      id
      documents {
        id
        url
      }
    }
  }
`
export const paymentMethodQuery = `
  payment_method {
    id
    method
    company
    last_four
  }
`
export const eventRegistrationQuery = `
  event_registration {
    id
    amount
    tickets
    event_answers {
      question
      answer
    }
    event_optional_addons {
      name
      quantity
      cost
    }
    created_at
    approved_at
  }
`
