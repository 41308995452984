import classnames from 'classnames'
import { FC, useMemo } from 'react'

import { File } from 'components/uploader'
import placeholder from 'images/etc/avatar.svg'

function isDocument(avatar: File | Document): avatar is Document {
  return (avatar as Document).public_url !== undefined
}

type Document = {
  id: number
  public_url: string
}

type Props = {
  avatar?: File | Document
  height?: number
  width?: number
  className?: string
  onClick?: () => void
}

const Avatar: FC<Props> = ({ avatar, width, height, className, onClick }) => {
  const image = useMemo(() => {
    if (!avatar) return
    if (isDocument(avatar)) return avatar.public_url
    return avatar.preview
  }, [avatar])

  return (
    <img
      src={image || placeholder}
      width={width}
      height={height}
      className={classnames(className, {
        'avatar-placeholder': !image,
        'c-pointer': onClick,
      })}
      onClick={onClick}
      alt=""
    />
  )
}

export default Avatar
