import { extFromName, iconForMimeType } from 'helpers'
import { FC } from 'react'

import { ExpandableImage } from 'components/utilities'
import { Document } from 'types/document'

type Props = {
  document: Document | undefined
  empty?: string
}

const Preview: FC<Props> = ({ document, empty }) => {
  if (!document) {
    return <em className="d-block m-t-2 text-center">{empty || 'no documents uploaded'}</em>
  }

  if (document.mimetype.match(/^image\//)) {
    return (
      <ExpandableImage image={document.url} className="preview-holder">
        <img src={document.url} alt="preview" className="preview" />
        <em className="d-block text-center m-t-1 text-muted">view larger</em>
      </ExpandableImage>
    )
  }

  const icon = iconForMimeType(document.mimetype, document.filename)
  const ext = document.filename && _.upperCase(extFromName(document.filename))

  return (
    <a target="_blank" href={document.url} className="preview-holder" rel="noreferrer">
      <img src={icon} alt="" className="m-auto d-block m-t-2" style={{ width: '30%' }} />
      <span className="d-block text-center m-t-1">download {ext}</span>
    </a>
  )
}

export default Preview
