import React from 'react'
import PropTypes from 'prop-types'
import connect from 'helpers/connect'

import { InputGroup, FormControl } from 'react-bootstrap'
import { FaIcon } from 'components/utilities/font-awesome'
import DatePickerModal from 'components/modals/date-picker'
import Field from './field'

const { object, string, func } = PropTypes

class DatePicker extends React.Component {
  static propTypes = {
    name: string.isRequired,
    label: string.isRequired,
    date: object,
    dateFormat: string,
    hint: string,
    onDateSelected: func,
  }

  static defaultProps = {
    dateFormat: 'MMM D, YYYY',
  }

  constructor(props) {
    super(props)

    this.state = {
      date: props.date,
    }
  }

  onDateSelected = date => {
    if (this.props.onDateSelected) {
      this.props.onDateSelected(date)
    }

    this.setState({ date })
  }

  render = () => {
    const { openModal } = this.props.actions
    const { hint, name, label, dateFormat } = this.props
    const { date } = this.state

    return (
      <div>
        <Field name={name} label={label} inputSize={3} hint={hint}>
          <InputGroup onClick={openModal('DatePicker')}>
            <input type="hidden" name={name} value={date ? date.format('YYYY-MM-DD') : ''} />
            <FormControl
              id={name}
              type="text"
              style={{ cursor: 'pointer' }}
              value={date ? date.format(dateFormat) : ''}
              readOnly
              onChange={() => { }}
            />
            <InputGroup.Addon onClick={openModal('DatePicker')} className="btn bg-white">
              <FaIcon calendar />
            </InputGroup.Addon>
          </InputGroup>
        </Field>

        <DatePickerModal onDateSelected={this.onDateSelected} />
      </div>
    )
  }
}

export default connect(DatePicker)
