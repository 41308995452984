import React from 'react'
import modal from 'helpers/modal'

import { Modal } from 'react-bootstrap'
import { Typeahead } from 'components/typeahead'
import { Form, Field, SubmitButton } from 'components/form'

import { closeModal } from 'hooks/useModal'

type Props = {
  data: Record<string, any>
  valueProperty: string
  action: string
  selected: number[]
  title: string
  button: string
  query?: string
  members: {}[]
  membersName: string
  metadata: {
    ids: string
  }
  onSuccess: (data: any) => void
}

type State = {
  selected: number[]
}

class SelectMembersModal extends React.Component<Props, State> {
  static defaultProps = {
    valueProperty: 'id',
  }

  constructor(props: Props) {
    super(props)

    let selected = props.selected
    if (_.isEmpty(selected) && props.metadata.ids) {
      selected = _.map(props.metadata.ids.split(/-/), _.toNumber)
    }

    this.state = { selected }
  }

  onMemberChange = (members: string[]) => {
    const { valueProperty } = this.props
    const selected = _.map(members, valueProperty)
    this.setState({ selected })
  }

  render() {
    const { title, button, members, membersName } = this.props
    const { selected } = this.state
    const { valueProperty } = this.props

    const formProps = _.pick(this.props, ['action', 'data', 'query', 'onSuccess'])
    const fieldProps = _.pick(this.props, ['name', 'label'])

    _.set(formProps.data, membersName, this.state.selected)

    return (
      <Modal show onHide={closeModal}>
        {/* @ts-ignore */}
        <Form {...formProps}>
          <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Field {...fieldProps} autoFocus />

            <Field label="Members">
              <Typeahead
                name="transaction.member_ids"
                multiple
                labelKey="name"
                placeholder="Select members..."
                multiplePlaceholder="Select members..."
                options={members || []}
                selected={_.filter(members, m => _.includes(selected, _.get(m, valueProperty)))}
                onChange={this.onMemberChange}
              />
            </Field>
          </Modal.Body>
          <Modal.Footer>
            <SubmitButton offset={5}>{button}</SubmitButton>
          </Modal.Footer>
        </Form>
      </Modal>
    )
  }
}

export default modal<Props>('SelectMembers', SelectMembersModal)
