import asyncThunk from 'utils/asyncThunk'

export type Term = {
  id: number
  name: string
  started_on: string
}

export type Request = {
  query: string
  organization_id: number
}

export const listTerms = asyncThunk<Request, any>({
  name: 'terms/list',
  method: 'GET',
  uri: '/terms',
})
