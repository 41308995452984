import { useMemo } from 'react'

import { Voter } from 'thunks/poll-votes/list'

import { Poll } from '../show-container/type'

export default function useVoteMap(poll: Poll | undefined, voters: Voter[] | undefined) {
  return useMemo(() => {
    if (!poll) return

    const optionMap = poll.poll_options.reduce((acc, option) => {
      acc[option.id] = []
      return acc
    }, {} as Record<number, Voter[]>)

    return voters?.reduce((acc, voter) => {
      if (!voter.poll_option_ids) return acc

      voter.poll_option_ids.forEach(id => {
        if (!(id in acc)) {
          acc[id] = []
        }

        acc[id].push(voter)
      })

      return acc
    }, optionMap)
  }, [poll, voters])
}
