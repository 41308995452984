import { FC, ReactNode } from 'react'

type Props = {
  children: ReactNode
  className?: string
  show: boolean
}

const Toggle: FC<Props> = ({ show, children, className }) => (
  <div className={show ? className : 'hidden'}>{children}</div>
)

export default Toggle
