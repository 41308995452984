import { FC } from 'react'
import { EmptyState } from 'components/utilities'
import VotingContainer from 'components/voting/index-container'
import { listPollsByUser, Request } from 'thunks/polls/list-by-user'
import useLegacyContext from 'hooks/useLegacyContext'
import useList from 'hooks/useList'

import { Poll } from 'components/voting/index-container/type'
// @ts-ignore
import { pollQuery } from 'components/voting/index-container/type?query'

const VotingIndex: FC = () => {
  const { user } = useLegacyContext()

  const [polls] = useList<Poll, Request>(listPollsByUser, {
    query: pollQuery,
    user_id: user!.id,
  })

  return <VotingContainer polls={polls} emptyState={<EmptyState>There are no open votes</EmptyState>} />
}

export default VotingIndex
