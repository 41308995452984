import { FC, useCallback } from 'react'
import { Modal } from 'react-bootstrap'
import { FormProvider } from 'react-hook-form'

import SubmitButton from 'components/form/button'
import ConnectedField from 'components/form/connected-field'
import modal from 'helpers/modal'
import { useAPIFormHandler } from 'hooks/useAPIFormHandler'
import useLegacyContext from 'hooks/useLegacyContext'
import { closeModal } from 'hooks/useModal'
import { createMessageCategory, Request } from 'thunks/message-categories/create'
import { isFederation, isMember } from 'types/user-functions'
import { Visibility } from 'types/visibility'
import notify from 'utils/notify'
import objectKeys from 'utils/object-keys'

import { MessageCategory } from '../type'
// @ts-ignore
import { messageCategoryQuery } from '../type?query'
import VisibilitySelector, { RecipientType } from './_Visibility'

type FormData = Visibility<RecipientType> & {
  name: string
}

type Props = {
  onCreate: (category: MessageCategory) => void
}

const NewCategoryModal: FC<Props> = ({ onCreate }) => {
  const { user } = useLegacyContext()

  const [handleSubmit, { form, timer }] = useAPIFormHandler<FormData, MessageCategory, Request>({
    model: 'message_category',
    thunk: createMessageCategory,
    defaultValues: {
      visibility: 'members_and_alumni',
    },
    request: data => {
      if (!isMember(user) && !isFederation(user)) throw new Error('invalid role')

      const info = isFederation(user)
        ? {
            visibility: data.visibility,
            owner_type: 'Federation',
            owner_id: user.federation.id,
          }
        : {
            visibility: 'organization_members',
            owner_type: 'Organization',
            owner_id: user.member.organization.id,
          }

      return {
        query: messageCategoryQuery,
        message_category: {
          ...data,
          ...info,
        },
      }
    },
    onSuccess: messageCategory => {
      notify('Your category has been created')
      onCreate(messageCategory)
      closeModal()
    },
  })

  const vals = form.watch()
  const handleVisibilityChange = useCallback(
    (values: Visibility<RecipientType>) => {
      objectKeys(values).forEach(key => form.setValue(key, values[key]))
    },
    [form]
  )

  return (
    <Modal show onHide={closeModal}>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(handleSubmit)}>
          <Modal.Header closeButton>
            <Modal.Title>New category</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <hr className="spacer-xs" />

            <ConnectedField label="Category title" name="name" autoFocus />

            {user.role === 'federation' && (
              <ConnectedField label="Access" name="visibility">
                <VisibilitySelector
                  value={vals}
                  options={[
                    'members_and_alumni',
                    'federation_members',
                    'federation_alumni',
                    'organization_members',
                    'organization_admins',
                    'executive_board_positions',
                  ]}
                  onChange={handleVisibilityChange}
                />
              </ConnectedField>
            )}
          </Modal.Body>
          <Modal.Footer>
            <SubmitButton timer={timer}>Create category</SubmitButton>
          </Modal.Footer>
        </form>
      </FormProvider>
    </Modal>
  )
}

export default modal<Props>('NewCategory', NewCategoryModal)
