import { FC, useEffect, useState } from 'react'

import Loading from 'components/utilities/loading'
import useActiveSidebarItem from 'hooks/useActiveSidebarItem'
import { useAPI } from 'hooks/useAPI'
import { listPaymentPlans, Request } from 'thunks/payment-plans/list'

import ActivePaymentPlan from './_active'
import PaymentPlanForm from './_form'
import PendingPaymentPlan from './_pending'
import ProposedPaymentPlan from './_proposed'
import { PaymentPlan as PaymentPlanType } from './type'
// @ts-ignore
import { paymentPlanQuery } from './type?query'

type Props = {
  memberId: number
}

const SharedPaymentPlansIndex: FC<Props> = ({ memberId }) => {
  useActiveSidebarItem('Payment plan')

  const [paymentPlan, setPaymentPlan] = useState<PaymentPlanType | null>()
  const [list] = useAPI<PaymentPlanType[], Request>(listPaymentPlans)
  useEffect(() => {
    list({
      query: paymentPlanQuery,
      member_id: memberId,
    }).then(([paymentPlans]) => {
      if (paymentPlans.length === 0) setPaymentPlan(null)
      else setPaymentPlan(paymentPlans[0])
    })
  }, [memberId, list])

  if (paymentPlan === undefined) {
    return <Loading />
  }

  if (paymentPlan === null) {
    return <PaymentPlanForm memberId={memberId} onPaymentPlanCreate={setPaymentPlan} />
  }

  if (paymentPlan.status === 'requested') {
    return <PendingPaymentPlan paymentPlan={paymentPlan} />
  }

  if (paymentPlan.status === 'proposed') {
    return (
      <ProposedPaymentPlan
        paymentPlan={paymentPlan}
        onPaymentPlanAccept={setPaymentPlan}
        onPaymentPlanReject={() => setPaymentPlan(null)}
      />
    )
  }

  return <ActivePaymentPlan paymentPlan={paymentPlan} />
}

export default SharedPaymentPlansIndex
