import { createRef, FC, useCallback, useEffect, useRef, useState } from 'react'
import SignatureCanvas from 'react-signature-canvas'
import { Button } from 'react-bootstrap'
import { Body, Portlet } from 'components/utilities'
import S3ProgressBar from 'components/uploader/s3-progress-bar'
import getS3UploadInfo from 'utils/getS3UploadInfo'

import { Errors } from './index'

const height = window.document.body.clientWidth < 600 ? 250 : 150

type Props = {
  acceptance: {
    has_signature: boolean
  }
  isReady: boolean
  isSaving: boolean
  errors: Errors
  doSubmit: () => void
  onUpdateAcceptance: (key: string, value: any) => void
}

const Signature: FC<Props> = ({ acceptance, isReady, isSaving, errors, onUpdateAcceptance, doSubmit }) => {
  const [s3Props, setS3Props] = useState({})

  const canvasRef = createRef<SignatureCanvas>()

  const uploadSignature = useCallback(() => {
    canvasRef.current?.getCanvas().toBlob(async (file: Blob | null) => {
      if (!file) return

      const { data: s3 } = await getS3UploadInfo()()
      onUpdateAcceptance('s3_path', s3.path)
      setS3Props({ s3: s3, file })
    }, 'image/png')
  }, [canvasRef, onUpdateAcceptance])

  const onSignatureChange = useCallback(() => {
    const isEmpty = canvasRef.current?.isEmpty()
    if (acceptance.has_signature === isEmpty) {
      onUpdateAcceptance('has_signature', !isEmpty)
    }
  }, [acceptance.has_signature, canvasRef, onUpdateAcceptance])

  const prevErrorsRef = useRef<Errors>()
  useEffect(() => {
    prevErrorsRef.current = errors
  })

  const prevErrors = prevErrorsRef.current
  useEffect(() => {
    if (prevErrors && Object.keys(prevErrors).length !== 0 && !_.isEmpty(s3Props)) {
      setS3Props({})
    }
  }, [prevErrors, s3Props])

  return (
    <Portlet boxed>
      <Body>
        <h6 className="m-b-1">Please sign in the box below.</h6>

        <SignatureCanvas
          ref={canvasRef}
          onEnd={onSignatureChange}
          canvasProps={{
            height,
            className: 'signature-canvas',
          }}
        />

        {Object.keys(errors).length === 0 && !_.isEmpty(s3Props) && !isSaving && (
          <S3ProgressBar {...s3Props} sendDimensions onSuccess={doSubmit} />
        )}

        <div className="m-t-1">
          <Button
            onClick={() => {
              canvasRef.current?.clear()
              onSignatureChange()
            }}
            disabled={isSaving}
            className="pull-left"
          >
            Clear
          </Button>

          <Button
            onClick={uploadSignature}
            disabled={!acceptance.has_signature || !isReady || isSaving || Object.keys(errors).length > 0}
            bsStyle="secondary"
            className="pull-right"
          >
            {isSaving ? 'Please wait...' : 'Sign'}
          </Button>
        </div>
      </Body>
    </Portlet>
  )
}

export default Signature
