import React from 'react'
import PropTypes from 'prop-types'
import { Radio } from 'react-bootstrap'
import Container from 'container'
import { Field, RemoteSelect } from 'components/form'
import { Typeahead } from 'components/typeahead'
import { HiddenMultipleInput } from 'components/utilities'
import { Organization, Federation } from 'resources'

const { string, arrayOf, number, func } = PropTypes

class SuperRecipientPicker extends Container {
  static propTypes = {
    defaultReceivers: string,
    receiverInputName: string.isRequired,
    chapterInputName: string,
    defaultOrganizationIds: arrayOf(number),
    defaultCustomList: string,
    federationForVisibilityId: number,
    onChangeFederation: func,
  }

  static defaultProps = {
    chapterInputName: 'permitted_organization_ids',
  }

  constructor(props) {
    const { defaultReceivers, defaultOrganizationIds } = props
    const receivers = defaultReceivers === undefined ? 'federation_members' : defaultReceivers

    super(props, {
      receivers,
      selectedOrganizationIds: defaultOrganizationIds,
    })
  }

  componentDidMount() {
    super.componentDidMount()

    Organization.list({
      query: `
        organization {
          name
          secondary

          federation {
            name
          }
        }
      `,
      onSuccess: this.onSuccessOrganization,
    })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    super.UNSAFE_componentWillReceiveProps(nextProps)

    const { federationId } = nextProps

    if (this.props.federationId !== federationId) {
      Organization.list({
        query: `
          organization {
            name
            secondary

            federation {
              name
            }
          }
        `,
        data: { federation_id: federationId },
        onSuccess: this.onSuccessOrganization,
      })
    }
  }

  onSuccessOrganization = ({ data }) => {
    const organizations = _.chain(data)
      .map(org =>
        _.merge(org, {
          full: `${org.federation.name} at ${org.secondary}`,
        })
      )
      .sortBy('full')
      .value()

    this.setState({ organizations })
  }

  onVisibilityChange = e => {
    this.setState({ receivers: e.target.value })
  }

  onRecipientChange = keyName => values => {
    this.setState({ [keyName]: _.map(values, 'id') })
  }

  createInputName = name => {
    const { formPrefix } = this.props

    return `${formPrefix}.${name}`
  }

  renderOrganizationsPicker() {
    const { chapterInputName } = this.props

    const { selectedOrganizationIds, organizations } = this.state

    return (
      <Field name={this.createInputName(chapterInputName)} noFeedbackControl>
        <Typeahead
          multiple
          labelKey="full"
          placeholder="Select chapters..."
          multiplePlaceholder="Select chapters..."
          options={organizations}
          selected={_.filter(organizations, o => _.includes(selectedOrganizationIds, o.id))}
          onChange={this.onRecipientChange('selectedOrganizationIds')}
          className="m-t-1"
        />
        <HiddenMultipleInput name={this.createInputName(chapterInputName)} values={selectedOrganizationIds} />
      </Field>
    )
  }

  renderFederationPicker = () => {
    const { federationForVisibilityId, onChangeFederation } = this.props

    return (
      <Field name="required_document.federation_for_visibility_id" noFeedbackControl>
        <RemoteSelect
          resource={Federation}
          query="federation { name }"
          name="required_document.federation_for_visibility_id"
          display="name"
          placeholder="&mdash; select federation &mdash;"
          selectedIds={[federationForVisibilityId]}
          onChange={onChangeFederation}
          className="m-t-1"
        />
      </Field>
    )
  }

  render = () => {
    const { receiverInputName, defaultCustomList } = this.props

    const { receivers, organizations } = this.state

    const fullReceiverInputName = this.createInputName(receiverInputName)

    if (_.isNil(organizations)) return null

    return (
      <div>
        <Radio
          defaultChecked={receivers === 'federation_members' || _.isEmpty(receivers)}
          name={fullReceiverInputName}
          onChange={this.onVisibilityChange}
          value="federation_members"
        >
          Federation members
        </Radio>

        {receivers === 'federation_members' && this.renderFederationPicker()}

        <Radio
          defaultChecked={receivers === 'federation_admins'}
          name={fullReceiverInputName}
          onChange={this.onVisibilityChange}
          value="federation_admins"
        >
          Federation admins
        </Radio>

        {receivers === 'federation_admins' && this.renderFederationPicker()}

        <Radio
          defaultChecked={receivers === 'federation_alumni'}
          name={fullReceiverInputName}
          onChange={this.onVisibilityChange}
          value="federation_alumni"
        >
          Federation alumni
        </Radio>

        {receivers === 'federation_alumni' && this.renderFederationPicker()}

        <Radio
          defaultChecked={receivers === 'organization_members'}
          name={fullReceiverInputName}
          onChange={this.onVisibilityChange}
          value="organization_members"
        >
          Members from an organization
        </Radio>

        {receivers === 'organization_members' && this.renderOrganizationsPicker()}

        <Radio
          defaultChecked={receivers === 'organization_admins'}
          name={fullReceiverInputName}
          onChange={this.onVisibilityChange}
          value="organization_admins"
        >
          Admins from an organization
        </Radio>

        {receivers === 'organization_admins' && this.renderOrganizationsPicker()}

        <Radio
          name={fullReceiverInputName}
          defaultChecked={receivers === 'custom_list'}
          onChange={this.onVisibilityChange}
          value="custom_list"
        >
          Specify a custom list of recipients
        </Radio>

        {receivers === 'custom_list' && (
          <div className="m-t-2">
            <Field
              className="col-md-12"
              textarea={6}
              defaultValue={defaultCustomList}
              name={this.createInputName('custom_list')}
              showAllErrors
            />
            <em className="text-muted m-t-neg-1 d-block">
              Separate the email addresses by either commas or new lines.
            </em>
          </div>
        )}
      </div>
    )
  }
}

export default SuperRecipientPicker
