import asyncThunk from 'utils/asyncThunk'

export type Request = {
  query: string
  federation_id: number
  include_organization_groups?: boolean
}

export const listAlumniGroupsByFederation = asyncThunk<Request, any>({
  name: 'groups/alumni-groups-by-federation',
  uri: '/groups/alumni_groups_by_federation',
})
