import classNames from 'classnames'
import { FC, ReactNode, useCallback, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'

import modal from 'helpers/modal'
import { closeModal } from 'hooks/useModal'

type Props = {
  id?: string | number
  prompt: ReactNode
  yes: string
  showLoading?: boolean
  onConfirm: () => void
}

const ConfirmModal: FC<Props> = ({ prompt, yes, showLoading, onConfirm }) => {
  const [isLoading, setIsLoading] = useState(false)

  const handleConfirm = useCallback(() => {
    onConfirm()

    if (showLoading) {
      setIsLoading(true)
    } else {
      closeModal()
    }
  }, [showLoading, onConfirm])

  return (
    <Modal show onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>Double checking&hellip;</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <hr className="spacer-xs" />
        <h6 className="text-center m-b-1">{prompt}</h6>
      </Modal.Body>
      <Modal.Footer>
        <Button
          bsStyle="primary"
          onClick={handleConfirm}
          className={classNames({
            'btn-loading': isLoading,
          })}
          disabled={isLoading}
        >
          Yes, {yes}
        </Button>

        <Button onClick={() => closeModal()} autoFocus>
          No, cancel
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default modal<Props>('Confirm', ConfirmModal)
