import asyncThunk from 'utils/asyncThunk'

export type Request = {
  query: string
  federation_id?: number
  has_expiration?: boolean
}

export const listPaymentCategories = asyncThunk<Request, any>({
  name: 'payment-categories/list',
  uri: '/payment_categories',
  method: 'GET',
})
