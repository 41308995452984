import asyncThunk from 'utils/asyncThunk'

export type Request = {
  query: string
  document: {
    filename: string
    orig_path: string
    document_folder_id: number
  }
}

export const createDocument = asyncThunk<Request, any>({
  name: 'documents/create',
  uri: '/documents',
  method: 'POST',
})
