import classNames from 'classnames'
import { FC, ReactNode } from 'react'
import { ButtonToolbar } from 'react-bootstrap'

import { FaButton } from './font-awesome'

type Props = any

const PrintAction: FC<Props> = props => {
  return <TableAction icon="print" onClick={window.print} {...props} />
}

type TableActionProps = {
  icon: string
  className?: string
  disabled?: boolean
  children?: ReactNode
  onClick?: () => void
}

const TableAction: FC<TableActionProps> = props => {
  return <FaButton {...props} />
}

type ButtonBarProps = {
  className?: string
  children?: ReactNode
}

const ButtonBar: FC<ButtonBarProps> = ({ className, children }) => (
  <ButtonToolbar className={classNames('pull-right hidden-print hidden-xs', className)}>{children}</ButtonToolbar>
)

export { PrintAction, TableAction }

export default ButtonBar
