import { isGranted as isGrantedHelper } from 'helpers'
import { FC } from 'react'
import r from 'routes'
import { useRequiredActionsSelector } from 'store'

import { faMoneyBillWave } from '@fortawesome/pro-regular-svg-icons'

import { useLegacyFederation } from 'hooks/useLegacyContext'
import {
  ALUMNI_GROUPS_FEATURE,
  BUDGET_APPROVAL,
  CALENDAR_FEATURE,
  DOCUMENTS_FEATURE,
  EVENTS_FEATURE,
  FEDERATION_BILLPAY,
  FINANCES_FEATURE,
  MESSAGE_BOARD_FEATURE,
  MESSAGING_FEATURE,
  VOTING_FEATURE,
} from 'resources/feature'
import { Feature, FederationFeature } from 'types/feature'

import ContributionTooltip from './alerts/contribution-tooltip'
import OrganizationPaymentPlanTooltip from './alerts/organization-payment-plan-tooltip'
import ReimbursementsTooltip from './alerts/reimbursements-tooltip'
import ReportsTooltip from './alerts/reports-tooltip'
import Divider from './divider'
import FullStory from './fullstory'
import Header from './header'
import Item from './item'

type RequiredActions = {
  reimbursement_count: number
  payment_plan_count: number
  contribution_count: number
  report_count: number
}

const FederationMenu: FC = () => {
  const user = useLegacyFederation()
  const requiredActions = useRequiredActionsSelector() as any as RequiredActions

  const isGranted = (feature: Feature | FederationFeature) => {
    if (user === undefined) {
      return false
    }
    return isGrantedHelper(user, feature)
  }

  const isDemo = () => {
    return user.federation.id === 1
  }

  return (
    <ul className="sidebar-menu">
      {isGranted(FINANCES_FEATURE) && (
        <>
          <Header>Finances</Header>
          <Item id="chapter-financials" url={r.federation.financials.chapters} icon="university">
            Chapter balances
            <OrganizationPaymentPlanTooltip count={requiredActions.payment_plan_count} />
          </Item>
          <Item id="financial-summary" url={r.federation.financials.summary} icon="usd">
            Financial summary
          </Item>
          {/* <Item url={r.federation.financials.trends} icon="line-chart">
            Financial trends
          </Item> */}
          <Item url={r.federation.reports.root} icon="list" id="reports">
            Reports
            <ReportsTooltip pending={requiredActions.report_count} />
          </Item>
          <Item id="Budgets" url="/federation/budgets" icon="book">
            Budgets
            {isGranted(BUDGET_APPROVAL) && (
              <ReimbursementsTooltip
                reimbursements={{
                  pending: requiredActions.reimbursement_count,
                }}
              />
            )}
          </Item>
          {isDemo() && (
            <Item url="/federation/grants" icon="money">
              Grants
            </Item>
          )}
          {isGranted(FEDERATION_BILLPAY) && (
            <Item url="/federation/g-capital" icon="usd">
              gCapital
            </Item>
          )}
          <Item url="/federation/donations" icon="money">
            Donations
          </Item>
          <Item url="/federation/taxes" icon="institution">
            Tax information
          </Item>
          <Item url="/federation/transfers" icon="book">
            Transfers
          </Item>
          {user.federation.id === 29 && (
            <Item url={r.federation.aoe.promissorNotes} icon={faMoneyBillWave}>
              Promissory Notes
            </Item>
          )}
          <Divider />
        </>
      )}

      <Header>Membership</Header>
      <Item url={r.federation.organizations.root} icon="list">
        Chapters
      </Item>
      <Item url={r.federation.executiveBoards.root} icon="address-book">
        Executive Boards
      </Item>
      <Item url="/federation/committees" icon="address-book">
        Chairs/Committees
      </Item>
      {isGranted(EVENTS_FEATURE) && (
        <Item url="/federation/events" icon="calendar">
          Events
        </Item>
      )}
      <Item url="/federation/forms" icon="file-text-o">
        Forms
      </Item>
      <Item url={r.federation.members.root} icon="users">
        Members
      </Item>
      {isDemo() && (
        <Item url="/federation/entities" icon="address-card-o">
          Entities
        </Item>
      )}
      <Item id="contribution-tracker" url={r.federation.contributionTrackers.root} icon="thermometer-three-quarters">
        Contribution Tracker
        <ContributionTooltip count={requiredActions.contribution_count} />
      </Item>
      <Item url={r.federation.attendance.root} icon="clock-o">
        Attendance
      </Item>
      <Item url="/federation/volunteers-and-staff" icon="users">
        Volunteers &amp; staff
      </Item>
      <Item url="/federation/statistics" icon="bar-chart">
        Statistics
      </Item>
      <Item url="/federation/required-documents" icon="file-text">
        Required documents
      </Item>
      {isDemo() && (
        <Item url="/federation/incident-reports" icon="exclamation-triangle">
          Incident reports
        </Item>
      )}

      {(isGranted(CALENDAR_FEATURE) ||
        isGranted(DOCUMENTS_FEATURE) ||
        isGranted(MESSAGE_BOARD_FEATURE) ||
        isGranted(MESSAGING_FEATURE) ||
        isGranted(ALUMNI_GROUPS_FEATURE) ||
        isGranted(VOTING_FEATURE)) && (
          <>
            <Divider />
            <Header>Communication</Header>
            {isGranted(CALENDAR_FEATURE) && (
              <Item url="/federation/calendar" icon="calendar">
                Calendar
              </Item>
            )}
            {isGranted(DOCUMENTS_FEATURE) && (
              <Item url="/federation/document-folders" icon="folder">
                Documents
              </Item>
            )}
            {isGranted(MESSAGE_BOARD_FEATURE) && (
              <Item url={r.federation.messageCategories.root} icon="comment">
                Message Board
              </Item>
            )}
            {isGranted(MESSAGING_FEATURE) && (
              <Item url="/federation/messaging" icon="envelope-o">
                Messaging
              </Item>
            )}
            {isGranted(ALUMNI_GROUPS_FEATURE) && (
              <Item url={r.federation.groups.root} icon="users">
                Alumni groups
              </Item>
            )}
            {isGranted(VOTING_FEATURE) && (
              <Item url="/federation/voting" icon="gavel">
                Voting
              </Item>
            )}
          </>
        )}

      <Divider />
      <Item url={r.federation.settings.root} icon="cog">
        Settings
      </Item>
      <FullStory />
    </ul>
  )
}

export default FederationMenu
