import { FC, useEffect, useMemo, useState } from 'react'
import { Button, ButtonToolbar } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import AddDocumentModal from 'components/documents/add-document-modal'
import { Body, FaButton, Portlet } from 'components/utilities'
import useLegacyContext from 'hooks/useLegacyContext'
import { openModal } from 'hooks/useModal'
import { isAlumni, isFederation } from 'types/user-functions'

import DocumentList from '../document-list'
import FolderList from '../folder-list'
import Header from './_Header'
import useManageDocuments from './_useManageDocuments'
import { DocumentFolder as DocumentFolderType } from './type'

type Props = {
  preview?: true
  canUpload: boolean
  folder: DocumentFolderType
}

const DocumentFolder: FC<Props> = ({ preview = false, canUpload, folder }) => {
  const { user } = useLegacyContext()

  const [documents, setDocuments] = useState<DocumentFolderType['documents']>([])
  useEffect(() => {
    if (preview) {
      setDocuments(folder.recent_documents)
    } else {
      setDocuments(folder.documents.sort((a, b) => a.filename.localeCompare(b.filename)))
    }
  }, [folder, preview])

  const { handleAdd } = useManageDocuments(documents, setDocuments)

  const folderURI = useMemo(() => {
    let url
    if (isFederation(user) && folder.executive_board_position) {
      url = `/federation/executive-boards/document-folders/${folder.executive_board_position.id}`
    } else if (isFederation(user) && folder.organization) {
      url = `/federation/organizations/${folder.organization.id}/documents`
    } else if (isFederation(user)) {
      url = `/federation/document-folders/${folder.id}`
    } else if (isAlumni(user)) {
      url = `/alumni/document-folders/${folder.id}`
    } else {
      url = `/member/document-folders/${folder.id}`
    }
    return url
  }, [user, folder])

  return (
    <>
      <Portlet className="portlet-posts portlet-with-table portlet-with-table">
        <div className="portlet-header clearfix">
          <h4 className="portlet-title">
            <Header folder={folder} folderUri={folderURI} />
          </h4>

          {canUpload && (
            <ButtonToolbar className="hidden-print pull-right">
              <FaButton onClick={openModal('AddDocument', folder.id)} icon="cloud-upload" bsStyle="primary">
                Upload file
              </FaButton>
            </ButtonToolbar>
          )}
        </div>
        <Body>
          {documents.length || folder.document_folders.length ? (
            <>
              {folder.document_folders && <FolderList folders={folder.document_folders} />}

              <DocumentList documents={documents} />
            </>
          ) : (
            <p className="text-center fs-125 p-t-1">No documents have been uploaded yet</p>
          )}

          {preview && !!documents.length && (
            <Link to={folderURI}>
              <Button bsStyle="default" bsSize="sm" className="hidden-print">
                View all documents
              </Button>
            </Link>
          )}
        </Body>
      </Portlet>

      <AddDocumentModal id={folder.id} documentFolderId={folder.id} onUpload={handleAdd} />
    </>
  )
}

export default DocumentFolder
