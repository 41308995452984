import { Moment } from 'moment'

import { createAsyncThunk } from '@reduxjs/toolkit'

export type Request = {
  start: Moment
  end: Moment
}

export type Response = void

const setHalfYearDateRange = createAsyncThunk<Response, Request>('half-year-date-range/set', async () => { })

export default setHalfYearDateRange
