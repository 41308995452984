import { FC, useMemo } from 'react'
import Select from 'react-select'

import { useLegacyFederation } from 'hooks/useLegacyContext'
import useList from 'hooks/useList'
import { listOrganizations, Request } from 'thunks/organizations/list'

type Organization = {
  id: number
  secondary: string
}

type Option = {
  label: string
  value: number
}

type Props = {
  value: number[]
  onChange: (organizationIds: number[]) => void
}

const Organizations: FC<Props> = ({ value, onChange }) => {
  const user = useLegacyFederation()

  const [organizations] = useList<Organization, Request>(listOrganizations, {
    query: 'organization { secondary }',
    federation_id: user.federation.id,
  })

  const options = useMemo(
    (): Option[] =>
      organizations
        ?.map(o => ({
          label: o.secondary,
          value: o.id,
        }))
        .sort((l, r) => l.label.localeCompare(r.label)) || [],
    [organizations]
  )

  return (
    <Select<Option, true>
      isMulti
      placeholder="Select members..."
      options={options}
      value={options?.filter(o => value.includes(o.value)) || []}
      onChange={options => onChange(options.map(o => o.value))}
    />
  )
}

export default Organizations
