import { useCallback } from 'react'

import { useAPI } from 'hooks/useAPI'
import { closeModal } from 'hooks/useModal'
import { archiveParent } from 'thunks/parents/archive'
import notify, { notifyError } from 'utils/notify'

export default function useArchiveParent(parentId: number, onArchive: (parentId: number) => void) {
  const [run, { timer }] = useAPI(archiveParent)

  const archive = useCallback(async () => {
    try {
      await run({ id: parentId })
      onArchive(parentId)
      notify({
        type: 'warning',
        message: 'The parent has been removed.',
      })
      closeModal()
    } catch (err) {
      console.log('[ERROR ARCHIVING PARENT]', err)
      notifyError('Error archiving parent')
    }
  }, [parentId, run, onArchive])

  return [archive, { timer }] as const
}
