import asyncThunk from 'utils/asyncThunk'

export type Request = {
  id: number
}

type Response = {
  balance: number
}

export const organizationBillPayBalance = asyncThunk<Request, Response>({
  name: 'organizations/bill-pay-balance',
  uri: ({ id }) => `/organizations/${id}/bill_pay_balance`,
})
