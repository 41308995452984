import cx from 'classnames'
import React from 'react'
import { Button, Col, FormGroup, Modal, Row } from 'react-bootstrap'
import { PaymentMethod } from 'resources'

import BraintreeBadge from 'components/braintree/badge'
import BraintreeForm from 'components/braintree/form'
import { hasErrors } from 'components/form'
import { Loading, PaymentMethodIcon, Toggle } from 'components/utilities'
import LegacyContext, { LegacyContextType } from 'contexts/legacy'
import modal, { ModalProps } from 'helpers/modal'
import { closeModal } from 'hooks/useModal'
import { PaymentPlanStatus } from 'types/payment-plan'
import notify from 'utils/notify'

type PaymentMethodType = {
  id: number
  method: 'card' | 'bank'
  company: string
  last_four: string
  updated_at: string
  created_at: string

  last_payment?: {
    id: number
    amount: number
    updated_at: string
    created_at: string
  }

  payment_plans: {
    id: number
    status: PaymentPlanStatus
    updated_at: string
    created_at: string
  }[]
}

type OwnProps = {
  onCreditCardCreate: (paymentMethod: PaymentMethodType) => void
  paymentMethodQuery?: string
}

type Props = ModalProps<OwnProps>

type State = {
  ready: boolean
  isLoading: boolean
  cardType?: string
  errors?: Record<string, string>
  validityErrors: Record<string, boolean>
  authorization: unknown
}

class AddCreditCardModal extends React.Component<Props, State> {
  static contextType = LegacyContext
  declare context: LegacyContextType

  static defaultProps = {
    paymentMethodQuery: `
        payment_method {
          method
          company
          last_four
          updated_at
          created_at

          last_payment {
            amount
            updated_at
            created_at
          }

          payment_plans {
            status
            updated_at
            created_at
          }
        }
      `,
  }

  constructor(props: Props) {
    super(props)

    this.state = {
      ready: false,
      cardType: 'unknown',
      isLoading: false,
      validityErrors: {},
      authorization: null,
    }
  }

  componentDidMount() {
    if (this.props.show) {
      this.loadPaymentMethod()
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (nextProps.show && !this.props.show) {
      this.loadPaymentMethod()
      this.setState({
        ready: false,
      })
    }
  }

  onPaymentMethodAuthorizationCreate = ({ data: { authorization } }: { data: { authorization: unknown } }) =>
    this.setState({ authorization })

  onBraintreeReady = () => this.setState({ ready: true })

  onCardTypeChange = (cardType: string) => this.setState({ cardType })

  onError = (errors: Record<string, string>) => this.setState({ errors, isLoading: false })

  onValidityErrorChange = (field: string, validity: boolean) => {
    const { validityErrors } = this.state

    if (validity) {
      delete validityErrors[field]
    } else {
      validityErrors[field] = true
    }

    this.setState({ validityErrors })
  }

  onProcessPayment = ({ nonce }: { nonce: string }) => {
    const { paymentMethodQuery } = this.props
    this.setState({ isLoading: true })

    PaymentMethod.create({
      data: {
        payment_method: {
          user_id: this.context.user!.id,
          braintree_nonce: nonce,
        },
      },
      query: paymentMethodQuery,
      onSuccess: this.onPaymentMethodCreate,
      onFailure: this.onPaymentMethodCreateFailure,
    })
  }

  onPaymentMethodCreate = ({ data: paymentMethod }: { data: PaymentMethodType }) => {
    const { onCreditCardCreate } = this.props

    closeModal()
    notify(`Your ${paymentMethod.company} ending in ${paymentMethod.last_four} has been added to your account.`)

    onCreditCardCreate(paymentMethod)
  }

  onPaymentMethodCreateFailure = ({ data: errors }: { data: Record<string, string> }) => this.setState({ errors })

  getFieldErrors() {
    const ERRMSG_NUMBER_EMPTY = 'A credit card number is required'
    const ERRMSG_NUMBER_INVALID = 'That credit card number appears to be incorrect'
    const ERRMSG_EXPDATE_EMPTY = 'Expiration is required'
    const ERRMSG_EXPDATE_INVALID = 'Expiration appears invalid'
    const ERRMSG_CVV_EMPTY = 'CVV is required'
    const ERRMSG_CVV_INVALID = 'CVV is invalid'
    const ERRMSG_POSTAL_CODE = 'Postal code is invalid'

    const fieldErrors = {} as Record<string, string>
    const { cardType, errors, validityErrors } = this.state

    if (errors) {
      const { code } = errors

      if (code === 'HOSTED_FIELDS_FIELDS_EMPTY') {
        fieldErrors.number = ERRMSG_NUMBER_EMPTY
        fieldErrors.expirationDate = ERRMSG_EXPDATE_EMPTY
        fieldErrors.cvv = ERRMSG_CVV_EMPTY
        fieldErrors.postalCode = ERRMSG_POSTAL_CODE
      } else if (code === 'HOSTED_FIELDS_FIELDS_INVALID') {
        const invalidFieldKeys = _.get(errors, 'details.invalidFieldKeys')

        if (_.includes(invalidFieldKeys, 'number')) {
          fieldErrors.number = ERRMSG_NUMBER_EMPTY
        }
        if (_.includes(invalidFieldKeys, 'expirationDate')) {
          fieldErrors.expirationDate = ERRMSG_EXPDATE_EMPTY
        }
        if (_.includes(invalidFieldKeys, 'cvv')) {
          fieldErrors.cvv = ERRMSG_CVV_EMPTY
        }
        if (_.includes(invalidFieldKeys, 'postalCode')) {
          fieldErrors.postalCode = ERRMSG_POSTAL_CODE
        }
      }
    }

    if (validityErrors.number) {
      fieldErrors.number = ERRMSG_NUMBER_INVALID
    }
    if (validityErrors.expirationDate) {
      fieldErrors.expirationDate = ERRMSG_EXPDATE_INVALID
    }
    if (validityErrors.cvv) {
      fieldErrors.cvv = ERRMSG_CVV_INVALID
    }

    if (cardType === 'american-express') {
      fieldErrors.number = "Sorry, we don't accept American Express"
    }

    return fieldErrors
  }

  loadPaymentMethod = () => {
    PaymentMethod.authorization({
      onSuccess: this.onPaymentMethodAuthorizationCreate,
    })
  }

  render() {
    const { closeModal } = this.props.actions
    const { authorization, cardType, errors, validityErrors, isLoading } = this.state
    const fieldErrors = this.getFieldErrors()

    let disabled = this.state.isLoading
    if (!this.state.ready) disabled = true
    if (cardType === 'american-express') disabled = true
    if (_.size(validityErrors) > 0) disabled = true

    return (
      <Modal show dialogClassName="credit-card-modal" onHide={closeModal}>
        <BraintreeForm
          authorization={authorization}
          onBraintreeReady={this.onBraintreeReady}
          onError={this.onError}
          onProcessPayment={this.onProcessPayment}
          onCardTypeChange={this.onCardTypeChange}
          onValidityErrorChange={this.onValidityErrorChange}
        >
          <Modal.Header closeButton>
            <Modal.Title>Add a credit card</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <hr className="spacer-xs" />

            {this.state.ready ? null : (
              <div className="loading">
                <Loading />
              </div>
            )}

            <div className={cx({ hidden: !this.state.ready })}>
              <FormGroup validationState={fieldErrors.number ? 'error' : null}>
                <Row>
                  <Col sm={12}>
                    <div id="number" className="form-control" />

                    {cardType ? <PaymentMethodIcon icon={cardType} size={36} /> : null}

                    <Toggle show={!_.isEmpty(fieldErrors.number)}>
                      <span className="help-block text-danger m-l-1">{fieldErrors.number}</span>
                    </Toggle>
                  </Col>
                </Row>
              </FormGroup>

              <Row>
                <Col sm={4}>
                  <FormGroup validationState={fieldErrors.expirationDate ? 'error' : null}>
                    <div id="expirationDate" className="form-control" />
                    <Toggle show={!_.isEmpty(fieldErrors.expirationDate)}>
                      <span className="help-block text-danger m-l-1">{fieldErrors.expirationDate}</span>
                    </Toggle>
                  </FormGroup>
                </Col>
                <Col sm={3}>
                  <FormGroup validationState={fieldErrors.cvv ? 'error' : null}>
                    <div id="cvv" className="form-control" />
                    <Toggle show={!_.isEmpty(fieldErrors.cvv)}>
                      <span className="help-block text-danger m-l-1">{fieldErrors.cvv}</span>
                    </Toggle>
                  </FormGroup>
                </Col>
                <Col sm={5}>
                  <FormGroup validationState={fieldErrors.postalCode ? 'error' : null}>
                    <div id="postalCode" className="form-control" />
                    <Toggle show={!_.isEmpty(fieldErrors.postalCode)}>
                      <span className="help-block text-danger m-l-1">{fieldErrors.postalCode}</span>
                    </Toggle>
                  </FormGroup>
                </Col>
              </Row>

              {errors && hasErrors(errors, 'payment_method', '_') && (
                <p className="text-danger fw-semibold m-b-0">{_.get(errors, 'payment_method._')[0]}</p>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <BraintreeBadge height={28} className="pull-left" />

            <Button
              type="submit"
              bsStyle="primary"
              disabled={disabled}
              className={cx({
                'btn-loading': isLoading,
              })}
            >
              Add credit card
            </Button>
          </Modal.Footer>
        </BraintreeForm>
      </Modal>
    )
  }
}

export default modal<OwnProps>('AddCreditCard', AddCreditCardModal)
