import { FC, ReactNode } from 'react'

type Props = {
  className?: string
  children: ReactNode
}

const Email: FC<Props> = ({ children, className }) => (
  <a href={`mailto:${children}`} className={className}>
    {children}
  </a>
)

export default Email
