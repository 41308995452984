import asyncThunk from 'utils/asyncThunk'

export type Request = {
  query: string
  id: number
}

export const retrieveHourRequirement = asyncThunk<Request, any>({
  name: 'hour-requirements/retrieve',
  uri: ({ id }) => `/hour_requirements/${id}`,
})
