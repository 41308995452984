import { forwardRef, useMemo } from 'react'

import createTable, { TableRef } from 'components/table-v2'
import { Body as Wrapper, Date, Label, Money, Portlet } from 'components/utilities'
import { LedgerItem } from 'thunks/issued-cards/ledger'
import formatMoney from 'utils/format-money'
import formatDate from 'utils/format_date'

type Props = {
  ledger: LedgerItem[]
  startDate: string
  startingBalance: number
}

const { Table, Header, Column, FirstRow, Body, Row, Cell } = createTable<LedgerItem>()

const IssuedCardLedger = forwardRef<TableRef, Props>(({ ledger, startDate, startingBalance }, ref) => {
  const balances = useMemo(() => {
    if (!ledger) return {}

    let balance = startingBalance
    return ledger.reduce((memo, t) => {
      balance -= t.amount
      memo[t.id] = balance
      return memo
    }, {} as Record<string, number>)
  }, [ledger, startingBalance])

  if (ledger.length === 0) {
    return (
      <Portlet boxed>
        <Wrapper>
          <p className="text-center text-muted text-2xl">No charges have been made on the card yet.</p>
        </Wrapper>
      </Portlet>
    )
  }

  return (
    <Portlet boxed>
      <Wrapper>
        <Table ref={ref} data={ledger} noSort>
          <Header>
            <Column width={15}>Date</Column>
            <Column width={14}>Status</Column>
            <Column width={20}>Merchant</Column>
            <Column width={20} align="right">
              Amount
            </Column>
            <Column width={15} align="right">
              Balance
            </Column>
          </Header>
          <FirstRow>
            <tr>
              <td>
                <Date date={startDate} short />
              </td>
              <td className="text-muted">n/a</td>
              <td>Starting budget</td>
              <td className="text-right">
                <Money amount={startingBalance} />
              </td>
              <td className="text-right">
                <Money warning amount={startingBalance} />
              </td>
            </tr>
          </FirstRow>
          <Body>
            {transaction => (
              <Row key={transaction.id} id={transaction.id}>
                <Cell value={formatDate(transaction.created_at, 'short')}>
                  <Date date={transaction.created_at} short />
                </Cell>
                <Cell>
                  {transaction.id.match(/IssuedCardTransfer/) ? (
                    <>
                      {transaction.status === 'approved' ? (
                        <Label info>Transfer</Label>
                      ) : (
                        <Label warning>Pending approval</Label>
                      )}
                    </>
                  ) : transaction.id.match(/IssuedCardTransaction/) && transaction.amount > 0 ? (
                    <Label success>Paid</Label>
                  ) : transaction.id.match(/IssuedCardTransaction/) && transaction.amount < 0 ? (
                    <Label warning>Refunded</Label>
                  ) : (
                    <></>
                  )}
                </Cell>
                <Cell>{transaction.description}</Cell>
                <Cell value={formatMoney(transaction.amount)}>
                  <Money amount={transaction.amount} praise plus />
                </Cell>
                <Cell value={formatMoney(balances[transaction.id])}>
                  <Money amount={balances[transaction.id]} praise plus />
                </Cell>
              </Row>
            )}
          </Body>
        </Table>
      </Wrapper>
    </Portlet>
  )
})

export default IssuedCardLedger
