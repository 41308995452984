import { store } from 'store'

export function useModal(id: string, suffix?: string) {
  const parts = window.location.hash.split(':')
  const hash = parts[0]
  const match = hash === `#${id}` || hash === `#${id}:${suffix}`
  return [match, parts[1]] as const
}

// This counter will be used as the key on the modal, reseting it each time it's opened
var counter = 0

export function openModal(id: string, suffix?: string | number, append = true) {
  return () => {
    let hash = suffix ? `${id}:${suffix}` : id
    if (window.location.hash && append) hash = `${window.location.hash};${hash}`
    window.location.hash = hash
    return true
  }
}

export function closeModal(opts?: { closeAll?: boolean }) {
  if (opts?.closeAll) return removeHash()

  const parts = window.location.hash.split(';')
  if (parts.length === 1) return removeHash()

  window.location.hash = parts.slice(0, -1).join(';')

  counter++
}

export function modalKey(): number {
  return counter
}

function removeHash() {
  window.history.pushState('', document.title, window.location.pathname + window.location.search)
  store.dispatch({
    type: '@@router/LOCATION_CHANGE',
    payload: {
      location: {
        pathname: window.location.pathname,
        search: window.location.search,
        hash: '',
      },
      action: 'POP',
      isFirstRendering: false,
    },
  })
}
