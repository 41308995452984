import { createContext, ReactElement } from 'react'

export type FormContextType = {
  form: {
    addSubmit: (el: ReactElement) => void
    removeSubmit: (el: ReactElement) => void
  }
  errors: Record<string, string[]>
}

export const FormContext = createContext<FormContextType>({} as FormContextType)
