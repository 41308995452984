import { FC, useCallback, useState } from 'react'
import { Col, Nav, NavItem, Row } from 'react-bootstrap'
import { ProposedPayment } from 'components/payment-plans/common'
import { PaymentMethod } from 'types/payment-method'
import { PaymentPlanStatus } from 'types/payment-plan'

import ConfirmationSection from './_confirmation'
import NoteSection from './_note'
import PaymentMethodSection from './_payment-method'
import ScheduleSection from './_schedule'

type PaymentPlan = {
  id?: number
  status?: PaymentPlanStatus
  note?: string
  payment_method?: PaymentMethod
  payment_plan_payments: {
    amount: number
    due_on: string
  }[]
}

type Props = {
  paymentPlan?: PaymentPlan
  balance: number
  isLoading: boolean
  doPaymentPlanCreate: (paymentPlan: PaymentPlan) => void
}

type Section = 1 | 2 | 3 | 4

const PaymentPlanForm: FC<Props> = ({ paymentPlan: pPlan, balance, isLoading, doPaymentPlanCreate }) => {
  const [section, setSection] = useState<Section>(1)
  const [paymentPlan, setPaymentPlan] = useState<PaymentPlan>(pPlan || { payment_plan_payments: [] })

  // Section Management
  const goToSection = useCallback((section: Section) => setSection(section), [])
  const previousSection = useCallback(() => goToSection((section - 1) as Section), [goToSection, section])
  const nextSection = useCallback(() => goToSection((section + 1) as Section), [goToSection, section])

  // Section success callbacks
  const onScheduleSelection = useCallback(
    (payments: ProposedPayment[]) => {
      setPaymentPlan({
        ...paymentPlan,
        payment_plan_payments: payments,
      })
      nextSection()
    },
    [nextSection, paymentPlan]
  )

  const onNoteSelection = useCallback(
    (note: string) => {
      setPaymentPlan({
        ...paymentPlan,
        note: note,
      })
      nextSection()
    },
    [nextSection, paymentPlan]
  )

  const onPaymentMethodSelection = useCallback(
    (paymentMethod: PaymentMethod) => {
      setPaymentPlan({
        ...paymentPlan,
        payment_method: paymentMethod,
      })
      nextSection()
    },
    [nextSection, paymentPlan]
  )

  const onConfirmation = useCallback(() => {
    doPaymentPlanCreate(paymentPlan)
  }, [doPaymentPlanCreate, paymentPlan])

  const renderStep = useCallback(() => {
    switch (section) {
      case 1:
        return <ScheduleSection balance={balance} onScheduleSelection={onScheduleSelection} />

      case 2:
        return <NoteSection paymentPlan={paymentPlan} onNoteSelection={onNoteSelection} goBack={previousSection} />

      case 3:
        return <PaymentMethodSection onPaymentMethodSelection={onPaymentMethodSelection} goBack={previousSection} />

      case 4:
        return (
          <ConfirmationSection
            paymentPlan={paymentPlan}
            onConfirmation={onConfirmation}
            goBack={previousSection}
            isLoading={isLoading}
          />
        )

      default:
        return null
    }
  }, [
    balance,
    isLoading,
    onConfirmation,
    onNoteSelection,
    onPaymentMethodSelection,
    onScheduleSelection,
    paymentPlan,
    previousSection,
    section,
  ])

  return (
    <Row>
      <Col sm={3}>
        <Nav bsStyle="pills" stacked activeKey={section} onSelect={(key: any) => goToSection(key as Section)}>
          <NavItem eventKey={1}>1. Dates &amp; amounts</NavItem>
          <NavItem eventKey={2} disabled={section < 2}>
            2. Note
          </NavItem>
          <NavItem eventKey={3} disabled={section < 3}>
            3. Payment method
          </NavItem>
          <NavItem eventKey={4} disabled={section < 4}>
            4. {paymentPlan.status === 'proposed' ? 'Accept proposal' : 'Submit request'}
          </NavItem>
        </Nav>
      </Col>

      <Col sm={9} style={{ minHeight: '358px' }}>
        {renderStep()}
      </Col>
    </Row>
  )
}

export default PaymentPlanForm
