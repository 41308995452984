import asyncThunk from 'utils/asyncThunk'

export type Request = {
  id: number
  query: string
}

export const approveContribution = asyncThunk<Request, any>({
  name: 'contributions/approve',
  uri: ({ id }) => `/contributions/${id}/approve`,
  method: 'PUT',
})
