import cx from 'classnames'
import { iconForMimeType } from 'helpers'
import { FC, ReactNode, useMemo } from 'react'
import { Link } from 'react-router-dom'

import { Date, FaIcon, Label, Size } from 'components/utilities'
import useLegacyContext from 'hooks/useLegacyContext'
import parseMetadata from 'utils/parse-metadata'

import { DocumentFolder } from 'components/documents/folder/type'

export type Document = DocumentFolder['documents'][number]

type Props = {
  documents: Document[]
  actions?: (document: Document) => ReactNode
}

const DocumentList: FC<Props> = ({ documents, actions }) => {
  const { user } = useLegacyContext()

  const highlightedId = useMemo(() => +parseMetadata(window.location).id, [])

  if (documents.length === 0) return <></>

  return (
    <div>
      <table className="table table-documents mb-0">
        <tbody>
          {documents.map((document, idx) => (
            <tr
              key={idx}
              data-id={document.id}
              className={cx({
                highlighted: highlightedId === document.id,
              })}
            >
              <td style={{ width: '45%' }}>
                {highlightedId === document.id && <FaIcon arrow-right className="text-primary m-r-1" />}
                <img
                  src={iconForMimeType(document.mimetype, document.filename)}
                  width={36}
                  height={36}
                  alt={document.filename}
                />
                <div>
                  <a href={document.url} target="_blank" rel="noreferrer">
                    {document.filename}
                  </a>
                  {document.document_labels && (
                    <div className="m-t-05">
                      {document.document_labels.map(({ label }, i) => (
                        <Link
                          key={i}
                          to={
                            user.role === 'federation'
                              ? `/federation/documents/search/${label}`
                              : `/member/documents/search/${label}`
                          }
                        >
                          <Label inverted className="m-r-05">
                            {label}
                          </Label>
                        </Link>
                      ))}
                    </div>
                  )}
                </div>
              </td>
              <td className="text-right" style={{ width: '10%' }}>
                <Size size={document.size} />
              </td>
              <td className="text-right" style={{ width: '20%' }}>
                <Date short date={document.created_at} />
              </td>
              {actions && (
                <td className="hidden-print text-right text-muted" style={{ width: '25%' }}>
                  {actions(document)}
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default DocumentList
