import asyncThunk from 'utils/asyncThunk'

export type Request = {
  query: string
  organization_id?: number
  federation_id: number
}

export const listStaffMembers = asyncThunk<Request, any>({
  name: 'staff-members/list',
  uri: '/staff_members',
})
