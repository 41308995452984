import React, { FC } from 'react'
import { Modal } from 'react-bootstrap'

import { Field, Form, SubmitButton } from 'components/form'
import modal from 'helpers/modal'
import { closeModal } from 'hooks/useModal'

type Props = {
  title: string
  button: string
  name: string
  label: string
  // TODO Refactor Form
  action: (opts: Request) => void
  // TODO Refactor Form
  onSuccess: (opts: Request) => void
}

const MoneyModal: FC<Props> = props => {
  const { title, button } = props

  const formProps = _.pick(props, ['method', 'action', 'data', 'query', 'id', 'onSuccess'])
  const fieldProps = _.pick(props, [
    'hint',
    'name',
    'label',
    'textarea',
    'placeholder',
    'defaultValue',
    'filename',
    'onChange',
  ])

  return (
    <Modal show onHide={closeModal}>
      {/* TODO Refactor Form */}
      {/* @ts-ignore */}
      <Form {...formProps}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <hr className="spacer-xs" />

          <Field money {...fieldProps} autoFocus />
        </Modal.Body>
        <Modal.Footer>
          <SubmitButton offset={5}>{button}</SubmitButton>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default modal<Props>('Money', MoneyModal)
