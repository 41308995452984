import { FC, useMemo, useRef } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useAppDispatch } from 'store'

import { TableRef } from 'components/table-v2'
import TermSelector from 'components/terms/selector'
import TransactionsTable from 'components/transactions/table'
import { Body, ButtonBar, FaButton, Portlet, PrintAction, TableAction } from 'components/utilities'
import downloadPDFBill from 'thunks/downloads/pdf-bill'
import { Ledgers } from 'thunks/members/ledgers'

type Props = {
  memberId: number
  ledgers: Ledgers
}

const Ledger: FC<Props> = ({ memberId, ledgers }) => {
  const dispatch = useAppDispatch()
  const ref = useRef<TableRef>(null)

  const transactions = useMemo(
    () =>
      _.chain(ledgers)
        .map('ledger')
        .flatten()
        .sortBy('created_at')
        // @ts-ignore
        .map(t => _.assign(t, t.transaction || {}))
        .uniqBy('id')
        .value(),
    [ledgers]
  )

  return (
    <>
      <Portlet boxed>
        <Body table>
          {ledgers ? (
            <TransactionsTable ref={ref} transactions={transactions} />
          ) : (
            <p className="fw-semibold m-t-1 text-center fs-125">No transactions yet</p>
          )}
        </Body>
      </Portlet>

      <Row>
        <Col sm={5} lg={4}>
          <TermSelector showAll />
        </Col>
        <Col sm={7} lg={8} className="align-right">
          <ButtonBar>
            <FaButton icon="download" onClick={() => dispatch(downloadPDFBill({ memberId }))}>
              Download PDF
            </FaButton>

            <PrintAction />
            <TableAction icon="download" onClick={() => ref.current?.downloadCSV('Ledger')} />
          </ButtonBar>
        </Col>
      </Row>
    </>
  )
}

export default Ledger
