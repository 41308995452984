/**
 * KeyCode
 *
 * Map of common (non-printable) keycodes for the `keydown` and `keyup` events.
 * Note that `keypress` handles things differently and may not return the same
 * values.
 */

export const BACKSPACE = 8
export const TAB = 9
export const RETURN = 13
export const ESC = 27
export const SPACE = 32
export const LEFT = 37
export const UP = 38
export const RIGHT = 39
export const DOWN = 40
