import React, { FC } from 'react'
import { FormControl } from 'react-bootstrap'

const countries = [
  {
    name: 'United States',
    states: {
      AL: 'Alabama',
      AK: 'Alaska',
      AZ: 'Arizona',
      AR: 'Arkansas',
      CA: 'California',
      CO: 'Colorado',
      CT: 'Connecticut',
      DE: 'Delaware',
      FL: 'Florida',
      GA: 'Georgia',
      HI: 'Hawaii',
      ID: 'Idaho',
      IL: 'Illinois',
      IN: 'Indiana',
      IA: 'Iowa',
      KS: 'Kansas',
      KY: 'Kentucky',
      LA: 'Louisiana',
      ME: 'Maine',
      MD: 'Maryland',
      MA: 'Massachusetts',
      MI: 'Michigan',
      MN: 'Minnesota',
      MS: 'Mississippi',
      MO: 'Missouri',
      MT: 'Montana',
      NE: 'Nebraska',
      NV: 'Nevada',
      NH: 'New Hampshire',
      NJ: 'New Jersey',
      NM: 'New Mexico',
      NY: 'New York',
      NC: 'North Carolina',
      ND: 'North Dakota',
      OH: 'Ohio',
      OK: 'Oklahoma',
      OR: 'Oregon',
      PA: 'Pennsylvania',
      RI: 'Rhode Island',
      SC: 'South Carolina',
      SD: 'South Dakota',
      TN: 'Tennessee',
      TX: 'Texas',
      UT: 'Utah',
      VT: 'Vermont',
      VA: 'Virginia',
      WA: 'Washington',
      WV: 'West Virginia',
      WI: 'Wisconsin',
      WY: 'Wyoming',
      AS: 'American Samoa',
      DC: 'District of Columbia',
      FM: 'Federated States of Micronesia',
      GU: 'Guam',
      MH: '',
      MP: 'Northern Mariana Islands',
      PW: 'Palau',
      PR: 'Puerto Rico',
      VI: 'U.S. Virgin Islands',
    },
  },
  {
    name: 'Canada',
    states: {
      ON: 'Ontario',
      QC: 'Quebec',
      NS: 'Nova Scotia',
      NB: 'New Brunswick',
      MB: 'Manitoba',
      BC: 'British Columbia',
      PE: 'Prince Edward Island',
      SK: 'Saskatchewan',
      AB: 'Alberta',
      NL: 'Newfoundland and Labrador',
    },
  },
]

type Props = {
  id: string
  name?: string
  value: string
  onChange: React.FormEventHandler<FormControl>
}

const StateSelect: FC<Props> = ({ id, name, value, onChange }) => {
  return (
    <FormControl
      id={id}
      name={name || ''}
      componentClass="select"
      value={value || ''}
      onChange={onChange}
    >
      {_.isEmpty(value) ? (
        <option value="" disabled>
          State
        </option>
      ) : null}
      {_.map(countries, country => (
        <optgroup key={country.name} label={country.name}>
          {_.map(country.states, (state, code) => (
            <option key={code} value={code}>
              {state}
            </option>
          ))}
        </optgroup>
      ))}
    </FormControl>
  )
}

export default StateSelect
