import { FC } from 'react'

type Props = {
  decimalPlaces?: number
  className?: string
  value: number
}

const Percentage: FC<Props> = ({ value, decimalPlaces, className }) => {
  const afterDecimal = _.defaultTo(decimalPlaces, 2)
  if (isNaN(value) || Math.abs(value) === Infinity) {
    return <span className="text-muted">n/a</span>
  }
  return <span className={className}>{`${(value * 100).toFixed(afterDecimal)}%`}</span>
}

export default Percentage
