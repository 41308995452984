import asyncThunk from 'utils/asyncThunk'

export type Request = {
  query: string
  federation_id?: number
}

export const listDistributionLists = asyncThunk<Request, any>({
  name: 'distribution-lists/list',
  uri: '/distribution_lists',
  method: 'GET',
})
