import { useEffect, useMemo, useState } from 'react'

import useTimer from './useTimer'

type State = 'ready' | 'loading' | 'succeeded' | 'failed'

type TimerState = {
  state: State
  isReady: boolean
  isLoading: boolean
  didSucceed: boolean
  didFail: boolean
  id?: string | number
}

type TimerFuncs = {
  start: () => void
  succeeded: (minimumWait?: number) => void
  failed: () => void
  reset: () => void
  setId: (id: string | number) => void
  clearId: () => void
}

export type ActionTimer = TimerState & TimerFuncs & { delay: number }

const initialState: TimerState = {
  state: 'ready',
  isReady: true,
  isLoading: false,
  didSucceed: false,
  didFail: false,
}

const delay = 1250

const useActionTimer = (initialId?: string, initialLoading: boolean = false): ActionTimer => {
  const [isLoading, setIsLoading] = useState<boolean>(initialLoading)
  const [didSucceed, setDidSucceed] = useTimer(delay)
  const [didFail, setDidFail] = useTimer(delay * 1.5)
  const [id, setId] = useState<string | number | undefined>(initialId)

  function start() {
    setIsLoading(true)
  }

  function succeeded() {
    setIsLoading(false)
    setDidSucceed()
  }

  function failed() {
    setIsLoading(false)
    setDidFail()
  }

  function reset() {
    setIsLoading(false)
  }

  function clearId() {
    setId(undefined)
  }

  useEffect(() => {
    if (!id) return
    if (isLoading || didSucceed || didFail) return
    setId(undefined)
  }, [isLoading, id, didSucceed, didFail])

  const state = useMemo(() => {
    if (isLoading) {
      return 'loading'
    } else if (didSucceed) {
      return 'succeeded'
    } else if (didFail) {
      return 'failed'
    }
    return 'ready'
  }, [isLoading, didSucceed, didFail])

  const isReady = !isLoading && !didSucceed && !didFail

  const [timer] = useState(Object.assign({}, initialState))
  return Object.assign(timer, {
    state,
    isReady,
    isLoading,
    didSucceed,
    didFail,
    start,
    failed,
    succeeded,
    reset,
    setId,
    clearId,
    delay,
    id,
  })
}

export default useActionTimer

export const idleTimer: ActionTimer = {
  state: 'ready',
  isReady: true,
  isLoading: false,
  didSucceed: false,
  didFail: false,
  delay: 0,
  start: () => { },
  succeeded: (minimumWait?: number) => { },
  failed: () => { },
  reset: () => { },
  setId: (id: string | number) => { },
  clearId: () => { },
} as const

export const successTimer: ActionTimer = {
  state: 'succeeded',
  isReady: false,
  isLoading: false,
  didSucceed: true,
  didFail: false,
  delay: 0,
  start: () => { },
  succeeded: (minimumWait?: number) => { },
  failed: () => { },
  reset: () => { },
  setId: (id: string | number) => { },
  clearId: () => { },
} as const
