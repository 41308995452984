import asyncThunk from 'utils/asyncThunk'

import { Request as ListRequest } from './list'

export type Request = ListRequest

export const listEventsByUser = asyncThunk<Request, any>({
  name: 'events/list-by-user',
  uri: '/events/list_by_user',
  method: 'GET',
})
