import asyncThunk from 'utils/asyncThunk'

export type Request = {}

type Response = {
  id: string
  client_secret: string
}

export const createBankAccountIntent = asyncThunk<Request, Response>({
  name: 'stripe/create-bank-account-intent',
  uri: '/stripes/create_bank_account_intent',
  method: 'POST',
})
