const bootstrapQuery = `
  user {
    first_name
    last_name
    email
    phone_number
    role
    is_root
    all_features

    morph_alumni {
      user {
        id
      }
    }

    data {
      has_required_documents
      has_budgets
      typesense_key
    }

    avatar {
      public_url
    }

    member {
      is_admin
      unique_id
      custom_fields
      graduation_year
      no_physical_bills
      organization_id

      organization {
        name
        secondary
        member_roster
        admin_roster
        pledge_roster
        cc_upcharge
        pledge_info
        stripe_onboarded_at
        typesense_key

        data {
          default_account_id
        }

        terms {
          name
          started_on
        }

        primary_logo {
          public_url
        }

        secondary_logo {
          public_url
        }

        federation {
          name
        }

        primary_admin {
          user {
            first_name
            last_name
            email
            phone_number
          }
        }
      }

      budgets {
        issued_cards {
          name_on_card
          company
          last_four
          balance
        }
      }

      data {
        all_features
      }
    }

    alumni {
      organization {
        alumni_group_id
        federation_id
      }
    }

    parents {
      send_email_bills
      send_physical_bills
      send_sms_reminders

      member {
        is_admin
        unique_id
        custom_fields
        no_physical_bills

        organization {
          cc_upcharge
        }

        user {
          first_name
          last_name
          phone_number
          email
          role
        }

        data {
          all_features
        }
      }
    }

    alumni {
      graduation_year
      custom_fields
      last_reviewed_at

      organization {
        name
        secondary
        alumni_group_id

        federation {
          name

          primary_logo {
            public_url
          }

          secondary_logo {
            public_url
          }
        }
      }

      lifetime_donation_tier {
        level
        threshold
      }

      promissory_note {
        amount
        due_on
        paid_at
      }
    }

    federation {
      name
      organization_roster
      alumni_group_id

      primary_logo {
        public_url
      }
      secondary_logo {
        public_url
      }
      custom_fields {
        name
        slug
      }
    }

    sales_permissions {
      organization {
        name
        secondary
        federation {
          name
        }
      }
    }
  }
`

export default bootstrapQuery
