import asyncThunk from 'utils/asyncThunk'

export type Request = {
  id: number
  message_category_folder: {
    name: string
  }
}

export const updateMessageCategoryFolder = asyncThunk<Request, any>({
  name: 'message-category-folders/update',
  uri: ({ id }) => `/message_category_folders/${id}`,
  method: 'PUT',
})
