import asyncThunk from 'utils/asyncThunk'

export type Request = {
  query: string
  poll_vote: {
    poll_option_id?: number
    poll_option_ids?: number[]
  }
}

export const createPollVote = asyncThunk<Request, any>({
  name: 'poll-votes/create',
  uri: '/poll_votes',
  method: 'POST',
})
