import { FC } from 'react'
import { Button, Col, Modal, Row } from 'react-bootstrap'
import { Money } from 'components/utilities'
import renderPreview from 'components/reimbursements/receipt-preview'
import { closeModal } from 'hooks/useModal'
import modal from 'helpers/modal'

import { Reimbursement } from 'components/reimbursements/type'

import Status from './_Status'

type Props = {
  id?: number
  reimbursement: Reimbursement
}

const ReimbursementDetailsModal: FC<Props> = ({ reimbursement }) => (
  <Modal show bsSize="lg" onHide={closeModal}>
    <Modal.Header closeButton>
      <Modal.Title>Reimbursement details</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <Row>
        <Col sm={6}>{renderPreview(reimbursement.document)}</Col>
        <Col sm={6}>
          <Status reimbursement={reimbursement} />

          <dl className="dl-vertical m-t-1">
            <dt>Description</dt>
            <dd>{reimbursement.description}</dd>

            <hr className="spacer-sm" />

            <dt>Amount</dt>
            <dd>
              <Money amount={reimbursement.amount} />
            </dd>

            {reimbursement.status !== 'proposed' && (
              <>
                <hr className="spacer-sm" />

                <dt>Reimbursement method</dt>
                <dd>{reimbursement.method === 'cash' ? 'Handled outside of GCM' : 'Credited to GCM account'}</dd>
              </>
            )}
          </dl>
        </Col>
      </Row>
    </Modal.Body>
    <Modal.Footer>
      <Button onClick={() => closeModal()}>Close</Button>
    </Modal.Footer>
  </Modal>
)

export default modal<Props>('ReimbursementDetails', ReimbursementDetailsModal)
