export const contributionTrackerQuery = `
  contribution_tracker {
    id
    purpose
    specificity
  }
`
export const contributionQuery = `
  contribution {
    id
    amount
    details
    approved_at
    denied_at
    created_at
    member {
      id
      user {
        id
        first_name
        last_name
      }
    }
    creator {
      id
      first_name
      last_name
    }
  }
`
export const memberQuery = `
  member {
    id
    user {
      id
      first_name
      last_name
    }
  }
`
