import { forwardRef } from 'react'
import { Radio } from 'react-bootstrap'

import { Field } from 'components/form'
import ConnectedField from 'components/form/connected-field'

type Props = {
  accounts: {
    id: number
    name: string
  }[]
  accountId?: number
}

const ChargeAccounts = forwardRef<HTMLInputElement, Props>(({ accounts, accountId }, ref) => {
  if (accounts.length === 0) {
    return <></>
  }

  if (accounts.length === 1) {
    return <input ref={ref} name="transaction.account_id" type="hidden" value={accounts[0].id} />
  }

  if (accountId) {
    const account = accounts.find(({ id }) => id === accountId)
    return (
      // @ts-ignore
      <Field ref={ref} name="transaction.account_id" label="Applied to">
        <div className="m-t-05">{account?.name}</div>
        <input type="hidden" value={account?.id} />
      </Field>
    )
  }

  return (
    <ConnectedField name="account_id" label="Apply to">
      {props => (
        <>
          {accounts.map(({ id, name }) => (
            <Radio {...props} key={id} value={id} checked={props.value === id} onChange={() => props.onChange(id)}>
              {name}
            </Radio>
          ))}
        </>
      )}
    </ConnectedField>
  )
})

export default ChargeAccounts
