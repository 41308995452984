import { FC, useCallback } from 'react'
import { Col, Modal, Row } from 'react-bootstrap'
import { FormProvider, useForm } from 'react-hook-form'

import Button from 'components/form/button'
import ConnectedField from 'components/form/connected-field'
import modal from 'helpers/modal'
import { useAPI } from 'hooks/useAPI'
import { useLegacySuper } from 'hooks/useLegacyContext'
import { closeModal } from 'hooks/useModal'
import { recordCheck } from 'thunks/payments/check'
import { Member } from 'types/user'
import notify, { notifyError } from 'utils/notify'

import { Payment } from './record-check-type'
// @ts-ignore
import { paymentQuery } from './record-check-type?query'

type FormData = {
  amount: number
  external_ref: string
}

type Props = {
  id: number
  member: Pick<Member, 'id' | 'unique_id' | 'user'>
  onSuccess: (payment: Payment) => void
}

const RecordCheckModal: FC<Props> = ({ member, onSuccess }) => {
  const user = useLegacySuper()
  const form = useForm<FormData>({})

  const [record, { timer }] = useAPI(recordCheck)
  const handleSubmit = useCallback(
    async (data: FormData) => {
      try {
        const [payment] = await record({
          query: paymentQuery,
          payment: {
            ...data,
            source: `web/${user.role}`,
            member_id: member.id,
          },
        })

        notify('The check has been recorded.')
        closeModal({ closeAll: true })
        onSuccess(payment)
      } catch (err) {
        console.error(err)
        notifyError('There was an error recording the check.')
      }
    },
    [member, onSuccess, record, user.role]
  )

  return (
    <Modal show onHide={closeModal}>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(handleSubmit)}>
          <Modal.Header closeButton>
            <Modal.Title>Record check payment</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <hr className="spacer-xs" />

            <Row>
              <Col md={7} mdPush={3}>
                Check payment for {member.user.first_name} {member.user.last_name}
                &nbsp; <span className="text-muted">{member.unique_id}</span>
                <hr className="spacer-xs" />
              </Col>
            </Row>

            <ConnectedField name="amount" label="Amount" inputSize={4} money autoFocus />

            <ConnectedField name="external_ref" label="Reference" placeholder="Check number for example (optional)" />
          </Modal.Body>
          <Modal.Footer>
            <Button timer={timer}>Record payment</Button>
          </Modal.Footer>
        </form>
      </FormProvider>
    </Modal>
  )
}

export default modal<Props>('RecordCheck', RecordCheckModal)
