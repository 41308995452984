import { FC, useMemo } from 'react'
import { Panel } from 'react-bootstrap'

import { Date, FaIcon, Units } from 'components/utilities'

import useVoteMap from '../hooks/useVoteMap'
import useVoters from '../hooks/useVoters'
import { Poll } from '../show-container/type'

type Props = {
  poll: Poll
  voteCounts: Record<number, number>
  winnerIds: number[]
  canSeeAnswers: boolean
}

const VoteSidebar: FC<Props> = ({ poll, voteCounts, winnerIds, canSeeAnswers }) => {
  const [voters] = useVoters(poll.id)
  const voteMap = useVoteMap(poll, voters)

  const description = useMemo(() => {
    return winnerIds
      .map(id => poll.poll_options.find(o => o.id === id))
      .filter(o => !!o)
      .map(o => (
        <span key={o!.id} className="m-r-05">
          {o!.title}{' '}
          {winnerIds.length === 1 && (
            <small className="text-muted">
              (<Units noun="vote" count={voteCounts[o!.id]} />)
            </small>
          )}
        </span>
      ))
  }, [winnerIds, poll, voteCounts])

  return (
    <Panel>
      <Panel.Body>
        <div className="vote-sidebar">
          <h2 className="vote-sidebar-title">{poll.title}</h2>
          <p>{poll.description}</p>
        </div>
      </Panel.Body>

      <Panel.Footer>
        <div>
          <div>
            <div className="vote-sidebar-stat" id="vote-winner">
              <FaIcon gavel success />
              <div className="vote-sidebar-stat-text">
                <div className="text-success">
                  {winnerIds.length === 1 ? (
                    'The winner is...'
                  ) : (
                    <span>
                      Tie between {winnerIds.length} with <Units noun="vote" count={voteCounts[winnerIds[0]]} />
                    </span>
                  )}
                </div>
                <strong>{description}</strong>
              </div>
            </div>
            <div className="vote-sidebar-stat">
              <FaIcon clock-o />
              <div className="vote-sidebar-stat-text">
                <div>Voting ended on</div>
                <strong>
                  <Date date={poll.ended_at!} human />
                </strong>
              </div>
            </div>
          </div>

          <div className="vote-sidebar-stat">
            <FaIcon users />
            <div className="vote-sidebar-stat-text">
              <div>Members who voted</div>
              <strong>
                {voters?.filter(v => v.data.has_voted).length ?? '...'} of {voters?.length ?? '...'}
              </strong>
            </div>
          </div>

          {canSeeAnswers && voteMap && (
            <div className="vote-sidebar-stat vote-list">
              <FaIcon list />
              <div className="vote-sidebar-stat-text">
                {poll.poll_options.map(o => (
                  <dl key={o.id} className="m-b-1">
                    <dt>
                      {o.title}
                      &nbsp; (<Units noun="vote" count={voteCounts[o.id]} />)
                    </dt>
                    {!poll.is_anonymous && (
                      <>
                        {voteMap[o.id].length > 0 ? (
                          voteMap[o.id].map(v => (
                            <dd key={v.id}>
                              {v.last_name}, {v.first_name}
                            </dd>
                          ))
                        ) : (
                          <dd>
                            <em className="text-muted">No votes</em>
                          </dd>
                        )}
                      </>
                    )}
                  </dl>
                ))}
              </div>
            </div>
          )}
        </div>
      </Panel.Footer>
    </Panel>
  )
}

export default VoteSidebar
