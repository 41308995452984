import { FC, ReactNode } from 'react'
import { Label as ReactBootstrapLabel } from 'react-bootstrap'

const styles = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'inverted'] as const

type Style = typeof styles[number]

type Props = {
  title?: string
  success?: boolean
  primary?: boolean
  secondary?: boolean
  info?: boolean
  warning?: boolean
  danger?: boolean
  default?: boolean
  inverted?: boolean
  className?: string
  children: ReactNode
  onClick?: () => void
}

const Label: FC<Props> = props => {
  const style = _.reduce(props, (result, value, key) => (styles.indexOf(key as Style) !== -1 ? key : result), 'default')

  return (
    <ReactBootstrapLabel bsStyle={style} className={props.className} onClick={props.onClick} title={props.title}>
      {props.children}
    </ReactBootstrapLabel>
  )
}

export default Label
