import { FC } from 'react'
import { Alert } from 'react-bootstrap'

import { Date } from 'components/utilities'

import areTicketsAvailable from '../helpers/are-tickets-available'
import isRegistrationClosed from '../helpers/is-registration-closed'
import { Event } from './type'

type Props = {
  event: Event
  eventRegistration?: {
    approved_at: string | undefined
    created_at: string
  }
}

const RegistrationAlert: FC<Props> = ({ event, eventRegistration }) => {
  if (eventRegistration) {
    if (event.is_approval_required && !eventRegistration.approved_at) {
      return (
        <Alert bsStyle="warning">
          <strong>
            Your {event.purpose === 'event' ? 'registration' : 'submission'} is pending. It was submitted on{' '}
            <Date date={eventRegistration.created_at} />.
          </strong>
        </Alert>
      )
    }

    return (
      <Alert bsStyle="info">
        <strong>
          You {event.purpose === 'event' ? 'registered' : 'submitted this form'} on{' '}
          <Date date={eventRegistration.created_at} />
        </strong>
      </Alert>
    )
  }

  if (event.registration_closes_on && isRegistrationClosed(event)) {
    return (
      <Alert bsStyle="warning">
        <strong>
          Registration closed for {event.name} on <Date date={event.registration_closes_on} />.
        </strong>
      </Alert>
    )
  }

  if (!areTicketsAvailable(event)) {
    return (
      <Alert bsStyle="warning">
        <strong>All {event.maximum_tickets} tickets were reserved for this event</strong>
      </Alert>
    )
  }

  return null
}

export default RegistrationAlert
