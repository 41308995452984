import moment from 'moment'
import { FC, useMemo } from 'react'
import { Link } from 'react-router-dom'
import r from 'routes'

import { Name } from 'components/utilities'
import Units from 'components/utilities/units'

import { MessageCategory, MessageThread } from '../type'

type Props = {
  role: 'member' | 'federation' | 'alumni'
  messageCategory: MessageCategory
  messageThread: MessageThread
  isNew?: boolean
}

const Thread: FC<Props> = ({ role, messageCategory, messageThread, isNew = false }) => {
  const link = useMemo(() => {
    return r[role].messageCategories.thread(messageCategory.id, messageThread.id)
  }, [role, messageCategory, messageThread])

  return (
    <tr>
      <td>
        <h5 className="table-post-title">
          {isNew ? <span className="hidden-print label label-primary">New</span> : null}
          <Link to={link}>{messageThread.name}</Link>
        </h5>
        <span>
          <span className="text-muted">Posted </span>
          {moment(messageThread.created_at).fromNow()}
          <span className="text-muted"> by </span>

          <Name user={messageThread.creator} />
        </span>
      </td>
      <td className="text-muted">
        {messageThread.data.replies > 0 ? (
          <Units count={messageThread.data.replies} noun="reply" plural="replies" />
        ) : (
          <span className="text-muted">no replies</span>
        )}
      </td>
      <td className="text-right">
        <span className="text-muted">updated </span>
        {moment(messageThread.updated_at).fromNow()}
      </td>
    </tr>
  )
}

export default Thread
