import React, { ReactNode } from 'react'
import { Button, Col, FormGroup, Modal, Row } from 'react-bootstrap'

import { Field, Form, SubmitButton } from 'components/form'
import { FormContext } from 'components/form/context'
import modal, { ModalProps } from 'helpers/modal'
import { closeModal } from 'hooks/useModal'
import { Request, RequestWithID } from 'resources/helpers'

type OwnProps = {
  button: string
  label: string
  title: string
  hint?: string
  action?: string | ((request: Request) => void) | ((request: RequestWithID) => void)
  data?: Record<string, any>
  children?: ReactNode
  defaultValue?: string | number
  disabled?: boolean
  hashPrefix?: string
  id?: number | string
  placeholder?: string
  money?: boolean
  query?: string
  method?: 'POST' | 'PUT'
  name?: string
  rootErrorKey?: string
  textarea?: number
  onSuccess?: ({ data }: { data: any }) => void
}

type Props = ModalProps<OwnProps>

type State = {
  errors?: Record<string, string>
}

class InputModal extends React.Component<Props, State> {
  static contextType = FormContext

  state = {
    errors: {},
  }

  onValidationErrors = (errors: Record<string, string>) => this.setState({ errors })

  render() {
    const { errors } = this.state
    const { title, button, children, rootErrorKey } = this.props

    const formProps = _.pick(this.props, ['method', 'action', 'data', 'query', 'id', 'onSuccess'])
    const fieldProps = _.pick(this.props, [
      'hint',
      'name',
      'label',
      'textarea',
      'placeholder',
      'disabled',
      'defaultValue',
      'filename',
      'money',
      'onChange',
    ])

    return (
      <Modal show onHide={closeModal}>
        {/* @ts-ignore */}
        <Form {...formProps} onValidationErrors={this.onValidationErrors}>
          <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <hr className="spacer-xs" />

            <Field {...fieldProps} autoFocus />

            {rootErrorKey && errors && (
              <FormGroup validationState="error">
                <span className="help-block text-danger m-l-1">{_.get(errors, rootErrorKey)}</span>
              </FormGroup>
            )}
            {children}
          </Modal.Body>
          <Modal.Footer>
            <Row>
              {this.props.disabled ? (
                <Col md={7} mdPush={5}>
                  <Button bsStyle="primary" disabled>
                    {button}
                  </Button>
                </Col>
              ) : (
                <SubmitButton offset={5}>{button}</SubmitButton>
              )}
            </Row>
          </Modal.Footer>
        </Form>
      </Modal>
    )
  }
}

export default modal<OwnProps>('Input', InputModal)
