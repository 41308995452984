function shortenUrl(url: string) {
  return url.length >= 40 ? `${url.substring(0, 40)}...` : url
}

// Replace all links with <a> tags and make them shorter
export default function formatLinks(text: string, newWindow: boolean = false) {
  const virtualContainer = document.createElement('div')

  const urlPattern = /((https?|ftps?):\/\/[^"<\s]+)(?![^<>]*>|[^"]*?<\/a)/gi
  const target = newWindow ? '_blank' : ''

  virtualContainer.innerHTML = text.replace(urlPattern, url => {
    const protocolPattern = /^(?:(?:https?|ftp):\/\/)/i
    const href = protocolPattern.test(url) ? url : `http://${url}`

    return `<a href="${href}" target="${target}">${url}</a>`
  })

  // Process link anchors
  virtualContainer.querySelectorAll('a').forEach(aTag => {
    aTag.innerHTML = aTag.innerHTML.replace(urlPattern, shortenUrl) // eslint-disable-line no-param-reassign
  })

  return virtualContainer.innerHTML
}
