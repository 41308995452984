import moment from 'moment'
import { FC } from 'react'

import { Label } from 'components/utilities'

type Props = {
  note?: {
    paid_at?: string
    due_on?: string
  }
}

const PromissoryNoteBadge: FC<Props> = ({ note }) => {
  if (!note?.due_on) return null

  if (note.paid_at)
    return (
      <Label success className="ml-4">
        active
      </Label>
    )

  if (moment(note.due_on).isAfter(moment()))
    return (
      <Label info className="ml-4">
        unpaid
      </Label>
    )

  return (
    <Label danger className="ml-4">
      delinquent
    </Label>
  )
}

export default PromissoryNoteBadge
