export const committeePositionQuery = `
  committee_position {
    id
    title
    member {
      id
      user {
        id
        first_name
        last_name
      }
    }
  }
`
export const memberQuery = `
  member {
    id
    user {
      id
      first_name
      last_name
    }
  }
`
