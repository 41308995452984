import asyncThunk from 'utils/asyncThunk'

export type Request = {
  query: string
  start_date?: string
  end_date?: string
  federation_id?: number
  organization_id?: number
}

export const listReports = asyncThunk<Request, any>({
  name: 'reports/list',
  uri: '/reports',
  method: 'GET',
})
