const memberRoutes = {
  root: '/member/balance',
  settings: {
    root: '/member/settings',
    profile: '/member/settings/profile',
    password: '/member/settings/password',
    access: '/member/settings/access',
    paymentMethods: '/member/settings/payment-methods',
  },
  budgets: {
    root: '/member/budgets',
    show: (id: number | ':id' = ':id') => `/member/budgets/${id}`,
  },
  cards: {
    show: (id: number | ':id' = ':id') => `/member/cards/${id}`,
  },
  contributionTrackers: {
    show: (id: number | ':id' = ':id') => `/member/contribution-trackers/${id}`,
  },
  documentFolders: {
    root: '/member/document-folders',
    show: (id: number | ':id' = ':id') => `/member/document-folders/${id}`,
  },
  requiredDocuments: {
    root: '/member/required-documents',
  },
  hourTracker: {
    root: '/member/hour-tracker',
    show: (id: number | ':id' = ':id') => `/member/hour-tracker/${id}`,
  },
  voting: {
    root: '/member/voting',
    new: '/member/voting/new',
    show: (id: number | ':id' = ':id') => `/member/voting/${id}`,
    finished: (id: number | ':id' = ':id') => `/member/voting/${id}/finished`,
    ended: '/member/voting/ended',
  },
  eventRegistrations: {
    new: (id: number | ':id' = ':id') => `/member/event-registrations/${id}/new`,
  },
  messageCategories: {
    root: '/member/message-board',
    show: (id: number | ':id' = ':id') => `/member/message-categories/${id}`,
    thread: (id: number | ':id' = ':id', threadId: number | string = ':threadId') =>
      `/member/message-categories/${id}/thread/${threadId}`,
    folder: (id: number | ':id' = ':id', folderId: number | string = ':folderId') =>
      `/member/message-categories/${id}/folder/${folderId}`,
  },
}

export default memberRoutes
