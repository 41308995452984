import { iconForMimeType } from 'helpers'
import { FC } from 'react'
import { Grid } from 'react-bootstrap'
import { Link, useParams } from 'react-router-dom'
import r from 'routes'

import { Body, BreadcrumbHeader, BreadcrumbItem, Content, Date, Label, Portlet } from 'components/utilities'
import Loading from 'components/utilities/loading'
import useActiveSidebarItem from 'hooks/useActiveSidebarItem'
import useList from 'hooks/useList'
import { Request, searchDocumentsByLabel } from 'thunks/documents/search'

import { Document } from './type'
// @ts-ignore
import { documentQuery } from './type?query'

type RouteParams = {
  label: string
}

const SharedDocumentsByLabel: FC = () => {
  useActiveSidebarItem('Documents')
  const params = useParams<RouteParams>()

  const [documents] = useList<Document, Request>(searchDocumentsByLabel, {
    query: documentQuery,
    term: params.label,
  })

  if (!documents) {
    return <Loading />
  }

  return (
    <Content>
      <Grid>
        <BreadcrumbHeader>
          <BreadcrumbItem link={r.member.documentFolders.root}>Documents</BreadcrumbItem>
          <BreadcrumbItem>{params.label}</BreadcrumbItem>
        </BreadcrumbHeader>
      </Grid>
      <Portlet boxed>
        <Body table>
          <table className="table table-documents mb-0">
            <tbody>
              {documents.map((document, idx) => (
                <tr key={idx} data-id={document.id}>
                  <td style={{ width: '50%' }}>
                    <img
                      src={iconForMimeType(document.mimetype, document.filename)}
                      width={36}
                      height={36}
                      alt={document.filename}
                    />
                    <div className="py-2">
                      <a href={document.url} target="_blank" rel="noreferrer">
                        {document.filename}
                      </a>
                      {document.document_labels && (
                        <div className="m-t-05">
                          {document.document_labels.map(({ label }, i) => (
                            <Link key={i} to={`/member/documents/search/${label}`}>
                              <Label inverted className="m-r-05">
                                {label}
                              </Label>
                            </Link>
                          ))}
                        </div>
                      )}
                    </div>
                  </td>
                  <td className="text-right" style={{ width: '30%' }}>
                    <Link to={r.member.documentFolders.show(document.document_folder.id)}>
                      {document.document_folder.name}
                    </Link>
                  </td>
                  <td className="text-right" style={{ width: '20%' }}>
                    <Date short date={document.created_at} />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Body>
      </Portlet>
    </Content>
  )
}

export default SharedDocumentsByLabel
