import { useMemo } from 'react'

import { Budget } from './type'

export default function useSummary(budgets: Budget[]) {
  const startingBalanceSum = useMemo(
    () => budgets.reduce((sum, b) => sum + (b.starting_balance?.amount ?? 0), 0),
    [budgets]
  )

  const totalSpent = useMemo(
    () =>
      budgets.reduce((sum, b) => {
        const s = b.budget_expenses.reduce((s, e) => s + e.cost, 0)
        return sum + s
      }, 0),
    [budgets]
  )

  const totalBudget = useMemo(() => budgets.reduce((sum, b) => sum + (b.starting_balance?.amount ?? 0), 0), [budgets])

  return { startingBalanceSum, totalSpent, totalBudget }
}
