import { apiRequest } from 'actions/use'
import { Request } from 'utils/apiRequest'

import apiWrapper, { generatePayload, list, RequestWithID, updateMethod } from './helpers'

export type LedgersResponse = Record<
  number,
  {
    id: number
    name: string
    is_default_account: boolean
    scope: 'user' | 'chapter'
    weight: number
    ledger: Ledger[]
    is_active: boolean
  }
>

export interface Ledger {
  id: number
  description: string
  amount: number
  txn_type: 'charge' | 'credit' | 'payment'
  due_on: any
  late_fee: any
  created_by: {
    id: number
    first_name: string
    last_name: string
  }
  account: {
    id: number
    name: string
  }
}

export default class Member extends apiWrapper('/members') {
  static sendToCollections = updateMethod('/members', 'send_to_collections')
  static removeFromCollections = updateMethod('/members', 'remove_from_collections')
  static collectionsNotes = updateMethod('/members', 'collections_notes')

  static ledgers({ id, onSuccess }: Pick<Request<LedgersResponse>, 'id' | 'onSuccess'>) {
    return apiRequest<void, LedgersResponse>({
      method: 'GET',
      uri: `/api/members/${id}/ledgers`,
      on: {
        200: onSuccess,
      },
    })
  }

  static quickNotes({ id, data, query, onSuccess, onFailure }: RequestWithID) {
    apiRequest({
      method: 'PUT',
      uri: `/api/members/${id}/quick_notes`,
      data: generatePayload({ data, query }),
      onSuccess,
      onFailure,
    })
  }

  static budgets({ id, data, query, onSuccess, onFailure }: RequestWithID) {
    apiRequest({
      method: 'PUT',
      uri: `/api/members/${id}/budgets`,
      data: generatePayload({ data, query }),
      on: {
        200: onSuccess,
      },
      onFailure,
    })
  }

  static groups({ id, data, query, onSuccess, onFailure }: RequestWithID) {
    apiRequest({
      method: 'PUT',
      uri: `/api/members/${id}/groups`,
      data: generatePayload({ data, query }),
      on: {
        200: onSuccess,
      },
      onFailure,
    })
  }

  static sendBalanceEmails(id: number) {
    return new Promise((onSuccess, onFailure) => {
      apiRequest({
        method: 'PUT',
        uri: `/api/members/${id}/send_balance_emails`,
        onSuccess,
        onFailure,
      })
    })
  }

  static history({ id, onSuccess }: Pick<Request, 'id' | 'onSuccess'>) {
    apiRequest({
      method: 'GET',
      uri: `/api/members/${id}/history`,
      on: {
        200: onSuccess,
      },
    })
  }

  static listSearch(opts: Request) {
    list('/members/list_search', opts)
  }

  static search(opts: Request) {
    list('/members/search', opts)
  }

  static archived({ data, query, onSuccess }: Request) {
    apiRequest({
      method: 'GET',
      uri: '/api/members/archived',
      data: generatePayload({ data, query }),
      on: {
        200: onSuccess,
      },
    })
  }
}
