import { FC } from 'react'

import { useLegacyMember } from 'hooks/useLegacyContext'
import SharedPaymentPlansIndex from 'pages/Shared/PaymentPlans'

const MemberPaymentPlansIndex: FC = () => {
  const user = useLegacyMember()

  return <SharedPaymentPlansIndex memberId={user.member.id} />
}

export default MemberPaymentPlansIndex
