import moment, { Moment } from 'moment'
import { FC, useMemo } from 'react'

const Time: FC<{ date: Moment }> = ({ date }) => {
  if (date.minute() === 0) {
    return <>{date.format('ha')}</>
  }
  return <>{date.format('h:mma')}</>
}

const Year: FC<{ date: Moment }> = ({ date }) => {
  if (moment().isSame(date, 'year')) {
    return <></>
  }
  return <>, ${date.format('YYYY')}</>
}

type Props = {
  startDate?: string
  endDate?: string
}

const EventDate: FC<Props> = ({ startDate: origStart, endDate: origEnd }) => {
  const startDate = useMemo(() => {
    if (!origStart) return
    return moment(origStart)
  }, [origStart])

  const endDate = useMemo(() => {
    if (!origEnd) return
    return moment(origEnd)
  }, [origEnd])

  const isSameDay = useMemo(() => {
    return startDate ? startDate.isSame(endDate, 'day') : false
  }, [startDate, endDate])

  if (!startDate || !endDate) return null

  return (
    <p className="m-b-2">
      {isSameDay ? (
        <>
          Event date: {startDate.format('MMMM Do')}
          <Year date={startDate} />
          &nbsp;from <Time date={startDate} /> to <Time date={endDate} />
        </>
      ) : (
        <>
          Event dates: {startDate.format('MMM Do')}
          <Year date={startDate} /> at <Time date={startDate} />
          &nbsp;to {endDate.format('MMM Do')}
          <Year date={endDate} /> at <Time date={endDate} />
        </>
      )}
    </p>
  )
}

export default EventDate
