import { FC, useState } from 'react'
import { Col, Modal, Row } from 'react-bootstrap'

import Button from 'components/form/button'
import modal, { ModalProps } from 'helpers/modal'
import { ActionTimer } from 'hooks/useActionTimer'
import { closeModal } from 'hooks/useModal'

type OwnProps = {
  title: string
  label: string
  defaultValue?: string
  action: string
  error?: string
  hint?: string
  timer: ActionTimer
  onSubmit: (value: string) => void
}

type Props = ModalProps<OwnProps>

const PlainInputModal: FC<Props> = ({ title, label, defaultValue = '', hint, error, action, timer, onSubmit }) => {
  const [value, setValue] = useState(defaultValue)

  return (
    <Modal show onHide={closeModal}>
      {/* @ts-ignore */}
      <form
        onSubmit={e => {
          e.preventDefault()
          onSubmit(value)
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <hr className="spacer-xs" />
          <div className="m-b-1 m-r-2" style={{ display: 'flex' }}>
            <label
              className="col-md-3 control-label"
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}
            >
              {label}
            </label>
            <input value={value} onChange={e => setValue(e.target.value)} autoFocus className="form-control" />
          </div>
          {hint && (
            <Row>
              <Col md={9} mdPush={2}>
                {hint}
              </Col>
            </Row>
          )}
          {error && (
            <Row className="ml-2">
              <Col md={10} mdPush={2} className="font-semibold text-danger">
                {error}
              </Col>
            </Row>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button timer={timer}>{action}</Button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}

export default modal<OwnProps>('PlainInput', PlainInputModal)
