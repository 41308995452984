import cx from 'classnames'
import { isGranted as isGrantedHelper } from 'helpers'
import moment from 'moment'
import { FC, useCallback, useMemo } from 'react'
import { Grid } from 'react-bootstrap'
import { useHistory, useParams } from 'react-router-dom'

import isTicketsExist from 'components/event/helpers/are-tickets-available'
import Table, { Column } from 'components/table'
import { Body, Content, EmptyState, FaButton, Header, Loading, Portlet } from 'components/utilities'
import useActiveSidebarItem from 'hooks/useActiveSidebarItem'
import { useLegacyMember } from 'hooks/useLegacyContext'
import useList from 'hooks/useList'
import { listEventsByUser, Request } from 'thunks/events/list-by-user'
import { Feature } from 'types/feature'

import { Event } from './type'
// @ts-ignore
import { eventQuery } from './type?query'

type Params = {
  federation?: string
}

const AdminEventRegistrations: FC = () => {
  useActiveSidebarItem('eventRegistration')
  const params = useParams<Params>()

  const history = useHistory()

  const user = useLegacyMember()

  const [allEvents] = useList<Event, Request>(listEventsByUser, {
    query: eventQuery,
    purpose: 'event',
    user_id: user?.id,
  })
  const events = useMemo(() => {
    const events = allEvents?.filter(
      e => !e.registration_closes_on || moment(e.registration_closes_on).endOf('day').isAfter(moment())
    )
    if (!params.federation) return events
    return events?.filter(e => e.owner_type === 'Federation')
  }, [allEvents, params.federation])

  const isGranted = useCallback(
    (feature: Feature) => {
      if (user === undefined) {
        return false
      }
      return isGrantedHelper(user, feature)
    },
    [user]
  )

  if (!events) return <Loading />

  return (
    <div>
      <Content>
        <Grid>
          <div>
            <Header>Events</Header>

            <Portlet boxed>
              <Body table>
                <Table
                  csvName="Events"
                  data={events}
                  noResults={
                    <div>
                      <EmptyState
                        message="No events added yet"
                        action={isGranted('Events') ? 'Create an event' : undefined}
                        onClick={isGranted('Events') ? () => history.push('/admin/events/new') : undefined}
                      />
                    </div>
                  }
                >
                  <Column width={30} value="name" link={({ id }: Event) => `/member/event-registrations/${id}/new`}>
                    Name
                  </Column>

                  <Column
                    width={20}
                    value="cost"
                    money
                    align="right"
                    none={<span className="text-muted">no cost</span>}
                  >
                    Cost
                  </Column>

                  <Column
                    width={20}
                    value="registration_closes_on"
                    formatter={(v?: string) => (v ? moment(v).format('MMM Do, YYYY') : 'N/A')}
                    align="right"
                  >
                    Registration closes
                  </Column>

                  <Column
                    width={15}
                    unit="registration"
                    value={(e: Event) => (e.event_registrations.length > 0 ? 'yes' : 'no')}
                    align="right"
                    formatter={(val: 'yes' | 'no') =>
                      val === 'yes' ? <strong className="text-success">yes</strong> : 'no'
                    }
                  >
                    Registered
                  </Column>

                  <Column
                    width={15}
                    noSort
                    align="right"
                    formatter={(_: any, event: Event) => {
                      const registered = event.event_registrations.length > 0
                      const pending =
                        event.is_approval_required &&
                        event.event_registrations.length > 0 &&
                        !event.event_registrations[0].approved_at
                      const noTickets = !isTicketsExist(event)

                      const icon = (() => {
                        if (pending) {
                          return 'clock-o'
                        }
                        if (registered) {
                          return 'check'
                        }
                        if (noTickets) {
                          return 'times'
                        }
                        return 'user-plus'
                      })()

                      const action = (() => {
                        if (pending) {
                          return 'Pending'
                        }
                        if (registered) {
                          return 'Registered'
                        }
                        if (noTickets) {
                          return 'Sold out'
                        }
                        return 'Register'
                      })()

                      return (
                        <FaButton
                          icon={icon}
                          bsSize="sm"
                          onClick={() => history.push(`/member/event-registrations/${event.id}/new`)}
                          className={cx({
                            'o-75': noTickets,
                          })}
                        >
                          {action}
                        </FaButton>
                      )
                    }}
                  />
                </Table>
              </Body>
            </Portlet>
          </div>
        </Grid>
      </Content>
    </div>
  )
}

export default AdminEventRegistrations
