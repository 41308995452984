import asyncThunk from 'utils/asyncThunk'

export type Request = {
  query: string
  owner_type: 'Organization' | 'Federation'
  owner_id?: number
  start_date?: string
  end_date?: string
}

export const listBudgets = asyncThunk<Request, any>({
  name: 'budgets/list',
  method: 'GET',
  uri: '/budgets',
})
