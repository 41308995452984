export const labelQuery = `
  label {
    id
    label
  }
`
export const documentQuery = `
  document {
    id
    document_labels {
      id
      label
    }
  }
`
