import { isGranted } from 'helpers'
import moment from 'moment'
import { FC, useCallback, useMemo, useState } from 'react'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import { Grid } from 'react-bootstrap'

import { DetailsModal, EventModal } from 'components/calendar'
import { ConfirmModal } from 'components/modals'
import { Content, Loading } from 'components/utilities'
import useActiveSidebarItem from 'hooks/useActiveSidebarItem'
import { useAPI } from 'hooks/useAPI'
import { useLegacyMemberOrAdmin } from 'hooks/useLegacyContext'
import useList from 'hooks/useList'
import { closeModal, openModal } from 'hooks/useModal'
import { archiveCalendarEvent } from 'thunks/calendar-events/archive'
import { CalendarEvent, listCalendarEvents } from 'thunks/calendar-events/list'

const localizer = momentLocalizer(moment)

export type UnpersistedEvent = Omit<CalendarEvent, 'id'>

function isPersistedEvent(event: UnpersistedEvent | CalendarEvent | undefined): event is CalendarEvent {
  return !!event && 'id' in event
}

type Slot = {
  start: string | Date
  end: string | Date
  slots: (string | Date)[]
}

const CalendarRetrieve: FC = () => {
  useActiveSidebarItem('Calendar')
  const user = useLegacyMemberOrAdmin()

  const [events, { remove, headers, add, patch }] = useList(listCalendarEvents, {
    organization_id: user.member.organization.id,
  })

  const hasAdminPermissions = useMemo(() => user.role === 'admin' || isGranted(user, 'Calendar'), [user])

  const canUpdateEvent = useCallback(
    (event: CalendarEvent | UnpersistedEvent) => hasAdminPermissions && event.owner_type === 'Organization',
    [hasAdminPermissions]
  )

  const [modalEvent, setModalEvent] = useState<CalendarEvent | UnpersistedEvent>()
  const [counter, setCounter] = useState(0)
  const handleSelectSlot = useCallback(
    (slot: Slot) => {
      if (!hasAdminPermissions) return

      const startDate = slot.start.toString().match(/ 00:00:00 /)
        ? moment(slot.start).add(19, 'hours')
        : moment(slot.start)
      const endDate = slot.end.toString().match(/ 00:00:00 /) ? moment(slot.end).add(-4, 'hours') : moment(slot.end)

      const modalEvent: UnpersistedEvent = {
        title: '',
        description: '',
        start: startDate.startOf('hour').format(),
        end: endDate.startOf('hour').format(),
        owner_type: 'Organization',
        owner_id: user.member!.organization.id,
        all_day: false,
      }

      setCounter(c => c + 1)
      setModalEvent(modalEvent)
      openModal('Event')()
    },
    [hasAdminPermissions, user]
  )

  const [archive] = useAPI(archiveCalendarEvent)
  const handleEventArchive = useCallback(async () => {
    if (!modalEvent || !('id' in modalEvent)) return

    await archive({
      id: modalEvent.id,
      calendar_event: {
        organization_id: user.member!.organization.id,
      },
    })

    // calendar event ids are strings
    remove(modalEvent.id as any)
    closeModal({ closeAll: true })
  }, [modalEvent, archive, remove, user])

  const [scrollTime, setScrollTime] = useState(() => moment().startOf('day').add(7.5, 'hours').toDate())

  if (!events || !headers) return <Loading />

  return (
    <Content>
      <Grid>
        <Calendar
          localizer={localizer}
          selectable
          events={events}
          titleAccessor={event => {
            if (event.all_day) return event.title

            const startTime = moment(event.start).format('h:mma').replace(/:00/, '')
            return `${startTime} ${event.title}`
          }}
          startAccessor={ev => moment(ev.start).toDate()}
          endAccessor={ev => moment(ev.end).toDate()}
          views={['month', 'week']}
          onSelectSlot={handleSelectSlot}
          onSelectEvent={ev => {
            setModalEvent(ev)
            const modal = canUpdateEvent(ev) ? 'Event' : 'Details'
            openModal(modal)()
          }}
          formats={{
            dayRangeHeaderFormat: ({ start, end }) => {
              const startDay = moment(start).format('MMM D, YYYY')
              const endDay = moment(end).format('MMM D, YYYY')
              return `${startDay} - ${endDay}`
            },
            weekdayFormat: 'dddd',
            dayFormat: 'ddd M/D',
          }}
          scrollToTime={scrollTime}
          onNavigate={d => {
            const time = moment(d).startOf('day').add(7.5, 'hours').toDate()
            setScrollTime(time)
          }}
        />

        {headers['x-calendar-uid'] && modalEvent && canUpdateEvent(modalEvent) && (
          <EventModal
            key={`event-${isPersistedEvent(modalEvent) ? modalEvent.id : counter}`}
            event={modalEvent}
            onEventSave={data => {
              if (isPersistedEvent(data) && events.some(ev => ev.id === data.id)) {
                patch(data)
              } else {
                add(data)
              }
            }}
          />
        )}

        {modalEvent && <DetailsModal event={modalEvent} />}

        {isPersistedEvent(modalEvent) && (
          <ConfirmModal
            key={modalEvent.id}
            prompt="Are you sure you want to delete this event?"
            yes="delete it"
            showLoading
            onConfirm={handleEventArchive}
          />
        )}
      </Grid>
    </Content>
  )
}

export default CalendarRetrieve
