import { Request } from 'utils/apiRequest'

import T from './types'

export function apiRequest(payload: Request) {
  return {
    type: T.API_REQUEST,
    ...payload,
  }
}
