import { FC } from 'react'
import { Link } from 'react-router-dom'

import { Label, Name, Thumbnail } from 'components/utilities'

type Props = {
  member: {
    id: number
    is_admin: boolean
    user: {
      id: number
      first_name: string
      last_name: string
      avatar?: {
        id: number
        public_url: string
      }
    }
  }
  primaryAdminId: number
  isAdminView: boolean
}

const MemberThumbnail: FC<Props> = ({ member, primaryAdminId, isAdminView }) => {
  return (
    <Thumbnail
      avatar={member.user.avatar}
      popoverId={`avatar-${member.id}`}
      popoverTitle={`${member.user.first_name} ${member.user.last_name}`}
    >
      {isAdminView ? (
        <Link to={`/admin/members/${member.id}`}>
          <Name full user={member.user} />
          {primaryAdminId === member.id ? (
            <Label primary className="m-l-1">
              primary
            </Label>
          ) : member.is_admin ? (
            <Label info className="m-l-1">
              admin
            </Label>
          ) : null}
        </Link>
      ) : (
        <Name full user={member.user} />
      )}
    </Thumbnail>
  )
}

export default MemberThumbnail
