import { useMemo } from 'react'

type Event = {
  cost_type: 'free' | 'fixed' | 'variable'
  cost: number
  optional_addons: {
    cost: number
  }[]
}

type FormData = {
  variable_amount: number | undefined
  tickets: number
  event_optional_addons: number[]
}

export default function useRegistrationCost(event: Event | undefined, values: FormData) {
  return useMemo(() => {
    if (!event) return

    const tickets = (event.cost_type === 'variable' ? values.variable_amount || 0 : event.cost) * values.tickets

    const addOns = Object.values(values.event_optional_addons).reduce(
      (sum, count, i) => sum + (event.optional_addons[i] || 0).cost * (count || 0),
      0
    )

    return tickets + addOns
  }, [event, values])
}
