export const GRADES = {
  freshman1st: '1st semester Freshman',
  freshman2nd: '2nd semester Freshman',
  sophomore1st: '1st semester Sophomore',
  sophomore2nd: '2nd semester Sophomore',
  junior1st: '1st semester Junior',
  junior2nd: '2nd semester Junior',
  senior1st: '1st semester Senior',
  senior2nd: '2nd semester Senior',
  other: 'Other',
} as const
