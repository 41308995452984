import asyncThunk from 'utils/asyncThunk'

export type Request = {
  query: string
  id: number
  message_thread: {
    name: string
  }
}

export const updateMessageThread = asyncThunk<Request, any>({
  name: 'message-threads/update',
  uri: ({ id }) => `/message_threads/${id}`,
  method: 'PUT',
})
