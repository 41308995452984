import { isGranted } from 'helpers'
import { FC, useCallback, useMemo, useRef } from 'react'
import { Col, Grid, Row } from 'react-bootstrap'
import { useHistory, useParams } from 'react-router-dom'
import r from 'routes'

import { ConfirmModal } from 'components/modals'
import { Action, ActionGroup, BreadcrumbHeader, BreadcrumbItem, Content, Loading } from 'components/utilities'
import useVoters from 'components/voting/hooks/useVoters'
import useWinners from 'components/voting/hooks/useWinners'
import RealtimeVotingProvider from 'components/voting/shared/RealtimeVotingProvider'
import Images, { ImagesRef } from 'components/voting/show-container/_Images'
import useActiveSidebarItem from 'hooks/useActiveSidebarItem'
import { useAPI } from 'hooks/useAPI'
import useLegacyContext from 'hooks/useLegacyContext'
import { openModal } from 'hooks/useModal'
import useObject from 'hooks/useObject'
import 'react-bnb-gallery/dist/style.css'
import { archivePoll } from 'thunks/polls/archive'
import { Request, retrievePoll } from 'thunks/polls/retrieve'
import { isAdmin, isFederation } from 'types/user-functions'
import notify from 'utils/notify'

import useCanManage from '../hooks/useCanManage'
import Options from './_Options'
import { Poll } from './type'
// @ts-ignore
import { pollQuery } from './type?query'
import VoteSidebar from './vote-sidebar'

type RouteParams = {
  id: string
}

export type Ref = {
  poll: Poll | undefined
}

type Props = {}

const FinishedContainer: FC<Props> = () => {
  const { user } = useLegacyContext()
  useActiveSidebarItem('Voting')
  const history = useHistory()
  const params = useParams<RouteParams>()
  const imageRef = useRef<ImagesRef>(null)

  const [poll] = useObject<Poll, Request>(retrievePoll, {
    id: +params.id,
    query: pollQuery,
  })

  const [, voteCounts] = useVoters(+params.id)
  const winners = useWinners(voteCounts)

  const linkToRootVoting = useMemo(() => {
    switch (user.role) {
      case 'alumni':
        return r.alumni.voting.root
      case 'federation':
        return r.federation.voting.root
      case 'admin':
        return r.admin.voting.root
      default:
        return r.member.voting.root
    }
  }, [user])

  const canArchive = useCanManage(poll, user)
  const [archive] = useAPI(archivePoll)
  const handleArchive = useCallback(async () => {
    if (!canArchive) return

    await archive({
      id: +params.id,
      query: 'poll { id }',
    })

    notify('The vote has been removed')
    history.push(linkToRootVoting)
  }, [canArchive, archive, params.id, history, linkToRootVoting])

  const canSeeAnswers = useMemo(() => {
    if (poll?.owner_type === 'Federation') return isFederation(user)
    return isAdmin(user) || isGranted(user, 'Voting')
  }, [poll, user])

  if (!poll || !winners || !voteCounts) return <Loading />

  return (
    <RealtimeVotingProvider>
      <Content className="voting">
        <Grid>
          <BreadcrumbHeader
            actions={
              canArchive && (
                <ActionGroup>
                  <Action icon="trash" bsStyle="secondary" onClick={openModal('Confirm')}>
                    Remove
                  </Action>
                </ActionGroup>
              )
            }
          >
            <BreadcrumbItem onClick={() => history.push(linkToRootVoting)}>Voting</BreadcrumbItem>
            <BreadcrumbItem>{poll.title}</BreadcrumbItem>
          </BreadcrumbHeader>
          <Row>
            <Col sm={7}>
              <Options
                options={poll.poll_options}
                winnerIds={winners}
                onSelectImage={id => imageRef.current?.selectImage(id)}
              />
            </Col>

            <Col sm={5}>
              <VoteSidebar poll={poll} voteCounts={voteCounts} winnerIds={winners} canSeeAnswers={canSeeAnswers} />
            </Col>

            <Images ref={imageRef} options={poll.poll_options} />
          </Row>
        </Grid>
      </Content>
      <ConfirmModal
        prompt="Are you sure you want to remove this vote?"
        yes="remove it"
        showLoading
        onConfirm={handleArchive}
      />
    </RealtimeVotingProvider>
  )
}

export default FinishedContainer
