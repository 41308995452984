import actionProps, { Actions } from 'actions'
import { RouterLocation } from 'connected-react-router'
import moment from 'moment'
import {
  Context, createContext, FC, JSXElementConstructor, useContext, useEffect, useMemo,
} from 'react'
import { connect, MapStateToPropsParam } from 'react-redux'
import {
  AppState, useAppDispatch, useDateRangeSelector, useParentSelector, useTermsSelector,
} from 'store'

import LegacyContext from 'contexts/legacy'
import { useAPI } from 'hooks/useAPI'
import { DateRange } from 'slices/dateRange'
import setDateRange from 'thunks/date-range/set'
import { listTerms, Request, Term } from 'thunks/terms/list'
// @ts-ignore
import { termQuery } from 'thunks/terms/list?query'
import { isMember, isParent } from 'types/user-functions'

export type ConnectWithDateRangeProps<Props = {}> = Props &
  Actions & {
    dateRange: DateRange
    params: Record<string, string>
    location: RouterLocation<any>
  }

const defaultContext = createContext({})

const connectWithDateRange = function (
  WrappedComponent: JSXElementConstructor<any>,
  mapStateToProps: MapStateToPropsParam<any, any, AppState> = null,
  context?: Context<any>
) {
  const ExtendedComponent: FC<Actions> = ({ actions, ...props }) => {
    const legacyContext = useContext(LegacyContext)
    const localContext = useContext(context || defaultContext)
    const { activeParent } = useParentSelector()

    /**
     * Get Organization ID from Context
     */
    const organizationId: number = useMemo(() => {
      if (localContext.member) return localContext.member.organization.id
      if (isMember(legacyContext.user!)) {
        return legacyContext.user.member.organization.id
      }
      if (isParent(legacyContext.user!)) {
        return activeParent?.member.organization.id
      }
    }, [legacyContext, localContext, activeParent])

    /**
     * Retrieve Organization Terms
     */
    const [list] = useAPI<Term[], Request>(listTerms)
    useEffect(() => {
      if (!organizationId) return

      list({
        query: termQuery,
        organization_id: organizationId,
      })
    }, [organizationId, list])

    const { dateRange } = useDateRangeSelector()

    /**
     * Set Date Range
     */
    const { terms } = useTermsSelector()
    const dispatch = useAppDispatch()
    useEffect(() => {
      if (dateRange) return
      if (!terms) return

      const term = _.find(terms, t => {
        const startedOn = moment(_.get(t, 'started_on'))
        return startedOn.isBefore()
      })
      if (!term) return

      const range = {
        start: moment(term.started_on),
        end: moment(term.ended_on).endOf('day'),
      }

      dispatch(setDateRange(range))
    }, [dateRange, terms, dispatch])

    if (!dateRange) {
      return null
    }

    return <WrappedComponent actions={actions} {...props} />
  }

  const map = (state: AppState, ownProps: any) => ({
    ...(mapStateToProps ? mapStateToProps(state, ownProps) : {}),
    dateRange: state.dateRange.dateRange,
    params: ownProps.match ? ownProps.match.params : undefined,
    location: state.router.location,
  })

  return connect(map, actionProps)(ExtendedComponent)
}

export default connectWithDateRange
