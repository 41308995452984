import React, { FC, ReactNode } from 'react'
import { Modal, Row } from 'react-bootstrap'

import { SubmitButton } from 'components/form'
import modal from 'helpers/modal'
import { closeModal } from 'hooks/useModal'

type Props = {
  id?: number | string
  form: ReactNode | React.ComponentClass
  modalTitle: string
  modalSubmitText: string
  federationId?: number
  onSuccess?: ({ data }: { data: any }) => void
  lifetimeTier?: any
  action?: 'create' | 'update'
}

const FormModal: FC<Props> = props => {
  const renderFormModalBody = ({ children }: { children: ReactNode }) => {
    const { modalTitle, modalSubmitText } = props

    return (
      <div>
        <Modal.Header closeButton>
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <hr className="spacer-xs" />

          {children}
        </Modal.Body>
        <Modal.Footer>
          <Row>
            <SubmitButton offset={5} tabIndex={3}>
              {modalSubmitText}
            </SubmitButton>
          </Row>
        </Modal.Footer>
      </div>
    )
  }

  const { form: Form } = props
  const formOptions = _.omit(props, ['show', 'form', 'modalTitle', 'modalSubmitText'])

  return (
    <Modal show onHide={closeModal}>
      {/* @ts-ignore */}
      <Form {...formOptions} fieldsWrapper={renderFormModalBody} />
    </Modal>
  )
}

export default modal<Props>('Form', FormModal)
