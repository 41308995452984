import asyncThunk from 'utils/asyncThunk'

export type Request = {
  query: string
  id: number
  message_category: {
    name: string
  }
}

export const updateMessageCategory = asyncThunk<Request, any>({
  name: 'message-categories/update',
  uri: ({ id }) => `/message_categories/${id}`,
  method: 'PUT',
})
