import * as yup from 'yup'
import modal from 'helpers/modal'
import { FC } from 'react'
import { Modal } from 'react-bootstrap'
import { FormProvider } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import r from 'routes'
import SubmitButton from 'components/form/button'
import ConnectedField from 'components/form/connected-field'
import QuillEditor from 'components/quill'
import { createMessageThread, Request } from 'thunks/message-threads/create'
import useYupValidationResolver from 'hooks/useYupValidationResolver'
import { useAPIFormHandler } from 'hooks/useAPIFormHandler'
import { closeModal } from 'hooks/useModal'
import notify from 'utils/notify'

import { MessageThread } from './type'
// @ts-ignore
import { messageThreadQuery } from './type?query'

const schema = yup.object().shape({
  name: yup.string().required('Name is a required field'),
  body: yup.string().required('Body is a required field'),
})

type ValidationData = yup.InferType<typeof schema>

type FormData = {
  name: string
  body: string
}

type Props = {
  role: 'member' | 'federation' | 'alumni'
  messageCategoryId: number
  messageCategoryFolderId?: number
  onCreate: (messageThread: MessageThread) => void
}

const NewThreadModal: FC<Props> = ({ role, messageCategoryId, messageCategoryFolderId, onCreate }) => {
  const history = useHistory()

  const resolver = useYupValidationResolver<ValidationData>(schema)

  const [handleSubmit, { form, timer }] = useAPIFormHandler<FormData, MessageThread, Request>({
    model: 'message_thread',
    thunk: createMessageThread,
    resolver: resolver,
    request: data => ({
      query: messageThreadQuery,
      message_thread: {
        ...data,
        message_category_folder_id: messageCategoryFolderId,
        message_category_id: messageCategoryId,
      },
    }),
    onSuccess: messageThread => {
      notify('Your message has been posted')
      onCreate(messageThread)
      history.push(r[role].messageCategories.thread(messageCategoryId, messageThread.id))
    },
  })

  return (
    <Modal show onHide={closeModal}>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(handleSubmit)}>
          <Modal.Header closeButton>
            <Modal.Title>New thread</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <hr className="spacer-xs" />

            <ConnectedField label="Name" name="name" tabIndex={1} inputSize={8} autoFocus />

            <ConnectedField label="Description" name="body" inputSize={8}>
              <QuillEditor
                onChange={val => {
                  if (_.isEmpty(val)) return
                  form.setValue('body', val)
                  form.clearErrors('body')
                }}
              />
            </ConnectedField>
          </Modal.Body>

          <Modal.Footer>
            <SubmitButton timer={timer} disabled={!_.isEmpty(form.formState.errors)}>
              Create thread
            </SubmitButton>
          </Modal.Footer>
        </form>
      </FormProvider>
    </Modal>
  )
}

export default modal<Props>('NewThread', NewThreadModal)
