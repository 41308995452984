import { connect } from 'helpers'

const Next = () => {
  return null
  // const location = useLocation()

  // const handleClick = useCallback(() => {
  //   localStorage.setItem('dontTransfer', 'true')
  //   const payload = btoa(JSON.stringify(localStorage))
  //   window.location.href = `https://my.greekcapitalmanagement.com${location.pathname}?ls=${payload}`
  // }, [location.pathname])

  // return (
  //   <Button bsStyle="default" className="m-t-1 m-r-1" onClick={handleClick}>
  //     Don't transfer
  //   </Button>
  // )
}

export default connect(Next)
