import asyncThunk from 'utils/asyncThunk'

export type Request = {
  id: number
  query: string
  payment_method_id: number
}

export const acceptPaymentPlan = asyncThunk<Request, any>({
  name: 'payment-plans/accept',
  uri: ({ id }) => `/payment_plans/${id}/accept`,
  method: 'PUT',
})
