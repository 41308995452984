import moment, { Moment } from 'moment'

type DateRange = {
  start: Moment
  end: Moment
}

type NamedDateRange = DateRange & {
  name: string
}

export function readableDateRange(dateRange: DateRange, namedDateRanges: NamedDateRange[]) {
  const { start, end } = dateRange

  if (start.isSame('2017-06-01', 'day') && end.isSameOrAfter(moment(), 'day')) return 'All time'

  const named = namedDateRanges.find(namedDateRange => {
    const { start: namedStart, end: namedEnd } = namedDateRange
    return start.isSame(namedStart, 'day') && end.isSame(namedEnd, 'day')
  })
  if (named) return named.name

  const todayOrFormat = (date: Moment, format: string) => {
    const formatted = date.format(format)
    const nowFormatted = moment().format(format)
    return formatted === nowFormatted ? 'Today' : formatted
  }

  const nowYear = moment().year()
  const format = start.year() === nowYear && end.year() === nowYear ? 'MMM D' : 'MMM D YYYY'

  return `${start.format(format)} - ${todayOrFormat(end, format)}`
}
