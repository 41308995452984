import { iconForMimeType } from 'helpers'
import { FC } from 'react'

import { MimeType } from 'helpers/ext-helpers'

type Props = {
  file: {
    name: string
    preview: string
    s3_path: string
    type: MimeType
  } | null
}

const Preview: FC<Props> = ({ file }) => {
  if (!file) return <></>

  const { preview, type } = file

  if (type.match(/^image\//)) {
    return <img src={preview} alt="" className="upload-preview" />
  }

  const icon = iconForMimeType(type, file.name)

  return <img src={icon} alt="" className="upload-preview-icon m-b-1" />
}

export default Preview
