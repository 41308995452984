import { FC } from 'react'

import { ExpandableImage, FaButton, FaIcon } from 'components/utilities'
import { Document } from 'types/document'

type Props = Pick<Document, 'mimetype' | 'url'>

const ReceiptDetailButton: FC<Props> = doc => {
  if (_.isNull(doc)) {
    return <FaButton icon="search" disabled />
  }

  if (doc.mimetype === 'application/pdf') {
    return (
      <a target="_blank" href={doc.url} className="btn btn-sm btn-default" rel="noreferrer">
        <FaIcon file-text-o />
      </a>
    )
  }

  if (_.includes(['image/jpeg', 'image/png'], doc.mimetype)) {
    return (
      <ExpandableImage image={doc.url} className="btn btn-sm btn-default">
        <FaIcon image />
      </ExpandableImage>
    )
  }

  return (
    <a href={doc.url} className="btn btn-sm btn-default">
      <FaIcon download />
    </a>
  )
}

export default ReceiptDetailButton
