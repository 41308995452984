import asyncThunk from 'utils/asyncThunk'

export type Request = {
  query: string
  member_id?: number
  organization_id?: number
  archived?: boolean
  start_date?: string
  end_date?: string
}

export const listHourRequirements = asyncThunk<Request, any>({
  name: 'hour-requirements/list',
  uri: '/hour_requirements',
  method: 'GET',
})
