import { FC } from 'react'
import { Label, OverlayTrigger, Tooltip } from 'react-bootstrap'

import Date from 'components/utilities/date'

import { Reimbursement } from './type'

type Props = Pick<Reimbursement, 'status' | 'reason' | 'created_at' | 'approved_at' | 'denied_at'>

const Status: FC<Props> = ({ status, reason, created_at: createdAt, approved_at: approvedAt, denied_at: deniedAt }) => {
  if (status === 'pending') {
    return (
      <Label bsStyle="warning">
        Requested on <Date short date={createdAt} />
      </Label>
    )
  }

  if (status === 'proposed') {
    return (
      <Label bsStyle="info">
        Proposed on <Date short date={createdAt} />
      </Label>
    )
  }

  if (status === 'approved') {
    return (
      <Label bsStyle="success">
        Approved on <Date short date={approvedAt!} />
      </Label>
    )
  }

  if (status === 'accepted') {
    return (
      <Label bsStyle="success">
        Accepted on <Date short date={approvedAt!} />
      </Label>
    )
  }

  if (status === 'denied') {
    return (
      <span>
        <Label bsStyle="danger">
          Denied on <Date short date={deniedAt!} />
        </Label>
        &nbsp;
        <OverlayTrigger placement="bottom" overlay={<Tooltip id="tooltip">reason for denial: {reason}</Tooltip>}>
          <small className="text-muted" style={{ fontStyle: 'italic' }}>
            {' '}
            why?
          </small>
        </OverlayTrigger>
      </span>
    )
  }

  if (status === 'rejected') {
    return (
      <span>
        <Label bsStyle="danger">
          Rejected on <Date short date={deniedAt!} />
        </Label>
        &nbsp;
        <OverlayTrigger placement="bottom" overlay={<Tooltip id="tooltip">reason for rejection: {reason}</Tooltip>}>
          <small className="text-muted" style={{ fontStyle: 'italic' }}>
            {' '}
            why?
          </small>
        </OverlayTrigger>
      </span>
    )
  }

  return null
}

export default Status
