import cx from 'classnames'
import moment from 'moment'
import { FC, useCallback, useEffect, useState } from 'react'
import { Button, Col, FormGroup, Grid, Radio, Row } from 'react-bootstrap'
import { store } from 'store'

import * as Sentry from '@sentry/react'

import { fetchRequiredActions } from 'actions/required-actions'
import AddBankAccountButton from 'components/add-bank-account-button'
import { AddCreditCardModal } from 'components/modals'
import {
  Body, Content, Date, Loading, Money, PaymentMethod as PaymentMethodComponent, Portlet, Units,
} from 'components/utilities'
import { useAPI } from 'hooks/useAPI'
import useLegacyContext from 'hooks/useLegacyContext'
import useList from 'hooks/useList'
import { openModal } from 'hooks/useModal'
import { listPaymentMethods, Request } from 'thunks/payment-methods/list'
import { acceptPaymentPlan, Request as AcceptRequest } from 'thunks/payment-plans/accept'
import { rejectPaymentPlan, Request as RejectRequest } from 'thunks/payment-plans/reject'
import { PaymentMethod } from 'types/payment-method'
import notify, { notifyError } from 'utils/notify'

import { PaymentPlan } from './type'
// @ts-ignore
import { paymentPlanQuery } from './type?query'

type Props = {
  paymentPlan: Pick<PaymentPlan, 'id' | 'payment_plan_payments'>
  onPaymentPlanAccept: (paymentPlan: PaymentPlan) => void
  onPaymentPlanReject: (paymentPlan: PaymentPlan) => void
}

const ProposedPaymentPlan: FC<Props> = ({ paymentPlan, onPaymentPlanAccept, onPaymentPlanReject }) => {
  const { user } = useLegacyContext()
  const [selectedPaymentMethodId, setSelectedPaymentMethodId] = useState<number>()

  const [paymentMethods, { set: setPaymentMethods }] = useList<PaymentMethod, Request>(listPaymentMethods, {
    query: `
      payment_method {
        method
        company
        last_four
      }
    `,
    user_id: user.id,
  })

  useEffect(() => {
    if (!paymentMethods || paymentMethods.length === 0) return
    setSelectedPaymentMethodId(paymentMethods[0].id)
  }, [paymentMethods])

  const [accept, { timer: acceptTimer }] = useAPI<PaymentPlan, AcceptRequest>(acceptPaymentPlan)
  const handleAccept = useCallback(async () => {
    try {
      if (!selectedPaymentMethodId) return

      const [updated] = await accept({
        query: paymentPlanQuery,
        id: paymentPlan.id,
        payment_method_id: selectedPaymentMethodId,
      })

      notify("You've accepted the payment plan!")
      onPaymentPlanAccept(updated)
      store.dispatch(fetchRequiredActions())
    } catch (err) {
      Sentry.captureException(err)
      notifyError('Unable to accept this payment plan.')
    }
  }, [paymentPlan, selectedPaymentMethodId, accept, onPaymentPlanAccept])

  const [reject, { timer: rejectTimer }] = useAPI<PaymentPlan, RejectRequest>(rejectPaymentPlan)
  const handleReject = useCallback(async () => {
    try {
      const [updated] = await reject({
        query: paymentPlanQuery,
        id: paymentPlan.id,
      })

      notify({
        type: 'warning',
        message: "You've rejected the payment plan",
      })

      onPaymentPlanReject(updated)
      store.dispatch(fetchRequiredActions())
    } catch (err) {
      Sentry.captureException(err)
      notifyError('Unable to deny this payment plan.')
    }
  }, [paymentPlan, reject, onPaymentPlanReject])

  const sum = paymentPlan.payment_plan_payments.reduce((acc, ppp) => acc + ppp.amount, 0)
  const pastPaymentsCount = _.filter(paymentPlan.payment_plan_payments, ppp => moment(ppp.due_on).isBefore()).length

  if (!paymentMethods) return <Loading />

  return (
    <Content>
      <Grid>
        <Portlet boxed>
          <Body>
            <h5 className="m-b-1">You've been proposed this payment plan:</h5>

            <Row>
              <Col sm={12} lg={3}>
                <table id="schedule" className="table table-striped m-b-0">
                  <tfoot>
                    <tr>
                      <td className="text-right">Total:</td>
                      <td className="text-right">
                        <Money amount={sum} />
                      </td>
                    </tr>
                  </tfoot>
                  <tbody>
                    {paymentPlan.payment_plan_payments.map((ppp, i) => (
                      <tr key={i} className="m-b-1">
                        <td width="50%">
                          <Date short date={ppp.due_on} />
                        </td>
                        <td width="50%" className="text-right">
                          <Money amount={ppp.amount} />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </Col>
            </Row>
          </Body>
        </Portlet>

        <Portlet boxed>
          <Body>
            <h5>Payment method</h5>
            {pastPaymentsCount >= 1 && (
              <p>
                <Units noun="payment" count={pastPaymentsCount} /> that were due already will be charged upon accepting
                the payment plan
              </p>
            )}

            {paymentMethods.length === 0 ? (
              <div>
                <Button bsStyle="secondary" onClick={openModal('AddCreditCard')} className="m-r-1">
                  Add a credit card
                </Button>

                <AddBankAccountButton
                  onCreate={paymentMethod => {
                    setPaymentMethods([paymentMethod])
                    setSelectedPaymentMethodId(paymentMethod.id)
                  }}
                />
              </div>
            ) : (
              <FormGroup className="m-b-0">
                {paymentMethods.map((paymentMethod, i) => (
                  <div key={i}>
                    <Radio
                      name="payment_plan.payment_method_id"
                      value={paymentMethod.id}
                      checked={selectedPaymentMethodId === paymentMethod.id}
                      onChange={() => {
                        setSelectedPaymentMethodId(paymentMethod.id)
                      }}
                    >
                      <PaymentMethodComponent paymentMethod={paymentMethod} />
                    </Radio>
                  </div>
                ))}
              </FormGroup>
            )}
          </Body>
        </Portlet>

        <Button
          bsSize="lg"
          bsStyle="primary"
          disabled={paymentMethods.length === 0 || acceptTimer.isLoading}
          onClick={handleAccept}
          className={cx({
            'btn-loading': acceptTimer.isLoading,
          })}
        >
          Accept payment plan
        </Button>

        <Button
          bsSize="lg"
          disabled={rejectTimer.isLoading}
          onClick={handleReject}
          className={cx({
            'pull-right': true,
            'btn-loading': rejectTimer.isLoading,
          })}
        >
          Reject it
        </Button>

        <AddCreditCardModal
          onCreditCardCreate={paymentMethod => {
            setPaymentMethods([paymentMethod])
            setSelectedPaymentMethodId(paymentMethod.id)
          }}
        />
      </Grid>
    </Content>
  )
}

export default ProposedPaymentPlan
