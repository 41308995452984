import { FC } from 'react'
import { Modal } from 'react-bootstrap'
import { Member } from 'resources'

import { Form, RemoteSelect, SubmitButton } from 'components/form'
import modal from 'helpers/modal'
import { closeModal } from 'hooks/useModal'

type MemberType = {
  id: number
  user: {
    id: number
    first_name: string
    last_name: string
  }
}

type Props = {
  organizationId: number
  title: string
  action?: (opts: Request) => void
  onSuccess: (id: number) => void
}

const SelectMemberModal: FC<Props> = props => {
  const { title, organizationId, onSuccess } = props

  const mapListBy = {
    name: (_: never, { user }: MemberType) => `${user.last_name}, ${user.first_name}`,
  }

  const formProps = _.pick(props, ['method', 'action', 'onSuccess'])

  return (
    <Modal show onHide={closeModal}>
      {/* @ts-ignore */}
      <Form {...formProps}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <RemoteSelect
            // @ts-ignore
            resource={Member}
            scope={{ organization_id: organizationId }}
            query={`
              member {
                user {
                  first_name
                  last_name
                }
              }
            `}
            name="name"
            display="name"
            onChange={list => list?.length > 0 && onSuccess(list[0].id)}
            placeholder="Select a member&hellip;"
            mapListBy={mapListBy}
          />
        </Modal.Body>
        <Modal.Footer>
          <SubmitButton offset={5}>Select member</SubmitButton>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default modal<Props>('SelectMember', SelectMemberModal)
