import { Moment } from 'moment'

import { createAsyncThunk } from '@reduxjs/toolkit'

export type Request = {
  start: Moment
  end: Moment
}

export type Response = void

const setDateRange = createAsyncThunk<Response, Request>('date-range/set', async () => { })

export default setDateRange
