import { FC, ReactNode } from 'react'
import { Link } from 'react-router-dom'
import { Grid } from 'react-bootstrap'

import Search from './search'

type BreadcrumbItemProps = {
  link?: string
  children: string
  onClick?: () => void
}

const BreadcrumbItem: FC<BreadcrumbItemProps> = ({ link, onClick, children }) => {
  const key = _.kebabCase(children)

  if (link) {
    return (
      <li key={key}>
        <Link to={link}>{children}</Link>
      </li>
    )
  }

  if (onClick) {
    return (
      <li key={key}>
        <span className="clickable" onClick={onClick}>
          {children}
        </span>
      </li>
    )
  }

  return (
    <li key={key} className="active">
      {children}
    </li>
  )
}

type BreadcrumbHeaderProp = {
  search?: string
  actions?: ReactNode
  children: ReactNode
}

const BreadcrumbHeader: FC<BreadcrumbHeaderProp> = ({ search, actions, children }) => {
  return (
    <Grid className="page-header page-header-breadcrumbs">
      <ol className="breadcrumb breadcrumb-header">{children}</ol>

      <div className="page-actions hidden-print">
        {!search || <Search className="m-r-1 btn-group" />}
        {actions}
      </div>
    </Grid>
  )
}

export { BreadcrumbItem }

export default BreadcrumbHeader
