import asyncThunk from 'utils/asyncThunk'

export type Request = {
  query: string
  user_id: number
}

export const listMessageCategoriesByUser = asyncThunk<Request, any>({
  name: 'message-categories/list-by-user',
  uri: '/message_categories/list_by_user',
})
