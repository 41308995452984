import { FC, ReactNode } from 'react'

type Props = {
  children: ReactNode
}

const Header: FC<Props> = ({ children }) => {
  return <li className="sidebar-header">{children}</li>
}

export default Header
