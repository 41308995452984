import { connectRouter, routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import QueryString from 'qs'
import { useDispatch, useSelector } from 'react-redux'
import { combineReducers } from 'redux'
import thunk from 'redux-thunk'

import { configureStore, isPlain } from '@reduxjs/toolkit'
import * as Sentry from '@sentry/react'

import auth from './slices/auth'
import dateRange from './slices/dateRange'
import halfYearDateRange from './slices/halfYearDateRange'
import halfYears from './slices/halfYears'
import parent from './slices/parent'
import requiredActions from './slices/requiredActions'
import terms from './slices/terms'
import ui from './slices/ui'

const history = createBrowserHistory()

const rootReducer = combineReducers({
  router: connectRouter(history),
  auth: auth.reducer,
  dateRange: dateRange.reducer,
  halfYearDateRange: halfYearDateRange.reducer,
  halfYears: halfYears.reducer,
  parent: parent.reducer,
  requiredActions: requiredActions.reducer,
  terms: terms.reducer,
  ui: ui.reducer,
})

const serializableCheck = {
  isSerializable: (val: any): boolean => {
    if (isPlain(val)) return true
    return !!val._isAMomentObject
  },
  getEntries: (val: any) => (!!val._isAMomentObject ? [] : Object.entries(val)),
}

const sentryReduxEnhancer = Sentry.createReduxEnhancer({})

const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({ serializableCheck }).concat(routerMiddleware(history)).concat(thunk),
  devTools: { name: 'Greek Capital Management' },
  enhancers: [sentryReduxEnhancer],
})

const payload = QueryString.parse(window.location.search, { ignoreQueryPrefix: true }) as { ls?: string }
if (payload.ls) {
  try {
    const storage = JSON.parse(atob(payload.ls))
    localStorage.clear()
    for (const key in storage) {
      localStorage.setItem(key, storage[key])
    }
    window.history.replaceState({}, '', window.location.pathname)
    store.dispatch(auth.actions.setJWT(storage.token))
  } catch (err) {
    console.error('[XFER ERROR]', err)
  }
}

export { history, store }

export type AppStore = typeof store
export type AppState = ReturnType<typeof rootReducer>
export type AppDispatch = typeof store.dispatch

export function useAppDispatch(): AppDispatch {
  return useDispatch()
}

export function useAuthSelector() {
  return useSelector((state: AppState) => state.auth)
}

export function useDateRangeSelector() {
  return useSelector((state: AppState) => state.dateRange)
}

export function useHalfYearDateRangeSelector() {
  return useSelector((state: AppState) => state.halfYearDateRange)
}

export function useHalfYearsSelector() {
  return useSelector((state: AppState) => state.halfYears)
}

export function useParentSelector() {
  return useSelector((state: AppState) => state.parent)
}

export function useTermsSelector() {
  return useSelector((state: AppState) => state.terms)
}

export function useRequiredActionsSelector() {
  return useSelector((state: AppState) => state.requiredActions)
}
