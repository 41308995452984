import asyncThunk from 'utils/asyncThunk'

export type Request = {
  query: string
  id: number
}

// TODO Refactor
export function retrieveMember<T extends Record<string, any>>(req: Request) {
  return asyncThunk<Request, T>({
    name: 'members/retrieve',
    method: 'GET',
    uri: ({ id }) => `/members/${id}`,
  })(req)
}

export const retrieveMemberNew = asyncThunk<Request, any>({
  name: 'members/retrieve',
  method: 'GET',
  uri: ({ id }) => `/members/${id}`,
})
