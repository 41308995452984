export const requestQuery = `
  request {
    organization_id
  }
`
export const calendarEventQuery = `
  calendar_event {
    id
    title
    description
    all_day
    start
    end
    owner_type
    owner_id
    purpose
    link
  }
`
