import asyncThunk from 'utils/asyncThunk'

export type Request = {
  query: string
  pending?: boolean
  contribution_tracker_id?: number
  organization_id?: number
  federation_id: number
}
export const listContributions = asyncThunk<Request, any>({
  name: 'contributions/list',
  uri: '/contributions',
})
