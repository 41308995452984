import { APIResponse } from 'hooks/useAPI'
import asyncThunk from 'utils/asyncThunk'

export type Request = {
  query: string
  payment: {
    amount: number
    external_ref: string | undefined
    source: string
    member_id: number
  }
}

export const recordCheck = asyncThunk<Request, any>({
  name: 'payments/check',
  uri: '/payments/check',
  method: 'POST',
})
