import Quill from 'quill'
// @ts-ignore
import Delta from 'quill-delta'
import { FC, useCallback } from 'react'

import { UploadFileModal } from 'components/modals'
import { File } from 'components/uploader'
import { closeModal } from 'hooks/useModal'

import './s3-image-blot'

type Props = {
  getEditor: () => Quill
}

const QuillImageHandler: FC<Props> = ({ getEditor }) => {
  const handleUpload = useCallback(
    (file: File) => {
      const editor = getEditor()
      const { index } = editor.getSelection(true)

      const delta = new Delta().retain(index).insert({
        s3Image: file,
      })

      editor.updateContents(delta, 'user')
      editor.setSelection(index + 1, 0, 'silent')

      closeModal()
    },
    [getEditor]
  )

  return <UploadFileModal id="editor" onSuccess={handleUpload} />
}

export default QuillImageHandler
