import { FC } from 'react'

import americanExpress from 'images/credit-cards/american-express.svg'
import dinersclub from 'images/credit-cards/dinersclub.svg'
import discover from 'images/credit-cards/discover.svg'
import masterCard from 'images/credit-cards/master-card.svg'
import unknown from 'images/credit-cards/unknown.svg'
import visa from 'images/credit-cards/visa.svg'

const iconMap: { [key: string]: string } = {
  visa: visa,
  'master-card': masterCard,
  'american-express': americanExpress,
  discover: discover,
  dinersclub: dinersclub,
  unknown: unknown,
}

type Props = {
  icon: string
  size: number
}

const PaymentMethodIcon: FC<Props> = ({ icon, size }) => {
  let displayIcon = 'unknown'
  if (icon in iconMap) {
    displayIcon = icon
  }

  return <img src={iconMap[displayIcon]} width={size} height={size} alt="" className="credit-card-logo" />
}

export default PaymentMethodIcon
