import asyncThunk from 'utils/asyncThunk'

export type Request = {
  query: string
  parent: {
    member_id: number
    user: {
      first_name: string | undefined
      last_name: string | undefined
      email: string | undefined
      phone_number: string | undefined

      address?: {
        address: string
        address_2: string | undefined
        city: string
        state: string
        postal_code: string
        country: string
      }
    }
  }
}

export const createParent = asyncThunk<Request, any>({
  name: 'parents/create',
  uri: '/parents',
  method: 'POST',
})
