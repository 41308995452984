import { FC } from 'react'
// @ts-ignore
import T from 'react-thermometer'

import { Body, Portlet, Units } from 'components/utilities'

import { HourRequirement } from './type'

type Props = {
  hoursCompleted: number
  hourRequirement: HourRequirement
}

const Thermometer: FC<Props> = ({ hoursCompleted, hourRequirement }) => {
  return (
    <Portlet boxed>
      <Body className="thermometer">
        <h5 className="text-center m-b-2">
          {hoursCompleted.toFixed(0)} of <Units count={hourRequirement.hours_required} noun="hour" /> completed
        </h5>

        <T
          min={0}
          max={1}
          current={
            (Math.min(hoursCompleted, hourRequirement.hours_required) / hourRequirement.hours_required) * 0.8 + 0.2
          }
          width={30}
          height={300}
          backgroundColor="#f4f4f4"
          fillColor="#D74B4B"
        />
      </Body>
    </Portlet>
  )
}

export default Thermometer
