import '-lodash'
import { ConnectedRouter } from 'connected-react-router'
import mixpanel from 'mixpanel-browser'
import { Button, Label, ProgressBar } from 'react-bootstrap'
import ReactGA from 'react-ga4'
import { Provider } from 'react-redux'
import { history, store } from 'store'

import { addStyle } from 'react-bootstrap/lib/utils/bootstrapUtils'
import ReactDOM from 'react-dom/client'
import 'styles/app.less'

import App from './App'
import './env'
import './sentry'

mixpanel.init(import.meta.env.VITE_MIXPANEL_TOKEN, {
  // debug: _env_.GCM_ENV === 'development',
})

if (import.meta.env.VITE_GCM_ENVIRONMENT === 'development') {
  mixpanel.disable()
}

const _orig = console.error
console.error = (...params: Parameters<typeof console.error>) => {
  if (typeof params[0] === 'string' && params[0].includes('unstable_renderSubtreeIntoContainer')) {
    return
  }
  _orig(...params)
}

console.log('===', import.meta.env.VITE_GCM_ENVIRONMENT, '===')

if (import.meta.env.VITE_GCM_ENVIRONMENT === 'production') {
  ReactGA.initialize('G-F84V2KZ0NY')
}

addStyle(Button, 'secondary')
addStyle(ProgressBar, 'secondary')
addStyle(Label, 'inverted')

const root = ReactDOM.createRoot(document.getElementById('root')!)

root.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
  </Provider>
)
