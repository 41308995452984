import moment from 'moment'
import { ReactElement, ReactNode } from 'react'
import reactToText from 'react-to-text'

import download from 'utils/download-csv'

import { BodyProps, CellProps, ColumnProps, FirstRowProps, HeaderProps } from './'

export default function downloadCSV<Obj>(
  title: string,
  metadata: [ReactElement<HeaderProps>, ReactElement<FirstRowProps> | undefined, ReactElement<BodyProps<Obj>>],
  data: Obj[]
) {
  const headers = metadata[0].props.children
    .flat()
    .filter(c => !!c)
    .map(c => c as ReactElement<ColumnProps>)
    .map(({ props }) => reactToText(props.children))
  const content = [headers]

  if (metadata[1]) {
    const tds = metadata[1].props.children.props.children as ReactElement<{
      'data-value'?: string
      children: ReactNode
    }>[]
    const firstRow = tds
      .filter(c => !!c?.props)
      .map(({ props }) => (props && (props['data-value'] as string)) ?? reactToText(props.children))
    content.push(firstRow)
  }

  data.forEach(obj => {
    const row = metadata[2].props
      .children(obj)
      .props.children.flat()
      .filter((_, idx) => {
        const child = metadata[0].props.children.flat()[idx]
        return child !== false && (child.type as any).name !== 'Actions'
      })
      .map(c => c as ReactElement<CellProps>)
      .map(({ props }) => ('value' in props ? `${props.value}` : reactToText(props.children)))
    content.push(row)
  })

  const date = moment().format('YYYY-MM-DD')
  const filename = `${title}.${date}.csv`
  download(filename, content)
}
