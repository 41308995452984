import { apiRequest } from 'actions/use'

import apiWrapper, { generatePayload, Request } from './helpers'

export default class PaymentMethod extends apiWrapper('/payment_methods') {
  static createBankAccount({ data, query, onSuccess, onFailure }: Request) {
    apiRequest({
      method: 'POST',
      uri: '/api/payment_methods/create_bank_account',
      data: generatePayload({ data, query }),
      on: {
        201: onSuccess,
      },
      onFailure,
    })
  }

  static authorization({ onSuccess }: Request) {
    apiRequest({
      method: 'POST',
      uri: '/api/braintree/authorization',
      on: {
        201: onSuccess,
      },
    })
  }

  static bankLookup({ routingNumber, onSuccess }: { routingNumber: string; onSuccess: () => void }) {
    apiRequest({
      method: 'GET',
      uri: '/api/payment_methods/bank_lookup',
      data: {
        routing_number: routingNumber,
      },
      on: {
        200: onSuccess,
      },
    })
  }
}
