import { toast, ToastOptions } from 'react-hot-toast'

export type Notification = {
  type?: 'success' | 'error' | 'warning' | 'info'
  message: string
}

const opts: ToastOptions = {
  position: 'top-right',
}

export default function notify(notification: Notification | string) {
  if (typeof notification === 'string') {
    toast.success(notification, opts)
    return
  }

  switch (notification.type) {
    case 'success':
    case undefined:
      toast.success(notification.message, opts)
      break
    case 'error':
      toast.error(notification.message, opts)
      break
    case 'warning':
      toast(notification.message, {
        ...opts,
        icon: '⚠️',
      })
      break
    case 'info':
      toast(notification.message, opts)
      break
    default:
      toast.error(`!!!! ${notification.type} !!!! ${notification.message}`, opts)
  }
}

export function notifyError(message: string) {
  notify({ type: 'error', message })
}
