import { createContext } from 'react'

import { BootstrappedUser } from 'thunks/auth/bootstrap'
import { MemberUser, Organization, Role } from 'types/user'

export type LegacyContextType<T extends Role = Role> = {
  user: BootstrappedUser<T>
  organization?: Organization
  member?: MemberUser['member']
  owner?: {
    owner_type: 'Organization' | 'Federation'
    owner_id: number
  }
}

const LegacyContext = createContext<LegacyContextType>({} as LegacyContextType)

export default LegacyContext
