import { FC, useMemo, useState } from 'react'
import { Button } from 'react-bootstrap'
import ScheduleOptions from 'components/payment-plans/schedule-options'
import { ProposedPayment } from 'components/payment-plans/common'

type Props = {
  balance: number
  onScheduleSelection: (payments: ProposedPayment[]) => void
}

const Schedule: FC<Props> = ({ balance, onScheduleSelection }) => {
  const [payments, setPayments] = useState<ProposedPayment[]>([])

  const paymentSum = useMemo(() => {
    const sum = payments.reduce((sum, payment) => sum + payment.amount, 0)
    return sum.toFixed(2) === balance.toFixed(2)
  }, [balance, payments])

  return (
    <div>
      <p className="fs-125">
        Want to get on a payment plan? You can propose one, and once approved, a payment will be made automatically
        according to your schedule.
      </p>

      <hr className="spacer-sm" />

      <ScheduleOptions balance={balance} onScheduleChange={setPayments} />

      <hr />
      <div>
        <Button
          bsStyle="secondary"
          className="pull-right"
          onClick={() => onScheduleSelection(payments)}
          disabled={!paymentSum}
        >
          Next &raquo;
        </Button>
        <Button disabled>Previous</Button>
      </div>
    </div>
  )
}

export default Schedule
