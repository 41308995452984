import moment, { Moment } from 'moment'
import React from 'react'
import { Button, Modal } from 'react-bootstrap'

import Calendar from 'components/date-range/Calendar'
import modal, { ModalProps } from 'helpers/modal'
import { closeModal } from 'hooks/useModal'

type OwnProps = {
  id?: number | string
  date?: Moment
  title?: string
  minDate?: Moment
  maxDate?: Moment
  hideClearDateButton?: boolean
  onDateSelected: (date: Moment | undefined) => void
}

type Props = ModalProps<OwnProps>

type State = {
  date: Moment | undefined
}

class DatePickerModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      date: props.date,
    }
  }

  UNSAFE_componentWillReceiveProps = (nextProps: Props) => {
    if (!this.props.show && nextProps.show) {
      this.setState({ date: nextProps.date })
    }
  }

  onChange = (date: Moment) => this.setState({ date })

  doSetDate = () => {
    const { onDateSelected } = this.props
    onDateSelected(this.state.date)
    closeModal()
  }

  doClearDate = () => {
    this.setState(
      {
        date: undefined,
      },
      this.doSetDate
    )
  }

  render() {
    const { title, minDate, maxDate, hideClearDateButton } = this.props
    const { date } = this.state

    return (
      <Modal show onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>{title || 'Select date'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* @ts-ignore This tag has been deprecated in HTML 4 */}
          <center>
            <Calendar
              date={date || moment().utcOffset(-4).add(1, 'day')}
              minDate={minDate}
              maxDate={maxDate}
              onChange={this.onChange}
            />
            {/* @ts-ignore */}
          </center>
        </Modal.Body>
        <Modal.Footer>
          {hideClearDateButton ? null : (
            <Button className="pull-left" onClick={this.doClearDate}>
              Clear date
            </Button>
          )}
          <Button bsStyle="primary" onClick={this.doSetDate}>
            Set date
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default modal<OwnProps>('DatePicker', DatePickerModal)
