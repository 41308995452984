import { FC } from 'react'
import r from 'routes'

import useMorph from 'hooks/useMorph'

import Header from './header'
import Item from './item'

const SalespersonMenu: FC = () => {
  const morph = useMorph()

  return (
    <ul className="sidebar-menu">
      <Header>Super</Header>
      <Item url={r.super.organizations.root} icon="list">
        Chapters
      </Item>
      <Header>Demo</Header>
      <Item onClick={() => morph(25)} icon="user-secret">
        Member
      </Item>
      <Item onClick={() => morph(47)} icon="user-secret">
        Admin
      </Item>
    </ul>
  )
}

export default SalespersonMenu
