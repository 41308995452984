import asyncThunk from 'utils/asyncThunk'

export type Request = {
  poll_id: number
}

export type Voter = {
  id: number
  first_name: string
  last_name: string
  data: {
    has_voted: boolean
  }
  poll_option_ids: number[] | undefined
}

export type Headers = {
  'x-votes': string
}

export const listPollVotes = asyncThunk<Request, Voter[], Headers>({
  name: 'poll-votes/list',
  uri: '/poll_votes',
  method: 'GET',
})
